import { useContext } from "react";
import { db } from "../../db";
import { styled } from "../../component";
import { ValueType } from "../ValueType";
import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { SystemContext } from "../../etc/SystemContext";
import { $EntityDatabase } from "./$EntityDatabase";
import { $EntityQueryView } from "./$EntityQueryView";

export const $Sidebar = typeRegistry.registerType({
  $: 'af4abb3c-6d0e-50ba-8f5b-3cb2817a20a5',
  Content: 'd59dd51c-3301-590e-ac4a-c98535cbd91a',
}, ids => ({
  _id: ids.$,
  type: 'Sidebar',
  name: 'Sidebar',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [ValueType.Array, []],
      property: 'content',
    },
  ],
}));

const Wrapper = styled.div`
  padding: 0 8px;
  color: rgb(155, 155, 155);
  font-weight: 600;
  > div {
    padding: 2px 10px 2px 5px;
    &:hover {
      background: rgba(255, 255, 255, 0.055);

    }
    border-radius: 3px;
  }
`;

structRenderers[$Sidebar.$] = (value) => {
  const mapped = mapStructure(value);
  const context = useContext(SystemContext);

  return (
    <Wrapper data-value-point={value._id}>
      {mapped.content.content.map(x => {
        let c;
        let nav;
        if (x.type[0] == ValueType.Entity) {
          const entity = db.entities.findById(x.content);
          c = entity.name;
          nav = {
            type: 'entity',
            id: x.content,
          }
        }
        else if (x.type[1] == $EntityDatabase.$) {
          const mapped = mapStructure(x);
          c = mapped.name.content;
          nav = {
            type: 'glue',
            id: x._id,
          }
        }
        else if (x.type[1] == $EntityQueryView.$) {
          const mapped = mapStructure(x);
          c = mapped.name.content;
          nav = {
            type: 'glue',
            id: x._id,
          }
        }
        return (
          <div key={x._id}
            data-value-point={x._id}
            onClick={() => {
              context.navigate(nav)
            }}
          >
            {c}
          </div>
        )
      })}
    </Wrapper>
  )
}