import React from "react";
import { x } from "../XObject";
import classNames from "classnames";
import { Svg } from "../components/Svg";
import { expandToHtml } from "../components/richTextHelpers";
import { Wrapper } from "./Wrapper";
import { Block } from "../components/notionDocument/BlockManager";
import { CheckBox } from "../components/notionDocument/CheckBox";
import { MyBlock } from "./MyBlockManager";


export function renderBlock(block: MyBlock, {
  onMouseDownGrip, draggingId, beforeChange, changed,
  ctx,
  blockTypes, activeBlock, db: _db, onClickEntityDoc, noChildren = false, docId, onClickAddBlock, onContextMenu,
}) {
  throw new Error("Not implemented");
  const blockType = null; // blockTypes.find(b => b._id == block.type);
  let checkbox = blockType?.elements?.find?.(e => e.type == 'checkbox');

  const hasCheckbox = block.block.type == '89f7170f-3596-517d-bbba-319689893e94';

  /*if (block.id) {
    const entity = db.entities.findById(block.id);
    if (entity.type) {
      const type = db.entityTypes.findById(entity.type);
      const blockView = type.views?.find?.(v => v.viewType == EntityViewType.block);
      if (blockView) {
        if (blockView.valuePoint) {
          const mapped = mapStructure(execute(blockView.valuePoint));
          const a = mapped.elements?.content?.find?.(e => e.type?.[1] == $DocumentCheckbox.$);
 
          if (a) {
            const mappedA = mapStructure(a);
            
            checkbox = {
              binding: {
                get: (entityId) => {
                  return resolveEntityBinding(entityId, mappedA.binding, {}).get();
                },
    
                set:  (entityId, value) => {
                  return resolveEntityBinding(entityId, mappedA.binding, {}).set(value);
                }
              }
            }
          }
        }
      }
    }
  }*/
  const checked = block.block.checked; // checkbox ? checkbox.binding.get(block.id) : block.type == 'checkItem' && block.checked;
  return (
    <Wrapper
      key={block.getId()}
      // data-value-point={blockType?._id}
      // data-block-type={blockType?._id}
      // data-entity={block.id}
      // data-record={block.record}
      data-type="blockCont"

      className={classNames(null /* block.type */, {
        dragging: block.getId() == draggingId,
        hasCheckbox,
        checked: checked,
      })}

    >
      <div
        // data-ui-binding={block.id && JSON.stringify({
        //   viewType: EntityViewType.block,
        //   entity: block.id,
        //   context: {
        //     type: ObjectType.page,
        //   }
        // })}
        data-block-id={block.getId()}
        // data-inspect-id={block.id}
        className={classNames('block', { activeBlock: block.getId() == activeBlock })}
      >

        <Svg name="plus" className="addBlock"
          onClick={e => {
            onClickAddBlock(e, block);
          }} />
        <Svg name="grip" className="grip"
          onMouseDown={e => {
            onMouseDownGrip(e, block);
          }}
          onContextMenu={e => {
            e.preventDefault();
            onContextMenu(e, block);
          }} />

        {/* {block.type == 'code' && (
              <>
                <div contentEditable={false} className="codeBlock">
                  <Code block={block} />
                </div>
              </>
            )}*/}

        {/* block.type != 'code' &&  */(
          <>
            {/* {block.id && (
                      <span
                        draggable
                        onDragStart={(e) => {
                          e.dataTransfer.setData('text/plain', block.id);
                        }}
                        className="entityDot"  contentEditable={false}
                      
                        onClick={() => {
                          onClickEntityDoc(block.id);
                        }}
                      />
                    )}
                    {block.dataBinding && (
                      <span
                        className="entityDot dataBindingDot" draggable contentEditable={false}
                      
                        onClick={() => {
                          onClickEntityDoc(block.id);
                        }}
                      />
                    )} */}
            {(hasCheckbox) && <CheckBox
              get={() => checked}
              set={(value) => block.block.checked = value}
              _onBeforeChange={beforeChange}
              _onChange={changed}
            />}
            <div className="editor" dangerouslySetInnerHTML={{ __html: expandToHtml(ctx, x(block.getContent()) || []) }} data-type="blockData" />
            {/* {block.record && (
                      <>
                        <div className="meta" style={{display:'none'}} contentEditable={false}>
                          <RecordBadges
                            db={_db}
                            recordId={block.record}
                          />
                        </div>
                      </>
                    )}
                    {block.id && (
                      <div className="meta" style={{display:'none'}} contentEditable={false}>
                        <EntityBadges block={block} entity={db.entities.findById(block.id)} blockType={blockType} />
                      </div>
                    )}
                    {block.id && (
                      <div className="metaLine" contentEditable={false}>
                        <EntityPath entity={block.id} />
                      </div>
                    )} */}
          </>
        )}


      </div>
      {!noChildren && <div className="children">
        {block.hasChildren() && block.getChildren().map(b => renderBlock(b, {
          onMouseDownGrip,
          draggingId,
          ctx,
          changed,
          blockTypes,
          db: _db,
          activeBlock,
          beforeChange,
          onClickEntityDoc,
          docId,
          onClickAddBlock,
          onContextMenu,
        }))}
      </div>}
    </Wrapper>
  );
}
