let mobileComp;

export function setMobileComp(comp) {
  mobileComp = comp;
}

export function presentMobileModal(e, title?) {
  if (mobileComp) {
    mobileComp.presentModal(e, title);
  }
}

export async function closeMobileModal() {
  if (mobileComp) {
    await mobileComp.closeModal();
  }
}

export async function presentActionSheet(options) {
  if (mobileComp) {
    console.log('presentActionSheet', options);
    await mobileComp.presentActionSheet(options);
  }
}
