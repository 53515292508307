import { db } from '../db';
import { TEntityMatch, TEntityMatch2 } from "../types/TEntityMatch";
import { defaultWorkspace } from './defaultWorkspace';
import { compareTypes } from './compareTypes';

function entityMatch(id: string, match: TEntityMatch) {
  const entity = db.entities.findById(id);
  if (!entity) {
    return false;
  }

  if (match.type) {
    if (!compareTypes(entity.type, match.type)) {
      return false;
    }
  }

  if (match.states) {
    for (const state of match.states) {
      if (!entity.states?.[state.type] || !state.values?.includes?.(entity.states[state.type])) {
        return false;
      }
    }
  }

  return true;
}

export function entityMatch2(id: string, match: TEntityMatch2) {
  for (const e of match.entries) {
    let r = (() => {

      if (defaultWorkspace().metaStates.find(x => x._id == e.entry)) {
        const metaStates = entityMetaStates(id);

        if (metaStates.includes(e.entry)) {
          return true;
        }
      }

      if (db.entityTypes.findById(e.entry)) {
        if (compareTypes(db.entities.findById(id).type, e.entry)) {
          return true;
        }
      }
    })();

    if (e.not) {
      r = !r;
    }

    if (!r) {
      return false;
    }
  }

  return true;
}

export function entityMetaStates(id): string[] {
  return [];
  const r = [];
  for (const metaState of defaultWorkspace().metaStates) {
    if (metaState.matches) for (const match of metaState.matches) {
      if (entityMatch(id, match)) {
        r.push(metaState._id);
        break;
      }
    }
  }

  return r;
}
