import { useContext } from "react";
import { EntityDisplay } from "../../components/EntityDisplay";
import { EntityPath } from "../../components/EntityPath";
import { appState } from "../../etc/appState";
import { db } from "../../db";
import { resolvePath } from "../../etc/queryGraph";
import { styled } from "../../component2";
import { ValueType } from "../ValueType";
import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { doEntityQuery } from "./$EntityQuery";
import cx from 'classnames';
import { SystemContext } from "../../etc/SystemContext";
import { $AllEntitiesMatch, executeAllEntitiesMatch } from "./$AllEntitiesMatch";

const $EntityName = typeRegistry.registerType({
  $: '3454e879-7e6f-5db3-9a65-27e2c027c320',
  RootEntity: 'bfbda0ae-3fbf-5ae4-9e5c-df3b55172e0c',
  Graph: '6fc962d2-0245-55b1-b82d-0c13f13bd8f3',
}, ids => ({
  _id: ids.$,
  name: '[Linear List Element] Entity Name',
  definition: [
    {
      id: ids.RootEntity,
      name: 'Root Entity',
      type: [],
      property: 'rootEntity',
    },
    {
      id: ids.Graph,
      name: 'Graph',
      type: [],
      property: 'graph',
    },
  ]
}));

const $EntityType = typeRegistry.registerType({
  $: '838e87aa-2aeb-5fea-9fc6-0795c701e686',
}, ids => ({
  _id: ids.$,
  name: '[Linear List Element] Entity Type',
  definition: [],
}));

const $Spacer = typeRegistry.registerType({
  $: 'e1b0b5a1-1c5f-5b1f-9b1e-5e1b0b5a1c5f',
}, ids => ({
  _id: ids.$,
  name: '[Linear List Element] Spacer',
  definition: [],
}));

const $Attribute = typeRegistry.registerType({
  $: 'ae4495bb-4efb-5596-8e1c-ce9784851a23',
  Attribute: '7a16c316-4254-5c43-b2eb-367d60f5457a',
}, ids => ({
  _id: ids.$,
  name: '[Linear List Element] Attribute',
  definition: [
    {
      id: ids.Attribute,
      name: 'Attribute',
      type: [],
      property: 'attribute',
    },
  ],
}));



const $LinearList = typeRegistry.registerType({
  $: '7ad26c27-d5c1-54bf-80f1-89060c0efabc',
  Query: 'dcfa7637-f8a8-52a2-a61b-8d699fd8ed55',
  Elements: '67c2ed7f-7ccf-5175-8cba-bae95ff5db89',
  Entity: 'fb94ca0c-0dc1-525c-88b4-5d9c874c814b',
  Matches: {
    $: 'a837b17f-187d-5864-8b7a-40b376f1298c',
    $$: '4c082e76-48fd-5885-bd38-787ac7ec8be0',
    Match: '9eb250a8-a89b-56ae-84ad-1f080127359d',
    Elements: '715e24c4-12d9-5dbb-a985-90826c9cf9a5',
  },
}, ids => ({
  _id: ids.$,
  name: 'Linear List',
  definition: [
    {
      id: ids.Query,
      name: 'Query',
      type: [],
      property: 'query',
    },

    {
      id: ids.Elements,
      name: 'Elements',
      type: [ValueType.Array, []],
      property: 'elements',
      scope: [
        {
          id: ids.Entity,
          name: '[Linear List] %Entity',
          type: [],
        }
      ],

    },

    {
      id: ids.Matches.$,
      name: 'Matches',
      type: [ValueType.Array, [ValueType.Structure, ids.Matches.$$, [
        {
          id: ids.Matches.Match,
          name: 'Match',
          type: [],
          property: 'match',
        },
        {
          id: ids.Matches.Elements,
          name: 'Elements',
          type: [ValueType.Array, []],
          property: 'elements',
        },
      ]]],
      property: 'matches',
    }
  ],
}));

const Wrapper = styled.div`
  .row {
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0 16px;

    &:hover {
      background: #eee;
    }

    .col {
      &:not(:last-child) {
        margin-right: 9px;
      }
      &._${$Spacer.$} {
        margin-left: auto;
      }

      &._${$EntityName.$} {
        display: flex;
        align-items: center;
        ${EntityPath}, .path {
          color: #9d9d9d;
          margin-left: 4px;
          font-size: 8px;

          .component {
            margin-right: 4px;
            &:before {
              content: "›";
              margin-right: 4px;
            }
          }
        }
      }

      &._${$EntityType.$} {
        height: 20px;
        border-radius: 3px;
        padding-left: 6px;
        padding-right: 0px;
        font-size: 14px;
        line-height: 120%;
        color: rgb(50, 48, 44);
        background: rgb(250, 222, 201);
        display: inline-flex;
        font-size: 14px;
        align-items: center;
        padding-right: 6px;

      }
    }
  }
`;

structRenderers[$LinearList.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  const entities = doEntityQuery(mapped.query, map);
  const systemContext = useContext(SystemContext);

  return (
    <Wrapper data-value-point={value._id}>
      {entities.map(e => {
        const entity = db.entities.findById(e);
        return (
          <div className="row" key={e} data-inspect-id={e}
            onClick={() => {
              systemContext?.navigate?.({
                type: 'entity',
                id: e,
              })
              // appState.inspecting = {
              //   type: 'entity',
              //   id: e,
              // }
            }}
          >
            {mapped.elements.content.map((element, i) => {
              let c;
              const mappedElement = mapStructure(element);
              if (element.type[1] == $EntityName.$) {
                c = (
                <>
                  <span
                    onClick={() => {
                      systemContext?.navigate?.({
                        type: 'entity',
                        id: e,
                      })
                      appState.inspecting = {
                        type: 'entity',
                        id: e,
                      }
                    }}
                  >{entity.name}</span>
                  {/* <EntityDisplay id={e} graph={mappedElement.graph?.content} root={mappedElement.rootEntity?.content} /> */}
                  {mappedElement.graph?.content && mappedElement.rootEntity?.content && (() => {
                    const path = resolvePath(mappedElement.graph?.content, mappedElement.rootEntity?.content, entity._id);
                    return (
                      <span className="path">
                        {path.slice(1).map((p, i) => {
                          return <span className="component">{db.entities.findById(p).name}</span>
                        })}
                      </span>
                    )
                  })()}
                </>
                )
              }
              else if (element.type[1] == $Spacer.$) {
                c = <div />
              }
              else if (element.type[1] == $Attribute.$) {
                const entity = db.entities.findById(e);
                c = entity.attributes?.[mappedElement.attribute.content];
              }
              else if (element.type[1] == $EntityType.$) {
                const entity = db.entities.findById(e);
                c = entity.type && db.entityTypes.findById(entity.type).name;
              }
              else if (element.type[1] == $AllEntitiesMatch.$) {
                const pass = executeAllEntitiesMatch(element, {
                  ...map,
                  [$LinearList.Entity]: e,
                });
                c = pass ? '✓' : '✗';
              }

              return (
                <div key={element._id} className={cx("col",  '_' + element.type[1])} data-value-point={element._id}>
                  {c}
                </div>
              );
            })}

          </div>
        )
      })}
    </Wrapper>
  );
}