import React from 'react';
import { XObject } from '../../XObject';
import { db } from '../../db';
import { BlockList, _Block, _BlockManager } from '../../components/BlockEditor';
import { NotionDocumentDeprecated } from '../../components/NotionDocumentDeprecated';
import { ValueType } from './../ValueType';
import { structRenderers } from '../structRenderers';
import { _children, mapStructure } from '../main';
import { typeRegistry } from "../typeRegistry.1";
import { deleteEdgeByIndex } from '../../etc/getEdgesForEntity';

const $NotionDocument = typeRegistry.registerType({
  $: '91eb6846-2106-5e34-87ba-e30f9dca59cf',
  RootEntity: 'ad815924-4283-5033-bd48-276873d5bb05',
  Graph: '0f18c85f-72eb-5585-b3d6-16f276c6a781',
}, ids => ({
  _id: ids.$,
  name: 'Notion Document',
  definition: [
    {
      id: ids.RootEntity,
      name: 'Root Entity',
      type: [ValueType.Entity],
      property: 'rootEntity',
    },
    {
      id: ids.Graph,
      name: 'Graph',
      type: [ValueType.Graph],
      property: 'graph',
    },
  ]
}));


export class BlockWrapper extends _Block {
  constructor(public entityId, public parent, public parentEntityId, public state) {
    super();
  }

  get id() {
    return this.entityId;
    // return this.dataInterface.id(this.data);
    // return this.data._id;
  }

  get version() {
    return 0;
    // return this.dataInterface.version?.(this.data) || 0;
  }

  set version(value) {
  }

  children;

  get content() {
    return db.entities.findById(this.entityId).name;
  }
  set content(value) {
    // this.data.content = value;
    db.entities.findById(this.entityId).name = value;
  }

  hasChildren() {
    return !!this.children.length;
  }

  get collapsed() {
    return XObject.get(this.state, 'collapsed', {})[this.id];
    // return this.state.collapsed[this.id];
  }

  set collapsed(value) {
    this.state.collapsed[this.id] = value;
  }
}


export class BlockManager extends _BlockManager {
  _indexOf(list: BlockList, block: BlockWrapper) {
    return list.indexOf(block);
  }

  _splice(list: BlockList, index, deleteCount, ...items) {
    return list.splice(index, deleteCount, ...items);
  }

  _length(list: BlockList) {
    return list.length;
  }
}

export class ListWrapper implements BlockList<BlockWrapper> {
  constructor(private a: BlockWrapper[], private parentEntity) { }

  get(i) {
    return this.a[i];
  }

  indexOf(el: _Block) {
    return this.a.findIndex(a => a.id == el.id);
  }

  get length() {
    return this.a.length;
  }

  map(func) {
    return this.a.map(func);
  }

  splice(index, delCount, ...items: BlockWrapper[]) {
    console.log('splice', index, delCount, items);
    if (delCount) {
      if (items.length) {
        throw new Error();
      }
      if (delCount == 1) {
        const block = this.a[index];
        const edge = db.edges.findIndex(e => e.entities[1] == (block.entityId) && e.entities[0] == (this.parentEntity) && e.directed);
        if (edge == -1) {
          throw new Error();
        }
        else {
          deleteEdgeByIndex(edge);
        }

        // throw new Error();
      }
      else {
        throw new Error();
      }
    }
    else if (items.length == 1) {
      if (_children[this.parentEntity]) {
        _children[this.parentEntity].splice(index, 0, items[0].entityId);
      }

      const edge = XObject.obj({
        entities: [this.parentEntity, items[0].entityId],
        directed: true,
      });
      db.edges.push(edge);
    }
    else if (items.length > 1) {
      throw new Error();

    }
    // this.b.splice(index, delCount, ...items.map(i => i.data));
  }
}

structRenderers[$NotionDocument.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  return <NotionDocumentDeprecated
    state={XObject.get(state, 'notionDocument', {})}
    rootEntity={mapped.rootEntity.content} />;
};
