import { db } from '../db';

// function for comparing entity entity. types can be subtypes of others (type.parent)
// header: compareTypes(entityTypeId: string, compTypeId: string): boolean

const cache = {};

export function compareTypes(entityTypeId: string, compTypeId: string) {
  const key = `${entityTypeId} ${compTypeId}`;
  if (key in cache) {
    return cache[key];
  }

  return cache[key] = (() => {
    if (!entityTypeId) return false;
    while (true) {
      if (entityTypeId == compTypeId) {
        return true;
      }
  
      const type = db.entityTypes.findById(entityTypeId);
      entityTypeId = type?.parent;
      if (!entityTypeId) {
        return false;
      }
    }
  
  })();
}
