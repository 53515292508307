import { db } from "../../db";
import { getGraphParent, getPathInGraph } from "../../etc/queryGraph";
import { evaluate, mapStructure } from "../main";
import { structEvalutors, structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";

const $EntityParent = typeRegistry.registerType({
  $: '8a264c97-d33d-5bb9-9b9c-089dee112b92',
  Entity: 'ec7b3179-68f9-51fb-909a-9a60afe0c208',
}, ids => ({
  _id: ids.$,
  name: 'Entity Parent',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    }
  ]
}));

structEvalutors[$EntityParent.$] = (value, map) => {
  const mapped = mapStructure(value);
  const entity = evaluate(mapped.entity, map);
  return getGraphParent(null, entity);
}

structRenderers[$EntityParent.$] = (value, map, state, renderType) => {
  const mapped = mapStructure(value);
  const entity = evaluate(mapped.entity, map);
  const parent = getGraphParent(null, entity);
  return (
    <>
      {/* Test */}
      {db.entities.findById(parent).name}
    </>
  );
}