import React, { useContext } from 'react';
import cx from 'classnames';
import { component } from '../component2';
import { DraftSelect } from '../etc/draftHelpers';
import { X, x } from '../XObject';
import {  PaneContext, getValuePoint, getScopeForValuePoint, onValuePointClick, isBlock, structuresMap, enumMap, ReferenceType, matchType, getParameter, getStructureParameter, modeKeys } from './main';
import { trackChange, ObjectType, UndoActions, registerChangeAction } from "./changes";
import { typeRegistry } from "./typeRegistry.1";
import { valuePointComps } from "./valuePointComps";
import { ValueType } from "./ValueType";
import { ValuePointCont } from "./ValuePointCont";
import { memoryAppState } from '../etc/appState';

const Comp = React.Component;

const SetValuePointType = 'a9f5c7ac-02e8-5091-9583-6b03c4370465';
const SetValuePointTypeMode = 'fca934a1-b1cc-5e3d-9934-ccfd399139fc';

registerChangeAction(SetValuePointType, {
  name: 'Set Value Point Type',
})

registerChangeAction(SetValuePointTypeMode, {
  name: 'Set Value Point Type Mode',
})


const valuePointTicks = X({});

export function tickValuePoint(id) {
  if (!valuePointTicks[id]) {
    valuePointTicks[id] = 1;
  }
  else {
    valuePointTicks[id] = valuePointTicks[id] + 1;
  }
  console.log('tick', id, valuePointTicks[id]);
}

@component
export class ValuePoint extends Comp<{ id; path? }> {
  static styles = ValuePointCont;
  render(Container?) {
    const paneContext = useContext(PaneContext);
    const valueCont = getValuePoint(this.props.id);
    const possibleTypes = x(valueCont.possibleTypes || []);
    const scope = getScopeForValuePoint(this.props.id);

    const onClick = () => {
      onValuePointClick(this.props.id, paneContext.selectValuePoint);
    };
    const elId = this.props.id + valueCont.type?.[0] + valueCont.type?.[1] + (this.props.path ? this.props.path.join('.') : '');

    if (valueCont.type) {
      const Comp = valuePointComps[valueCont.type[0]];
      return (
        <Container
          data-el-id={elId}
          onMouseOver={() => {
            memoryAppState.hoverValuePoint = this.props.id;
            memoryAppState.hoveringValuePoints[this.props.id] = true;
          }}
          onMouseOut={() => {
            if (memoryAppState.hoverValuePoint == this.props.id) {
              memoryAppState.hoverValuePoint = null;
            }
            delete memoryAppState.hoveringValuePoints[this.props.id];
          }}  
          data-value-point-id={this.props.id}
          className={cx({ block: valueCont.type && isBlock(valueCont), active: paneContext.active == this.props.id })}
          onMouseDown={onClick}
          data-tick={valuePointTicks[this.props.id] || 0}
          data-type={valueCont.type[0]}
        >
          {Comp ? <Comp
            key={valuePointTicks[this.props.id]}
            tick={valuePointTicks[this.props.id]}
            scope={scope}
            state={valueCont}
            elId={elId}
            path={this.props.path}
          /> : <span>Unknown type {valueCont.type[0]}</span>}
        </Container>
      );
    }


    const options = (() => {
      if (possibleTypes.length == 0) {
        const namedOptions = [
          ...typeRegistry.types.map((struct) => ({
            name: struct.name,
            type: ValueType.Structure,
            id: struct._id,
          })),
        ];

        return namedOptions.map(({ name, type, id }) => ({
          display: name,
          value: { type: [type, id] },
        }));
      }
      else {
        const options = [];
        for (const t of possibleTypes) {
          if (t[0] == ValueType.Structure) {
            const st = structuresMap[t[1]];
            options.push({
              display: st.name || '(unnamed struct)',
              value: { type: [t[0], t[1]] },
            });
          }
          else if (t[0] == ValueType.Array) {
            options.push({
              display: 'Array',
              value: { type: [t[0], t[1]] },
            });
          }
          else if (t[0] == ValueType.Enum) {
            const en = enumMap[t[1]];
            options.push({
              display: en.name || '(unnamed enum)',
              value: { type: [t[0], t[1]] },
            });
          }
        }

        return options;
      }
    })();

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.Parameter && matchType(getParameter(s.id).type, possibleTypes)).map((s) => ({
      display: getParameter(s.id).name,
      value: { type: [ValueType.Param], content: { id: s.id } }
    })));

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.StructureParameter).map((s) => ({
      display: getStructureParameter(s.id).name,
      value: { type: [ValueType.Param], content: { id: s.id } }
    })));

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.Value && matchType(getValuePoint(s.id).type, possibleTypes)).map((s) => {
      const display = getValuePoint(s.id).name;
      return display && ({
        display,
        value: { type: [ValueType.Value], content: { id: s.id } },
      });
    }).filter(Boolean));



    options.push({
      display: 'If Chain',
      value: { type: [ValueType.IfChain] },
    });

    options.push({
      display: 'Graph',
      value: { type: [ValueType.Graph] },
    });

    options.push({
      display: 'Entity Match',
      value: { type: [ValueType.EntityMatch] },
    });

    options.push({
      display: 'Entity Type',
      value: { type: [ValueType.EntityType] },
    });

    options.push({
      display: 'Entity State',
      value: { type: [ValueType.EntityState] },
    });

    options.push({
      display: 'Entity State Type',
      value: { type: [ValueType.EntityStateType] },
    });

    options.push({
      display: 'Entity State Types',
      value: { type: [ValueType.EntityStateTypes] },
    });



    options.push({
      display: 'Entity Meta State',
      value: { type: [ValueType.EntityMetaState] },
    });

    options.push({
      display: 'Entity Attribute',
      value: { type: [ValueType.EntityAttribute] },
    });

    options.push({
      display: 'Entity Attributes',
      value: { type: [ValueType.EntityAttributes] },
    });


    options.push({
      display: 'Statement',
      value: { type: [ValueType.Statement] },
    });

    options.push({
      display: 'Boolean',
      value: { type: [ValueType.Boolean] },
    });

    options.push({
      display: 'Arg',
      value: { type: [ValueType.Arg] },
    });

    options.push({
      display: 'Relative Queries',
      value: { type: [ValueType.RelativeQueries] },
    });

    options.push({
      display: 'Relative Query',
      value: { type: [ValueType.RelativeQuery] },
    });

    options.push({
      display: 'Entity Element',
      value: { type: [ValueType.EntityElement] },
    });

    options.push({
      display: 'Code Component',
      value: { type: [ValueType.CodeComponent] },
    });

    return (
      <Container
        className={cx({ block: valueCont.type && isBlock(valueCont), active: paneContext.active == this.props.id })}
        onMouseDown={onClick}
        data-tick={valuePointTicks[this.props.id] || 0}
        data-type="none"
      >
        <DraftSelect
          inline
          id={elId}
          options={options.map(o => ({
            display: o.display || JSON.stringify(x(o.value)),
            value: o.value,
          }))}
          onFilterChange={v => {
            const mode = modeKeys.find(([k]) => k == v);
            if (mode) {
              trackChange(SetValuePointTypeMode, mode[1], [
                [ UndoActions.modifiedValuePoint, valueCont._id ]
              ]);
              valueCont.type = [mode[1]];
              if (mode[1] == ValueType.Element) {
                valueCont.presentation = { compact: true };
              }
            }
          }}
          onSelect={v => {
            trackChange(SetValuePointType, v, [
              [ UndoActions.modifiedValuePoint, valueCont._id ]
            ]);
            for (const p in v) {
              valueCont[p] = v[p];
            }
          }}
        />
      </Container>
    );
  }
}
