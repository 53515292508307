import { css } from '../component';

export const styleStyles = css`
  &._5c4ad6d5-b808-55f2-b0e2-8583876b97bd {
    text-decoration: line-through;
    color: gray;
  }

  &._73e24e6e-96e5-577d-bbae-5c9838d41439 {
    background-color: #ffffd2;
  }

  &._e712b7ba-7765-5f99-b3f9-76c423ce22e2 {
    color: #bababa;
  }
`;