import { db } from "../../db";
import { evaluate, execute } from "../main";
import { AttributeType } from "../../components/AttributeType";
import { Runtime } from '../Runtime';
import { PriorityIcon } from "../../etc/PriorityIcon";
import { styled } from "../../component2";
import { executeSwitch } from "../../components/executeSwitch";
import { $GroupedSelectAttribute } from "./$GroupedSelectAttribute";
import juration from "../../juration";

const DueDateWrapper = styled.div`
  &.text-danger {
    color: red;
  }
  &.text-warning {
    color: orange;
  }
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px 4px;
  border-radius: 25px;
  font-size: 8px;
  background: rgba(0, 0, 0, 0.05);
`

export const getAttributeKey = (attrId, entityId) => {
  const attr = db.attributeTypes.findById(attrId);
  if (attr.type == AttributeType.switch) {
    return executeSwitch(attr.valuePoint, entityId)?._id;
  }
  return null;
}

export function getEntityZIndex(id) {
  const entity = db.entities.findById(id);
  if (entity.attributes) for (const attrId in entity.attributes) {
    const t = db.attributeTypes.findById(attrId);
    if (t.type == AttributeType.switch) {
      const value = executeSwitch(t.valuePoint, id);
      if (value?.type?.[1] == $GroupedSelectAttribute.$) {
        const evaluated = evaluate(value);
        for (const group of evaluated.groups) {
          for (const entry of group.entries) {
            if (entry._id == entity.attributes[attrId]) {
              return group.zIndex;
            }
          }
        }
      }

    }
  }

  return 0;
}

export const renderAttributeValue = (attrId, key, value) => {
  if (!value) return ;
  const attr = db.attributeTypes.findById(attrId);
  if (attr.type == AttributeType.valuePoint) {
    const evaluted = evaluate(execute(attr.valuePoint, new Runtime({})));
    const entry = (() => {
      for (const group of evaluted.groups) {
        for (const entry of group.entries) {
          if (entry._id == value) {
            return entry;
          }
        }
      }
    })();
    return entry.name;
  }
  else if (attr.type == AttributeType.switch) {
    const evaluted = evaluate(execute(key, new Runtime({})));
    let _group;
    const entry = (() => {
      for (const group of evaluted.groups) {
        for (const entry of group.entries) {
          if (entry._id == value) {
            _group = group;
            return entry;
          }
        }
      }
    })();
    return <span style={{color: _group.color}}>{entry.name}</span>

  }
  else if (attr.type == AttributeType.priority) {
    return <PriorityIcon priority={value} />;
  }
  else if (attr.type == AttributeType.dueDate) {
    if (!value) return '';
    const now = new Date();
    const due = new Date(value);
    const diff = due.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days < 0) {
      return <DueDateWrapper className="text-danger">{days} days ago</DueDateWrapper>;
    }
    else if (days == 0) {
      return <DueDateWrapper className="text-warning">Today</DueDateWrapper>;
    }
    else if (days == 1) {
      return <DueDateWrapper className="text-warning">Tomorrow</DueDateWrapper>;
    }
    else if (days < 7) {
      return <DueDateWrapper className="text-warning">{days} days</DueDateWrapper>;
    }
    else {
      return <DueDateWrapper>{days} days</DueDateWrapper>;
    }
  }
  else if (attr.type == AttributeType.duration) {
    return juration.stringify(value);
  }
  else if (attr.type == AttributeType.text) {
    return value;
  }
  else if (attr.type == AttributeType.select) {
    return attr.options.find(o => o._id == value)?.title;
  }
};
