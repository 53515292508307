import { typeRegistry } from "../typeRegistry.1";

const $EntityCreator = typeRegistry.registerType({
  $: '308df6ab-5a8c-5341-b4ec-d5b98898baae',
  Type: '666157f9-e432-54f5-91d1-7d57f343d467',
  Parent: '90899667-5158-5539-b16d-a7d5b6a1e2d7',
  Space: '3564247e-e76a-5c90-9479-ed211f280bf1',
}, ids => ({
  _id: ids.$,
  name: 'Entity Creator',
  definition: [
    {
      id: ids.Type,
      name: 'Type',
      type: [],
      property: 'type',
    },
    {
      id: ids.Parent,
      name: 'Parent',
      type: [],
      property: 'parent',
    },
    {
      id: ids.Space,
      name: 'Space',
      type: [],
      property: 'space',
    },
  ]
}));
