import { db } from '../db';
import { isNullSpace } from "./isNullSpace";

export function entitiesInSpace(space) {
  if (isNullSpace(space)) {
    return db.entities.filter(x => !x.space);
  }
  else {
    return db.entities.filter(x => x.space == space._id);
  }
}
