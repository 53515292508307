import React from 'react';
import {component} from '../../component2';
import {XObject} from '../../XObject';
import {PropertyField} from '../../components/PropertyField';
import {showContextMenu} from '../../helpers';
import {db} from '../../db';
import copyToClipboard from 'copy-to-clipboard';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { createEntity } from '../../etc/createEntity';
import { SystemContext } from '../../etc/SystemContext';


@component
export class EntityValuePoint extends Comp<ValuePointProps> {
  static contextType = SystemContext;
  context: any;
  render() {
    return <><span
      onContextMenu={e => {
        e.preventDefault();
        showContextMenu(e, [
          {
            text: 'Copy ID',
            onClick: () => {
              copyToClipboard(this.props.state.content);
            }
          },
          {
            text:'View',
            onClick: () => {
              this.context.navigate?.({
                type: 'entity',
                id: this.props.state.content,
              })
            }

          }
        ]);
      }}
    ><PropertyField object={this.props.state} property="content" display={value => {
      return value && db.entities.findById(value)?.name || value;
    }} /></span> {!this.props.state.content && <button onClick={() => {
      const newEntity = XObject.obj({});
      createEntity(newEntity, null); // createEntityNull
      this.props.state.content = newEntity._id;
    }}>new</button>}</>;
  }
}

export const registerEntityType = () => {
    registerType(ValueType.Entity, {
        evaluate: (value, map) => {
            return value.content;
        },
        render: (value, map, state, renderType) => {
            // return <EntityRef key={value._id} entity={value.content} />;
        },
        editorComp: EntityValuePoint,
    });
};