import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { component, css, styled } from '../component';
import jQuery from 'jquery';
import { X, XInit, XObject, XTouch, x } from '../XObject';
import { appState } from '../etc/appState';
import { PaneType } from '../types/PaneType';
import { InspectState } from './InspectState';


function inspectKeys(e) {
	return appState.appInspectOverlay;

}

function elementDepth(el){
	var depth = 0
	while(null!==el.parentElement){
		el = el.parentElement
		depth++
	}
	return depth
}

@component
export class UIWrapper extends React.Component<{ children? }> {
	static styles = styled.div`
		.inspectOverlay {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 9999999999;
		}

		&.hovering {
			cursor: pointer;
		}
	`;
  state = XInit(class {
		hovering
	})
	els = [];
	componentDidMount() {
		let el = ReactDOM.findDOMNode(this);
		if (!el) return;
		let doc = el.ownerDocument;
		let win = doc.defaultView;
		let clientX, clientY;
		const update = () => {
			let els = []
			els = doc.elementsFromPoint(clientX, clientY).filter(el => el.getAttribute('data-ui-binding'));

			this.els = els;
			if (this.els.length) {
				this.state.hovering = true;
			}
			else {
				this.state.hovering = false;
			}
			this.forceUpdate();
		};

		win.addEventListener('mousedown', e => {
			if (appState.appInspectOverlay) {
				const el = this.els[0];
				if (el) {
					const binding = el.getAttribute('data-ui-binding');
					if (binding) {
						/*const paneEl = jQuery(el).parents('[data-pane-id]');
						if (paneEl.length) {
							const paneId = paneEl.attr('data-pane-id');
							const paneIndex = appState.panes.findIndex(pane => pane._id == paneId);
	
							const stackEl = jQuery(el).parents('[data-stack-id]');
							const stackId = stackEl.attr('data-stack-id');
							const pointer = appState.panes[paneIndex].stack.findIndex(stack => stack._id == stackId);
	
	
							appState.panes[paneIndex].stack = X([
								...x(appState.panes[paneIndex].stack.slice(0, pointer + 1)), XObject.obj({
									type: PaneType.uiInspect,
									args: JSON.parse(binding),
	
								})]);
						}	*/

						appState.appInspect = {
							mode: InspectState.ui,
							...JSON.parse(binding),
						}
					}

				}
				this.els = [];
				appState.appInspectOverlay = false;
			}
		});

		win.addEventListener('contextmenu', e => {
			if (inspectKeys(e)) {
				e.preventDefault();
			}
		});

		jQuery(win).mousemove(e => {
			clientX = e.clientX;
			clientY = e.clientY;
			if (inspectKeys(e)) {
				update();
			}
		});
	}
	render(Container?) {
		return (
			<Container
				className={this.state.hovering ? 'hovering' : ''}
			>
				{appState.appInspectOverlay && (
					<div className="inspectOverlay">
						{this.els.map((el, i) => {
							const bounding = el.getBoundingClientRect();
							const color = i == 0 ? 'green': 'blue';
							return (
								<div
									key={i}
									style={{
										zIndex: this.els.length - i,
										position: 'absolute',
										left: bounding.left,
										top: bounding.top,
										width: bounding.width,
										height: bounding.height,
										outline: `2px solid ${color}`
									}}
								/>
							)
						})}
					</div>
				)}
				{this.props.children}
			</Container>
		);
	}
}
