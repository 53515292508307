import React, { Component, FunctionComponent } from 'react';
import _ from 'lodash';
import { createGlobalStyle } from 'styled-components';
import ReactDOM from 'react-dom';
import { component, generateClassName, styled } from '../component2';
import SVG from 'react-svg-raw';
import jQuery from 'jquery';
import { XInit, x, XObject } from '../XObject';
import { ConnectDragPreview, ConnectDragSource, DragSource, DropTarget } from 'react-dnd'
import cx from 'classnames';
// import { _Board } from './Board';

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { img } from '../img';
import { toast } from 'react-toastify';


const Svg: FunctionComponent<{ className?: any, name: string, colors?: any, onClick?: any }> = props => {
  const src = img(props.name, props.colors);
  return (
    <SVG src={src} />
  );
}

const areaClass = generateClassName();

const GlobalStyles = createGlobalStyle`
  .${areaClass} {
    /* background: red; */
    /* opacity: .5; */
  }
`

export abstract class _Block {
  data?
  abstract version
  abstract get id(): any;
  
  abstract get content(): string;
  abstract set content(value);

  abstract get children(): BlockList;
  
  abstract get parent(): _Block;
  abstract set parent(value);

  findBlock(id): _Block {
    if (this.hasChildren()) {
      for (let i = 0; i < this.children.length; ++ i) {
        const block = this.children.get(i);
        if (block.id == id) return block;
        const r = block.findBlock(id);
        if (r) return r;
      }
    }
  }

  abstract hasChildren(): boolean;
  endBlock(): _Block {
    if (!this.collapsed && this.hasChildren()) {
      const lastChild = this.children.get(this.children.length - 1);
      return lastChild.endBlock();
    }
    else {
      return this;
    }
  }

  get collapsed() {
    return false;
  }

  set collapsed(value) {}
}

interface BlockManagerDelegate<TBlock extends _Block = _Block> {
  onDelete?;
  onAdd?;
  onMove?;
  setContent(block, content);
  joinBlocks?;
  setFocusedBlock;
  newBlock(content): _Block;
  blocks(): BlockList;
  onChanged?();
}

export type BlockList<TBlock extends _Block = _Block> = {
  indexOf(a: TBlock);
  splice(a, b, ...c);
  length: number;
  map(func);

  get(i: number): TBlock;

}

export abstract class _BlockManager<TBlock extends _Block = _Block> {
  constructor(private delegate: BlockManagerDelegate<TBlock>) {}

  components: { [id: string]: _BlockComp} = {};


  get blocks(): BlockList {
    return this.delegate.blocks();
  }

  findBlock(id): _Block {
    for (let i = 0; i < this.blocks.length; ++ i) {
      const block = this.blocks.get(i);
      if (block.id == id) return block;
      const r = block.findBlock(id);
      if (r) return r;
    }
  }

  prevBlock(block: _Block): _Block {
    const list = this.containingList(block);
    const index = this._indexOf(list, block);
    if (index == 0) {
      return block.parent;
    }
    else {
      return list.get(index - 1).endBlock();
    }
  }

  nextBlock(block: _Block): _Block {
    if (block.hasChildren()) {
      return block.children[0];
    }
    else {
      let current: _Block = block;
      while (current) {
        const list = this.containingList(current);
        const index = this._indexOf(list, current);
        if (list.get(index + 1)) {
          return list.get(index + 1);
        }

        current = current.parent;
      }

    }
  }

  setBlockContents(block: _Block, contents) {
    this.delegate.setContent(block, contents)
  }

  registerBlockComponent(block: _Block, comp: _BlockComp) {
    this.components[block.id] = comp;
  }

  containingList(block: _Block): BlockList {
    if (block.parent) return block.parent.children;
    else return this.blocks;
  }

  abstract _indexOf(list: BlockList, block: _Block): number;

  abstract _splice(list: BlockList, index, deleteCount, ...items);

  abstract _length(list: BlockList): number;

  unindentOrDelete(block: _Block, userInteraction=false) {
    if (block.parent && block.parent.children.indexOf(block) == block.parent.children.length - 1) {
      this.unindent(block);
    }
    else {
      return this.removeBlock(block, userInteraction);
    }
  }

  removeBlock(block: _Block, userInteraction=false) {
    const list = this.containingList(block);
    const index = this._indexOf(list, block);
    this._splice(list, index, 1);

    let nextBlock;

    if (userInteraction) {
      if (list.get(index - 1)) {
        const prevBlock = list.get(index - 1).endBlock();

        // this.delegate.joinBlocks?.(prevBlock, block);
        nextBlock = prevBlock;
        this.delegate.setFocusedBlock(prevBlock.id);
      }
      else {
        this.delegate.setFocusedBlock(block.parent?.id);
        return block.parent;
      }

      if (block.hasChildren()) {
        for (let i = 0; i < block.children.length; ++ i) {
          const child = block.children.get(i);
          this.insertChild(child, block.parent, index + i);

        }
      }
    }

    if (block.parent) {
      block.parent.version ++;
    }
    block.parent = null;
    this.delegate.onChanged?.();

    this.delegate?.onDelete?.(block);

    return nextBlock;
  }

  insertBefore(block: _Block, beforeBlock: _Block) {
    let list: BlockList;
    let parent: _Block;
    if (beforeBlock.parent) {
      parent = beforeBlock.parent;
      list = beforeBlock.parent.children;
    }
    else {
      list = this.blocks;
    }
    if (parent) {
      parent.version ++;
    }
    block.parent = parent;
    const index = this._indexOf(list, beforeBlock);
    this._splice(list, index, 0, block);
    this.delegate.onChanged?.();

    this.delegate?.onAdd?.(block);
  }

  insertChild(block: _Block, parent?: _Block, position?) {
    block.parent = parent;
    this._splice(parent ? parent.children : this.blocks, !_.isNil(position) ? position : this.blocks.length, 0, block);
    if (parent) parent.version ++;
    this.delegate.onChanged?.();
    this.delegate?.onAdd?.(block);
  }

  newBlock(content) {
    return this.delegate.newBlock(content);
  }

  addBlock(fromBlock: _Block, content?, refocus=false) {
    const newBlock = this.newBlock(content);
    // newBlock.blockManager = this;
    if (refocus) {
      this.delegate.setFocusedBlock(newBlock.id);
    }

    let list: BlockList;
    if (!fromBlock.collapsed && this._length(fromBlock.children)) {
      newBlock.parent = fromBlock;
      fromBlock.version++;
      // fromBlock.children.unshift(newBlock);
      this._splice(fromBlock.children, 0, 0, newBlock);
    }
    else {
      if (!fromBlock.parent) {
        list = this.blocks;
      }
      else {
        fromBlock.parent.version++;
        newBlock.parent = fromBlock.parent;
        list = fromBlock.parent.children;
      }
      this._splice(list, this._indexOf(list, fromBlock) + 1, 0, newBlock);  
    }
    this.delegate.onChanged?.();

    this.delegate?.onAdd?.(newBlock);
    return newBlock;
  }

  insertAfter(block: _Block, after: _Block) {
    let list: BlockList;
    let parent: _Block;
    if (after.parent) {
      parent = after.parent;
      list = after.parent.children;
    }
    else {
      list = this.blocks;
    }
    if (parent) {
      parent.version ++;
    }
    block.parent = parent;
    const index = this._indexOf(list, after);
    this._splice(list, index + 1, 0, block);
    this.delegate.onChanged?.();

    this.delegate?.onAdd?.(block);
  }

  unindent(block: _Block) {
    // console.log('unindent', block);
    if (block.parent) {
      const parent = block.parent;
      this.removeBlock(block, false);
      this.insertAfter(block, parent);
      return true;
    }
  }

  indent(block: _Block) {
    // console.log('indent', block);
    const list = this.containingList(block);
    const index = this._indexOf(list, block);
    if (index > 0) {
      const prev = list.get(index - 1);
      this.removeBlock(block, false);
      block.parent = prev;
      // prev.children.push(block);
      this._splice(prev.children, this._length(prev.children), 0, block);
      prev.version++;

      this.delegate.onChanged?.();
    }
  }
}

interface BlockCompProps {
  block: _Block;
  blockManager: _BlockManager;
  renderEditor;
  _onSelect?(block: _Block);
  _onClick?(block, e);
  _selected?(a: string): boolean;
  resolveClass;

  notionView: BlockEditor;
  children?
}


let cancelDrag;
@component
export class _BlockComp extends Component<BlockCompProps & { dragging, connectDragSource?: ConnectDragSource, connectDragPreview?: ConnectDragPreview }> {
  static t = {
    handle: styled.div`
      width: 18px;
      height: 24px;
      /* position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; */

      transition: all 100ms linear;

      &:hover {
        background: #f3f3f3;
        border-radius: 3px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: grab;

      path {
        /* fill: rgba(55, 53, 47, 0.88); */
        fill: rgba(55, 53, 47, 0.3)
      }
    `,
    cont: styled.div`
      /* font-size: 16px; */
      /* height: 30px; */
      /* padding-left: 10px; */
      position: relative;
      /* display: flex; */
      /* align-items: center; */

    `,
    children: styled.div`
      padding-left: 1.5em;
    `,

    editorCont: styled.div`
      /* flex: 1 1 auto; */

      &.collapsed {
        margin-left: 15px;
      }
    `,

    toggle: styled.span`
      /* background-color: black; */
      width: 10px;
      height: 10px;
      position: absolute;
      left: 5px;
      top: 8px;
      cursor: pointer;
      svg {
        transform: rotateZ(90deg);
      }
    `,
  }

  static c = {
    handleCont: '',
  }

  static styles() {
    const { t, c } = this;
    return styled.div`
      ${t.handle} {
        opacity: 0;
      }

      .${c.handleCont} {
        width: 18px;
        height: 24px;
        position: absolute;
        right: 100%;
        top: -3px;
      }

      /* ${t.cont} {
        > *:not(:first-child) {
          flex: 1 1 auto;
          display: inline-flex;
          align-items: center;
        }
      } */

      ${t.cont}:hover {
        > .${c.handleCont} > ${t.handle} {
          opacity: 1;
        }

      }
      &.dragging {
        opacity: .3;
      }

      &.hovering {
        background: #ecf6fc;
      }

      &.isSelected {
        > ${t.cont} {
          outline: 1px solid red;
        }
      }
    `;
  }

  componentDidMount() {
    this.props.blockManager.registerBlockComponent(this.props.block, this);
  }

  render(Container?) {
    const { t, c } = _BlockComp;
    const { block } = this.props;

    const handleEl = React.createRef<any>();

    return (
      <Container
        data-block-id={block.id}
        data-selected={JSON.stringify(this.props._selected?.(this.props.block.id))}
        className={cx({ isSelected: this.props._selected?.(this.props.block.id), hovering: state.hoveringBlock == block.id, dragging: this.props.dragging })}
      >
        <t.cont className={this.props.resolveClass?.(this.props.block)}>
          {this.props.connectDragSource(<div className={c.handleCont}><t.handle ref={handleEl} onMouseDown={e => {
            const target = e.target;
            let cancelClick = false;
            const timerId = setTimeout(() => {
              e.preventDefault();
              this.props._onSelect?.(this.props.block);
              cancelDrag = true;
              cancelClick = true;
            }, 500);
            jQuery(window).one('mouseup', () => {
              clearTimeout(timerId);
              cancelDrag = false;

              if (!cancelClick) {
                this.props._onClick?.(block, handleEl.current);
              }
            });
            jQuery(window).one('mousemove', () => {
              clearTimeout(timerId);
              cancelClick = true;
            });
          }}>
            <Svg name="grip" />
          </t.handle></div>)}
          <t.editorCont className={cx(block.collapsed && 'collapsed')}>
            {block.collapsed && <t.toggle onClick={() => {
              block.collapsed = !block.collapsed;
            }}><Svg name="toggle" /></t.toggle>}
            {this.props.renderEditor(block, this.props.blockManager)}
          </t.editorCont>
        </t.cont>
        {!block.collapsed && block.children.length > 0 && <t.children>
          {block.children.map(block => <BlockComp notionView={this.props.notionView} resolveClass={this.props.resolveClass} _onClick={this.props._onClick} _selected={this.props._selected} _onSelect={this.props._onSelect} renderEditor={this.props.renderEditor} key={block.id} block={block} blockManager={this.props.blockManager} />)}
        </t.children>}
      </Container>
    );
  }
}

@DragSource('block', {
  canDrag() {
    if (cancelDrag) {
      cancelDrag = false;
      return false;
    }
    return true;
  },
  beginDrag(props: any) {
    console.log('begin drag', props)
    // myEditor.updateAreas();
    props.notionView.updateAreas();
    state.showAreas = true;
    return {
      block: props.block
    }
  },
  endDrag(props: any) {
    // myEditor.blockManager.removeBlock(props.block);
    state.showAreas = false;
  },


}, (connect, monitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    dragging: monitor.isDragging(),
  }
})
class BlockComp extends Component<BlockCompProps> {
  render() {
    const { children, ...props } = this.props;
    return this.props['connectDragPreview'](<div><_BlockComp {...props as any} /></div>);
  }
}


@DropTarget('block', {
  hover(props: any, monitor, component) {
    // console.log(props, monitor, component);
    state.hoveringBlock = props.block?.id;
  },
  drop(props: any, monitor, component) {
    console.log('props', props, monitor, component, monitor.getItem());
    const block: _Block = monitor.getItem().block;
    const blockManager = props.notionView.blockManager;
    blockManager.removeBlock(block, false);
    props.action(block);
    state.hoveringBlock = null;
  },
}, (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    hovering: monitor.isOver(),
  }
})
class DropArea extends Component<{ block, side, action, style, hovering?, notionView }> {
  render() {
    return this.props['connectDropTarget'](<div style={this.props.style} className={cx(areaClass, this.props.side, { hovering: this.props.hovering  })} />);
  }
}

const state = XInit(class {
  showAreas = false;
  version = 0;
  hoveringBlock: number;

  // selectRange: [ string?, string? ] = [];
})

// let myEditor: NotionEditor;

@component
export class BlockEditor<TBlock extends _Block = _Block> extends React.Component<{
  className?;
  renderEditor(block: TBlock, blockManager: _BlockManager): any;
  dataTransform?(block: _Block, blockManager: _BlockManager): any;
  data_contextMenu?(data, pos, blockManager: _BlockManager),
  blockSelected?
  
  blockManager: _BlockManager;
  onSelectChanged?(selection: [ _Block?, _Block? ]): void;
  _onClick?(block: _Block, e);
  resolveClass?;

  layout?: 'notion' | 'board'
  state?

  onCut?

  
}> {
  onChange;
  state = state;

  selectedRange: [ _Block?, _Block? ] = [];

  clearSelection() {
    this.selectedRange = [];
  }

  static t = {
    cont: styled.div``
  }
  private focusBlock;

  constructor(props) {
    super(props);
    this.blockManager = this.props.blockManager;
    // myEditor = this;
    // this.blockManager.onChanged = () => {
    //   this.forceUpdate();
    //   state.version ++;
    // }
  }

  static styles = styled.div`
    color: #4a4a4a;
    svg {
      * {
       fill: #4a4a4a;
      } 
    }

    .diagram-component {
      height: 100%;
    }
  `;

  areas: { block: _Block, side: 'above' | 'below', top, left, width, height, action(block: _Block): void }[] = [];

  layout() {
    return this.props.layout || 'notion';
  }

  updateAreas() {
    if (this.layout() != 'notion') return;
    this.areas = [];
    const iterate = (list: BlockList) => {
      for (let i = 0; i < list.length; ++ i) {
        const block = list.get(i);
        const comp = this.blockManager.components[block.id];
        const el = ReactDOM.findDOMNode(comp);

        // above
        {
          const offset = jQuery(el).offset();
          this.areas.push({
            block: block.parent,
            side: 'above',
            action: b => {
              this.blockManager.insertBefore(b, block);
            },
            top: offset.top + 1,
            left: offset.left + 1,
            width: jQuery(el).width() - 2,
            height: jQuery(el).height()/2 - 2,
          })
        }

        // below
        {
          const offset = jQuery(el).offset();
          this.areas.push({
            block: block.parent,
            side: 'below',
            action: b => {
              this.blockManager.insertAfter(b, block);
            },

            top: offset.top + jQuery(el).height()/2 + 1,
            left: offset.left + 1,
            width: jQuery(el).width() - 2,
            height: jQuery(el).height()/2 - 2,
          });
        }

        // child
        if (!block.collapsed) {
          const offset = jQuery(el).offset();
          this.areas.push({
            block,
            side: 'below',
            action: b => {
              this.blockManager.insertChild(b, block, 0);
            },

            top: offset.top + 30/2 + 1,
            left: offset.left + 1 + 24,
            width: jQuery(el).width() - 2 - 24,
            height: 30/2 - 2,
          });
          iterate(block.children);
        }

      }

    }
    iterate(this.blockManager.blocks);
  }

  componentDidUpdate() {
    // this.updateAreas();
  }

  componentDidMount() {
    this.updateAreas();
    this.forceUpdate();
  }

  blockManager: _BlockManager;

  resolvedRange(): [ _Block?, _Block? ] {
    const getPath = (block: _Block): _Block[] => {
      let current = block;
      const path: _Block[] = [];
      while (current) {
        path.unshift(current);
        current = current.parent;
      }
      return path;
    }
    let range = this.selectedRange;

    let resolvedRange: [_Block?, _Block?] = [];

    if (range.length == 2) {
      const aPath = getPath(range[0]);
      const bPath = getPath(range[1]);
      for (let i = aPath.length - 1; i >= 0; --i) {
        const index = bPath.findIndex(b => b.id == aPath[i].id);
        if (index != -1) {
          resolvedRange[0] = aPath[i + 1];
          resolvedRange[1] = bPath[index + 1];
          break;
        }
      }
      
      if (!resolvedRange.length) {
        resolvedRange[0] = aPath[0];
        resolvedRange[1] = bPath[0];
      }
    }
    else if (range.length) {
      resolvedRange[0] = range[0].id;
    }

    

    return resolvedRange;
  }

  render(Container?) {
    const { t } = BlockEditor;
    const layout = this.layout();

    if (layout == 'notion') {
      return (
        <Container className={this.props.className}>
          <GlobalStyles />
          <t.cont>
            {this.blockManager.blocks.map((block, i) => {
              const key = block.id + '-' + block.version;
              return (
                <BlockComp
                  notionView={this}
                  key={key}
                  resolveClass={this.props.resolveClass}
                  renderEditor={this.props.renderEditor}
                  block={block}
                  blockManager={this.blockManager}
                  _selected={id => {
                    // return !!this.selectedRange.find(block => block.id == id);
                    return this.resolvedRange().findIndex(b => b.id == id) != -1;
                  }}
                  _onClick={(block, e) => {
                    this.props._onClick?.(block, e);
                  }}
                  _onSelect={block => {
                    if (this.selectedRange.length == 2 || this.selectedRange[0]?.id == block.id) {
                      this.selectedRange = [];
                      toast('Range cleared');
                      // registerKeyHandler(null);
                    }
                    else {
                      this.selectedRange.push(block);
                      if ((this.selectedRange as any).length == 2) {
                        toast('Range selected');

                        /*registerKeyHandler(e => {
                          
                          if (e.key == 'x' && e.metaKey) {
                            // console.log('cut', this.resolvedRange());
                            this.props.onCut?.(this.resolvedRange());
                            return true;
                          }
                        })*/
                        // this.selectedRange.sort((a, b) => a.id.localeCompare(b.id));
                      }
                      else {
                        toast('Range started');
                      }
                    }
                    this.forceUpdate();
                    this.props.onSelectChanged?.(this.resolvedRange());
                  }}
                />
              )
            })}
            {(this.state.showAreas) && ReactDOM.createPortal(this.areas.map((area, i) => {
              return (
                <DropArea
                  notionView={this}
                  key={i}
                  block={area.block}
                  side={area.side}
                  action={area.action}
                style={{ position: 'absolute', left: area.left + 'px', top: area.top + 'px', width: area.width + 'px', height: area.height + 'px', zIndex: 9999999999 }} />
              );
            }), document.body)}
          </t.cont>
        </Container>
      );
    }
    else if (layout == 'board') {
      return (<Container className={this.props.className}>
        <button style={{ position: 'relative', zIndex: 1}} onClick={() => {
          this.blockManager.addBlock(this.blockManager.blocks.get(this.blockManager.blocks.length - 1),  XObject.obj({ children: [], content: [] }))
        }}>+</button>
        {/* <_Board
          data={this.blockManager.blocks.map((block, i) => {
            const key = block.id + '-' + block.version;

            return { _id: key, block };
          })}

          id={''}
          state={this.props.state}
          // autoSize
          render={data => {

            return this.props.renderEditor(data.block, this.blockManager)
          }}
        /> */}
      </Container>);
    }
    else if (layout == 'graph') {


      const initDiagram = () => {

        const $ = go.GraphObject.make;

        const myDiagram = $(go.Diagram,
          {
            initialAutoScale: go.Diagram.UniformToFill,
            // define the layout for the diagram
            layout: $(go.TreeLayout, { nodeSpacing: 5, layerSpacing: 30 }),
            model: $(go.GraphLinksModel,
              {
                linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                // positive keys for nodes
                makeUniqueKeyFunction: (m: go.Model, data: any) => {
                  let k = data.key || 1;
                  while (m.findNodeDataForKey(k)) k++;
                  data.key = k;
                  return k;
                },
                // negative keys for links
                makeUniqueLinkKeyFunction: (m: go.GraphLinksModel, data: any) => {
                  let k = data.key || -1;
                  while (m.findLinkDataForKey(k)) k--;
                  data.key = k;
                  return k;
                }
              })
  
            // model: new go.TreeModel( {
            //   isReadOnly: true,  // don't allow the user to delete or copy nodes
            //   // linkKeyProperty: 'key'
            // })
          });

          myDiagram.nodeTemplate =
          $(go.Node, "Horizontal",
            { 
              selectionChanged: (a) => {
                this.props.blockSelected?.(this.blockManager.findBlock(a.data.key));
              },
              contextMenu: $(go.HTMLInfo, {
                show: (obj, diag, tool) => {
                  console.log(obj, diag, tool);

                  

                  this.props.data_contextMenu?.(obj.data, { x: diag.lastInput.viewPoint.x + jQuery(diag.div).offset().left, y: diag.lastInput.viewPoint.y + jQuery(diag.div).offset().top }, this.blockManager);
                },
                hide: () => {

                }
              }),
             },  // this event handler is defined below
            $(go.Panel, "Auto",
              $(go.Shape, { fill: "#1F4963", stroke: null }),
              $(go.TextBlock,
                {
                  font: "bold 13px Helvetica, bold Arial, sans-serif",
                  stroke: "white", margin: 3
                },
                new go.Binding("text", "text"))
            ),
            $("TreeExpanderButton")
          );
  
        // Define a trivial link template with no arrowhead.
        myDiagram.linkTemplate =
          $(go.Link,
            { selectable: false },
            $(go.Shape));  // the link shape
  
        // create the model for the DOM tree
        // myDiagram.model =
        //   new go.TreeModel( {
        //     isReadOnly: true,  // don't allow the user to delete or copy nodes
        //     // build up the tree in an Array of node data
        //     nodeDataArray: model,
        //   });

        return myDiagram;


      }

      const model = [];
      const links = []
      
      const generateModel = (blocks: _Block[], parent?) => {
        for (const block of blocks) {
          const node = this.props.dataTransform(block, this.blockManager);
          model.push(node);
          if (parent) {
            links.push({ key: node.key + parent.key, from: parent.key, to: node.key });
          }
          generateModel(block.children.map(block => block), node);
        }
      }

      generateModel(this.blockManager.blocks.map(block => block));


      return <Container clasName={this.props.className}><ReactDiagram
        initDiagram={initDiagram}
        divClassName='diagram-component'
        nodeDataArray={model}
        linkDataArray={links}
        // onModelChange={handleModelChange}
      /></Container>
    }

  }
}
