import { Component, useEffect } from 'react';
import _ from 'lodash';
import { component } from '../component2';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import euler from 'cytoscape-euler';
import d3Force from 'cytoscape-d3-force';
import { db } from '../db';
import { appState } from '../etc/appState';
import { AttributeType } from './AttributeType';
import { ObjectType } from '../types/ObjectRef';
import { collectEntitiesGood } from './collectEntitiesGood';
import { entityDisplayName } from './entityDisplayName';
import { x } from '../XObject';
import { executeQueryObj } from './ViewQuery';
import { ObjectDisplay } from './ObjectDisplay';
import { getScopeTree } from "./objectFuncs";
import { entitiesInSpace } from '../etc/entitiesInSpace';
import { styled } from '../component';
import { PaneType } from '../types/PaneType';
import { imgBlob } from '../img';
import { isMobile } from '../isMobile';
cytoscape.use(d3Force);
cytoscape.use(euler);

const collectEntities = blocks => {
  return collectEntitiesGood(blocks).map(e => e.entity);
}

export enum GraphViewStyle {
  navStack = '935ea2b7-8aa0-5029-b370-50531524afe6',
  currentSpace = '3d2ce5e4-b6e5-5f91-861b-29a19ba12e04',
  connections = '510ebcec-c4ce-5b5d-b0ae-bdf756c0fdc7',
}

@component
export class AllGraph extends Component<{ active?, _onClick?, config?: {
  edges
  properties
  pageLinks
  // all
  limitToSpace
  view: GraphViewStyle
} }> {

  paneIndex;
  stackPointer;

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .space {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  `;
  render() {
    
    let paneIndex, stackPointer;

    let usedSpace;
    let usedObject;
    
    const elements: {
      data: {
        id?: string;
        type?: 'entity' | 'edge' | 'value' | 'statement';
        label?: string;
        source?: string;
        target?: string;
        active?
        directed?
        core?
        // metadata?

        connectionType?: 'property' | 'edge' | 'pageLink';

        entityType?


      }
    }[] = [

    ];

    const map = {};

    function getRoot(entity) {
      if (!entity?.parent) {
        return entity;
      }
      else {
        return getRoot(db.entities.findById(entity.parent));
      }
    }

    const connectsTo: {
      [id: string]: {
        entity
        direction?
      }[]
    } = {
    };

    if (this.props.config.edges) {
      for (const edge of db.edges) {
        if (!db.entities.findById(edge.entities[0]) || !db.entities.findById(edge.entities[1])) {
          continue;
        }

        for (const i of edge.entities) {
          if (!connectsTo[i]) {
            connectsTo[i] = [];
          }
          for (const j of edge.entities) {
            if (i != j) {
              connectsTo[i].push({
                entity: j,
              })
            }
          }
        }
      }
    }
    if (this.props.config.properties) {
      for (const entity of db.entities) {
        for (const attrId in entity.attributes) {
          const attr = db.attributeTypes.findById(attrId);
          if (attr?.type == AttributeType.entities) {
            for (const i of entity.attributes[attrId]) {
              if (!connectsTo[entity._id]) {
                connectsTo[entity._id] = [];
              }
              connectsTo[entity._id].push({
                entity: i,
              });

              if (!connectsTo[i]) {
                connectsTo[i] = [];
              }
              connectsTo[i].push(entity._id);
            }
          }
          else if (attr?.type == AttributeType.entity) {
            if (!connectsTo[entity._id]) {
              connectsTo[entity._id] = [];
            }
            connectsTo[entity._id].push({
              entity: entity.attributes[attrId],
            });

            if (!connectsTo[entity.attributes[attrId]]) {
              connectsTo[entity.attributes[attrId]] = [];
            }
            connectsTo[entity.attributes[attrId]].push({
              entity: entity._id,
            });
          }
        }
      }
    }
    if (this.props.config.pageLinks) {
      for (const entity of db.entities) {
        if (entity.documents) {
          for (const document of entity.documents) {
            const entities = collectEntities(document.content || []);

            for (const i of entities) {
              if (!connectsTo[entity._id]) {
                connectsTo[entity._id] = [];
              }
              connectsTo[entity._id].push({
                entity: i,
              });

              if (!connectsTo[i]) {
                connectsTo[i] = [];
              }
              connectsTo[i].push({
                entity: entity._id,
              });
            }
          }
        }
      }
    
      for (const doc of db.notionDocuments) {
        if (doc.parent?.type == ObjectType.entity) {
          const entities = collectEntities(doc.blocks || []);
          for (const i of entities) {
            if (!connectsTo[doc.parent.id]) {
              connectsTo[doc.parent.id] = [];
            }
            connectsTo[doc.parent.id].push({
              entity: i,
            });

            if (!connectsTo[i]) {
              connectsTo[i] = [];
            }
            connectsTo[i].push({
              entity: doc.parent.id,
            });
          }
        }
      }
    }

    const getConnected = (entityId, space) => {
      const touched = {};
      const connected: {
        entity
        direction?
      }[] = [];
      const queue:{
        entity
        direction?
      }[] = [{entity:entityId, direction: null}];
      while (queue.length > 0) {
        const q = queue.pop();
        if (!touched[q.entity]) {
          touched[q.entity] = true;
          if (space) {
            const e = db.entities.findById(q.entity);
            if (!_.isEqual(x(e?.space), x(space))) {
              continue;
            }
          }
          connected.push(q);
          for (const i of connectsTo[q.entity] || []) {
            queue.push(i);
          }
        }
      }
      return connected;
    }

    const entitiesToInclude = {};
    const coreEntities = {};

    function addConnections(id, space) {
      for (const {entity} of getConnected(id, space)) {
        entitiesToInclude[entity] = true;
      }

    }
    if (this.props.config.view == GraphViewStyle.currentSpace) {
      let space;
      if (this.props.active) {
        const entity = db.entities.findById(this.props.active);
        space = entity.space;
      }
      for (const entity of db.entities) {
        if (!space || entity.space == space) {
          entitiesToInclude[entity._id] = true;
        }
      }

      usedObject = {
        type: ObjectType.space,
        id: space,
      }
    }
    else if (this.props.config.view == GraphViewStyle.connections) {
      coreEntities[this.props.active] = true;
      addConnections(this.props.active, null);
    }
    else if (this.props.config.view == GraphViewStyle.navStack) {
      // const pane = appState.panes[appState.lastActivePane];

      if (isMobile() || appState.panes[appState.lastActivePane]) {
        let entities = [];
        let space;
        paneIndex = appState.lastActivePane;

        const process = current => {
          if (current.type == 'query') {
            const tree = getScopeTree({
              type: ObjectType.query,
              id: current.id,
            });

            // TODO: Modify to use space or mode
            if (this.props.config.limitToSpace) for (const obj of tree) {
              if (obj.type == ObjectType.space) {
                space = obj.id;
                break;
              }
              else if (obj.type == ObjectType.mode) {
                space = {
                  type: ObjectType.mode,
                  id: obj.id,
                }
                break;
              }
            }
            entities = executeQueryObj(current.id);

            usedObject = {
              type: ObjectType.query,
              id: current.id,
            }
            return true;
          }
          else if (current.type == 'spaceEntities') {
            entities = entitiesInSpace(db.spaces.findById(current.space)).map(x => x._id);
            space = current.space;
            usedObject = {
              type: ObjectType.space,
              id: current.space,
            }
            return true;
          }
          else if (current.type == 'page') {
            const doc = db.notionDocuments.findById(current.id);
            entities = collectEntitiesGood(doc.blocks || []).map(x => x.entity);
            usedObject = {
              type: ObjectType.page,
              id: current.id,
            }
            return true;
          }
          else if (current.type == PaneType.pageEntities) {
            const doc = db.notionDocuments.findById(current.id);
            entities = collectEntitiesGood(doc.blocks || []).map(x => x.entity);
            usedObject = {
              type: ObjectType.page,
              id: current.id,
            }
            return true;
          }
        }


        if (isMobile()) {
          for (let i = appState.mobileStack.length - 1; i >= 0; --i) {
            if (process(appState.mobileStack[i])) {
              break;
            }
          }
        }
        else {
          console.log('poooop');
          const pane = appState.panes[appState.lastActivePane];
          for (let i = pane.stack.length - 1; i >= 0; --i) {
            const current = pane.stack[i];
            if (process(current)) {
              stackPointer = i;
              break;
            }
            /* if (current.type == 'query') {
              const tree = getScopeTree({
                type: ObjectType.query,
                id: current.id,
              });
  
              // TODO: Modify to use space or mode
              if (this.props.config.limitToSpace) for (const obj of tree) {
                if (obj.type == ObjectType.space) {
                  space = obj.id;
                  break;
                }
                else if (obj.type == ObjectType.mode) {
                  space = {
                    type: ObjectType.mode,
                    id: obj.id,
                  }
                  break;
                }
              }
              entities = executeQueryObj(current.id);
  
              stackPointer = i;
              usedObject = {
                type: ObjectType.query,
                id: current.id,
              }
              break;
            }
            else if (current.type == 'spaceEntities') {
              entities = entitiesInSpace(db.spaces.findById(current.space)).map(x => x._id);
              space = current.space;
              stackPointer = i;
              usedObject = {
                type: ObjectType.space,
                id: current.space,
              }
              break;
            }
            else if (current.type == 'page') {
              const doc = db.notionDocuments.findById(current.id);
              entities = collectEntitiesGood(doc.blocks || []).map(x => x.entity);
              usedObject = {
                type: ObjectType.page,
                id: current.id,
              }
              stackPointer = i;
            }
            else if (current.type == PaneType.pageEntities) {
              const doc = db.notionDocuments.findById(current.id);
              entities = collectEntitiesGood(doc.blocks || []).map(x => x.entity);
              usedObject = {
                type: ObjectType.page,
                id: current.id,
              }
              stackPointer = i;
            } */
          }
  
          if (_.isNil(stackPointer)) {
            if (this.props.active) {
              let space;
              if (this.props.active) {
                coreEntities[this.props.active] = true;
                const entity = db.entities.findById(this.props.active);
                space = entity.space;
              }
              for (const entity of db.entities) {
                if (!space || entity.space == space) {
                  entitiesToInclude[entity._id] = true;
                }
              }
  
              usedObject = {
                type: ObjectType.space,
                id: space,
              }
        
            }
          }
  
        }

        for (const entity of entities) {
          entitiesToInclude[entity] = true;
          coreEntities[entity] = true;
          addConnections(entity, space);
        }

        usedSpace = space;
      }
  
    }

    this.paneIndex = paneIndex;
    this.stackPointer = stackPointer;

    for (const entity of db.entities) {
      if (!entitiesToInclude[entity._id]) {
        continue;
      }
      // if (!entity.parent) {
        const name = entityDisplayName(entity._id);
        elements.push({
          data: {
            id: entity._id,
            type: 'entity',
            label: name ? name.length > 15 ? name.substr(0, 15) + '...' : name : '',
            active: this.props.active == entity._id ? true : undefined,
            core: coreEntities[entity._id] ? true : undefined,

            entityType: entity.type,
          },
        });  
      // }
      // else {
      //   map[entity._id] = getRoot(entity)?._id;
      // }
    }

    if (this.props.config.edges) {
      for (const edge of db.edges) {
        if (!db.entities.findById(edge.entities[0]) || !db.entities.findById(edge.entities[1]) || !entitiesToInclude[edge.entities[0]] || !entitiesToInclude[edge.entities[1]]) {
          continue;
        }

        elements.push({
          data: {
            id: edge._id,
            type: 'edge',
            directed: edge.directed && 'true',
            source: map[edge.entities[0]] || edge.entities[0],
            target: map[edge.entities[1]] || edge.entities[1],
            connectionType: 'edge',
          },
        });
      }
    }

    if (this.props.config.properties) {
      for (const entity of db.entities) {
        if (!entitiesToInclude[entity._id]) {
          continue;
        }
        for (const attrId in entity.attributes) {
          const attr = db.attributeTypes.findById(attrId);
          if (attr?.type == AttributeType.entities) {
            for (const i of entity.attributes[attrId]) {
              if (!entitiesToInclude[i]) {
                continue;
              }
              elements.push({
                data: {
                  id: entity._id + attrId + i,
                  type: 'edge',
                  directed: 'true',
                  source: entity._id,
                  target: i,
                  connectionType: 'property',
                },
              });
            }
          }
          else if (attr?.type == AttributeType.entity) {
            if (!entitiesToInclude[entity.attributes[attrId]]) {
              continue;
            }
            elements.push({
              data: {
                id: entity._id + attrId + entity.attributes[attrId],
                type: 'edge',
                source: entity._id,
                target: entity.attributes[attrId],
                directed: 'true',
                connectionType: 'property',
              },
            });
          }
        }
      }
    }

    if (this.props.config.pageLinks) {
      for (const entity of db.entities) {
        if (!entitiesToInclude[entity._id]) {
          continue;
        }
        if (entity.documents) {
          for (const document of entity.documents) {
            const entities = collectEntities(document.content || []);

            for (const i of entities) {
              if (!entitiesToInclude[i]) {
                continue;
              }
              elements.push({
                data: {
                  id: entity._id + 'page' + i,
                  type: 'edge',
                  source: entity._id,
                  target: i,
                  directed: 'true',
                  connectionType: 'pageLink',
                },
              });
            }
          }
        }
      }

      for (const doc of db.notionDocuments) {
        if (doc.parent?.type == ObjectType.entity) {
          if (!entitiesToInclude[doc.parent?.id]) {
            continue;
          }
          const entities = collectEntities(doc.blocks || []);
          for (const i of entities) {
            if (!entitiesToInclude[i]) {
              continue;
            }
            elements.push({
              data: {
                id: doc.parent?.id + 'page' + i,
                type: 'edge',
                source: doc.parent?.id,
                target: i,
                directed: 'true',
                connectionType: 'pageLink',
              },
            });
          }
        }
      }
    }


    let _cy;


    console.log(usedObject);
    useEffect(() => {
      // cy is available here
      _cy.on('tap', 'node', (evt) => {
        let node = evt.target;
        // console.log('tapped ' + node.id());
        this.props._onClick(node?.id(), this.paneIndex, this.stackPointer);
      });
    }, []);

    const mmm = {};
    for (const el of elements) {
      if (el.data.type != 'edge') {
        mmm[el.data.id] = true;
      }
    }

    const toDelete = [];
    for (let i = 0; i < elements.length; ++ i) {
      const el = elements[i];
      if (el.data.type == 'edge') {
        if (!mmm[el.data.source] || !mmm[el.data.target]) {
          toDelete.push(i);
        }
      }
    }

    for (let i = toDelete.length - 1; i >= 0; -- i) {
      elements.splice(toDelete[i], 1);
    }
    
  
  

    return (
      <>
        {usedObject && <span className="space"><ObjectDisplay obj={usedObject} showPath /></span>}
        <CytoscapeComponent
          elements={elements}
          style={ { width: '100%', height: '100%' } }
          stylesheet={[
            {
              selector: 'node',
              style: {
                'background-color': '#666',
                'label': 'data(label)',
              }
            },

            {
              selector: 'node[entityType="6463c87f193ab16879bc8e8c"]',
              style: {
                'background-image': imgBlob('icons8-person'),
                'background-image-containment': 'inside',
                'shape': 'round-rectangle',
              }
            },

            {
              selector: 'node[type="entity"]',
              style: {
                'width': 20,
                'height': 20,

              }
            },

            {
              selector: 'node[type="entity"][?active]',
              style: {
                // 'background-color': '#bb2e2e',
                'border-color': '#bb2e2e',
                'border-width': 3,
                // 'width': 20,
                // 'height': 20,
                'width': 17,
                'height': 17,
                "font-weight": "bold",
                'color': '#bb2e2e',

              }
            },

            {
              selector: 'node[type="entity"][?core]',
              style: {
                'background-color': '#2ebb33',
                // 'width': 20,
                // 'height': 20,
                "font-weight": "bold",

              }
            },

            // {
            //   selector: 'node[type="entity"][?core][?active]',
            //   style: {
            //     'background-color': '#bb7e2e',
            //     // 'width': 20,
            //     // 'height': 20,
            //     "font-weight": "bold",

            //   }
            // },


            {
              selector: 'node[type="statement"]',
              style: {
                'background-color': '#5151a3',
                'font-size': '12px',
                'width': 10,
                'height': 10,
                'color': 'gray',
              }
            },

            {
              selector: 'node[type="value"]',
              style: {
                'background-color': '#5151a3',
                'width': 5,
                'height': 5,
                color: '#cb9b42',

              }
            },


            {
              selector: 'edge',
              style: {
                'width': 2,
                'line-color': '#959595',
                'curve-style': 'bezier',
                'font-size': 8,
                "text-rotation": "autorotate",
              }
            },

            // {
            //   selector: 'edge[type="edge"]',
            //   style: {
            //     'line-color': '#d6d6d6',

            //     // 'target-arrow-color': '#ccc',
            //     // 'target-arrow-shape': 'triangle',
            //     'curve-style': 'bezier',
            //     // 'label': 'data(label)',
            //     // 'color': '#ccc',
            //     'font-size': 8,
            //     'color': 'gray',

            //   }
            // },

            {
              selector: 'edge[connectionType="pageLink"]',
              style: {
                'line-color': '#4188eb',
                'target-arrow-color': '#4188eb',
              }
            },

            {
              selector: 'edge[connectionType="property"]',
              style: {
                'line-color': '#bb7f4b',
                'target-arrow-color': '#bb7f4b',
              }
            },

            {
              selector: 'edge[directed="true"]',
              style: {
                'target-arrow-shape': 'triangle',
                // 'line-color': '#bcbcbc',

              }
            },


          ]}
          layout={
            {
              name: 'd3-force',
              animate: true,
              maxIterations: 1000,
              fixedAfterDragging: false,
              linkId: function id(d) {
                return d.id;
              },
              linkDistance: 80,
              manyBodyStrength: -300,
              ready: function(){},
              stop: function(){},
              tick: function(progress) {
                // console.log('progress - ', progress);
              },
              randomize: false,
              infinite: false
              // some more options here...

            } as any
          }
          cy={(cy) => {
            _cy = cy;
          }}
        />
      </>
    );
  }
}
