import { Component } from "react";
import { component, styled } from "../component2";
import { NotionDocument } from "../components/notionDocument/NotionDocument";
import { XInit, XObject } from "../XObject";
import { MyBlock, MyBlockManager } from "../MyNotionDocument/MyBlockManager";
import { renderBlock } from "../MyNotionDocument/renderBlock2";
import { types } from "../MyNotionDocument/types";

@component
export class NotionDocumentTestWindow extends Component<{ window; }> {
  static styles = styled.div`
    .--entity-- {
      color: #0275ff;
      cursor: pointer;
    }
    .--capture-- {
      border-bottom: 1px dashed #d0d0d0;
    }

    [data-type="blockCont"] > .block.activeBlock {
      /* background: #f3f3f3; */
      .entityDot, .dataBindingDot {
        opacity: 1;
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-left: -2px;
      }
    }

  `;
  state = XInit(class {
    title = '';
    blocks = [
      XObject.obj({
        children: [],
      }),
    ]
  })
  memory = {};
  render() {
    return (
      <NotionDocument
        title={this.state.title}
        setTitle={title => this.state.title = title}
        renderBlock={renderBlock}

        types={types}
        blockManager={new MyBlockManager(() => this.state.blocks, blocks => this.state.blocks = blocks, {
          baseEntity: null,
          ctx: {},
          extendEntity: null,
          memory: this.memory,
        })}
        menuIniters={{
          '/': [
            {
              label: 'To-do list',
              action: (block: MyBlock) => {
                block.block.type = '89f7170f-3596-517d-bbba-319689893e94';
              }
            }
          ],
          // '@': [],
        }}
      />
    );
  }
}