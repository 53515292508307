import { XInit } from "./XObject";

const state = XInit(class {
  isMobile
})

function update() {
  state.isMobile = window.innerWidth < 600;

}

update();

setInterval(() => {
  update();
}, 100);

export function isMobile() {
  return state.isMobile;
}

export function isCordova() {
  // return true;
  return window['cordova'];
}

export function useMobileNavSystem() {
  // return true;
  return isMobile() && isCordova();
}

export function isMobileBrowse() {
  return isMobile() && !isCordova();
}

export function isDevMode() {
  return false;
}