import React from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";

const Set = '1adbff09-6a5e-55e0-a0be-73026a61a90c'

registerChangeAction(Set, {
  name: 'Set String',
});

@component
export class ArgValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`
    /* color: #ca8f76; */
  `;
  timerId
  render() {
    return (
      <>
        %<_Editor inline id={this.props.elId + this.props.tick} defaultContent={this.props.state.content} onChange={(_, v) => {
          clearTimeout(this.timerId);
          this.timerId = setTimeout(() => {
          if (v !== this.props.state.content && this.props.state.type?.[0] == ValueType.Arg) {
            trackChange(Set, {}, [
              [ UndoActions.modifiedValuePoint, this.props.state._id ],
            ]);
  
            this.props.state.content = v;
          }
        }, 1000);
        }} />
      </>
    );
  }
}

export const registerArgType = () => {
  registerType(ValueType.Arg, {
    evaluate: (value, map) => {
      return map['%' + value.content];
      // return value.content;
    },
    render: (value, map, state, renderType) => {
      return <span key={value._id} data-value-point={value._id}>%{value.content}</span>;
    },
    editorComp: ArgValuePoint,
  });
};