import { Component } from 'react';
import { component } from './component2';
import { x } from './XObject';
import { db } from './db';
import { MyBlockManager } from './MyNotionDocument/MyBlockManager';
import { types } from "./MyNotionDocument/types";
import { componentSystem } from './componentSystem';
import { openWindow } from './osHelpers';
import { WindowType } from './etc/WindowType';
import { CodeComponenType } from './ideIndex';

@component
export class NotionDocument_CodePartEditorWindow extends Component<{ window; }> {
  memory = {};
  render() {
    const doc = db.notionDocuments.findById(this.props.window.doc);

    const blockManager = new MyBlockManager(() => doc.blocks, () => {}, {
      ctx: {
        types: types,
      }
      ,memory: this.memory
    });

    const block = blockManager.findBlock(this.props.window.block);
    console.log(block, x(block.getContent()));

    const content = block.getContent();

    let p;

    for (const part of content) {
      if (part[1] == 'code') {
        if (part[0]?.id == this.props.window.part) {
          console.log(x(part[0]));
          p = part;
          break;
        }
      }
    }


    return (
      <>
        {!p?.[0]?.component && (
          <>
            <button
              onClick={() => {
                const comp = componentSystem.createComponent(CodeComponenType.function);
                p[0].component = comp._id;
                doc.tick = (doc.tick || 0) + 1;
              }}
            >Func</button>
          </>
        )}

        {p?.[0]?.component && (

          <>

            <button
              onClick={() => {
                openWindow({
                  type: WindowType.CodeComponent,
                  component: p[0].component,

                })
              }}
            
            >Edit</button>
          </>
        )}
      </>
    )
  }
}
