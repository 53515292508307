import React from 'react';
import jQuery from 'jquery';
import classNames from "classnames";
import _ from 'lodash';
import { EntityViewType } from "../components/EntityRow";
import { db } from "../db";
import { execute, mapStructure } from "../glue/main";
import { $DocumentCheckbox } from "../glue/structs/$Document";
import { resolveEntityBinding } from "../glue/structs/resolveBinding";
import { Wrapper } from "./Wrapper";
import { ObjectType } from "../types/ObjectRef";
import { Svg } from "../components/Svg";
import { Code } from "./Code";
import { Badge, EntityBadges, RecordBadges } from "./Badge";
import { EntityPath } from "../components/EntityPath";
import { MyBlock } from "./MyBlockManager";
import { CheckBox } from "./CheckBox";
import { RenderData } from "./Editor";
import { component } from "../component";
import { Component } from "react";
import { styled } from "../component2";
import { color } from "../components/notionDocument/color";
import { x } from "../XObject";
import { getCodeComponent } from "../pushCode";
import { FileUpload, uploadedFileUrl } from "../components/FileUpload";
import { isMobile } from '../isMobile';

@component
export class AttributeBadges extends Component<{ block }> {
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }

    align-items: center;

    .type {
      font-size: 10px;
      color: ${color('block.metaLine.text')};
      margin-left: 2px;
    }
  `;
  render() {
    const { block } = this.props;
    if (!block.attributes) return null;

    return (
      <>
        {Object.keys(x(block.attributes))?.map?.(attr => {
            const value = block.attributes[attr];
            return (
              <Badge
                key={attr}
                text={`${db.attributeTypes.findById(attr).name}: ${value}`}
              />
            );
        })}
      </>
    );
  }
}

@component
export class CodeComponentBadges extends Component<{ block, page }> {
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }
    align-items: center;
  `;
  render() {
    const { block } = this.props;
    return (
      <>
        {this.props.page?.blockCodeComponents?.map?.(ud => {
          const comp = getCodeComponent(ud);
          let value;
          try {
            value = comp?.({ blockId: block._id, pageId: this.props.page._id });
          }
          catch (e) {
            value = 'error';
          }
          if (_.isNil(value)) return null;
            return (
              <Badge
                key={ud}
                text={value}
              />
            );
        })}
      </>
    );
  }
}

@component
class MediaBlock extends Component<{ block }> {
  static styles = styled.div`
    .media {
      display: block;
      height: 300px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  `;
  render() {
    const { block } = this.props;
    return (
      <FileUpload
        onUpload={file => {
          block.media = file;
        }}
      >
        {block.media && (
          <span className="media"
            style={{
              backgroundImage: `url(${uploadedFileUrl(block.media)})`,
            }}
          />
        )}
        {!block.media && 'No media'}
      </FileUpload>
    )
  }
}

@component
class Asdf extends Component<{ func }> {
  render() {
    this.props.func();
    return null;
  }
}

export function renderBlock(
  block2: MyBlock,
  {
    onMouseDownGrip,
    draggingId,
    beforeChange,
    changed,
    activeBlock,
    noChildren = false,
    onClickAddBlock,
    onContextMenu,
    ctx,
  },
  args: {
    docId
    onClickEntityDot,
  }
) {
  const onClickEntityDoc = args.onClickEntityDot;
  const blockTypes = [];
  const block: { _id, children, type, checked, id, record, dataBinding, attributes, media } = block2.block;
  const blockType = blockTypes.find(b => b._id == block.type);
  let checkbox = blockType?.elements?.find?.(e => e.type == 'checkbox');
  if (block.id) {
    const entity = db.entities.findById(block.id);
    if (entity?.type) {
      const type = db.entityTypes.findById(entity.type);
      const blockView = type.views?.find?.(v => v.viewType == EntityViewType.block);
      if (blockView) {
        if (blockView.valuePoint) {
          try {
            const mapped = mapStructure(execute(blockView.valuePoint));
            const a = mapped.elements?.content?.find?.(e => e.type?.[1] == $DocumentCheckbox.$);
  
            if (a) {
              const mappedA = mapStructure(a);
              
              checkbox = {
                binding: {
                  get: (entityId) => {
                    return resolveEntityBinding(entityId, mappedA.binding, {}).get();
                  },
      
                  set:  (entityId, value) => {
                    return resolveEntityBinding(entityId, mappedA.binding, {}).set(value);
                  }
                }
              }
            }
          }
          catch (e) {
            console.log(e);
          }
        }
      }
    }
  }

  let meta = [];

  if (block.id) {
    meta.push(<EntityBadges key="entity" block={block} entity={db.entities.findById(block.id)} blockType={blockType} />)
  }

  if (block.attributes) {
    meta.push(<AttributeBadges key="attributes" block={block} />)
  }

  meta.push(<CodeComponentBadges key="CodeComponentBadges" block={block} page={db.notionDocuments.findById(args.docId)} />)

  const isChecked = () => {
    return checkbox ? checkbox.binding.get(block.id) : block.type == 'checkItem' && block.checked;
  }
  const checked = isChecked();
  const ref = React.createRef<any>();

  return (
    <Wrapper
      key={block._id}
      ref={ref}
      data-value-point={blockType?._id}
      data-block-type={blockType?._id}
      data-entity={block.id}
      data-record={block.record}
      data-type="blockCont"
      className={classNames(block.type, {
        dragging: block._id == draggingId,
        hasCheckbox: checkbox || block.type == 'checkItem',
        checked: checked,
        mobile: isMobile(),
      })}
    >
      <Asdf func={() => {
        if (isChecked()) {
          jQuery(ref.current).addClass('checked');
        }
        else {
          jQuery(ref.current).removeClass('checked');
        }
      }} />
      <div 
        data-ui-binding={block.id && JSON.stringify({
          viewType: EntityViewType.block,
          entity: block.id,
          context: {
            type: ObjectType.page,
          }
        })}
        data-block-id={block._id}
        data-inspect-id={block.id}
        className={classNames('block', {
          activeBlock: block._id == activeBlock,
          mobile: isMobile(),
        })}
      >
        {/* <Svg name="plus" className="addBlock"
          onClick={e => {
            onClickAddBlock(e, block);
          }}
        /> */}

        {!isMobile() && (
          <>
            <Svg name="grip" className="grip"
              onMouseDown={e => {
                onMouseDownGrip(e, block);
              }}
              onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                onContextMenu(e, block);
              }}
            />

          </>
        )}
        {block.type == 'code' && (
          <>
            <div contentEditable={false} className="codeBlock">
              <Code block={block} />
            </div>
          </>
        )}
        {block.type == 'media' && (
          <>
            <div contentEditable={false} className="mediaBlock">
              <MediaBlock block={block} />
            </div>
          </>
        )}
        {block.type != 'code' && block.type != 'media' && (
          <>
            {block.id && (
              <span 
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData('text/plain', block.id);
                }}
                className="entityDot"  contentEditable={false}
              
                onClick={() => {
                  onClickEntityDoc(block.id);
                }}
              />
            )}
            {block.dataBinding && (
              <span 
                className="entityDot dataBindingDot" draggable contentEditable={false}
              
                onClick={() => {
                  onClickEntityDoc(block.id);
                }}
              />
            )}
            {(checkbox || block.type == 'checkItem') && <CheckBox dataValuePoint={checkbox?._id} checkbox={checkbox} block={block} _onBeforeChange={beforeChange} _onChange={changed} />}
            {/* <div className="editor" dangerouslySetInnerHTML={{ __html: expandToHtml(ctx, x(block.data) || []) }} data-type="blockData" /> */}
            <RenderData tag="div" attrs={{
              className: 'editor',
              'data-type': "blockData",
            }} ctx={ctx} data={block2.getContent()} args={{
              blockId: block._id,
              pageId: args.docId,
            }} />

            {meta.length > 0 && (
               <div className="meta" style={{display:'none'}} contentEditable={false}>
                {meta}
               </div>
            )}
            {block.id && (
              <div className="metaLine" contentEditable={false}>
                <EntityPath entity={block.id} />
              </div>
            )}
          </>
        )}
      </div>
      {!noChildren && (
        <div className="children" data-type="blockChildren">
          {block2.hasChildren() && block2.getChildren().map(b => renderBlock(b, {
            onMouseDownGrip,
            draggingId,
            changed,
            activeBlock,
            beforeChange,
            onClickAddBlock,
            onContextMenu,
            ctx,
          }, args))}
        </div>
      )}
    </Wrapper>
  );
}
