import React, { Component } from 'react';
import { component } from '../component2';
import { XObject } from '../XObject';
import { EntityMatchEditor } from './EntityMatchEditor';
import { TRelMatch } from "../types/TRelMatch";


@component
export class RelMatchEditor extends Component<{ value: TRelMatch; }> {
  render() {
    return (
      <>
        <select
          value={this.props.value.direction}
          onChange={(e) => {
            this.props.value.direction = e.target.value as any || null;
          }}
        >
          <option value="any">Any</option>
          <option value="undirected">Undirected</option>
          <option value="out">&gt;</option>
          <option value="in">&lt;</option>
        </select>

        <EntityMatchEditor value={XObject.get(this.props.value, 'entityMatch', {})} />
      </>
    );
  }
}
