import { ValueType } from '../ValueType';
import { typeRegistry } from '../typeRegistry.1';

const $EntityMatch = typeRegistry.registerType({
  $: '26207c90-eff1-5fd9-a65e-6d1039135d24',
  Entries: {
    $: 'db11619d-3186-5dda-84c8-00447342c933',
    Not: '1265e387-5def-5c15-abf2-849ff7b90f98',
    Entry: '82773fe0-cc39-540b-895c-bf5dbc593944',
  }
}, ids => ({
  _id: ids.$,
  name: 'Entity Match 2',
  definition: [
    {
      id: ids.Entries.$,
      name: 'Entries',
      type: [ValueType.Array, [ValueType.Structure, 'b0fd1a40-d757-5d73-b68d-e969b007fbce', [
        {
          id: ids.Entries.Not,
          name: 'Not',
          type: [],
          property: 'not',
        },
        {
          id: ids.Entries.Entry,
          name: 'Entry',
          type: [],
          property: 'entry',
        },
      ]]],
      property: 'entries',
    }
  ]
}));
