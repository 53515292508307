import React from 'react';
import _ from 'lodash';
import { component, styled } from '../component2';
import classNames from 'classnames';
import Around from './Around';
import fuzzy from 'fuzzy';
import { XInit } from '../XObject';

@component
class DropTarget extends React.Component<any> {
  render() {
    return this.props.children;
  }
}

@component
export class Selector extends React.Component<{ beforeSelect?(value, clear), showOnFocus?: boolean, autoFocus?: boolean, create?(filter): Promise<any>, createView?, onFilterChange?, entries: { key: string, display: any, filter?: string }[], onSelected(value, clear, filter?): void, onEsc?, className?, onNew?, onDrop?, placeholder?, maxEntries?: number }> {
  refs;
  dropKey;
  state = XInit(class {
    state: any;
    selectedIndex = 0
    filter;
    newValue;
    focused = false;
  });
  newKey;
  static styles = styled.span``;

  static t = {
    entry: styled.li`
      cursor: pointer;
      &:hover {
        font-weight: bold;

      }
    `,

    input: styled.input``,
  }

  componentDidUpdate() {
    if (this.props.autoFocus && this.refs.filter) {
      this.refs.filter.focus();
    }
  }
  entriesLength() {
    return this.entries().length + (this.props.create ? 1 : 0);
  }
  selectedValue() {
    if (this.dropKey) {
      return this.dropKey;
    }
    else if (this.state.state == 'new') {
      return this.newKey;
    }
    else {
      return this.entries().length ? this.entries()[this.state.selectedIndex].key : null;
    }
  }
  entries() {
    let list;
    if (this.props.onFilterChange) {
      list = this.props.entries;
    }
    else {
      if (this.props.showOnFocus && this.state.focused && !this.state.filter) {
        list = this.props.entries;
      }
      else {
        list = this.state.filter ? this.props.entries.filter(entry => {
          return (entry.filter || entry.display) && fuzzy.test(this.state.filter, (entry.filter || ((_.isFunction(entry.display) ? entry.display() : entry.display) || '').toString()));
        }) : [];  
      }
    }

    if (this.props.maxEntries) return list.slice(0, this.props.maxEntries);
    else return list;
  }
  clear() {
    this.state.filter = '';
    this.refs.filter.value = '';
    if (this.props.onFilterChange)
      this.props.onFilterChange('');
  }
  render() {
    const { t } = Selector;
    const keyPress = async (event) => {
      if (event.key == 'Escape') {
        if (this.props.onEsc)
          this.props.onEsc();
      }
      else if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (this.entriesLength()) {
          this.state.selectedIndex = (this.state.selectedIndex + 1) % this.entriesLength();
        }
        return false;
      }
      else if (event.key === 'ArrowUp') {
        event.preventDefault();

        if (this.entriesLength()) {
          this.state.selectedIndex = (this.state.selectedIndex - 1 + this.entriesLength()) % this.entriesLength();
        }
        return false;
      }
      else if (event.key === 'Enter') {
        let value;
        if (!this.props.beforeSelect || this.props.beforeSelect?.(this.state.filter, () => this.clear()) !== false) {
          if (this.props.create && this.state.selectedIndex == this.entries().length) {
            value = await this.props.create(this.state.filter);
            if (this.props.createView) {
              this.state.newValue = value;
              this.clear();
              return;
            }
          }
          else {
            value = this.selectedValue();
          }
          console.log(value);
          this.props.onSelected(value, () => this.clear(), this.state.filter);  
        }
        return false;
      }
    }

    if (this.state.state == 'new') {
      return (<span className={classNames('selector', this.props.className)}>
        <input type="text" onKeyDown={async (e) => {
          if (e.key == 'Enter') {
            let key = await this.props.onNew(e.target['value']);
            this.newKey = key;
            this.props.onSelected(key, () => {
              e.target['value'] = '';
            });
          }
        }} />
        <button onClick={() => this.state.state = 'select'}>select</button>
      </span>);
    }
    else {
      const entries = this.entries();
      return (
        <DropTarget onDrop={(item) => {
          if (this.props.onDrop) {
            let key = this.props.onDrop(item);
            this.dropKey = key;
            this.props.onSelected(key, () => { });
          }
        }} className={classNames('selector', this.props.className)}>
          <t.input autoFocus={this.props.autoFocus} ref="filter" placeholder={this.props.placeholder} onKeyDown={keyPress} onChange={(e) => {
            this.state.filter = e.target.value;
            if (this.props.onFilterChange)
              this.props.onFilterChange(e.target.value);
            }
          } type="text" onFocus={() => this.state.focused = true} onBlur={() => setTimeout(() => this.state.focused = false, 300)} />

          {this.props.onNew && <button onClick={() => this.state.state = 'new'}>new</button>}

          {this.state.newValue && (
            <Around position="below" parents={[ 'selector' ]} anchor={() => this.refs.filter}>
              <div className="autocomplete entities">
                {this.props.createView(this.state.newValue)}
                <button onClick={() => {
                  this.props.onSelected(this.state.newValue, () => {});
                  this.state.newValue = null;
                }}>done</button>
              </div>
            </Around>
          )}

          {(entries.length > 0 || this.props.create && this.state.filter) && (
            <Around position="below" parents={[ 'selector' ]} anchor={() => this.refs.filter}>
              <ul className="autocomplete entities">
                {entries.map((entry, i) => (<t.entry onClick={() => {
                  this.props.onSelected(entry.key, () => this.clear(), this.state.filter);
                }} className={classNames({ selected: i === this.state.selectedIndex })} key={entry.key}>{(_.isFunction(entry.display) ? entry.display() : entry.display) || <span>&mdash;</span>}</t.entry>))}
                {this.props.create && <t.entry className={classNames({ selected: this.state.selectedIndex == entries.length })}><i>Create "{this.state.filter}"</i></t.entry>}
              </ul>
            </Around>)}
        </DropTarget>
      );
    }
  }
}
