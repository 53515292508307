import { ValueType } from "../ValueType";
import { typeRegistry } from "../typeRegistry.1";

export const $GroupedSelectAttribute = typeRegistry.registerType({
  $: '6ae7ecdb-4de9-5d83-9ab1-877696044424',
  Groups: {
    $: '98fafa74-1678-55b4-bd17-0815164cb08e',
    $$: '5a34f65c-472b-5ef3-871d-36f70cc5038b',
    Name: 'bd121f65-50c6-50fe-a0e0-cf65d1f596dd',
    ZIndex: 'dc35a460-6b33-583e-89e7-3214fa36cd26',
    Color: '9780a538-fd38-5944-a26b-556727e8b156',
    Entries: {
      $: '28d83fba-341d-59a5-aac8-1bcdde14e9dd',
      $$: 'a7caab1b-41e3-5e0c-a250-a44b19896e6a',
      Name: 'b45dd36a-4d5e-50b4-ac42-e962422823ff',
    }
  }
}, ids => ({
  _id: ids.$,
  name: 'Grouped Select Attribute',
  definition: [
    {
      id: ids.Groups.$,
      name: 'Groups',
      type: [ValueType.Array, [ValueType.Structure, ids.Groups.$$, [
        {
          id: ids.Groups.Name,
          name: 'Name',
          type: [],
          property: 'name',
        },
        {
          id: ids.Groups.ZIndex,
          name: 'Z Index',
          type: [],
          property: 'zIndex',
        },
        {
          id: ids.Groups.Color,
          name: 'Color',
          type: [],
          property: 'color',
        },
        {
          id: ids.Groups.Entries.$,
          name: 'Entries',
          type: [ValueType.Array, [ValueType.Structure, ids.Groups.Entries.$$, [
            {
              id: ids.Groups.Entries.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
          ]]],
          property: 'entries',
        },
      ]]],
      property: 'groups',
    }
  ]
}));