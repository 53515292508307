import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { EntityGraphView } from '../components/EntityGraphView';
import { getEdgesForEntity } from '../etc/getEdgesForEntity';


@component
export class GraphWindow extends Component<{ window; }> {
  static styles = styled.div`
    > .entity {
      position: absolute;
      top: 0;
      left: 0;
      z-index:999;
    }
  `;

  render() {

    const workspace = defaultWorkspace();
    const graph = workspace.graphs.find(x => x._id == this.props.window.graph);

    function getMatch(entity) {
      return graph?.graph?.matches?.find?.(x => !x.match?.type || x.match?.type == entity.type);
    }

    function processEntity(id) {
      const entity = db.entities.findById(id);
      const match = getMatch(entity);
      if (!match) {
        return [];
      }



      const edges = getEdgesForEntity(id); // db.edges.filter(x => x.entities.includes(id));
      const entities = [];
      for (const edge of edges) {
        const otherId = edge.entities.find(x => x != id);
        const other = db.entities.findById(otherId);
        for (const relMatch of match.rels) {
          if (!relMatch.entityMatch.type || other.type == relMatch.entityMatch.type) {
            if (relMatch.direction == 'any') {
              entities.push(otherId);
            }
            else if (relMatch.direction == 'in') {
              if (edge.entities[0] == otherId) {
                entities.push(otherId);
              }
            }
            else if (relMatch.direction == 'out') {
              if (edge.entities[1] == otherId) {
                entities.push(otherId);
              }
            }
            // else if (relMatch.direction == 'undirected') {
          }
        }

      }

      const result = [];
      for (const entity of entities) {
        result.push({
          entity,
          parent: id,
        });
        result.splice(result.length, 0, ...processEntity(entity));
      }

      return result;
    }

    const nodes: {
      entity;
      parent;
    }[] = this.props.window.entity ? [
      {
        entity: this.props.window.entity,
        parent: null,
      }
    ] : [];

    if (this.props.window.entity)
      nodes.splice(nodes.length, 0, ...processEntity(this.props.window.entity));

    return (
      <>
        <div className="entity">
          <PropertyField object={this.props.window} property="entity" />
        </div>
        {this.props.window.entity && <EntityGraphView
          graph={this.props.window.graph}
          rootEntity={this.props.window.entity}
          state={XObject.get(this.props.window, 'state', {})} />}
        {/* {this.props.window.entity && <GraphView
                  _state={XObject.get(this.props.window, 'state', {})}
                  data={nodes.map(x => ({
                    id: x.entity,
                    name: (() => {
                      const entity = db.entities.findById(x.entity);
                      const name = entity.name;
        
                      const parts = [];
        
                      if (name) {
                        parts.push(name);
                      }
        
                      if (entity.type) {
                        const type = db.entityTypes.findById(entityain);
                        if (type) {
                          parts.push(`(${type.name})`);
                        }
                      }
        
                      return parts.join(' ');
        
                    })(),
                    meta: {
                      parent: x.parent,
                    }
                  }))}
                  root={this.props.window.entity}
                />} */}
      </>
    );
  }
}
