import React, { Component } from "react";
import { component, styled } from "../component";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


@component
export class Code extends Component<{ block; }> {
  static styles = styled.div`
    pre {
      margin: 0;
    }
    textarea {
      width: 100%;
      height: 500px;
    }
  `;
  render() {
    const { block } = this.props;
    return (
      <>
        {block['editing'] && (
          <>
            <textarea defaultValue={block['code']}

              onChange={(e) => {
                block['code'] = e.target.value;
              }}
            ></textarea>

            <button
              onClick={() => {
                block['editing'] = !block['editing'];
              }}
            >Done</button>
          </>
        )}
        {!block['editing'] && (
          <>

            <SyntaxHighlighter
              // lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
              onDoubleClick={() => {
                block['editing'] = true;
              }}
              language="javascript" style={docco}>
              {block['code']}
            </SyntaxHighlighter>
          </>
        )}



      </>
    );
  }
}
