import { appState } from './etc/appState';
import { ObjectType } from './types/ObjectRef';
import { InspectState } from './components/InspectState';

export function viewObject(obj, ctx) {
  if (obj.type == ObjectType.codeComponent) {
    appState.appInspect = {
      mode: InspectState.code,
      component: obj.id,
    };

  }
  else if (obj.type == ObjectType.page) {
    ctx.navigate({
      type: 'page',
      id: obj.id,
    })
  }
}
