import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { x, XObject } from '../XObject';
import { objectName } from "../components/objectFuncs";
import { ObjectType } from '../types/ObjectRef';


@component
export class SpaceWindow extends Component<{ window; }> {
  render() {
    const space = db.spaces.findById(this.props.window.space);
    return (
      <>
        <button
          onClick={() => {
            console.log(x(space));
          }}
        >.</button>

        <PropertyField object={space} property="name" />

        <div>
          <PropertyField object={space} property="config" />
        </div>

        <ul>
          {space.imports?.map?.((x, i) => {
            return (
              <li key={x._id}>
                {objectName(x)} <button
                  onClick={() => {
                    space.imports.splice(i, 1);
                  }}
                >-</button>
              </li>
            );
          })}
        </ul>

        <select
          onChange={e => {
            XObject.push(space, 'imports', XObject.obj({
              type: ObjectType.library,
              id: e.target.value,
            }));
            e.target.value = '';
          }}
        >
          <option />
          {db.libraries.map(library => {
            return (
              <option key={library._id} value={library._id}>{library.name}</option>
            );
          }
          )}
        </select>
      </>
    );
  }
}
