import { db } from '../db';
import { appState } from './appState';
import { XObject } from '../XObject';

export function getSidebar() {
  if (appState.currentMode) {
    const mode = db.modes.findById(appState.currentMode);
    if (mode?.sidebar) {
      return db.sidebar.findById(mode.sidebar);
    }
    else {
      return db.sidebar.find(s => s.key == 'root');
    }
  }
  else {
    return db.sidebar.find(s => s.key == 'root');
  }
}
