import { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';


@component
export class PriorityIcon extends Component<{ priority? }> {
  static styles = styled.span`
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
  `;
  render(Container?) {

    if (this.props.priority == 1) {
      return <Container title={this.props.priority}>🔥</Container>
    }
    else {
      const priority = this.props.priority ? (4 - (this.props.priority)) + 1 : 0;
      return (
        <Container title={this.props.priority}>
        <svg fill="#858699" width="16" height="16" viewBox="0 0 16 16" aria-label="">
          <rect x="1" y="8" width="3" height="6" rx="1" fillOpacity={priority >= 1 ? undefined : '0.4'}></rect>
          <rect x="6" y="5" width="3" height="9" rx="1" fillOpacity={priority >= 2 ? undefined :'0.4'}></rect>
          <rect x="11" y="2" width="3" height="12" rx="1"  fillOpacity={priority >= 3 ?undefined :'0.4'}></rect>
        </svg>
        </Container>
      );
  
    }

  }
}
