import { connectWs } from './connectWs';
import ReconnectingWebSocket from 'reconnecting-websocket';

class ChatGPTConversationManager {
  constructor() {
  }

  socket: ReconnectingWebSocket;

  async init() {
    this.socket = await connectWs('chatGpt', {
      onMessage: (msg) => {
        const m = JSON.parse(msg.data);
        const conv = this.conversations[m.conversation];
        conv.onResponse(m);
      },
      onClose: () => {
      },
      onOpen: () => {
      },
    });
  }

  nextId = 1;

  conversations = {};

  createConversation() {

    const conv = new ChatGPTConversation(this.nextId++, this);

    this.conversations[conv.id] = conv;

    return conv;
  }
}
export const chatGptManager = new ChatGPTConversationManager();
export class ChatGPTConversation {
  constructor(public id, public manager: ChatGPTConversationManager) {
  }

  onResponse(resp) {
    this.messageResolvers[resp.message](resp.content);
    delete this.messageResolvers[resp.message];
  }

  nextMessageId = 1;
  messageResolvers = {};
  async sendMessage(message): Promise<string> {
    let messageId = this.nextMessageId++;

    this.manager.socket.send(JSON.stringify({
      conversation: this.id,
      message: messageId,
      content: message,
    }));

    const p = new Promise<string>((resolve, reject) => {
      this.messageResolvers[messageId] = resolve;
    });

    return p;
  }

  close() {
  }
}
