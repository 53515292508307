import React, { Component } from 'react';
import { component } from '../component2';
import { XObject } from '../XObject';
import { PropertyField } from '../components/PropertyField';
import { showContextMenu } from '../helpers';
import { db } from '../db';
import copyToClipboard from 'copy-to-clipboard';
import { createEntity } from '../etc/createEntity';


@component
export class EntityField extends Component<{ value; setValue; }> {
  render() {
    return <><span
      onContextMenu={e => {
        e.preventDefault();
        showContextMenu(e, [
          {
            text: 'Copy ID',
            onClick: () => {
              copyToClipboard(this.props.value);
            }
          }
        ]);
      }}
    ><PropertyField get={() => this.props.value} set={value => this.props.setValue(value)} display={value => {
      return value && db.entities.findById(value)?.name || value;
    }} /></span> {!this.props.value && <button onClick={() => {
      const newEntity = XObject.obj({});
      createEntity(newEntity, null);
      this.props.setValue(newEntity._id);
    }}>new</button>}</>;
  }

}
