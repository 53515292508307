import { db } from "../../db";
import _ from 'lodash';
import { x, XClone, XObject } from "../../XObject";
import { CompiledValuePoint, evaluate, mapStructure } from "../main";
import { ValueType } from "../ValueType";
import { $StatementDataBinding } from "./$StatementDataBinding";
import { setEntityState } from "./$Document";
import { $EntityNameBinding } from "./$EntityTemplate";
import { $EntityAttributeBinding } from "./$EntityAttributeBinding";

export function resolveEntityBinding(entityId, binding, map) {
  if (binding?.type?.[0] == ValueType.EntityState) {
    let set, get;

    const stateValue = evaluate(binding, map);
    const state = db.stateTypes.find(st => st.values.find(v => v._id == stateValue));

    get = () => {
      const entity = db.entities.findById(entityId);
      return entity?.states?.[state?._id] == stateValue;
    };
    set = (value) => {
      const entity = db.entities.findById(entityId);
      setEntityState(entity, state._id, value ? stateValue : undefined);
    };

    return { get, set };
  }

  else if (binding?.type?.[1] == $StatementDataBinding.$) {
    const getStatement = () => {
      const m = mapStructure(binding);
      // const type = m.type.content;
      const statement = evaluate(m.statement, map);

      const foundStatement = db.statements.find(s => {
        if (s.type != statement.type)
          return false;
        for (const paramId in statement.params) {
          const sParam = s.parameters?.find?.(p => p.type == paramId);
          const param = statement.params[paramId];
          if (param.type == '@') {
            if (sParam?.value.content != entityId)
              return false;
          }
          else if (_.isString(param.type) && param.type in map) {
            const value = map[param.type];
            if (sParam?.value.content != value)
              return false;

          }

          else if (!_.isEqual(x(sParam.value), x(param)))
            return false;
        }
        return true;
      });

      return foundStatement;

    };
    return {
      get: () => {
        const m = mapStructure(binding);
        const type = evaluate(m.type, map);
        const statement = m.statement.content;
        // XTouch(statement);
        if (type == 'boolean') {
          const foundStatement = getStatement();
          // console.log('foundStatement', x(foundStatement), x(db.statements));
          return !!foundStatement;

        }

      },
      set: (value) => {
        const m = mapStructure(binding);
        const type = evaluate(m.type, map);
        const statement = evaluate(m.statement, map);
        console.log('set', x(statement));

        // return;

        // return;
        if (type == 'boolean') {
          const foundStatement = getStatement();


          if (value) {
            if (!foundStatement) {
              const parameters = [];
              for (const paramId in statement.params) {
                const param = statement.params[paramId];
                if (param.type == '@') {
                  parameters.push(XObject.obj({
                    type: paramId,
                    value: {
                      type: 'entity',
                      content: entityId
                    }
                  }));
                }
                else if (_.isString(param.type) && param.type in map) {
                  parameters.push(XObject.obj({
                    type: paramId,
                    value: { type: 'entity', content: map[param.type] }
                  }));

                }
                else {
                  parameters.push(XObject.obj({
                    type: paramId,
                    value: XClone(param),
                  }));
                }
              }
              const newStatement = XObject.obj({
                type: statement.type,
                parameters,
              });

              db.statements.push(newStatement);

              // console.log(x(newStatement));
            }
          }
          else {
            if (foundStatement) {
              db.statements.splice(db.statements.indexOf(foundStatement), 1);
            }
          }
        }

        // console.log(m, m.type.content, x(m.statement.content));
        // console.log(entityId, value);
      }
    };
  }

  else if (binding?.type?.[1] == $EntityAttributeBinding.$) {
    const mapped = mapStructure(binding);
    let get, set;
    get = () => {
      const entity = db.entities.findById(entityId);
      return entity?.attributes?.[mapped.attribute?.content] == mapped.value?.content;
    }

    set = value => {
      const entity = db.entities.findById(entityId);
      const attributes = XObject.get(entity, 'attributes', {});
      attributes[mapped.attribute.content] = value ? mapped.value?.content : undefined;
    }

    return { get, set };
  }

  return { get: () => { }, set: () => { } };

}


export function resolveDataBinding(value: CompiledValuePoint, map={}) {
  if (value.type[1] == $EntityNameBinding.$) {
    const evaluated = evaluate(value, map);
    return {
      get: () => {
        const entity = db.entities.findById(evaluated.entity);
        return entity?.name;
      },
      set: (value) => {
        const entity = db.entities.findById(evaluated.entity);
        entity.name = value;
      }
    }
  }
}