// export { initEditor } from './initEditor';
// export { ComponentIDEEditor } from './ComponentIDEEditor';
// export { CodeComponenType, CodeElementType, ComponentSystem } from './ComponentSystem';
// export { EditorState } from './EditorState';
// export { registerComponentRenderer } from './componentRenderers';
// export { registerPaneType } from './paneTypes';
// export { ComponentEditor } from './ComponentEditor';

// ----------------------------


export const initEditor = (a?) => {
}

export const ComponentIDEEditor = null;
export const CodeComponenType = null;
export enum CodeElementType {
  valuePoint,
}
export class ComponentSystem {
  constructor(a?) {
  }

  createComponent(a?, b?):any {

  }

  componentEditorObject(a?):any {

  }
}

export class EditorState {
  constructor(a?) {
  }  
}

export const registerComponentRenderer = (a?, b?) => {

}

export const registerPaneType = (a?, b?) => {

}



export const ComponentEditor = null;

