import React from 'react';
import { component } from '../component2';
import { XObject, X } from '../XObject';
import { Comp } from '../glue/Comp';
import { GlueView } from '../glue/main';


@component
export class GlueWindow extends Comp<{ win; }> {
  static title(win, ctx) {
    // const value = execute(win.id, new Runtime(ctx, { stateStore: {} }));
    return 'Glue';
  }
  state = X({});
  constructor(props) {
    super(props);
    this.state = XObject.get(this.props.win, 'state', {});
  }
  // componentDidMount() {
  //   let prevState = x(this.state);
  //   setInterval(() => {
  //     if (!_.isEqual(x(this.state), prevState)) {
  //       prevState = x(this.state);
  //       console.log(prevState);
  //     }
  //   }, 1000);
  // }
  render() {
    return (
      <GlueView
        args={this.props.win.args}
        id={this.props.win.id}
        state={this.state}
        renderType={this.props.win.renderType} />
    );
  }
}
