import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import _ from 'lodash';
import {component} from '../../component2';
import {_Editor, DraftSelect} from '../../etc/draftHelpers';
import {XObject} from '../../XObject';
import {showContextMenu} from '../../helpers';
import {
    _iterate,
    addValuePoint,
    CompiledValuePoint,
    execute,
    isBlock,
    render,
    resolveTypeList,
    ValuePointProps,
    ValuePointType
} from '../main';
import {ValueType} from "../ValueType";
import {borderColor} from "../borderColor";
import {ValuePointCont} from "../ValuePointCont";
import {ValuePoint} from "../ValuePoint";
import {Runtime} from "../Runtime";
import {registerType} from "../__typeRegistry";

const Comp = React.Component;


@component
export class ElementValuePoint extends Comp<ValuePointProps> {
  static styles = styled.div`
    > ul {
      margin: 0;

      li.block {
        > ${ValuePointCont} {
          padding-left: 4px;
          border-left: 2px solid ${borderColor};
          /* > * {
            margin-left: 4px;
          } */
        }
      }

    }

    .blockProp {
      padding-left: 4px;
          border-left: 2px solid ${borderColor};

    }

    &.compact {
      &.inline {
        display: inline;
      }
      > ul {
        display: inline;
        list-style-type: none;
        margin: 0;
        padding: 0;
        > li {
          display: inline;
          &:not(:last-child) {
            &:after {
              content: ', ';
            }
          }
        }
      }
    }
  `;
  render(Container?) {
    const state = XObject.get(this.props.state, 'content', {});
    const properties = XObject.get(state, 'properties', []);
    const structure = {
      blockProperty: '5aaf17c8-beae-5df9-a792-e8b01af4108e',
      definition: [
        {
          id: '5aaf17c8-beae-5df9-a792-e8b01af4108e',
          name: 'Children',
          type: [ValueType.Array, []],
        }
      ],
    };
    const blockPropertyDef = structure.blockProperty && structure.definition.find((f) => f.id == structure.blockProperty);
    const blockProperty = blockPropertyDef && properties.find((p) => p.prop == structure.blockProperty);


    return (
      <Container className={cx({ compact: this.props.state.presentation?.compact, inline: this.props.state.presentation?.compact && !isBlock(this.props.state) })}>
        {'<'}<_Editor inline id={this.props.elId + 'tag'} defaultContent={state.tag} onChange={(_, v) => {
          state.tag = v;
        }} />
        <ul>
          {properties.map((prop, i) => {
            if (prop.prop == structure.blockProperty)
              return null;
            const field = structure.definition.find((f) => f.id == prop.prop);
            return (
              <li key={prop._id} className={prop.value.type && isBlock(prop.value) && 'block'}>
                <span onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Remove',
                      onClick: () => {
                        properties.splice(i, 1);
                      },
                    },
                  ]);
                }}>{field.name.toLowerCase()}</span> = <ValuePoint id={prop.value._id} path={this.props.path && this.props.path.concat(prop._id)} />
              </li>
            );
          })}
          {properties.length < structure.definition.length && (
            <li>
              <DraftSelect
                inline
                id={this.props.elId + 'properties'}
                options={structure.definition.filter(p => !properties.find(pp => pp.prop == p.id)).map(({ name, id }) => ({
                  display: name.toLowerCase(),
                  value: id,
                }))}
                onSelect={(v) => {
                  const prop = structure.definition.find(p => p.id == v);
                  properties.push(XObject.obj({
                    prop: v,
                    value: addValuePoint(XObject.obj({
                      parent: this.props.state._id,
                      possibleTypes: resolveTypeList(prop.type),
                    })),
                  }));
                }} />
            </li>
          )}
        </ul>{'>'}
        {blockProperty && (
          <div className="blockProp" onContextMenu={e => {
            if (e.target == e.currentTarget) {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Remove',
                  onClick: () => {
                    properties.splice(properties.indexOf(blockProperty), 1);
                  }
                }
              ]);
            }
          }}>
            <ValuePoint id={blockProperty.value._id} path={this.props.path && this.props.path.concat(blockProperty._id)} />
          </div>
        )}
      </Container>
    );
  }
}

export const registerElementType = () => {
const elementWrappers = {};

    function executeElement(value: ValuePointType, rt: Runtime): CompiledValuePoint {
        const newValue = {
            _id: value._id,
            type: value.type,
            content: {
                styles: value.content?.styles,
                tag: value.content?.tag,
                properties: [],
            },
            rt,
            isState: false,
            presentation: value.presentation,
        };

        if (value.content.properties)
            for (const prop of value.content.properties) {
                newValue.content.properties.push({
                    _id: prop._id,
                    prop: prop.prop,
                    value: execute(prop.value._id, rt.appendPath([value._id, prop._id])),
                });
            }

        return newValue;
    }

    registerType(ValueType.Element, {
        render(value, map, state, renderType) {
          let Tag;

          if (value.content?.styles) {
            // const Styles = compileSnippet(value.content?.styles);
            // console.log(Styles);
            // if ((Styles instanceof NoValue)) {
            //   return null;
            // }
            
    
            // const tag = value.content?.tag || 'div';
      
            // if (!elementWrappers[value._id + tag]) {
            //   elementWrappers[value._id + tag] = _.isString(Styles) ? styled(tag)`${Styles}` : Styles;
            // }
      
            // Tag = elementWrappers[value._id + tag];
          }
          else {
            Tag = value.content?.tag || 'div';
          }
    
    
          const children = value.content?.properties?.find?.(p => p.prop == '5aaf17c8-beae-5df9-a792-e8b01af4108e')?.value;
          return <Tag key={value._id} data-test>{children && render(children, map, renderType, XObject.get(state, value._id, {}))}</Tag>
    
        },
        execute: (value, rt) => {
            return executeElement(value, rt);
        },
        isBlock: value => {
            return true;
        },
        iterate: (value, parent, func, set) => {
            const list = value.content?.properties || [];
            for (let i = 0; i < list.length; i++) {
                const r = _iterate(list[i].value, value, func, value => {
                    list[i].value = value;
                });
                if (r !== undefined) return r;
            }
        },
        editorComp: ElementValuePoint,

    });
};