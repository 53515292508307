import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import config from './config';
import _ from 'lodash';
import classNames from 'classnames';
import { db, collections } from './db';
import { SortableContainer, SortableElement, SortableHandle, SortableContainerProps } from 'react-sortable-hoc';
import { XObject, x } from './XObject';
import jQuery from 'jquery';
import { component, styled } from './component2';
import { DndProvider } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import { isMobile } from './isMobile';
import { presentActionSheet } from './components/presentMobileModal';

export const EditorConfigContext = React.createContext(null);

export function configApiServer() {
	if (GET().port) {
		const port = GET().port;
		return config.apiServer.replace('9001', (parseInt(port) + 1).toString());
	}
	else {
		return config.apiServer;
	}
}

export function configObj() {
	if (!db.config.length) {
		db.config.push(XObject.obj({
			title: ''
		}))
	}

	return db.config[0];
}

export function configWsServer() {
	if (GET().port) {
		const port = GET().port;
		return config.wsServer.replace('9090', port);
	}
	else {
		return config.wsServer;
	}
}



export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function GET(): any {
	return parseQuery(document.location.search);
}

export function renderLevels(levels) {
	function renderLevel(level, data?) {
		function r(l) {
			let args = { next: (data) => renderLevel(level + 1, data), data };
			if (_.isFunction(l)) {
				return l(args);
			}
			else if (l.display) {
				return l.display(args);
			}
		}

		let l = levels[level];
		if (_.isArray(l)) {
			return l.map((l2, i) => {
				return <div key={i}>{r(l2)}</div>
			})
		}
		else {
			return r(l);
		}
	}

	return renderLevel(0);
}

export function domain() {
	let d = GET().domain;
	if (d) {
		return d;
	}
	else {
		let instance = GET().instance || 'default';
		let user = db.users.find((user) => user.authKey == localStorage.getItem('authKey'));
		return user.os[instance].domain;
	}
}

export function globalComponents() {
	let components = db.components.filter((component) => !component.adHoc && !component.local);

	for (let routine of db.routines) {
		for (let invocationStyle of routine.invocationStyles) {
			if (invocationStyle.type == 'function') components.push({
				_id: invocationStyle._id,
				deleted: routine.deleted,
				type: 'Routine',
				get name() { return invocationStyle.defaultReferenceIdentifier || invocationStyle.identifier || '(unnamed)' },
				// name: `${invocationStyle.defaultReferenceIdentifier} (${routine.description}) Routine`,
				routine,
				invocationStyle
			});	
		}
	}

	return components;
}

export function getComponentById(id) {
	let component = collections.components.findById(id);
	if (!component) {
		component = collections.routines.findById(id);
	}

	if (!component) {
		return globalComponents().find(component => component._id == id);
	}

	return component;
}

export function getComponentForEditorById(id) {
	let component = collections.components.findById(id);
	if (component) return component;

	component = collections.routines.findById(id);
	if (component) {
		return {
			_id: component._id,
			name: component.description,
			type: 'Routine',
			routine: component
		}
	}
	else {
		let comp = globalComponents().find( i => i._id == id);
		if (comp && comp.type == 'Routine') {
			return {
				_id: comp.routine._id,
				name: comp.routine.description,
				type: 'Routine',
				routine: comp.routine
			}	
		}
	}
	// else {
	// 	throw new Error(id);
	// }

}

export function evalParams(code, defaultValue={}) {
	if (code) {
		code = code.replace(/_\$|\$_/g, '\'');
		try {
			return eval(`(function() { return ${code} })()`);
		}
		catch (e) {
			// console.log(e)
		}	
	}
	return defaultValue;
}

export const _SortableCont = SortableContainer(({ className, children, tag, style }: { className?, children?, tag?, style? }) => {
	const Tag = tag || 'div';
	return <Tag style={style} className={className}>{children}</Tag>
});



export class SortableCont extends Component<{
	className?: any;
	children?: any;
	tag?: any;
	style?
	disabled?
} & SortableContainerProps & SortableContainerProps> {
	render() {
		return <_SortableCont style={this.props.style} helperClass="draggingEl" {...this.props} />;
	}
}

export const SortableEl: any = SortableElement(({ children }) => {
	return children;
});

export const SortHandle = SortableHandle(({ children }: { children? }) => {
	return <span>{children}</span>;
});

export const registerDragger = (() => {
	let currentDragger, _started, _ended;
	let root = () => jQuery(document.body);

	let _root

	jQuery(window).mousemove(e => {
		if (currentDragger) {
			const __root = _root || root();
			if (_started) {
				_started();
				_started = null;
			}
	
			const x = e.pageX - __root.offset().left;
			const y = e.pageY - __root.offset().top;
	
			currentDragger({ x, y });
		}
	});

	window.addEventListener('mouseup', e => {
		if (currentDragger) {
			e.preventDefault();
			e.stopPropagation();
			currentDragger = null;
			_root = null;
			if (_ended) {
				_ended();
				_ended = null;
			}
		}
	});

	return Object.assign(({ started, dragger, ended, root }: { event?, started?, ended?, dragger, root? }) => {
		_started = started;
		_ended = ended;
		_root = root;
		currentDragger = dragger;
	}, { setRoot: r => root = r })
})();



export class DebugMarkerContext {
	root;
	type;
	path;
	value;
	constructor(root, type, path=[]) {
		this.root = root;
		this.type = type;
		this.path = path;
		this.value = [ root ].concat(path).join('-');
	}

	addPath(comp) {
		return new DebugMarkerContext(this.root, this.type, this.path.concat(comp));
	}

	makePath(end) {
		return [ this.root ].concat(this.path).concat(end);
	}
}

export function isId(str) {
	return str && str.length == 24 && !!str.match(/^[0-9a-z]{24}$/);
}


export function resolveRefToObj(ref) {
	if (ref.slice(0, 2) == '_$') return resolveRefToObj(ref.slice(2, -2));
	else if (isId(ref)) {
		{
			const param = collections.parameters.findById(ref);
			if (param) return param;
		}
		{
			const param = collections.statementTypes.findById(ref);
			if (param) return param;
		}

		return ref;
	}

}

export function resolveRef(ref) {
	if (ref.slice(0, 2) == '_$') return resolveRef(ref.slice(2, -2));
	else if (isId(ref)) {
		{
			const param = collections.parameters.findById(ref);
			if (param) return param.handle;
		}
		{
			const param = collections.statementTypes.findById(ref);
			if (param) return param.handle;
		}

		return ref;
	}
	else {
		return ref;
	}
}

@component
class CtxMenu extends Component<{
  items: { text, onClick }[],
  _onMount?, _onClick? }> {
  static styles = styled.div`
    background-color: white;
    border-color: #999da3;
    box-shadow: 0px 0px 0px 3px rgb(0 0 0 / 7%);
    border-radius: 3px;
    border: 1px solid #999da3;
		-webkit-app-region: no-drag;
  `;

  static t = {
    menuItem: styled.div`
      height: 25px;
      &:not(:last-child) {
        border-bottom: 1px solid #999da3;
      }
      line-height: 25px;
      padding: 0 8px;
      &:hover {
        background: #e2e2e2;
      }
			cursor: pointer;
    `
  }

  componentDidMount() {
    this.props._onMount(ReactDOM.findDOMNode(this));
  }
  render() {
    const { t } = CtxMenu;
    return (
      <>
        {/* <t.menuItem onClick={() => this.props._onClick()}>Add As Sub Component</t.menuItem> */}
        {this.props.items.map((item, i) => (
					item && <t.menuItem key={i} onClick={e => { this.props._onClick(e); item?.onClick?.(e) }}>{item.text}</t.menuItem>
        ))}
      </>
    )
  }
}


let _close;
export function showContextMenu(e: { clientX, clientY }, menu?: { text, onClick }[], right?) {
	if (isMobile()) {
		presentActionSheet(menu);
		return;
	}
	
	if (_close) _close();
  function updatePosition() {
    cont.css({
      // right: jQuery(window).width()- e.clientX,
      top: e.clientY,    
    })
		if (right === 'bottom') {
			cont.css({
				top: 'auto',
				bottom: jQuery(window).height() - e.clientY,
				left: e.clientX,
			})
		}

		else if (right) {
			cont.css({
				right: jQuery(window).width()- e.clientX,
			})
		}
		else {
			cont.css({
				left: e.clientX,
			})
		}
  }

  const cont = jQuery('<div />').css({
    position: 'absolute',
    // left: e.clientX,
    // top: e.clientY,
    zIndex: 9999999999,
  });

	updatePosition();

  const close = () => {
    ReactDOM.unmountComponentAtNode(cont[0]);
    cont.remove();
		_close = null;
  }

	_close = close;

  jQuery(window).one('mousedown', e => {
    if (e.target == cont[0] || jQuery.contains(cont[0], e.target)) return;
    close()
  });

  document.body.appendChild(cont[0]);
  ReactDOM.render(<CtxMenu
    items={menu.filter(Boolean)}
    _onMount={el => {

			updatePosition();
    }}
    _onClick={() => close()}
  />, cont[0]);

}

@component
class Wrapper extends Component<{ _children }> {
	render() {
		return this.props._children();
	}
}



function resolveDecoration(block) {
  return {
    actions: block.children.map(child => child.content)
  }
}

export interface ViewLayerModel {
  types: {
    [id: string]: {
      match;
      title;
      name: string;
			items: string[];
    }
  }
  descriptions: any[];
  relationships: any[];
  decorations: {
		actions: string[];
	}[];
}

export enum BlockType {
  defType = 1,
  defDescription,

  defRelationsip,


  refModelProperty,
  refModelRelationship,

  timestamp,
  title,


  match,

  traversion,

  procedure,

	item,

}


export function viewLayerModel(viewLayer): ViewLayerModel {
  const types = {};
  const descriptions = [];
  const relationships = [];
  const decorations = [];

  const resolveType = block => {
		const items = [];

    const type: any = {
      name: block.content,
      _id: block._id,
			items
    }

    for (const child of block.children) {
      if (child.type == BlockType.match) {
        type.match = child.query;
      }
      else if (child.type == BlockType.title) {
        type.title = child.children[0]?.content;
      }
      else if (child.type == BlockType.item) {
				items.push(child.content);
      }
    }

    return type;
  };

  const resolveDescription = block => {
    const desc = {
      _id: block._id,
      name: block.content,
      modelProperty: block.children[0]?.content,
    };

    return desc;
  }

  const resolveRelationship = block => {
    const rel = {
      _id: block._id,
      name: block.content,
      modelRelationships: block.children.filter(block => block.type == BlockType.refModelRelationship).map(b => b.content),
      traversion: block.children.find(block => block.type == BlockType.traversion)?.content,
    }
    return rel;
  }

  for (const block of x(viewLayer.modelDef || [])) {
    if (block.type == BlockType.defType) {
      const type = resolveType(block);
      types[type._id] = type;
    }
    else if (block.type == BlockType.defDescription) {
      descriptions.push(resolveDescription(block));
    }
    else if (block.type == BlockType.defRelationsip) {
      relationships.push(resolveRelationship(block));
    }
    else if (block.type == BlockType.match) {
      decorations.push(resolveDecoration(block));
    }

  }

  return { types, descriptions, relationships, decorations };

}





@component
export class DataWrapper extends Component<{ data, default?, display? }> {
  render() {
    return <span>{this.props.display ? this.props.display(this.props.data) : this.props.data.valueOf(this.props.default)}</span>
  }
}






export function valueParam(statementType) {
  return statementType.parameters.find(param => param.type != statementType.owner).type;
}


export function getValueParams(statementType) {
  return statementType.parameters.filter(param => param.type != statementType.owner).map(param => param.type);
}




export function arrayMove(array, oldIndex, newIndex) {
	const value = array[oldIndex];
	array.splice(oldIndex, 1);
	array.splice(newIndex, 0, value);

}

export const Svg = {
  x: <svg viewBox="0 0 8 8" style={{ width: '8px', height: '8px', display: 'block',  backfaceVisibility: 'hidden', opacity: 0.5 }}><polygon points="8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4"></polygon></svg>,
  dots: <svg viewBox="0 0 13 3" style={{ width: '14px', height: '14px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><g> <path d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"></path> <path d="M8,1.5A1.5,1.5,0,1,1,6.5,0,1.5,1.5,0,0,1,8,1.5Z"></path> <path d="M13,1.5A1.5,1.5,0,1,1,11.5,0,1.5,1.5,0,0,1,13,1.5Z"></path> </g></svg>,
  grip: <svg viewBox="0 0 10 10" style={{ width: '12px', height: '12px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><path d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path></svg>,
  plus: <svg viewBox="0 0 16 16" className="plus" style={{ width: '12px', height: '100%', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path></svg>,
  open: <svg viewBox="0 0 14 14" style={{ width: '14px', height: '14px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><polygon points="9.13029 3.66667 3.66667 9.13029 3.66667 7 2 7 2 12 7 12 7 10.3333 4.82065 10.3333 10.3333 4.82065 10.3333 7 12 7 12 2 7 2 7 3.66667"></polygon></svg>,

  downArrow: <svg viewBox="0 0 30 30" style={{ width: '10px', height: '100%', display: 'block', fill: 'inherit', flexShrink: 0, backfaceVisibility: 'hidden' }}><polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon></svg>,
}



@component
export class Chip extends Component<{ text, onX?, color?, size?, onClick? }> {
  static styles = styled.span`
    margin: 0px 6px 6px 0px;
    padding-left: 6px;
    border-radius: 3px;
    background-color: rgb(211, 229, 239);
    font-size: 14px;
    height: 18px;

    display: inline-flex;

    align-items: center;

    white-space: nowrap;

    &:not(.hasX) {
      padding-right: 6px;
    }

		&.hasOnClick {
			cursor: pointer;
		}
  `;

  static t = {
    xCont: styled.div`
      width: 18px;
      height: 18px;
      margin: 0 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    `,

  }


  render(Container?) {
    const { t } = Chip;
    
    return <Container style={{ backgroundColor: this.props.color, zoom: this.props.size }} className={classNames(this.props.onX && 'hasX', this.props.onClick && 'hasOnClick')} onClick={this.props.onClick}>{this.props.text}{this.props.onX && <t.xCont onClick={this.props.onX}>{Svg.x}</t.xCont>}</Container>
  }
}



