import { Component } from 'react';
import cx from 'classnames';
import { component } from '../component2';
import { styled } from '../component';
import { ObjectRef, ObjectType } from '../types/ObjectRef';
import { Svg } from './Svg';
import { typeIcon, objectName, getScopeTree, getObject } from './objectFuncs';
import { db } from '../db';
import { Tag } from './Tag';



@component
export class ObjectDisplay extends Component<{ obj: ObjectRef; showPath?: boolean; _onClick?; onContextMenu?; }> {
  static styles = styled.span`
    display: inline-flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
      flex: 0 0 auto;
    }
    .path {
      display: inline-flex;
      align-items: center;
      color: gray;
      margin-left: 8px;
      font-size: 12px;
      svg {
        fill: gray;
        width: 11px;
        height: 11px;
      }
      > span {
        &:not(:last-child) {
          &:after {
            content: ' / ';
          }
        }
      }
    }
  `;

  render(Container?) {
    const icon = typeIcon(this.props.obj.type);
    let c = <>{icon && <Svg name={icon} />} <span className="name">{objectName(this.props.obj, false)}</span></>;


    return (
      <Container
        onClick={this.props._onClick}
        onContextMenu={this.props.onContextMenu}
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', JSON.stringify(this.props.obj));
        }}

      >
        {c}

        {this.props.showPath && (() => {
          const tree = getScopeTree(this.props.obj);
          const path = tree.reverse().slice(1, -1);
          if (path.length) {
            return (
              <span className="path">
                {path.map(obj => {
                  return (
                    <span key={obj.id}><ObjectDisplay obj={obj} /></span>
                  );
                })}
              </span>
            );

          }
        })()}
      </Container>
    );
  }
}


@component
export class ObjectRow extends Component<{ obj, _onClick?, active? }> {
  static styles = styled.div`
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    padding: 0px 6px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #f8f8f8;
    }
    &.active {
      background: #f2f5fe;
    }

    > .right {
      margin-left: auto;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    ${ObjectDisplay} {
      margin-right: 4px;
      white-space: nowrap;
      flex: 1 1 auto;
      overflow: hidden;

      > .name {
        flex: 1 1 auto;
        overflow: hidden;
      }
    }


  `;
  render(Container?) {
    const obj = getObject(this.props.obj);
    let right;
    if (this.props.obj.type == ObjectType.entity) {
      const type = obj.type && db.entityTypes.findById(obj.type);
      right = type && <><Tag text={type.name} /></>
    }
    return (
      <Container onClick={this.props._onClick} className={cx({ active: this.props.active })}>
        <ObjectDisplay obj={this.props.obj} showPath />
        {right && <div className="right">{right}</div>}
      </Container>
    )
  }
}