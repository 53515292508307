import { ValueType } from "../ValueType";
import { typeRegistry } from "../typeRegistry.1";

export const $BlockView = typeRegistry.registerType({
  $: 'b3907708-e0c9-5396-915f-0e11558912e8',
  Elements: '640754d6-c2e3-58bf-9cea-ca862526dbb0',
}, ids => ({
  _id: ids.$,
  name: 'Block View',
  definition: [
    {
      id: ids.Elements,
      name: 'Elements',
      type: [ValueType.Array, []],
      property: 'elements',
    },
  ]
}));