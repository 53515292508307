import React, { Component } from 'react';
import { component, styled } from '../component2';
import { x, X, XObject } from '../XObject';
import { BlockEditor, _BlockManager, _Block, BlockList } from './BlockEditor';

class BlockWrapper extends _Block {
  constructor(public data, public dataInterface: DataInterface, public parent) {
    super();
  }

  get id() {
    return this.dataInterface.id(this.data);
    // return this.data._id;
  }

  get version() {
    return this.dataInterface.version?.(this.data) || 0;
  }

  set version(value) {
  }

  children;

  get content() {
    return this.dataInterface.getContent(this.data);
  }
  set content(value) {
    // this.data.content = value;
    this.dataInterface.setContent(this.data, value);
  }

  hasChildren() {
    return !!this.children.length;
  }

  get collapsed() {
    return this.dataInterface.collapsed(this.data);
  }

  set collapsed(value) {
    this.dataInterface.setCollapsed(this.data, value);
  }
}

export class BlockManager extends _BlockManager {
  _indexOf(list: BlockList, block: BlockWrapper) {
    return list.indexOf(block);
  }

  _splice(list: BlockList, index, deleteCount, ...items) {
    return list.splice(index, deleteCount, ...items);
  }

  _length(list: BlockList) {
    return list.length;
  }
}


const Cont = styled.div`
      width: 600px;
      margin: 200px auto;
      /* border: 1px solid black; */
`

function block(content, children=[]) {
  return XObject.obj({ content, children: X(children) })
}


class ListWrapper implements BlockList {
  constructor(private a: _Block[], private b: any[]) {}

  get(i) {
    return this.a[i];
  }

  indexOf(el: _Block) {
    return this.a.findIndex(a => a.id == el.id);
  }

  get length() {
    return this.a.length;
  }

  map(func) {
    return this.a.map(func);
  }

  splice(index, delCount, ...items: BlockWrapper[]) {
    this.b.splice(index, delCount, ...items.map(i => i.data));
  }
}

export interface DataInterface {
  id(data): any;
  version?(data): any;

  children(data): any[]

  getContent(data): any;
  setContent(data, content);
  collapsed(data): boolean;
  setCollapsed(data, value): void;

}

@component
export class NotionDataEditor extends Component<{
  resolveClass?
  _onClick?(block: _Block, e)
  className?
  onSelectChanged?(selection: [ _Block?, _Block? ]): void
  renderBlock(block, blockManager: _BlockManager)

  dataTransform?(block: _Block, blockManager: _BlockManager): any;
  data_contextMenu?(data, pos, blockMananger: _BlockManager)
  blockSelected?(block)

  onCut?

  data, dataInterface: DataInterface, setFocusedBlock?, setContent(block, content), newBlock?, state?, layout?
}> {
  

  notionEditorRef = React.createRef<BlockEditor>();

  createBlocks = (blocks, parent?) => {
    if (!blocks) throw new Error();
    // console.log(blocks, parent);
    const list = [];
    for (const b of blocks) {
      const block = new BlockWrapper(b, this.props.dataInterface, parent);
      block.children = this.createBlocks(this.props.dataInterface.children(b), block);
      list.push(block);
    }

    return new ListWrapper(list, blocks);
  }

  blockManager = new BlockManager({
    onAdd: block => {
      // console.log(block);
    },
    setContent: (block: BlockWrapper, content: string) => {
      this.props.setContent(block, content);
    },
    joinBlocks:(prevBlock: BlockWrapper, block: BlockWrapper) => {
      this.props.setContent(prevBlock, prevBlock.content + block.content);
    },
    setFocusedBlock: (id) => {
      this.props.setFocusedBlock?.(id);
      
    },
    newBlock: (content) => {
      return new BlockWrapper(this.props.newBlock?.(content), this.props.dataInterface, null);
    },
    blocks: () => {
      return this.createBlocks(this.props.data);
    }
  })

  render() {
    return (
      <BlockEditor
        ref={this.notionEditorRef}
        className={this.props.className}
        onSelectChanged={this.props.onSelectChanged}
        renderEditor={this.props.renderBlock}
        dataTransform={this.props.dataTransform}
        data_contextMenu={this.props.data_contextMenu}
        _onClick={this.props._onClick}
        blockManager={this.blockManager}
        resolveClass={this.props.resolveClass}
        state={this.props.state}
        layout={this.props.layout}

        blockSelected={this.props.blockSelected}
        onCut={this.props.onCut}
      />
    );
  }
}
