import { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { component } from '../component2';
import { XObject } from '../XObject';
import { TEntityMatch } from "../types/TEntityMatch";
import { entityTypeName } from '../etc/entityTypeName';


@component
export class EntityMatchEditor extends Component<{ value: TEntityMatch; }> {
  static styles = styled.div`
    .selected {
      > span {
        font-weight: bold;
      }
    }

    li > span:not(:last-child):after {
      content: ', ';
    }
  `;

  render() {
    return (
      <>
        <select
          value={this.props.value.type || ''}
          onChange={(e) => {
            this.props.value.type = e.target.value || null;
          }}
        >
          <option value="">Any</option>
          {db.entityTypes.map((x, i) => <option key={i} value={x._id}>{entityTypeName(x._id)}</option>)}
        </select>

        <ul>
          {this.props.value.states?.map?.((x, i) => {
            return (
              <li key={i}>
                {db.stateTypes.findById(x.type)?.values?.map?.((v, i) => <span
                  className={x.values?.includes?.(v._id) ? 'selected' : ''}
                  key={i}
                  onClick={() => {
                    const index = x.values?.indexOf?.(v._id);
                    if (index == -1 || index == undefined) {
                      XObject.push(x, 'values', v._id);
                    }
                    else {
                      x.values.splice(index, 1);
                    }
                  }}
                ><span>{v.name}</span></span>)}
              </li>
            );
          })}
          <li>
            <select

              onChange={e => {
                XObject.push(this.props.value, 'states', XObject.obj({
                  type: e.target.value,
                }));
                e.target.value = '';
              }}
            >
              <option />
              {db.stateTypes
                .filter(x => !this.props.value.states?.find?.(y => y.type == x._id))
                .map((x, i) => <option key={i} value={x._id}>{x.values.map(v => v.name).join(', ')}</option>)}
            </select>
          </li>
        </ul>
      </>
    );
  }
}
