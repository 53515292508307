import React from 'react';
import {component} from '../../component2';
import {DraftSelect} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {addEnumToMap, enumMap, ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";


@component
export class EnumValuePoint extends Comp<ValuePointProps> {
  render() {
    const en = enumMap[this.props.state.type[1]];
    if (this.props.state.content) {
      return <span>{en.find(e => e[1] == this.props.state.content)[0]}</span>;
    }
    else {
      return <DraftSelect inline id={this.props.elId} options={en.map(option => ({
        display: option[0],
        value: option[1],
      }))}
        onSelect={(value) => {
          this.props.state.content = value;
        }} />;
    }
  }
}

export const registerEnumType = () => {
    registerType(ValueType.Enum, {
        evaluate: (value) => {
            return value.content;
            // return enumMap[value.type[1]].find(x => x[0] == value.content)?.[1];
        },
        addStructToMap: field => {
            addEnumToMap(field.type[1], field.type[2]);
        },
        editorComp: EnumValuePoint,
    });
};