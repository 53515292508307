import { XInit } from '../XObject';
import { getStorageState } from './getStorageState';

export const appState = getStorageState('state1', 0, class {
  currentPage;
  panes = [];
  rightSidebar = false;
  leftSidebar = true;

  inspecting: any = null;

  focus = null;

  glue

  glueInspectStack

  lastViewState

  appInspectOverlay
  appInspect

  graphView

  lastActivePane

  currentMode

  showGraphView = false

  mobileStack = []

  user

  mobile_firebaseUserId

  eventsState = {}
});


export const memoryAppState = XInit(class {
  hoverValuePoint
  hoveringValuePoints = {}

  user

  image = null
  showSidebar = false

  search = false

  firebaseUserId

  fromUrl
})