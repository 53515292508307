import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { state } from '../etc/draftHelpers';
import { XInit, XObject } from '../XObject';
import { showContextMenu } from '../helpers';
import { db } from '../db';
import { BlockEditor, _BlockComp } from './BlockEditor';
import { EntityEditor } from './BlockPage';
import Around from './Around';
import { getChildren } from '../glue/main';
import { BlockWrapper, ListWrapper, BlockManager } from "../glue/structs/$NotionDocument";
import { entityContextMenuItems } from './EntityDisplay';
import { deleteEdgeByIndex } from '../etc/getEdgesForEntity';
import { createEntity } from '../etc/createEntity';



@component
export class NotionDocumentDeprecated extends Component<{ rootEntity; state?; }> {

  static t = {
    block: styled.div`
      /* height: ; */
      padding: 3px 0;
    `,
    menu: styled.div`
    background-color: white;
    border: 1px solid black;
    > button {
      display: block;
    }
  `,
  };

  state = XInit(class {
    collapsed = {};
    active;
  });

  constructor(props) {
    super(props);
    if (this.props.state) {
      this.state = this.props.state;
    }
  }

  static styles = styled.div`
    .${_BlockComp.c.handleCont} {
      top: 2px;
    }


    > .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 300px;
      overflow: auto;
      padding: 24px;
    }

    > .right {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 300px;
      border-left: 1px solid black;
      overflow: auto;
    }
  `;

  createBlocks = (entity, parentBlock?, parentEntity?) => {
    // const edges = db.edges.filter(e => e.entities[0] == entity && e.directed);
    const children = getChildren(entity);
    const list = [];
    for (const e of children) {
      const block = new BlockWrapper(e, parentBlock, entity, this.state);
      block.children = this.createBlocks(e, block, entity);
      list.push(block);
    }

    return new ListWrapper(list, entity);

  };

  blockManager = new BlockManager({
    onAdd: (block: BlockWrapper) => {
      console.log(block);
      // console.log(block);
    },
    setContent: (block: BlockWrapper, content: string) => {
      // this.props.setContent(block, content);
    },
    joinBlocks: (prevBlock: BlockWrapper, block: BlockWrapper) => {
      // this.props.setContent(prevBlock, prevBlock.content + block.content);
    },
    setFocusedBlock: (id) => {
      // this.props.setFocusedBlock?.(id);
    },
    newBlock: (content) => {
      const newEntity = XObject.obj({});
      createEntity(newEntity, null); // createEntityNull
      return new BlockWrapper(newEntity._id, null, null, this.state);
    },
    blocks: () => {
      return this.createBlocks(this.props.rootEntity, null, this.props.rootEntity);
    }
  });

  clicked;

  render() {
    const { t } = NotionDocumentDeprecated;
    return (
      <>
        <div className="left">
          <BlockEditor<BlockWrapper>
            blockManager={this.blockManager}
            renderEditor={(block, blockManager) => {
              const entity = db.entities.findById(block.id);
              return (
                <t.block
                  onContextMenu={(e) => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Delete edge',
                        onClick: () => {
                          const index = db.edges.findIndex(e => e.entities[1] == block.entityId && e.entities[0] == block.parentEntityId && e.directed);
                          if (index == -1) {
                            throw new Error();
                          }
                          else {
                            deleteEdgeByIndex(index);
                          }
                        }
                      },

                      ...entityContextMenuItems({
                        id: block.entityId,
                        rootId: this.props.rootEntity,
                        graphId: '63e969838f513756f28239f7',
                      })
                    ]);
                  }}
                >
                  {entity && (
                    <EntityEditor
                      key={block.id}
                      blockManager={this.blockManager}
                      block={block}
                      id={entity._id}
                      blockType={entity?.type && db.entityTypes.findById(entity?.type)}
                      editorId={block.id}
                      onEnter={() => {
                        const b = blockManager.addBlock(block, undefined, true);
                        state.focused = b.id;
                        return 'handled';
                      }}
                      _onFocus={() => {
                        this.state.active = block.id;
                      }}
                      focused={this.state.active == block.id}
                      root={this.props.rootEntity}

                      handleKey={(e) => {
                        if (e.key == 'Tab') {
                          if (e.shiftKey) {
                            blockManager.unindent(block);
                          }
                          else {
                            blockManager.indent(block);
                          }
                          return 'handled';
                        }

                      }} />
                  )}

                  {!entity && '(deleted)'}


                </t.block>
              );
            }}
            _onClick={(block, e) => {
              if (this.clicked?.block?.id == block.id) {
                this.clicked = null;
              }
              else {
                this.clicked = { target: e, block };
              }
              this.forceUpdate();
            }} />
          {this.clicked && (
            <Around
              anchor={() => this.clicked?.target}
              position="below"
            >
              <t.menu>
                <button onClick={() => {
                  // this.clicked.block.data.collapsed = !this.clicked.block.data.collapsed;
                  this.state.collapsed[this.clicked.block.id] = !this.state.collapsed[this.clicked.block.id];
                  this.clicked = null;
                  this.forceUpdate();
                }}>Toggle</button>
                <button onClick={() => {
                  // ref.current.blockManager.removeBlock(this.clicked.block);
                  // this.clicked = null;
                  // this.forceUpdate();
                }}>Delete block</button>
                {this.clicked.block?.data?.type == 'entity' && (
                  <button onClick={() => {
                    // this.context.api.alter({ $entity: { [this.clicked.block.data.content]: '$deleteAll' } });
                    // ref.current.blockManager.removeBlock(this.clicked.block);
                    // this.clicked = null;
                    // this.forceUpdate();
                  }}>Delete block and entity</button>
                )}
                {/* <div><EntitySelector onSelected={value => {
          
                          this.clicked.block.data.type = 'entity';
                          this.clicked.block.data.content = value;
                          this.clicked = null;
                          this.forceUpdate();
                        }} /></div>
                        <ul>
                          {this.clicked.block.data.type != 'entity' && <li><span className="link" onClick={async () => {
                            const r = await this.context.api.alter([ { $entity: [ '$', { creationString: contentToString(this.clicked.block.data.content) } ] } ]);
                            this.clicked.block.data.content = r.entities.$;
                            this.clicked.block.data.type = 'entity';
                          }}>Create entity</span></li>}
                          {library.blockTypes.map(blockType => (
                            <li key={blockType._id}>
                              <span className="link" onClick={async () => {
                                await turnInto(this.context.api, this.clicked.block.data, blockType);
                                this.clicked = null;
                                this.forceUpdate();
                              }}>{blockType.name}</span>
                            </li>
                          ))}
                          {this.clicked.block.data.type == 'entity' && (
                            <li><EntityRef entity={this.clicked.block.data.content} /></li>
                          )}
                        </ul> */}
                {/* <Inspector data={x(this.clicked.block.data)} /> */}
              </t.menu>
            </Around>
          )}
        </div>
      </>
    );

  }
}
