import React from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { execute } from '../glue/main';
import { component } from '../component2';
import { X, x, XClone, XObject } from '../XObject';
import { NotionDocumentBad } from '../components/NotionDocumentBad';
import { Runtime } from '../glue/Runtime';
import { Comp } from '../glue/Comp';
import { resolveBlockTypes } from '../glue/structs/$Document';
import { SystemContext } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import { NotionTable } from '../components/NotionTable';
import { NotionDocument } from '../components/notionDocument/NotionDocument';
import { MyBlockManager } from '../MyNotionDocument/MyBlockManager';
import { MyNotionDocument } from '../MyNotionDocument/MyNotionDocument';
import { types } from "../MyNotionDocument/types";
import { triggerInspectObject } from '../osHelpers';
import classNames from 'classnames';
import { isMobile } from '../isMobile';


@component
export class NotionDocumentWindow extends Comp<{ window; insidePositionContext? }> {
  blocks;
  state = X({});
  constructor(props) {
    super(props);
    const document = db.notionDocuments.findById(this.props.window.notionDocument);
    if (!document)
      return;

    const blocks = XObject.get(document, 'blocks', []);
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    const observeExternalChanges = () => {
      XObject.observe(document.blocks, (change) => {
        this.blocks = XClone(document.blocks);
        this.forceUpdate();
      });
    }

    observeExternalChanges();

    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
      observeExternalChanges();
    });
  }

  static contextType = SystemContext;
  context: any;

  title;

  tick = 0;

  static styles = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${NotionTable} {
      margin: 0 30px;
    }

    &.mobile {
      ${NotionTable} {
        margin: 0;
      }
    }
  `;

  renderDocBad() {
    const ref = React.createRef<NotionDocumentBad>();
    const document = db.notionDocuments.findById(this.props.window.notionDocument);
    const value = document.config && execute(document.config, new Runtime(this.context, { stateStore: this.state }));

    return (
      <NotionDocumentBad
        onlyShowTitle={document.type != 'de89bc9c-9fb1-579d-8954-53f1519cbb33'}
        inline
        docId={this.props.window.notionDocument}
        ref={ref}
        tick={this.tick}
        blockTypes={value ? resolveBlockTypes(value, {}) : []}
        blocks={this.blocks}
        setBlocks={(blocks) => {
          console.log(x(blocks));
          document.blocks = XClone(blocks);
          this.blocks = XClone(blocks);
          XObject.observe(this.blocks, (change) => {
            document.blocks = XClone(this.blocks);
          });
          this.tick++;
          this.forceUpdate();
          ref?.current?.forceUpdate?.();
        }}
        database={null}
        title={x(document).name}
        setTitle={(title) => {
          document.name = title;
        }}
        entity={null}
      />

    )
  }

  timerId
  setBlocks = (blocks) => {
    const document = db.notionDocuments.findById(this.props.window.notionDocument);

    this.blocks = XClone(blocks);
    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
      this.tick++;
      this.forceUpdate();  
    });

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      document.blocks = XClone(this.blocks);
    }, 500);
  }



  renderDoc() {
    const ref = React.createRef<NotionDocumentBad>();
    const document = db.notionDocuments.findById(this.props.window.notionDocument);
    const value = document.config && execute(document.config, new Runtime(this.context, { stateStore: this.state }));

    return (
      <MyNotionDocument
        tick={() => document.tick}
        insidePositionContext={this.props.insidePositionContext}
        baseEntity={null}
        blocks={this.blocks}
        setBlocks={(blocks) => {
          this.setBlocks(blocks);
          // document.blocks = XClone(blocks);
          // this.blocks = XClone(blocks);
          // XObject.observe(this.blocks, (change) => {
          //   document.blocks = XClone(this.blocks);
          // });
        }}
        docId={this.props.window.notionDocument}
        extendEntity={null}
        title={x(document).name}
        setTitle={(title) => {
          document.name = title;
        }}

      />
    );
  }


  render(Container?) {
    const document = db.notionDocuments.findById(this.props.window.notionDocument);

    return (
      <Container
        className={classNames({
          mobile: isMobile(),
        })}
      >
        {/* <div>
                  <input type="checkbox"
                    checked={document.createsEdges}
                    onChange={e => {
                      document.createsEdges = e.target.checked;
                    }}
                   /> Graph
                </div>
                <div>
                  <input type="checkbox"
                    checked={document.scopedEntities}
                    onChange={e => {
                      document.scopedEntities = e.target.checked;
                    }}
                   /> Scoped Entities
                </div> */}

        {/* {this.renderDoc()} */}
        {!document.type && (
          <>
            <div>
              <button
                onClick={() => {
                  document.type = 'de89bc9c-9fb1-579d-8954-53f1519cbb33';
                }}
              >Init Page</button>
              <button
                onClick={() => {
                  document.type = '49746968-8568-5fbc-990d-ea4811d7f018';
                  document.tableData = X({});
                }}
              >Init Table</button>
            </div>
          </>
        )}
        {document.type == 'de89bc9c-9fb1-579d-8954-53f1519cbb33' && (
          this.renderDoc()
        )}
        {document.type == '49746968-8568-5fbc-990d-ea4811d7f018' && (
          <>
            <NotionDocumentBad
              onlyShowTitle
              inline
              docId={this.props.window.notionDocument}
              tick={this.tick}
              blocks={this.blocks}
              setBlocks={(blocks) => {
              }}
              database={null}
              title={x(document).name}
              setTitle={(title) => {
                document.name = title;
              }}
              entity={null}
              blockTypes={[]}
            />
            <NotionTable
              state={this.props.window}
              table={document.tableData}
              onClickRow={id => {
                this.context?.navigate?.({
                  type: PaneType.tableRow,
                  path: [document._id, id ],
                });
              }}
              active={(() => {
                const next = this.context?.next?.();
                if (next?.type == PaneType.tableRow) {
                  return next.path[next.path.length - 1];
                }
              })()}

              onClickAI={() => {
                this.context?.navigate?.({
                  type: PaneType.chatGPT2,
                  table: document._id,
                });
              }}
              editView={id => {
                triggerInspectObject({
                  type: '95d1b469-6eff-5bcc-88f9-a53e4377f1bf',
                  args: {
                    doc: document._id,
                    view: id,
                  },
                });
              }}
            />
          </>
        )}
      </Container>
    );
  }
}
