import { ObjectType } from '../types/ObjectRef';
import { XClone, XObject } from '../XObject';
import { db } from '../db';
import { appState, memoryAppState } from './appState';

export function createEntity(obj, fromEntityId) {
  const edges: {
    from
    to
    directed
  }[] = obj.$edges;
  delete obj.$edges;
  if (obj.$from && !fromEntityId) {
    fromEntityId = obj.$from;
  }
  delete obj.$from;

  obj = XObject.obj(obj);
  const fromEntity = fromEntityId && db.entities.findById(fromEntityId);
  obj.meta = {
    creation: {
      user: memoryAppState.user,
      timestamp: new Date(),
    }
  }
  if (!obj.space) {
    if (fromEntity?.space) {
      obj.space = fromEntity?.space;      
    }
    else if (appState.currentMode) {
      const mode = db.modes.findById(appState.currentMode);
      if (mode.useAsSpace) {
        obj.space = {
          type: ObjectType.mode,
          id: appState.currentMode,
        }  
      }
      else if (mode.space) {
        obj.space = XClone(mode.space);
      }
    }
  }
  db.entities.push(obj);

  if (edges) {
    for (const edge of edges) {
      if (edge.from) {
        db.edges.push(XObject.obj({
          entities: [edge.from, obj._id],
          directed: edge.directed,
        }));
      }
      else if (edge.to) {
        db.edges.push(XObject.obj({
          entities: [obj._id, edge.to],
          directed: edge.directed,
        }));
      }
    }
  }
  return obj;
}
