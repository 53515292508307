// import createSingleLinePlugin from 'draft-js-single-line-plugin'
// const singleLinePlugin = createSingleLinePlugin()

export const imgBlob = (() => {
  const _cache: any = {};
  return (name, colors?: { [color: string]: string }) => {
    const key = name + JSON.stringify(colors);
    if (_cache[key]) return _cache[key];
    return _cache[key] = URL.createObjectURL(new Blob([ img(name, colors) ], { type: 'image/svg+xml' }));
  }
})();

// require('!!raw-loader!./images/grip.svg').default
// export function cssImg(name, colors?: any) {
//   if (!name) return '';
//   if (name.endsWith('.png')) {
//     return `url(${require(`./images/${name}.svg`)})`;
//   }
//   else {
//     return `url('${imgBlob(name, colors)}')`;
//   }
// }

export function cssImg(name, colors?: any) {
  if (!name) return '';
  if (!name.endsWith('.png')) {
    return `url(${imgBlob(name, colors)})`;
  }
  else {
    return `url(${require(`./images/${name}`)})`;
  }
}

export const img = (() => {
  const _cache: any = {};
  return (name: string, colors?: { [color: string]: string; }) => {
    const key = name + JSON.stringify(colors);
    if (_cache[key])
      return _cache[key];
    let src: string = require(`./images/${name}.svg`);
    if (name == 'page') {
      // console.log(src)
    }
    if (colors) {
      for (const color in colors) {
        src = src.replace(new RegExp(color, 'g'), colors[color]);
      }
    }
    return _cache[key] = src;
  };
})();
