import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames'
import fuzzy from 'fuzzy';
import _ from 'lodash';
import { component, styled, generateClassName } from '../component2';
import { w } from '../w';
import Editor from '@draft-js-plugins/editor';
import { ContentState, EditorState, SelectionState, Modifier, CompositeDecorator, convertToRaw, convertFromRaw } from 'draft-js';
import { x, XInit, XObject } from '../XObject';
import Around from '../components/Around';
import { collections } from '../db';

export function isId(str) {
	return str && str.length == 24 && !!str.match(/^[0-9a-z]{24}$/);
}


export const state = XInit(class {
  focused;
  focusAnchor: FocusAnchor;
});


export const getEntities = (editorState, entityType = null): { start, end, entity: {
  data
} }[] => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(character => {
      if (character.getEntity() !== null) {
        const entity = content.getEntity(character.getEntity());
        if (!entityType || (entityType && entity.getType() === entityType)) {
          selectedEntity = {
            entityKey: character.getEntity(),
            blockKey: block.getKey(),
            entity: content.getEntity(character.getEntity()),
          };
          return true;
        }
      }
      return false;
    },
    (start, end) => {
      entities.push({...selectedEntity, start, end});
    });
  });
  return entities;
};


export enum FocusAnchor {
  start, end
}

// let focusAnchor: FocusAnchor;


const moveSelectionToEnd = editorState => {
  return EditorState.moveSelectionToEnd(editorState);  
};

const moveSelectionToBeginning = editorState => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.first().getKey();
  const length = blockMap.first().getLength();

  // On Chrome and Safari, calling focus on contenteditable focuses the
  // cursor at the first character. This is something you don't expect when
  // you're clicking on an input element but not directly on a character.
  // Put the cursor back where it was before the blur.
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: 0,
    focusKey: key,
    focusOffset: 0,
  });
  return EditorState.forceSelection(editorState, selection);
};


const entireSelection = (editorState) => {
  let currentContent = editorState.getCurrentContent();
  return editorState.getSelection().merge({
    anchorKey: currentContent.getFirstBlock().getKey(),
    anchorOffset: 0,  

    focusOffset: currentContent.getLastBlock().getText().length, 
    focusKey: currentContent.getLastBlock().getKey(),
  })
}

const selection = (editorState) => {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);

  return [ selectedText, [ start, end ] ];
}


function compareEditorContent(old: EditorState, n: EditorState) {
  if (!old && n || old && !n) return false;
  if (!old && !n) return true;
  return _.isEqual(convertToRaw(old.getCurrentContent()), convertToRaw(n.getCurrentContent()));
}

function plainSelection(selection: SelectionState) {
  return { 
    anchorKey: selection.getAnchorKey(),
    anchorOffset: selection.getAnchorOffset(),
    focusKey: selection.getFocusKey(),
    focusOffset: selection.getFocusOffset(),
    isBackward: selection.getIsBackward(),
    hasFocus: selection.getHasFocus(),
  }
}

function compareEditorStates(old: EditorState, n: EditorState) {
  if (!old && n || old && !n) return false;
  if (!old && !n) return true;
  return _.isEqual(convertToRaw(old.getCurrentContent()), convertToRaw(n.getCurrentContent())) && _.isEqual(plainSelection(old.getSelection()), plainSelection(n.getSelection()));
}


export function setEditorStateForKey(key: string, editorState: EditorState) {
  if (_.isNil(editorState)) {
    delete _editorStates[key];
  }
  else {
    if (_editorStates[key]) {
      if (compareEditorStates(_editorStates[key], editorState)) {
        return false;
      }
    }
    _editorStates[key] = editorState;
    return true;  
  }
}


export const _editorStates: { [id: string]: EditorState } = {};
export function editorStateForKey(key: string, defaultState?, decorator?): EditorState {
  if (!_editorStates[key] && !_.isNil(defaultState)) {
    _editorStates[key] = createContent(defaultState, decorator);
  }
  return _editorStates[key];
}


export function createContent(defaultState: string | ContentState, decorator?) {
  if (_.isString(defaultState)) {
    return EditorState.createWithContent(ContentState.createFromText(defaultState), decorator);
  }
  else if (!_.isNil(defaultState)) {
    try {
      return EditorState.createWithContent(defaultState, decorator);
    }
    catch (e) {
      console.log(x(defaultState));
      throw e;
    }
  }
}



@component
export class _Editor extends Component<{ className?, style?, inline?, placeholder?, onContentChanged?: (newEditorState, content) => void, stateOnlyOnFocus?: boolean, decorator?, id?: string, idSuffix?, defaultContent?: any, entityValue?, keyBindingFn?, onDeleteStart?, handleReturn?, onChange?(editorState: EditorState, value), onDownArrow?(e), onUpArrow?(e), onTab?, onBlur?, onFocus?, onEsc?, onEnter? }> {
  editorRef: { current?: Editor } = {}

  _content: EditorState;

  focused;

  blur() {
    this.editorRef.current.blur();
  }

  static styles() {
    const { c } = this;
    return styled.div`
      cursor: text;
      &.${c.inline} {
        display: inline-flex;
        .DraftEditor-root {
          min-width: 10px;
        }
      }
    `
  }

  static c = {
    empty: 'empty',
    inline: 'inline',
  }


  public editorState() {
    if (this.props.id) {
      if (this.props.stateOnlyOnFocus && !this.focused) {
        return createContent(this.props.defaultContent || this.props.entityValue || '', this.props.decorator);
      }
      const contentKey = this.props.id + (this.props.idSuffix || '');
      return editorStateForKey(contentKey, this.props.defaultContent || this.props.entityValue || '', this.props.decorator);  
    }
    else {
      if (!this._content) {
        this._content = createContent(this.props.defaultContent || this.props.entityValue || '', this.props.decorator);
      }
      return this._content;
    }
  }

  public setEditorState(content) {
    if (this.props.id) {
      const contentKey = this.props.id + (this.props.idSuffix || '');
      return setEditorStateForKey(contentKey, content);  
    }
    else {
      if (!compareEditorStates(this._content, content)) {
        this._content = content;
        return true;
      }
    }
  }

  componentWillUnmount() {
    if (this.props.id) delete _editorStates[this.props.id]
  }

  render(Container?) {
    const { c } = _Editor;
    let editor: Editor;
    const editorState = this.editorState();
    const setContent = content => this.setEditorState(content);
    return (
      <Container data-id={this.props.id} style={this.props.style} className={classNames(this.props.className, {[ c.empty]: !editorState.getCurrentContent().getPlainText(), [c.inline]: this.props.inline })}>
        <Editor
          placeholder={this.props.placeholder}
          onFocus={() => {
            this.props.onFocus?.();
            this.focused = true;
            if (this.props.id) {
              state.focused = this.props.id;
            }
          }}
          onBlur={() => {
            this.props.onBlur?.();

            this.focused = false;
            if (this.props.id) {
              if (this.props.stateOnlyOnFocus) {
                setContent(null);
              }  
              if (state.focused == this.props.id) {
                state.focused = null;
                if (editor) setContent(editor.getEditorState());
              }  
            }
          }}
          ref={e => {
            this.editorRef.current = e;
            if (e) {
              editor = e;
              if (this.props.id) {
                if (state.focused == this.props.id) {
                  editor.focus();
                  if (state.focusAnchor == FocusAnchor.end) {
                    setContent(moveSelectionToEnd(moveSelectionToEnd(editorState)));
                    this.forceUpdate();
                  }
                  state.focusAnchor = null;
                }  
              }
            }
          }}
          onDownArrow={this.props.onDownArrow}
          onUpArrow={this.props.onUpArrow}
          editorState={editorState}
          onChange={newEditorState => {
            const content = newEditorState.getCurrentContent().getPlainText();
            if (setContent(newEditorState)) {
              if (!compareEditorContent(editorState, newEditorState)) {
                this.props.onContentChanged?.(newEditorState, content);
              }
              this.props.onChange?.(newEditorState, content);
              this.forceUpdate();
            }
          }}
          
          keyBindingFn={e => {
            // if (e.key == 'UpArrow') {
            //   if (this.props.onUpArrow) return this.props.onUpArrow(e);
            // }
            // else if (e.key == 'DownArrow') {
            //   if (this.props.onDownArrow) return this.props.onDownArrow(e);
            // }
            if (e.key == 'Backspace' && !selection(editorState)[0]) {
              if (editorState.isSelectionAtStartOfContent()) {
                if (this.props.onDeleteStart?.(editorState)) {
                  return 'handled';
                }
              }
            }
            else if (e.key == 'Tab') {
              if (this.props.onTab) return this.props.onTab(e);
            }
            else if (e.key == 'Escape') {
              if (this.props.onEsc) {
                this.props.onEsc();
                return 'handled';
              }
            }
            else if (e.key == 'Enter' && !e.shiftKey) {
              if (this.props.onEnter) {
                return this.props.onEnter(e);
              }
            }
            return this.props.keyBindingFn?.(e);
          }}
          handleReturn={this.props.handleReturn}
        />
      </Container>
    );
  }
}


export function applyEntities(contentState: ContentState, entities: { entity, range: [ number, number ] }[]): ContentState {
  for (const entity of entities) {
    const contentStateWithEntity = contentState.createEntity('TOKEN', 'IMMUTABLE', entity.entity);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const sel = new SelectionState({
			anchorKey: contentStateWithEntity.getFirstBlock().getKey(),
			anchorOffset: entity.range[0],
			focusKey: contentStateWithEntity.getFirstBlock().getKey(),
			focusOffset: entity.range[1],
		});

		contentState = Modifier.applyEntity(contentStateWithEntity, sel, entityKey);

  }

  return contentState;

}

@component
export class DraftSelect extends Component<{
  right?
  style?
  inline?
  placeholder?
  id: string
  idSuffix?
  options: { display, filterText?, value }[]
  onSelect?
  onNoSelection?
  keyBindingFn?
  onFilterChange?
  filter?
  onDeleteStart?
  onTab?
  onEsc?
  onCreate?
  stateOnFocus?
  keepMenuOpen?
  renderCreate?
}> {
  state = XInit(class {
    filter = '';
    selected = 0;
    showMenu
  });

  static t = {
    menu: (() => {
      const c = {
        selected: generateClassName(),
      };

      return w('', styled.div`
        box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 22%);
        border: 1px solid #c2c2c2;
        background-color: white;
        max-height: 300px;
        overflow-y: auto;
        border-radius: 3px;
        color: black;

        .${c.selected} {
          /* font-weight: bold; */
          background-color: #e1e1e1
        }

        > div {
          cursor: pointer;
          padding: 4px;
          &:hover {
            background-color: #f3f3f3
          }
        }
      `, { c });
    })(),
  }

  _filter() {
    return 'filter' in this.props ? this.props.filter : this.state.filter;
  }


  render() {
    const { t } = DraftSelect;
    const ref = React.createRef<_Editor>();
    let options = (_.isFunction(this.props.options) ? this.props.options() : this.props.options).filter(option => fuzzy.test(this._filter() || '', option.filterText || option.display || '(none)'));
    if (this._filter()) options.sort((a, b) => (a.filterText || a.display).length < (b.filterText || b.display).length ? -1 : 1);

    if (this.props.onCreate && this.state.filter) {
      options.push({ create: true })
    }

    return (
      <>
        <_Editor
          style={this.props.style}
          inline={this.props.inline}
          ref={ref}
          onFocus={() => {
            this.state.showMenu = true;
          }}
          onBlur={() => {
            if (!this.props.keepMenuOpen || !this.state.filter) {
              setTimeout(() => {
                this.state.showMenu = false;
              }, 100);
            }
          }}
          placeholder={this.props.placeholder}
          stateOnlyOnFocus={this.props.stateOnFocus ?? true}
          id={this.props.id}
          idSuffix={this.props.idSuffix}
          defaultContent={''}
          onDownArrow={e => {
            this.state.selected = (this.state.selected + 1) % options.length;
            e.preventDefault();
          }}
          onTab={this.props.onTab}
          onUpArrow={e => {
            this.state.selected = (this.state.selected - 1 + options.length) % options.length;
            e.preventDefault();
          }}
          // keyBindingFn={e => {
          //   if (this.props.terminatingHandler.handle(e)) {
          //     return 'handled';
          //   }
          // }}
          onDeleteStart={this.props.onDeleteStart}
          handleReturn={async e => {
            if (options[this.state.selected]) {
              if (options[this.state.selected].create) {
                this.props.onSelect?.(await this.props.onCreate(this._filter()));
              }
              else {
                this.props.onSelect?.(options[this.state.selected].value);
              }
              ref.current?.blur?.();
              this.state.showMenu = false;
              this.state.selected = 0;

              setTimeout(() => {
                console.log('clear');
                delete _editorStates[this.props.id + (this.props.idSuffix || '')]; 
                this.state.filter = '';
              }, 0)
            }
            else {
              if (this.props.onNoSelection?.(this._filter())) {
                this.state.showMenu = false;
                this.state.selected = 0;
                delete this.state.filter;
                // state.focused = null;
                delete _editorStates[this.props.id + (this.props.idSuffix || '')]; 
                ref.current?.blur?.();  
              }
            }
            return 'handled';
          }}
          onContentChanged={(e, c) => {
            this.state.selected = 0;
            this.state.showMenu = true;
            this.state.filter = c;
            this.props.onFilterChange?.(c, () => {
              this.state.showMenu = false;
              this.state.selected = 0;
              delete this.state.filter;
              // state.focused = null;
              delete _editorStates[this.props.id + (this.props.idSuffix || '')]; 
              // ref.current.blur();
            });
          }}
          keyBindingFn={this.props.keyBindingFn}
          onEsc={() => {
            this.props.onEsc?.();
            this.state.showMenu = false;
          }}
        />
        {this.state.showMenu && <Around right={this.props.right} position="below" anchor={() => ReactDOM.findDOMNode(this)}>
          <t.menu>
            {options.map((option, i) => {
              return <div onClick={() => {
                if (option.create) {
                  
                }
                else {
                  this.props.onSelect?.(options[i].value);
                  this.state.selected = 0;
                  this.state.showMenu = false;
                  delete this.state.filter;
                  ref.current?.blur?.();
                  delete _editorStates[this.props.id + (this.props.idSuffix || '')];    
                }
              }} className={this.state.selected == i && t.menu.c.selected} key={i}>
                {option.create ? 
                  (this.props.renderCreate ? this.props.renderCreate(this._filter()) : `Create "${this._filter()}"`) :
                  option.display
                }
              </div>
            })}
          </t.menu>
        </Around>}
      </>
    );
  }
}

