import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { XObject } from '../XObject';
import { EntityField } from '../glue/EntityField';
import { NotionDocumentDeprecated } from '../components/NotionDocumentDeprecated';


@component
export class NotionWindow extends Component<{ window; }> {
  static styles = styled.div`
    > .entity {
      position:absolute;
      z-index: 111;
    }
  `;
  render() {
    return (
      <>
        <div
          className="entity"
          onClick={() => {
            this.props.window.notionState.active = this.props.window.entity;
          }}
        >
          <EntityField
            value={this.props.window.entity}
            setValue={value => {
              this.props.window.entity = value;
            }} />
        </div>

        {this.props.window.entity && <NotionDocumentDeprecated
          rootEntity={this.props.window.entity}
          state={XObject.get(this.props.window, 'notionState', {})} />}
      </>
    );
  }
}
