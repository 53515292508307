import jQuery from "jquery";
import { Component } from "react";
import ReactDOM from "react-dom";
import { component, css, styled } from "../component2";
import { x, X, XInit, XObject } from "../XObject";
import cx from "classnames";
import md5 from "md5";
import { appState } from "../etc/appState";

const Wrapper = styled.div`
  ${(props) => {
    return css`
      &.odd {

        [data-value="${props.activeValue}"] {
          outline: 2px solid ${oddColor};
        }
      }

      &:not(.odd) {
        [data-value="${props.activeValue}"] {
          outline: 2px solid ${evenColor};
        }

      }

      [data-value="${props.inspectValue}"] {
        outline: 2px solid #6baf76;
      }


      &.odd {
        [data-entity="${props.activeEntity}"] {
          outline: 2px solid ${oddColor};
        }
      }

      &:not(.odd) {
        [data-entity="${props.activeEntity}"] {
          outline: 2px solid ${evenColor};
        }
      }

    `;
  }}
`;

interface Interface {
  renderModule(args: {
    elId,
    n,
    prevN?,
    index,
    // pane,
    onSelectSelf,
    onChildHover,

    isHovering: boolean
    isActive: boolean

    childHover?: string
    childActive?: string

    inspectingNode,
    onSelectChild,


    onInspect,

  })
  children
  renderInspect

  paneWidth?
  paneLine?
}

const oddColor = '#85bfff';
const evenColor = '#c5c5c5';

@component
export class DrilldownView extends Component<{ hideInspectPane?, iface: Interface, root, state: {
  stack
  inspectingNode: {
    id
    context
  }
} }> {
  static styles() {
    const { t } = this;
    return styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    `;
  }

  static t = {
    panes: styled.div`
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 300px;
      bottom: 0;
      overflow: auto;
      &.hideInspectPane {
        right: 0;
      }
    `,
    pane: styled.div`
      position: relative;


        &:not(:nth-child(odd)) {
        border-top: 4px solid ${evenColor};
      }

      &:nth-child(odd) {
        border-top: 4px solid ${oddColor};

      }




      flex: 0 0 auto;
      width: 430px;
      border-right: 1px solid #ccc;
      padding-right: 8px;
      overflow: auto;
      padding: 8px;
      padding-top: 0;
      padding-top: 8px;

      .module {
        border-bottom: 1px solid #d5d5d5;

        &.active {
          &.odd {
            outline: 2px solid ${oddColor};
          }
          &:not(.odd) {
            outline: 2px solid ${evenColor};
          }

        }
      }

      &:not(.paneLine) {
        .module {
          outline: none !important;
          border: none !important;
        }
      }
    `,

    right: styled.div`
      right: 0;
      width: 300px;
      position: absolute;
      top: 0;
      bottom: 0;
      overflow: auto;
      border-left: 1px solid #ccc;
      padding: 8px;
      box-sizing: border-box;

      border-top: 4px solid #6baf76;
    `
  }

  state = XInit(class {})

  render(Container?) {

    const { t } = DrilldownView;

    const state = this.props.state;

    if (!state.stack) state.stack = X([]);
    if (!state.stack.length) {
      const id = this.props.root;
      state.stack = X([
        XObject.obj({
          type: 'node',
          id: id,
        }),
        XObject.obj({
          type: 'nodeContents',
          id: id,
        }),
      ]);

    }

    const renderInspect = ({ n }) => {
      return this.props.iface.renderInspect({ n, onInspect });
    }


    const onInspect = (n) => {
      console.log('inspect', n);
      state.inspectingNode = { id: n, context: null };
    }




    return (
      <Container className={this.props.hideInspectPane && 'hideInspectPane'}>
        {/* <t.pane>{this.props.root}</t.pane> */}
        <t.panes className={cx("panes", this.props.hideInspectPane && 'hideInspectPane')}>
          {state.stack.map((pane, i) => {

            const width = this.props.iface.paneWidth?.(i);
            const paneLine = this.props.iface.paneLine ? this.props.iface.paneLine?.(i) : true;



            const selectChild = (n, id) => {
              console.log('select child', n, id);
              if (id) {
                // state.inspectingNode = { id, context: 'listing' };
                // appState.ins

              }

              // state.stack = state.stack.slice(0, i);

              
                const newStack = []

                newStack.push(XObject.obj({
                  type: pane.type,
                  id: pane.id,
                  args: pane.args,
                  childId: n,
                }));
                newStack.push(XObject.obj({
                  type: 'nodeContents',
                  childId: id,
                  id: n
                }));
  
                if (id) {
                  newStack.push(XObject.obj({
                    type: 'nodeContents',
                    // childId: id,
                    id: id
                  }));
  
                }

                state.stack = X(x(state.stack.slice(0, i)).concat(newStack));

                setTimeout(() => {
                  const panes = jQuery(ReactDOM.findDOMNode(this)).find('.panes > *');
                  for (const pane of panes) {
                    const active = jQuery(pane).find('.module.active')[0];
                    active?.scrollIntoView?.({ behavior: 'smooth' });
                  }
                }, 300);
  
  


            }

            const renderEntitiesPane = (entities) => {
              
              let activeValue;
              const nextPane = state.stack[i + 1];
              if (nextPane?.type == 'interface') {
                activeValue = md5(JSON.stringify({ id: nextPane.id, args: x(nextPane.args) }));
              }
              else {
                activeValue = nextPane?.childId;
              }


              return (
                <t.pane key={i} style={{ width }} className={paneLine && 'paneLine'}>
                  <Wrapper
                  activeValue={activeValue}
                  data-activeValue={activeValue}
                  inspectValue={state.inspectingNode?.id}
                  >
        
                  {entities.map(n => {
        
        
                      return  (
                      <div
        
        
        
                      className={cx('module', {
                        active: pane.childId == n,
                        odd: i % 2,
                      })}
                    onMouseOver={() => {
                      // pane.hoverChildId = n;
                    }}
        
                    onMouseOut={() => {
                      // pane.hoverChildId = null;
                    }}
                  >
        
                    {this.props.iface.renderModule({
                      elId: i + n,
                      n: n,
                      prevN: this.props.state.stack[i - 1]?.childId,
                      index: i,
                      isActive: pane.childId == n,
                      isHovering: pane.hoverChildId == n,
                      onSelectSelf: n => {
                        state.inspectingNode = { id: n, context: 'node' };
                      },
                      onChildHover: id => {
                        if (state.stack[i + 1]?.id == n) {
                          if (state.stack[i + 1]) state.stack[i + 1].hoverChildId = id;
        
                        }
        
                      },
        
                      childActive: state.stack[i + 1]?.childId,
                      childHover: state.stack[i + 1]?.hoverChildId,
                      inspectingNode: state.inspectingNode,
                      onSelectChild: id => {
                        selectChild(n, id);
                      },
                      onInspect,
        
                    })}
                  </div>
                  );
                  
                })}
                </Wrapper>
                </t.pane>
              )
            }

            if (pane.type == 'node') {
              let activeValue;
              const nextPane = state.stack[i + 1];
              activeValue = nextPane?.childId;

              return (
                <t.pane key={i} style={{ width }} className={paneLine && 'paneLine'}>
                    <Wrapper
                      activeValue={activeValue}
                      data-active={activeValue}
                  inspectValue={state.inspectingNode?.id}

                      
                    >
                      <div className={cx('module', 'active', { odd: i % 2 })}>{this.props.iface.renderModule({
                        elId: i + pane.id,
                        n: pane.id,
                        index: i,
                        // pane: s,


                        isActive: true,
                        isHovering: false,
                        // isActive: state.stack[i + 1]?.id == pane.id,
                        // hovering: state.stack[i + 1]?.hoverChildId == pane.id,

                        childActive: state.stack[i + 1]?.childId,
                        childHover: state.stack[i + 1]?.hoverChildId,

                        onSelectSelf: n => {
                          state.inspectingNode = { id: n, context: 'node' };

                        },
                        onChildHover: id => {
                          if (state.stack[i + 1]?.id == pane.id) {
                            if (state.stack[i + 1]) state.stack[i + 1].hoverChildId = id;

                          }
                        },


                        inspectingNode: state.inspectingNode,
                        onSelectChild: id => {
                          selectChild(pane.id, id);
                          // state.inspectingNode = { id, context: 'listing' };
                          // state.stack = state.stack.slice(0, i + 1);
                          // state.stack.push(XObject.obj({
                          //   type: 'nodeContents',
                          //   childId: id,
                          //   id: pane.id,
                          // }));
                          // state.stack.push(XObject.obj({
                          //   type: 'nodeContents',
                          //   // childId: id,
                          //   id,
                          // }));


                        },


                        onInspect,
                      })}</div>
                  </Wrapper>
                  
                </t.pane>
              )
            }
            else if (pane.type == 'nodeContents') {
              const id = pane.id;
              const outgoingNodes: string[] = this.props.iface.children(id, this.props.state.stack[i - 2]?.childId);
              return renderEntitiesPane(outgoingNodes);
            }

          })}
        </t.panes>
        {!this.props.hideInspectPane && <t.right>
          {state.inspectingNode && renderInspect({ n: state.inspectingNode.id })}
        </t.right>}
      </Container>
    );
  }
}