

// export const iconColor = 'rgba(55, 53, 47, 0.45)';
export const iconColor = '#808080';

export function color(el) {
  return props => {
    if (props.theme.mode == 'dark') {
      if (el == 'bg') {
        return '#202020';
      }
      else if (el == 'separator') {
        return '#2b2b2b';
      }
      else if (el == 'bgTextColor') {
        return 'rgb(155,155,155)';
      }
    }
    else if (props.theme.mode == 'light') {
      if (el == 'bg') {
        return '#f7f7f5';
      }
      else if (el == 'separator') {
        return '#e8e8e8';
      }
      else if (el == 'bgTextColor') {
        return '#7d7d7d';
      }
    }

    return 'inherit';
  };
}
