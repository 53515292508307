import { ValueType } from "../ValueType";
import { typeRegistry } from "../typeRegistry.1";

export const $SpaceConfig = typeRegistry.registerType({
  $: '96766ecc-0a5a-5fbb-8ff8-f3645c18c6f9',
  Views: {
    $: '4beb874c-d5a7-595e-bc3c-de962bfb497a',
    $$: '4857b162-9320-5d9f-aa44-4d0e20a8a7d3',
    Title: 'fd5cef1e-ccf1-52c8-bc16-1df04646e391',
    Content: 'e6efc296-c47d-5099-8955-49f2d808b75d',
    Actions: {
      $: 'c2530b0c-dcc9-59e9-b555-2562c8fd1320',
      $$: '4ec6af5b-fd63-53eb-982f-6c1cf6b56bfa',
      Name: 'ec8a34e2-e1ff-5ddc-87a7-43299e23fb37',
      Action: 'feebbd7e-4343-5b20-b5a8-d1a32068cdcc',
    }
  },
  Space: 'e1b9a0e6-f570-5b46-a853-ddf3114caf6b',
}, ids => ({
  _id: ids.$,
  name: 'Space Config',
  definition: [
    {
      scope: [
        {
          id: ids.Space,
          name: '%Space',
        },
      ],
      id: ids.Views.$,
      name: 'Views',
      type: [ValueType.Array, [ValueType.Structure, ids.Views.$$, [
        {
          id: ids.Views.Title,
          name: 'Title',
          type: [],
          property: 'title',
        },
        {
          id: ids.Views.Content,
          name: 'Content',
          type: [],
          property: 'content',
        },
        {
          id: ids.Views.Actions.$,
          name: 'Actions',
          type: [ValueType.Array, [ValueType.Structure, ids.Views.Actions.$$, [
            {
              id: ids.Views.Actions.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              id: ids.Views.Actions.Action,
              name: 'Action',
              type: [],
              property: 'action',
            },
          ]]],
          property: 'actions',
        }
      ]

      ]],
      property: 'views',
    }
  ]
}));
