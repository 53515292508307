import { typeRegistry } from "../typeRegistry.1";
import { $EntityTemplate } from "./$EntityTemplate";


export const $EntityElement = typeRegistry.registerType({
  $: '8145e3be-62f8-5ba7-8ae5-733c83614ffc',
  Name: '91fae431-62f0-5c48-9af9-4160a4891a5f',
  Content: '8550c872-7895-59c9-8f66-909d3d59bb1b',
}, ids => ({
  _id: ids.$,
  name: 'Entity Element Template',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
    {
      scope: [
        {
          // id: '36ea2720-2136-5661-aeb1-fcd330f0aca4',
          id: $EntityTemplate.Entity,

          name: '%Poop',
        },
      ],
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    }
  ]
}));
