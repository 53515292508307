import React, { Component } from 'react';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { EntityMatchEditor } from '../components/EntityMatchEditor';


@component
export class MetaStatesWindow extends Component<{ window; }> {
  render() {
    const workspace = defaultWorkspace();
    return (
      <>
        <ul>
          {workspace.metaStates?.map?.((x, i) => (
            <li key={i}>

              <div><PropertyField object={x} property="name" /></div>
              <div>Closed state: <PropertyField object={x} property="closedState" type="bool" /></div>
              <div>Highlight state: <PropertyField object={x} property="highlightState" type="bool" /></div>
              <div>Background state: <PropertyField object={x} property="backgroundState" type="bool" /></div>
              <button
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    workspace.metaStates.splice(i, 1);
                  }
                }}
              >X</button>
              <ul>
                {x.matches?.map?.((xx, i) => (
                  <li key={i}>
                    <EntityMatchEditor value={xx} /> <button onClick={() => {
                      x.matches.splice(i, 1);
                    }}>X</button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => {
                      XObject.push(x, 'matches', XObject.obj({
                        type: null,
                      }));
                    }}
                  >
                    Add
                  </button>
                </li>
              </ul>
            </li>
          ))}
          <li>
            <button
              onClick={() => {
                XObject.push(workspace, 'metaStates', XObject.obj({
                  name: 'New Meta State',
                }));
              }}
            >
              Add
            </button>
          </li>
        </ul>
      </>
    );
  }
}
