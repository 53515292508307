import {component} from '../../component2';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { XObject } from '../../XObject';
import { defaultWorkspace } from '../../etc/defaultWorkspace';
import { db } from '../../db';
import { registerChangeAction, trackChange, UndoActions } from '../changes';

const SetValue = '46cfcb5d-a2aa-5535-8482-4c9db3c34669';
registerChangeAction(SetValue, {
  name: 'Set Value',
});

@component
export class EntityStateTypeValuePoint extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <select
          value={this.props.state.content || ''}
          onChange={(e) => {
            trackChange(SetValue, {}, [
              [ UndoActions.modifiedValuePoint, this.props.state._id ],
            ]);
            this.props.state.content = e.target.value || null;
          }}
        >
          <option value=""></option>
          {db.stateTypes.map(stateType => (
            <option key={stateType._id} value={stateType._id}>
              {stateType.values.map(v => v.name).join(', ')}
            </option>
          ))}

        </select>
      </>
    )
  }
}

export const registerEntityStateTypeType = () => {
    registerType(ValueType.EntityStateType, {
        editorComp: EntityStateTypeValuePoint,
        evaluate: (state) => {
          return state.content;
        }
    });
};