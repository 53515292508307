import { Component } from "react";
import _ from 'lodash';
import { component } from "../component2";
import { getCodeComponent } from "../pushCode";

@component
export class ComponentValueWrapper extends Component<{ component; args; }> {
  render() {
    let d;
    if (this.props.component) {
      const comp = getCodeComponent(this.props.component);
      if (comp) {
        d = comp?.(this.props.args);

      }
      else {
        d = '...';
      }
    }
    else {
      d = '...';
    }

    if (d === '' || _.isNil(d)) {
      d = '...';
    }
    return d;
  }
}
