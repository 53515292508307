import { db } from '../db';
import { CompiledValuePoint, execute, mapStructure } from '../glue/main';
import { Runtime } from '../glue/Runtime';

export function executeSwitch(id: string, entityId: string): CompiledValuePoint {
  const entity = db.entities.findById(entityId);
  if (!entity) throw new Error(`Entity ${entityId} not found`);
  const value = execute(id, new Runtime({}));
  const mapped = mapStructure(value);
  for (const entry of mapped.entries.content) {
    const mappedEntry = mapStructure(entry);
    if (mappedEntry.match.content == entity.type) {
      return mappedEntry.content;
    }
  }
}
