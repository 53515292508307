import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';



@component
export class MenuItemsWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        {db.sidebar.map((x, i) => (
          <div key={x._id}>
            <PropertyField object={x} property="name" />
            <button
              onClick={() => {
                db.sidebar.splice(i, 1);
              }}
            >-</button>
          </div>
        ))}
      </>
    );
  }
}
