import axios from 'axios';
import { x, X } from '../XObject';
import config from '../config';

const _state = X({
  tick: 0,
  data: null,
});
export function linearData(update?) {
  if (_state.data) return x(_state.data);
  else {
    axios.get(`${config.apiServer}linear-tasks` + (update ? '?update=1' : '')).then(r => {
      _state.data = r.data;
      _state.tick++;
    });
    return null;
  }
}


export async function update(update=true) {
  const r = await axios.get(`${config.apiServer}linear-tasks` + (update ? '?update=1' : ''));
  _state.data = r.data;
  _state.tick++;
}

