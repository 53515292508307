export enum SidebarItemType {
  query = '3df9f1c2-9f33-5af1-8882-fdc3a30f8a81',
  entity = '9d86458d-6850-5c2e-aea9-9fa60be4c1eb',
  space = 'a83b2867-1217-59af-adee-d0545da0d847',
  page = 'd66ebe4b-0658-5b28-811e-1757e11af112',
  folder = 'a2f3a7a4-d80e-5e65-9717-1480c2037d8d',

  pageEntities = '6d953be9-5232-5cb8-98cb-24a918c2fb84',
  library = '579b6b96-1fc6-526f-80f0-a132bd2beed7',
  error = '3564b35b-e8cf-549d-adb1-b6a27f3bb88d',

  canvas = 'e9951ef6-fdad-5df4-8a6e-e8db9d5eb9c0',
}
