import { Component } from 'react';
import { component, styled } from '../component2';
import { DragSource } from 'react-dnd';


@component
class _Entity extends Component<{ id; children?; connectDragSource; }> {
  static styles = styled.span``;
  render() {
    return this.props.connectDragSource((
      <span>
        {this.props.children}
      </span>
    ));
  }
}

export const EntityHandle = DragSource('object', {
  beginDrag(props: any) {
    return {
      type: 'entity',
      entity: props.id,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
  }
}, (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
})(_Entity);
