import React, { Component } from 'react';
import { component } from '../component2';
import { Inbox } from '../components/Inbox';


@component
export class InboxWindow extends Component<{ window; }> {
  render() {
    return <Inbox />;
  }
}
