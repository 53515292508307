import React, { Component } from 'react';
import { component } from '../component2';
import { XObject } from '../XObject';
import { EntityMatchEditor } from './EntityMatchEditor';
import { TGraph } from "../types/TGraph";
import { RelMatchEditor } from './RelMatchEditor';


@component
export class GraphEditor extends Component<{ value: TGraph; }> {
  render() {
    const matches: TGraph['graph']['matches'] = XObject.get(this.props.value, 'matches', []);
    return (
      <>
        <ul>
          {matches.map((x, i) => {
            const rels = XObject.get(x, 'rels', []);

            return (
              <li key={i}>
                <div><EntityMatchEditor value={XObject.get(x, 'match', {})} /></div>
                <h3>Relationships</h3>
                <ul>
                  {rels.map((x, i) => {
                    return (
                      <li key={i}>
                        <div><RelMatchEditor value={x} /></div>
                      </li>
                    );
                  })}
                  <li>
                    <button onClick={() => {
                      XObject.push(x, 'rels', XObject.obj({}));
                    }}>+</button>
                  </li>
                </ul>
              </li>
            );
          })}
          <li>
            <button onClick={() => {
              XObject.push(this.props.value, 'matches', XObject.obj({}));
            }}>+</button>
          </li>
        </ul>
      </>
    );
  }
}
