import { styled } from "../../component";
import classNames from "classnames";
import { ValueType } from "../ValueType";
import { evaluate, mapStructure, render } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { XObject } from "../../XObject";

const $TabView = typeRegistry.registerType({
  $: 'd740735f-2d73-5012-a3dc-77551500cf02',
  Tabs: {
    $: 'a59a9436-b7fd-5549-b2c5-ca5a9a249b94',
    $$: 'c1e86689-c2f9-5afc-9dd4-2748854e1066',
    Title: '0b8fbb5a-588d-5c1e-a77c-cc20bef17436',
    Content: '7b4859f6-7bb9-5e04-aaa2-ab13996b2ef6',
  }
}, ids => ({
  _id: ids.$,
  name: 'Tab View',
  definition: [
    {
      id: ids.Tabs.$,
      name: 'Tabs',
      type: [ValueType.Array, [ValueType.Structure, ids.Tabs.$$, [
        {
          id: ids.Tabs.Title,
          name: 'Title',
          type: [],
          property: 'title',
        },
        {
          id: ids.Tabs.Content,
          name: 'Content',
          type: [],
          property: 'content',
        }
      ]]],
      property: 'tabs',
    }
  ],
}));

const Wrapper = styled.div`
  .tabBar {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 0 16px;
    height: 44px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    > *{
      margin-right: 16px;

      &.active {
        border-bottom: 2px solid #000;
      }
    }
  }

  .tabContent {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

structRenderers[$TabView.$] = (value, map, state, renderType) => {
  const mapped = mapStructure(value);

  const activeTab = state.activeTab || mapped.tabs.content[0]._id;
  const activeTabValue = mapped.tabs.content.find(tab => tab._id === activeTab);
  const activeTabMapped = activeTabValue&& mapStructure(activeTabValue);

  

  return (
    <Wrapper data-value-point={value._id}>
      <div className="tabBar">
      {mapped.tabs.content.map((tab, i) => {
        const mappedTab = mapStructure(tab);
        return (
          <div key={i} className={classNames({ active: activeTab === tab._id })}
            onClick={() => {
              state.activeTab = tab._id;
            }}
          >
            {render(mappedTab.title, map)}
          </div>
        );
      })}
      </div>
      <div className="tabContent">
        {activeTabMapped && render(activeTabMapped.content, map, renderType, XObject.get(state, state.activeTab, {}))}
      </div>
    </Wrapper>
  )
}