import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { XInit, x } from '../XObject';
import { component, generateClassName, styled } from '../component2';
import { w } from '../w';
import { ContentState, EditorState, CompositeDecorator, Modifier, SelectionState } from 'draft-js';
import { editorStateForKey, setEditorStateForKey, applyEntities, getEntities, _Editor } from '../etc/draftHelpers';
import Around from './Around';

const styles = {
  root: {
    fontFamily: '\'Helvetica\', sans-serif',
    padding: 20,
    width: 600,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  immutable: {
    // backgroundColor: '#21212133',
    // padding: '2px 0',
    // fontWeight: 'bold',
    borderBottom: '1px solid #2121218f',
  },
  mutable: {
    backgroundColor: 'rgba(204, 204, 255, 1.0)',
    padding: '2px 0',
  },
  segmented: {
    backgroundColor: 'rgba(248, 222, 126, 1.0)',
    padding: '2px 0',
  },
};

function getDecoratedStyle(mutability) {
  switch (mutability) {
    case 'IMMUTABLE': return styles.immutable;
    case 'MUTABLE': return styles.mutable;
    case 'SEGMENTED': return styles.segmented;
    default: return null;
  }
}

const TokenSpan = (props) => {
  // const style = getDecoratedStyle(
  //   props.contentState.getEntity(props.entityKey).getMutability()
  // );
  const data = props.contentState.getEntity(props.entityKey).data;

  // console.log(props, data);

    // return (
    //   <span data-poop data-offset-key={props.offsetKey} style={{ fontWeight: 'bold' }}>
    //     {props.children}
    //   </span>
    // );


  if (data.type == 'entity') {
  }
  else {
    return (
      <span data-offset-key={props.offsetKey} style={{ fontWeight: 'bold' }}>
        {props.children}
      </span>
    );
  
  }
};


function getEntityStrategy(mutability) {
  return function(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return contentState.getEntity(entityKey).getMutability() === mutability;
      },
      callback
    );
  };
}


const decorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy('IMMUTABLE'),
    component: TokenSpan,
  },
  {
    strategy: getEntityStrategy('MUTABLE'),
    component: TokenSpan,
  },
  {
    strategy: getEntityStrategy('SEGMENTED'),
    component: TokenSpan,
  },
]);

@component
export class ImbuedTextInput extends Component<{ keyBindingFn?, id?, placeholder?, defaultValue, onChange?, onEnter?, _onFocus?, _onBlur?, inline?, onDeleteStart?, onTab?, onDownArrow?, onUpArrow?, entityDisplay?, triggerCharacters?, selectorEl?, options?(triggerCharacter: string, filter: string): {
  type
  name
  key
  value/* : {
    type
    content
  } | (() => {
    type
    content
  }) */
}[] }> {
  state = XInit(class {
    triggerCharPos
    triggerCharacter
    filter
    selected = 0;
    edited = false;
  });

	// content

	// entities = []

  useId = true;

	constructor(props) {
		super(props);
	}

  editorState() {
    // if (!this.useId) return this.editorRef.current.editorState();
    return editorStateForKey(this.props.id);
  }

  setEditorState(editorState) {
    // if (!this.useId) return this.editorRef.current.setEditorState(editorState);
    return setEditorStateForKey(this.props.id, editorState);
  }

  inited = true
	async componentDidMount() {
		const entities = [];
		let str = '';
		for (const s of this.props.defaultValue) {
			if (_.isString(s)) {
				str += s;
			}
			else {
				const strValue = (this.props.entityDisplay ? (await this.props.entityDisplay(s)) : '***') || '?';
				const start = str.length, end = str.length + strValue.length;
				str += strValue;
				entities.push({
					range: [ start, end ],
					entity: s,
				})
			}
		}


		const content = ContentState.createFromText(str);

    let newState = EditorState.create({
      currentContent: applyEntities(content, entities),
      selection: SelectionState.createEmpty(undefined),
      decorator,
    })

    newState = EditorState.acceptSelection(newState, new SelectionState({
      anchorKey: newState.getCurrentContent().getFirstBlock().getKey(),
      anchorOffset: str.length,
      focusKey: newState.getCurrentContent().getFirstBlock().getKey(),
      focusOffset: str.length,
    }));


    this.setEditorState(newState);

    this.inited = true;

    this.forceUpdate();

  
	}

  selectorElRef = React.createRef<any>();
	getSuggestions() {
    return this.props.selectorEl?.(this.state.triggerCharacter, this.state.filter, this.selectorElRef) || this.props.options?.(this.state.triggerCharacter, this.state.filter) || [];

	}

	static t = {
    menu: (() => {
      const c = {
        selected: generateClassName(),
      }
      return w('', styled.div`
        background: white;
        border: 1px solid black;
        .${c.selected} {
          font-weight: bold;
        }
      `, { c })
    })(),
  }



	currentValue() {
		const editorState = this.editorState();
		const entities = getEntities(editorState);
		const text = editorState.getCurrentContent().getPlainText('\u0001');
		const content = [];

		if (entities.length) {
			let i = 0;
			for (const entity of entities) {
				if (entity.start) {
					let start;
					if (i == 0) start = 0;
					else start = entities[i - 1].end;
					
					content.push(text.slice(start, entity.start));
				}
	
				content.push(entity.entity.data);
	
				++i;
			}
	
			if (entities[entities.length - 1].end < text.length) {
				content.push(text.slice(entities[entities.length - 1].end));
			}

		}
		else {
			content.push(text);
		}

		return content;
	}

	changed() {
		this.props.onChange?.(this.currentValue());
	}

  editorRef = React.createRef<_Editor>();

  isSelecting() {
    return !_.isNil(this.state.triggerCharPos);
  }

  _key = 0;


	render() {
    if (!this.inited) return null;
		const { t } = ImbuedTextInput;
    const editorRef = this.editorRef;

    return (
			<>
        <_Editor
          // stateOnlyOnFocus
          key={this._key}
          inline={this.props.inline}
          id={this.props.id}
          placeholder={this.props.placeholder}
          ref={editorRef}
          onFocus={this.props._onFocus}
          onBlur={this.props._onBlur}
          decorator={decorator}
          // defaultContent={this.content}
          onTab={this.props.onTab}
          keyBindingFn={e => {
            if (this.props.triggerCharacters && this.props.triggerCharacters.indexOf(e.key) != -1) {
              const editor = editorRef.current.editorRef.current;
              const start = editor.getEditorState().getSelection().getStartOffset();
              this.state.triggerCharPos = start;
              this.state.triggerCharacter = e.key;
            }
            else {
              return this.props.keyBindingFn?.(e);
            }
          }}
          onDeleteStart={this.props.onDeleteStart}
          onContentChanged={e => {
            const text = e.getCurrentContent().getPlainText('\u0001');
            const start = e.getSelection().getStartOffset();
            this.state.filter = text.slice(this.state.triggerCharPos + 1, start);
            if (text.length < this.state.triggerCharPos + 1) {
              this.state.triggerCharPos = null;
            }
            this.state.edited = true;

						if (_.isNil(this.state.triggerCharPos)) {
							this.changed();
						}
          }}
          onDownArrow={e => {

            if (this.isSelecting()) {
              if (this.selectorElRef.current) {
                this.selectorElRef.current.onDownArrow?.(e);
              }
              else {
                const size = this.getSuggestions().length;
                this.state.selected = (this.state.selected + 1) % size;  
                
  
              }
              e.preventDefault();
            }
            else {
              this.props.onDownArrow?.(e);
            }
          }}
          onUpArrow={e => {
            if (this.isSelecting()) {
              if (this.selectorElRef.current) {
                this.selectorElRef.current.onUpArrow?.(e);
              }
              else {

              const size = this.getSuggestions().length;
              this.state.selected = (this.state.selected + size - 1) % size;  
              }
              e.preventDefault();
            }
            else {
              this.props.onUpArrow?.(e);
            }
          }}
					handleReturn={e => {
            // if (this.props.selectorEl) return 'han'
						if (!_.isNil(this.state.triggerCharPos)) {
              if (this.selectorElRef.current) {
                this.selectorElRef.current.onEnter?.(e, () => {
                  const triggerCharPos = this.state.triggerCharPos;
                  this.state.triggerCharPos = null;
    
    

                    
                      const editorState = this.editorState();
                      const currentContent = editorState.getCurrentContent();
        
                      const start = editorState.getSelection().getStartOffset();
        
                      const selection = editorState.getSelection().merge({
                        anchorKey: currentContent.getFirstBlock().getKey(),
                        anchorOffset: triggerCharPos,  
                    
                        focusKey: currentContent.getLastBlock().getKey(),
                        focusOffset: start, 
                      });
        
                      // const selected = this.getSuggestions()[this.state.selected];
                      // const value = _.isFunction(selected.value) ? await selected.value() : selected.value;
        
                      const replaceText = '';
        
                      const newContentState = Modifier.replaceText(currentContent, selection, replaceText);
    
    
                      // const sel = new SelectionState({
                      //   anchorKey: newContentState.getFirstBlock().getKey(),
                      //   anchorOffset: triggerCharPos,
                    
                      //   focusKey: newContentState.getFirstBlock().getKey(),
                      //   focusOffset: triggerCharPos + replaceText.length,
                      // });
        
                      // const contentStateWithEntity = newContentState.createEntity('TOKEN', 'IMMUTABLE', value);
                      // const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                      // const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, sel, entityKey);
                    
                      let newState = EditorState.create({
                        currentContent: newContentState,
                        selection: editorState.getSelection(),
                        decorator,
                      });
                      
                      newState = EditorState.acceptSelection(newState, new SelectionState({
                        anchorKey: newState.getCurrentContent().getFirstBlock().getKey(),
                        anchorOffset: triggerCharPos + replaceText.length,
                        focusKey: newState.getCurrentContent().getFirstBlock().getKey(),
                        focusOffset: triggerCharPos + replaceText.length,
                      }));
        
                      this.setEditorState(newState);
    
                      this.forceUpdate();
                      this.changed();

    
                });
              }
              else {
                const triggerCharPos = this.state.triggerCharPos;
                this.state.triggerCharPos = null;
                (async () => {
                
                  const editorState = this.editorState();
                  const currentContent = editorState.getCurrentContent();
    
                  const start = editorState.getSelection().getStartOffset();
    
                  const selection = editorState.getSelection().merge({
                    anchorKey: currentContent.getFirstBlock().getKey(),
                    anchorOffset: triggerCharPos,  
                
                    focusKey: currentContent.getLastBlock().getKey(),
                    focusOffset: start, 
                  });
    
                  let replaceText;
                  const selected = this.getSuggestions()[this.state.selected];
                  if (selected.action) {
                    selected.action(selected);
                    const replaceText = '';
    
      
                    const newContentState = Modifier.replaceText(currentContent, selection, replaceText);
  
                    
                    let newState = EditorState.create({
                      currentContent: newContentState,
                      selection: editorState.getSelection(),
                      decorator,
                    });
                    
                    newState = EditorState.acceptSelection(newState, new SelectionState({
                      anchorKey: newState.getCurrentContent().getFirstBlock().getKey(),
                      anchorOffset: triggerCharPos + replaceText.length,
                      focusKey: newState.getCurrentContent().getFirstBlock().getKey(),
                      focusOffset: triggerCharPos + replaceText.length,
                    }));
      
                    this.setEditorState(newState);
  
                    this.forceUpdate();
      
  
  
      
                    this.changed();


                  }
                  else {
                    const value = _.isFunction(selected.value) ? await selected.value() : selected.value;
    
                    const replaceText = await this.props.entityDisplay(value);
      
                    const newContentState = Modifier.replaceText(currentContent, selection, replaceText);
  
  
                    const sel = new SelectionState({
                      anchorKey: newContentState.getFirstBlock().getKey(),
                      anchorOffset: triggerCharPos,
                  
                      focusKey: newContentState.getFirstBlock().getKey(),
                      focusOffset: triggerCharPos + replaceText.length,
                    });
      
                    const contentStateWithEntity = newContentState.createEntity('TOKEN', 'IMMUTABLE', value);
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, sel, entityKey);
                  
                    let newState = EditorState.create({
                      currentContent: contentStateWithLink,
                      selection: editorState.getSelection(),
                      decorator,
                    });
                    
                    newState = EditorState.acceptSelection(newState, new SelectionState({
                      anchorKey: newState.getCurrentContent().getFirstBlock().getKey(),
                      anchorOffset: triggerCharPos + replaceText.length,
                      focusKey: newState.getCurrentContent().getFirstBlock().getKey(),
                      focusOffset: triggerCharPos + replaceText.length,
                    }));
      
                    this.setEditorState(newState);
  
                    this.forceUpdate();
      
  
  
      
                    this.changed();
                  }

                })()
              }
              return 'handled';
            }
						else {
							this.props.onEnter?.(this.currentValue(), () => {
                this.setEditorState(null);
              }, e);
							return 'handled';
						}
					}}
          // stateOnlyOnFocus
        />
				{this.isSelecting() && (() => {
          const suggestions = this.getSuggestions();

          if (_.isArray(suggestions)) {
            return (
              <Around position="below" anchor={() => ReactDOM.findDOMNode(this)}>
                <t.menu>
                  <ul>
                    {suggestions.map((el, i) => {
                      return (
                        <li key={el.key} className={i == this.state.selected && t.menu.c.selected}>
                          {el.name} ({el.type})
                        </li>
                      );
                    })}
                  </ul>
                </t.menu>
              </Around>
            );
          }
          else {
            return (
              <Around position="below" anchor={() => ReactDOM.findDOMNode(this)}>
                {suggestions}
              </Around>
            );
          }

        })()}
			</>
		);
	}
}
