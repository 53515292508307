import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { x } from '../XObject';


@component
export class EntityConfigWindow extends Component<{ window; }> {
  render() {
    const entity = db.entities.findById(this.props.window.id);
    return (
      <>
        <button
          onClick={() => {
            console.log(x(entity));
          }}
        >.</button>
      </>
    );
  }
}
