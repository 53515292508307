import { TextCellType, TitleCellType, SelectCellType, MultiSelectCellType } from "./cells";

export const cellTypeMap: {
  [key: string]: any;
} = {};

cellTypeMap['text'] = TextCellType;
cellTypeMap['title'] = TitleCellType;
cellTypeMap['select'] = SelectCellType;
cellTypeMap['multiSelect'] = MultiSelectCellType;
