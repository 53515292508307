import {component} from '../../component2';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { x, XObject } from '../../XObject';
import { defaultWorkspace } from '../../etc/defaultWorkspace';
import { db } from '../../db';
import { registerChangeAction, trackChange, UndoActions } from '../changes';
import { PropertyField } from '../../components/PropertyField';
import { showContextMenu } from '../../etc/showContextMenu';
import { styled } from '../../component';

const SetValue = 'b038b6b3-5096-50f2-a415-1c43c8a72edb';
registerChangeAction(SetValue, {
  name: 'Set Value',
});

@component
export class RelativeQueriesValuePoint extends Comp<ValuePointProps> {
  static styles = styled.div`
    .value {
      &:hover {
        background-color: #eee;
      }
    }
  `;
  render() {
    const state = XObject.get(this.props.state, 'content', {});
    return (
      <>
        <div>
          {db.queries.filter(q => q.relative).map((stateType, i) => (
            <div key={stateType._id}
              // onClick={() => {
              //   state[stateType._id] = !state[stateType._id];
              // }}

              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Delete',
                    onClick: () => {
                      if (window.confirm('Are you sure?')) {
                        db.attributeTypes.splice(i, 1);
                      }
                    }
                  }
                ]);
              }}
            >
              <input type="checkbox" checked={!!state[stateType._id]} onChange={(e) => {
                state[stateType._id] = e.target.checked;
              }} />
              

              <span>
              <PropertyField object={stateType} property="name" />
              </span>
            </div>
          ))}
          {/* <button
            onClick={() => {
              db.attributeTypes.push(XObject.obj({name: 'New Attribute'}));
            }}
          >+</button> */}
        </div>

      </>
    )
  }
}

export const registerRelativeQueriesType = () => {
    registerType(ValueType.RelativeQueries, {
        editorComp: RelativeQueriesValuePoint,
        evaluate: (state) => {
          const values = [];
          for (const id in state.content) {
            if (state.content[id]) {
              values.push(id);
            }
          }
          return values;
        },
        isBlock: () => {
          return true;
        }
    });
};