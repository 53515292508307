import React from 'react';
import {component} from '../../component2';
import {XObject} from '../../XObject';
import {EntityMatchEditor} from '../../components/EntityMatchEditor';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";

@component
export class EntityMatchValuePoint extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <EntityMatchEditor value={XObject.get(this.props.state, 'content', {})} />
      </>
    );
  }
}

export const registerEntityMatchType = () => {
  registerType(ValueType.EntityMatch, {
    editorComp: EntityMatchValuePoint,
    isBlock: () => true,
  });
};
