
export const collectEntities = blocks => {
  const entities: string[] = [];
  const _collectEntities = blocks => {
    for (const block of blocks) {
      if (block.id) {
        entities.push(block.id);
      }
      if (block.children) {
        _collectEntities(block.children);
      }
    }
  };
  _collectEntities(blocks);

  return entities;
};
