import _ from 'lodash';
import { queryGraph } from '../etc/queryGraph';
import { entityMatch2 } from '../etc/entityMatch';
import { TEntityMatch2 } from "../types/TEntityMatch";
import { CompiledValuePoint, evaluate, mapStructure } from './main';
import { $RelativeEntityQueryStateful } from './structs/$RelativeEntityQuery';
import { db } from '../db';


export function doEntityQuery(value: CompiledValuePoint, rootEntity, includeRoot?, directDescendentsOnly?) {
  const mapped = mapStructure(value);

  let results = [];

  for (const entry of mapped.entries.content) {
    const e = evaluate(entry, {});
    if (entry.type[1] == $RelativeEntityQueryStateful.$) {
      results = results.filter(e => {
        const entity = db.entities.findById(e.entity);
        return entity.stateful;
      })
    }
    else if (_.isString(e)) {
      results = queryGraph(e, rootEntity, includeRoot, directDescendentsOnly);
    }
    else {
      const ee: TEntityMatch2 = e;
      results = results.filter(e => entityMatch2(e.entity, ee));
    }
  }

  // unique results by entity
  results = _.uniqBy(results, r => r.entity);

  return results;

}
