import React, { FunctionComponent } from "react";
import SVG from 'react-svg-raw';
import { img } from "../img";

export const Svg: FunctionComponent<{ className?: any; name: string; colors?: any; onClick?: any; onMouseDown?; onContextMenu? }> = props => {
  if (!props.name) return null;
  const src = img(props.name, props.colors);
  return (
    <SVG src={src} className={props.className} onClick={props.onClick} onMouseDown={props.onMouseDown} contentEditable={false} onContextMenu={props.onContextMenu} />
  );
};
