
import { isMobile } from './isMobile';
import { currentSpace, triggerInspectObject } from './osHelpers';


export function inspectObj(obj) {
  triggerInspectObject({
    type: 'e57eb0f2-4a72-5002-b64e-11a7ba64970a',
    args: {
      obj,
    }
  });
}


export function explicitInspectObj(obj) {
  if (isMobile()) {
    window['g_presentMobileInspectModal']({
      root: obj,
    })
  }
  else {
    inspectObj(obj);
    currentSpace().sideBar = true;  
  }
}
