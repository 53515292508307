import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { XClone, XObject } from '../XObject';
import { NotionDocumentBad } from '../components/NotionDocumentBad';


@component
export class DocumentWindow extends Component<{ window; }> {
  blocks;
  constructor(props) {
    super(props);
    const database = db.databases.findById(this.props.window.database);
    const document = XObject.get(database, 'document', {});
    const blocks = XObject.get(document, 'blocks', []);
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
    });

  }
  render() {
    const database = db.databases.findById(this.props.window.database);

    return (
      <>
        <NotionDocumentBad
          docId={null}
          entity={null}
          blockTypes={[]}
          blocks={this.blocks}
          database={database} />
      </>
    );
  }
}
