import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import jQuery from 'jquery';
import { openWindow } from '../osHelpers';
import { component } from '../component2';
import { XInit, XObject, X, x, XTouch, XClone } from '../XObject';
import { PropertyField } from '../components/PropertyField';
import { showContextMenu, isId, getValueParams } from '../helpers';
import { db } from '../db';
import { toast } from 'react-toastify';
import { tickValuePoint, ValuePoint } from './ValuePoint';
import { ValuePointCont } from './ValuePointCont';
import { borderColor, otherColor } from './borderColor';
import { Comp } from './Comp';
import { ValueType } from './ValueType';
import { __typeRegistry } from './__typeRegistry';
import { typeRegistry } from './typeRegistry.1';
import copyToClipboard from 'copy-to-clipboard';

import './structs/$Browser';
import './structs/$RelativeEntityQuery';
import './structs/$NotionDocument';
import './structs/$EntityMatch';
import './structs/$GraphView';
import './structs/$Document';
import './structs/$StatementDataBinding';
import './structs/$LinearList';
import './structs/$EntityQuery';
import './structs/$Browser2';
import './structs/$EntityTemplate';
import './structs/$EntityDocumentEditor';
import './structs/$EntityDocumentsEntities';
import './structs/$AllEntitiesMatch';
import './structs/$TabView';
import './structs/$Sidebar';
import './structs/$NotionEntityDetails';
import './structs/$EntityDatabase';
import './structs/$EntityCreator';
import './structs/$EntityParent';
import './structs/$EntityQueryView';
import './structs/$GroupedSelectAttribute';
import './structs/$EntitySwitch';
import './structs/$SpaceConfig';
import './structs/$EntityAttributeBinding';
import './structs/$BlockView';
import './structs/$EntityElement';

import { Runtime } from './Runtime';
import { changeState, changeTypes, registerChangeAction, trackChange, UndoActions } from './changes';
import {registerArrayType} from "./valuePoints/ArrayValuePoint";
import {registerElementType} from "./valuePoints/ElementValuePoint";
import {registerEntityMatchType} from "./valuePoints/EntityMatchValuePoint";
import {registerEntityMetaState} from "./valuePoints/EntityMetaStateValuePoint";
import {registerEntityStateType} from "./valuePoints/EntityStateValuePoint";
import {registerEntityTypeType} from "./valuePoints/EntityTypeValuePoint";
import {registerEnumType} from "./valuePoints/EnumValuePoint";
import {registerGraphType} from "./valuePoints/GraphValuePoint";
import {registerIfChainType} from "./valuePoints/IfChainValuePoint";
import {registerParamType} from "./valuePoints/ParameterValuePoint";
import {registerStringType} from "./valuePoints/StringValuePoint";
import {registerStructureType} from "./valuePoints/StructureValuePoint";
import {registerValueType} from "./valuePoints/ValueRefValuePoint";
import {registerEntityType} from "./valuePoints/EntityValuePoint";
import { registerEntityAttributeType } from './valuePoints/EntityAttributeValuePoint';
import { registerStatementType } from './valuePoints/StatementValuePoint';
import { getEdgesForEntity } from '../etc/getEdgesForEntity';
import { registerEntityStateTypeType } from './valuePoints/EntityStateTypeValuePoint';
import { css } from '../component';
import { registerEntityStateTypesType } from './valuePoints/EntityStateTypesValuePoint';
import { registerEntityAttributesType } from './valuePoints/EntityAttributesValuePoint';
import { registerBooleanType } from './valuePoints/BooleanValuePoint';
import { registerRelativeQueriesType } from './valuePoints/RelativeQueriesValuePoint';
import { registerRelativeQueryType } from './valuePoints/RelativeQueryValuePoint';
import { registerEntityElementType } from './valuePoints/EntityElementValuePoint';
import { registerArgType } from './valuePoints/ArgValuePoint';
import { registerCodeComponentType } from './valuePoints/CodeComponentValuePoint';
import { sideBarComps } from './valuePointComps';
import { stackSystems } from './stackSystems';
import { NotionButton } from '../components/AddButton';


export type OSContextType = any;

const valuePointRegistry: {
  [id: string]: {
    parent: string
  }
} = {}

const partialMatch = (o, p, c) =>
	Object.keys(p).every(k =>
		p[k] && o[k]
			? p[k] instanceof Object
				? partialMatch(o[k], p[k], c)
				: c
				? o[k]?.toLowerCase?.()?.includes?.(p[k].toLowerCase())
				: p[k] === o[k]
			: false
	)

// strips away the function *name*, arguments and the opening and closing brackets
function stripFunctionFrame(code: string) {
  return code.replace(/function\s*\w*\s*\(\)\s*\{/, '').replace(/\}\s*$/, '');
}


function removeDashes(str: string) {
  return str.replace(/-/g, '');
}

function addDashesToUUID(uuid: string) {
  return uuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
}
@component
export class ValuePointWindow extends Comp<{ win }> {
  render() {
    return (
      <>
        {this.props.win.id}
      </>
    )
  }
}

@component
export class GlueView extends Comp<{ state, id, args, renderType? }> {
  state = X({});
  constructor(props) {
    super(props);
    this.state = this.props.state;
  }
  componentDidMount() {
    let prevState = x(this.state);
    setInterval(() => {
      if (!_.isEqual(x(this.state), prevState)) {
        prevState = x(this.state);
        console.log(prevState);
      }
    }, 1000);
  }
  render() {
    XTouch(this.state);
    const value = execute(this.props.id, new Runtime(this.context, { stateStore: this.state }));
    const c = render(value, x(this.props.args), this.props.renderType, this.state);
    if (this.props.renderType == RenderType.entities) {
    }
    
    else {
      return (
        <>
          {/* <button onClick={() => {
            console.log(x(this.props.args));
          }}>Debug Args</button> */}
          {c}
        </>
      );
  
    }
  }
}

export function _matches(value, pattern) {
  try {
    return partialMatch(x(value), pattern, true);
  }
  catch (e) {
    console.log(e, x(value), pattern);
    return false;
  }
}

export function _findValue(value, pattern) {
  if (value.type?.[0] in __typeRegistry && __typeRegistry[value.type?.[0]].findValue) {
    return __typeRegistry[value.type?.[0]].findValue(value, pattern);
  }
}

export function findValue(valueId: string, pattern) {
  const value = getValuePoint(valueId);
  return _findValue(value, pattern);
}


const _state = () => {
  const key = 'f82e83a0-1f26-570a-a9f2-5c4435fa5f6f';
  const dataObj = db.dataObjects.find(dataObj => dataObj.type == key);
  if (dataObj) {
    return dataObj;
  }
  else {
    const doc = XObject.obj({
      type: key,
    });
    db.dataObjects.push(doc);
    return doc;
  }
}

export function resolveTypeList(type) {
  if (!type || type.length == 0) return [];
  if (type) {
    if (_.isArray(type?.[0])) {
      return type;
    }
    else {
      return [ type ];
    }  
  }
  else {
    return [];
  }
}


enum Trait {
  Renderable = '989c0339-48ad-5bc7-b070-e329572aaa7c',
  InlineRenderable = 'a2352c9f-5640-584d-ab5d-95a907976f0f',
  FrameRenderable = '10672cc5-3fda-5dfe-8376-979b6122bc53',
  BlockRenderable = '2dc6dc16-95ed-53d1-93d5-612f6a12c90e',

}

const traits = {
  [Trait.Renderable]: {
    name: 'Renderable',
  },
  [Trait.InlineRenderable]: {
    name: 'InlineRenderable',
    inherits: [ '989c0339-48ad-5bc7-b070-e329572aaa7c' ],
  },
  [Trait.FrameRenderable]: {
    name: 'FrameRenderable',
  },
  [Trait.BlockRenderable]: {
    name: 'BlockRenderable',
  }
}

export function addValuePoint(valuePoint) {
  valuePointIndex[valuePoint._id] = valuePoint;
  valuePointRegistry[valuePoint._id] = {
    parent: valuePoint.parent,
  }
  delete valuePoint.parent;
  return valuePoint;
}

export enum ReferenceType {
  Value = '94fa4701-3c7b-5301-8081-5cc0f16b8636',
  Parameter = '8fce4f81-b75e-51d2-892e-b687c23240e7',
  StructureParameter = 'a9cf651c-5e49-5aeb-92b2-fa71ec9c4456',
}

export function getParameter(id: string) {
  for (const value of values()) {
    if (value.parameters?.length) {
      for (const param of value.parameters) {
        if (param._id == id) {
          return param;
        }
        if (param.parameters) {
          for (const p of param.parameters) {
            if (p._id == id) {
              return p;
            }
          }
        }
      }
    }
  }
}

export function getStructureParameter(iid: string) {
  for (const id in structuresMap) {
    const struct = getStructureById(id);
    if (struct.scope) for (const prop of struct.scope) {
      if (prop.id == iid) {
        return prop;
      }
    }

    for (const prop of struct.definition) {
      if (prop.scope) for (const p of prop.scope) {
        if (p.id == iid) {
          return p;
        }
      }
    }
  }
}

export function isValuePointId(id: string) {
  return _.isString(id) && id in valuePointIndex;
}

export function getValuePoint(id: string, errorOnNotFound = true) {
  const v = valuePointIndex[id];
  // const v = _getValuePoint(id, values());
  if (!v && errorOnNotFound) {
    throw new Error('Value point not found ' + id);
  }
  return v;
}

const valuePointIndex: {
  [id: string]: ValuePointType;
} = {};


export function _iterate(value: ValuePointType | ValuePointType[], parent: ValuePointType, func: (value: ValuePointType, parent: ValuePointType, set) => void, set?) {
  if (_.isArray(value) || XObject.isArray(value)) {
    for (const v of (value as any)) {
      const r = _iterate(v, parent, func);
      if (r !== undefined) return r;
    }
  }
  else {
    const r = func(value, parent, set);
    if (r !== undefined) return r;

    if (value.type?.[0] in __typeRegistry && 'iterate' in __typeRegistry[value.type[0]]) {
      return __typeRegistry[value.type[0]].iterate(value, parent, func, set);
    }
  }

}

/*function _indexValuePoints(list: ValuePointType[], parent?: ValuePointType) {
  for (const value of list) {
    valuePointIndex[value._id] = value;
    valuePointRegistry[value._id] = {
      parent: parent?._id,
    }

    if (value.type?.[0] == ValueType.Structure) {
      _indexValuePoints(value.content?.map?.(p => p.value) || [], value);
    }
    if (value.type?.[0] == ValueType.Element) {
      _indexValuePoints(value.content?.properties?.map?.(p => p.value) || [], value);
    }
    if (value.type?.[0] == ValueType.Value && value.content?.arguments?.length) {
      _indexValuePoints(value.content.arguments.map(a => a.value) || [], value);
    }
    else if (value.type?.[0] == ValueType.Param && value.content?.arguments?.length) {
      _indexValuePoints(value.content.arguments.map(a => a.value) || [], value);
    }
    else if (value.type?.[0] == ValueType.Array && value.content?.length) {
      _indexValuePoints(value.content, value);
    }
    else if (value.type?.[0] == ValueType.IfChain) {
      _indexValuePoints(value.content.reduce((acc, curr) => {
        if (curr.condition) {
          acc = acc.concat(curr.condition);
        }
        if (curr.content) {
          acc = acc.concat(curr.content);
        }
        return acc;
      }, []), value);
    }
  }
}*/

export function indexValuePoints() {
  for (const id in valuePointIndex) {
    delete valuePointIndex[id];
  }

  _iterate(values(), null, (value, parent) => {
    valuePointIndex[value._id] = value;
    valuePointRegistry[value._id] = {
      parent: parent?._id,
    }
  })

  XObject.observe(values(), (changes) => {
    console.log('changes', x(changes));
    for (const key in executeCache) {
      delete executeCache[key];
    }
    st.tick++;
  })
}

function getStructProperty(propId: string): [StructureType, StructureDefinitionType] {
  for (const strut of Object.values(structuresMap)) { 
    for (const prop of strut.definition) {
      if (prop.id == propId) {
        return [strut, prop];
      }
    }
  }

  throw new Error('Struct property not found ' + propId);
}

function valuePointParent(id: string) {
  return valuePointRegistry[id]?.parent;
}

export function getScopeForValuePoint(id: string) {
  // Parameters and parent scope
  const value = getValuePoint(id);


  // eslint-disable-next-line
  value.parameters?.length;


  let scope: { type, id }[] = x(value.parameters || []).map(p => ({ type: ReferenceType.Parameter, id: p._id }));

  if (value.context) {
    const [parentStruct,prop] = getStructProperty(value.context);
    if (parentStruct.scope) {
      scope = scope.concat(parentStruct.scope.map(s => ({ type: ReferenceType.StructureParameter, id: s.id })));
    }

    // const parentProp = parentValue.content.find(p => p.value?._id == id);
    // const prop = parentStruct.definition.find(p => p.id == parentProp.prop);

    if (prop?.scope) {
      scope = scope.concat(prop.scope.map(s => ({ type: ReferenceType.StructureParameter, id: s.id })));
    }

  }


  const l = valuePointParent(value._id) || value.location;
  if (l) {
    scope = scope.concat(getScopeForValuePoint(l));

    if (valuePointParent(value._id)) {
      const parentValue = getValuePoint(l);
      if (parentValue.type?.[0] in __typeRegistry && 'scope' in __typeRegistry[parentValue.type[0]]) {
        scope = scope.concat(__typeRegistry[parentValue.type[0]].scope(value, parentValue));
      }
    }
  }

  let subValues = [];

  if (!valuePointParent(value._id)) {
    // subValues = values().filter(v => !v.location && v.namespace == value.namespace);
  }

  subValues = subValues.concat(values().filter(v => v.location == value._id));

  if (value.location) {
    subValues = subValues.concat(values().filter(v => v.location == value.location && v._id != value._id));
  }

  if (!value.location && !valuePointParent(value._id)) {
    subValues = subValues.concat(values().filter(v => !v.location && !valuePointParent(v._id)/*  && v.namespace == value.namespace */));
  }

  subValues = _.uniq(subValues.map(v => v._id));

  scope = scope.concat(subValues.map(v => ({ type: ReferenceType.Value, id: v })));

  scope = _.uniqBy(scope.filter(Boolean), (s:any) => s.id);

  return scope;
}

export const modeKeys = [
  [ '\'', ValueType.String ],
  [ '%', ValueType.Construct ],
  [ '#', ValueType.Number ],
  [ '=', ValueType.Expression ],
  [ '@', ValueType.Entity ],
  [ '<', ValueType.Element ],
];

export type Unserialize<T> = { $type, $unserialize(a): T, $class }


type ScopeEntry = {
  id
  name
}

export interface StructureType {
  _id: string
  name
  scope?: ScopeEntry[]
  definition: StructureDefinitionType[]
  blockProperty?: string
};



type TypeDef = [ ValueType ] |
  [ValueType.Array, TypeDef ] |
  [ ValueType.Structure, string, StructureDefinitionType[]? ] |
  [ ValueType.Enum, string, any? ] |
  [];

type TypeOrTypes = TypeDef | TypeDef[];

interface StructureDefinitionType {
  scope?: ScopeEntry[]
  id
  name
  type: TypeOrTypes
  property?
}

export const _children: any = {};
export function getChildren(entity) {
  const edges = getEdgesForEntity(entity).filter(e => e.entities[0] == entity && e.directed);

  if (!_children[entity]) {
    _children[entity] = edges.map(e => e.entities[1]);
  }
  else {
    // add missing entities to the end
    const missing = edges.filter(e => !_children[entity].includes(e.entities[1]));
    _children[entity] = _children[entity].concat(missing.map(e => e.entities[1]));

    // remove entities that are no longer there
    const extra = _children[entity].filter(e => !edges.find(ee => ee.entities[1] == e));
    _children[entity] = _children[entity].filter(e => !extra.includes(e));
  }

  return _children[entity];
}

function withProps(props, component) {
  return new Proxy({}, {
    get(target, prop) { return prop == 'props' ? { ...component.props, ...props } : component[prop]; }
  });
}


export const structuresMap: { [id: string]: StructureType } = {};
export const enumMap: any = {};

export function getStructureById(id) {
  if (!(id in structuresMap)) {
    throw new Error(`Structure ${id} not found`);
  }
  return structuresMap[id];
}

export function addStructToMap(id, struct) {
  structuresMap[id] = { _id: id, ...struct  };

  for (const field of struct.definition) {
    if ((field.type?.[0] in __typeRegistry) && ('addStructToMap' in __typeRegistry[field.type[0]])) {
      __typeRegistry[field.type[0]].addStructToMap(field);
    }
  }
}

export function addEnumToMap(id, _enum) {
  enumMap[id] = _enum;
}

export function isBlock(value: ValuePointType) {
  if (!value.type) return false;
  if (value.type[0] in __typeRegistry && 'isBlock' in __typeRegistry[value.type[0]]) {
    return __typeRegistry[value.type[0]].isBlock(value);
  }
  return false;
}

export interface ValuePointProps {
  scope
  state: ValuePointType
  elId
  tick
  path?
}

function isAnyType(type) {
  if (!type || !type.length) return true;
  return false;
}

export function matchType(type, against) {
  return true;
  if (isAnyType(type) || isAnyType(against)) return true;
  for (const t of against) {
    if (t[0] == type[0]) return true;
  }
  return false;
}

let ignore;
export function onValuePointClick(id, selectFunc) {
  if (!ignore) {
    ignore = true;
    // state.active = id;
    selectFunc(id);
    setTimeout(() => {
      ignore = false;
    }, 100);
  }
}

interface _ValuePointType {
  _id
  name?
  parameters?
  possibleTypes?
  type?
  location?
  // parent?
  content?
  isState?
  isTerminal?

  context?

  presentation?
  namespace?
}


// function to recursively replace IDs
function replaceIds(obj, replacedIds={}) {
  if (obj instanceof Array) {
    return obj.map(o => replaceIds(o, replacedIds));
  }
  else if (obj instanceof Object) {
    const newObj = {};
    for (const k in obj) {
      const value = obj[k];
      if (k == '_id') {
        newObj[k] = XObject.id();
        replacedIds[obj[k]] = newObj[k];
      }
      else if (_.isString(value) && value in replacedIds) {
        newObj[k] = replacedIds[value];
      }
      else {
        newObj[k] = replaceIds(obj[k], replacedIds);
      }
    }
    return newObj;
  }
  else {
    return obj;
  }
}

export type ValuePointType = _ValuePointType;


export function valueForProperty(propId, valueId?, parent?, values = {}) {
  const [,prop] = getStructProperty(propId);
  return X({
    _id: valueId || XObject.id(),
    possibleTypes: resolveTypeList(prop.type),
    parent,
    ...values,
  })
}

const clipboard = {
  state: null,
}

export const PaneContext = React.createContext<any>(null);

export function values(): ValuePointType[] {
  return XObject.get(_state(), 'values', [])//.concat(transientValues);
}

class CVP {
  constructor(public value) {}
}

export function evaluateCode(value: CompiledValuePoint, map={}) {
  const r = value.content.code.matchAll(/_\$([^\$]*)\$_/g);
  const scope = getScopeForValuePoint(value._id);

  const debug = value.presentation?.debug;

  const args = {};

  for (const match of r) {
    let m = match[1];
    let vv;
    let hasValue;
    if (m in map) {
      vv = map[m];
      hasValue = true;
    }
    else if (m.length == 32 && addDashesToUUID(m) in map) {
      vv = map[addDashesToUUID(m)];
      hasValue = true;
    }
    else {
      if (m.length == 32) {
        m = addDashesToUUID(m);
      }

      if (scope.find(s => s.id == m)) {
        if (isId(m)) {
          const v = getValuePoint(m);
          if (v) {
            if (v.isState) {
              vv = value.rt.getState(v._id);
              hasValue = true;
            }
            else {
              hasValue = true;
              vv = evaluate(value.content.values[v._id], map);
            }
          }
        }
        else {
          hasValue = true;
        }
      }
    }

    if (hasValue) {
      args['_$' + removeDashes(m) + '$_'] = vv;
    }
  }



  const code = stripFunctionFrame(value.content.code);


  if (debug) {
    console.log(args);
  }
  return value.rt.api.exec(code, args).valueOf();
}

export enum RenderType {
  full,
  inline,
  text,
  entities,
}


export function supportsRender(value: CompiledValuePoint, renderType) {
  return true;
}

export function render(value: CompiledValuePoint, map={}, renderType=RenderType.full, state?) {
  if (value.type) {
    // if (value.type[0] == ValueType.Structure) {
    //   const renderer = structRenderers[value.type[1]];
    //   if (renderer) {
    //     return renderer(value, map, state, renderType);
    //   }
    //   else {
    //     return 'no renderer for structure: ' + value.type[1];
    //   }
    // }
    if (value.type[0] == ValueType.Void) {
      return null;
    }
    else if (value.type[0] == ValueType.Graph) {
      return 'hello';
    }

    if (value.type[0] in __typeRegistry) {
      const renderer = __typeRegistry[value.type[0]].render;
      if (renderer) {
        return renderer(value, map, state, renderType);
      }
    }
  }



  return (
    <span>
      <button onClick={() => {
        console.log(x(value));
        console.log(x(value.rt.stateStore));

        if (value.isState) {
          console.log('current value', value.rt.getState(value._id))
        }
      }}>Debug</button>
    </span>
  );
}

export interface CompiledValuePoint {
  _id: string
  type//: TypeDef
  content: any
  rt: Runtime
  isState: boolean
  isTerminal?: boolean
  presentation
}

const executeCache = {};

const st = X({
  tick: 0
});

export function execute(id: string, rt: Runtime = new Runtime({})): CompiledValuePoint {
  if (id in executeCache) {
    // eslint-disable-next-line
    st.tick;
    return executeCache[id];
  }

  const r = (() => {
    const value = getValuePoint(id);

    const type = value.type?.[0];
  
    const subValues = values().filter(v => v.location == id); 
    rt = rt.registerSubValues(subValues.map(x => x._id));
  
    if (value.isTerminal) {
      return {
        _id: value._id,
        type: value.type,
        content: value.content,
        isState: value.isState,
        isTerminal: value.isTerminal,
        rt,
        presentation: value.presentation,
      }
    }
  
  
    switch (type) {
      default:
        if (type in __typeRegistry && 'execute' in __typeRegistry[type]) {
          return __typeRegistry[type].execute(value, rt);
        }
        return {
          _id: value._id,
          type: value.type,
          content: value.content,
          isState: value.isState,
          rt,
          presentation: value.presentation,
        }
    }
  })();

  return executeCache[id] = r;

}

export function mapStructure(value: CompiledValuePoint): any {
  if (!value.type) return {};
  const structure = getStructureById(value.type[1]);

  const result = {};

  for (const prop of structure.definition) {
    const propValue = value.content.find(x => x.prop === prop.id);
    if (propValue) {
      if (!prop.property) {
        throw new Error('no property name for prop ' + prop.id);
      }
      result[prop.property] = propValue.value;
    }
  }

  return result;
}
// function evaluateValueReference(value: ValuePointType, rt: Runtime) {
//   // const refValue = getValuePoint(value.id);
//   const memory = {};
//   if (value.content.arguments) {
//     for (const arg of value.content.arguments) {
//       memory[arg.param] = evaluate(arg.value._id, rt);
//     }
//   }
//   return evaluate(value.content.id, rt.push(memory));
// }

// function evaluateParam(value: ValuePointType) {
//   return rt.memory[value.content];
// }


export function evaluate(value: CompiledValuePoint, map={}) {
  if (value.isState) {
    return value.rt.getState(value._id);
  }

  if (value.isTerminal) {
    return value;
  }

  const type = value.type?.[0];

  switch (type) {

    // case ValueType.Value:
    //   return evaluateValueReference(value, rt);

    default:
      if (type in __typeRegistry && 'evaluate' in __typeRegistry[type]) {
        return __typeRegistry[type].evaluate(value, map);
      }
      return new CVP(x(value));
  }
}


export function getRootValuePoint(id) {
  let valuePoint = getValuePoint(id);
  while (valuePointParent(valuePoint._id)) {
    valuePoint = getValuePoint(valuePointParent(valuePoint._id));
  }
  return valuePoint;
}

export function getDepth(id) {
  let valuePoint = getValuePoint(id);
  let depth = 0;
  while (valuePointParent(valuePoint._id)) {
    valuePoint = getValuePoint(valuePointParent(valuePoint._id));
    ++ depth;
  }
  return depth;
}

function replace(id, props) {
  if (valuePointParent(id)) {
    const parent = getValuePoint(valuePointParent(id));
    _iterate(parent, undefined, (v, _parent, set) => {
      if (v._id == id) {
        set(X({ ...x(props), _id: id }));
        return true;
      }
    });  
  }
  else {
    for (let i = 0; i < values().length; ++ i) {
      if (values()[i]._id == id) {
        values()[i] = X({ ...x(props), _id: id });
        break;
      }
    }
  }

}

const ClearValuePoint = '06201f68-7806-5558-a09f-9a37abedd8c4';
registerChangeAction(ClearValuePoint, {
  name: 'Clear value point',
});

const ChangeValuePointName = '2a455454-f776-5fe4-9095-1757484321b5';
registerChangeAction(ChangeValuePointName, {
  name: 'Change value point name',
});

const AddValuePointParam = 'bdf69959-a051-5f9e-ab38-992b2ab8ea15';
registerChangeAction(AddValuePointParam, {
  name: 'Add value point parameter',
});

const RenameValuePointParam = '684257fc-2c42-5a4b-8491-a1b0bbb1c502';
registerChangeAction(RenameValuePointParam, {
  name: 'Rename value point parameter',
});

const RemoveValuePointParam = '250dcbba-4a32-5ced-8aa1-24f939438662';
registerChangeAction(RemoveValuePointParam, {
  name: 'Remove value point parameter',
});


const CreateValuePoint = 'd3f5af8b-054c-50f2-80ba-213c53475d94';
registerChangeAction(CreateValuePoint, {
  name: 'Create value point',
});

@component
export class RootValue extends Comp<{ id, path? }> {
  render() {
    const value = getValuePoint(this.props.id, false);
    if (!value) {
      return <div>Value not found</div>;
    }
    return (
      <>
        <span style={{ color: otherColor }} onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            { text: 'Delete', onClick: () => {
              if (window.confirm('Are you sure?')) {
                const index = values().findIndex(dataObj => dataObj._id == value._id);
                if (index >= 0) {
                  values().splice(index, 1);
                }  
              }
            } },
            { text: 'Add parameter', onClick: () => {
              trackChange(AddValuePointParam, {}, [
                [ UndoActions.modifiedValuePoint, value._id ]
              ]);
              XObject.push(value, 'parameters', XObject.obj({
                type: [ ],
              }));
            } },
          ])
        }}><PropertyField object={value} property="name"
          onBeforeChange={() => {
            trackChange(ChangeValuePointName, {}, [
              [ UndoActions.modifiedValuePoint, value._id ]
            ]);
          }}
        /></span> {(() => {
          if (value.parameters?.length) {
            return (
              <>({value.parameters.map((p, i) => {
                return <><span onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: p._id,
                      onClick: () => {
                      },  
                    },
                    { text: 'Remove parameter', onClick: () => {
                      if (window.confirm('Are you sure?')) {
                        trackChange(RemoveValuePointParam, {}, [
                          [ UndoActions.modifiedValuePoint, value._id ]
                        ]);
                        value.parameters.splice(i, 1);
                      }
                    } },

                    { text: 'Add parameter', onClick: () => {
                      XObject.push(p, 'parameters', XObject.obj({
                        type: [],
                      }));
                    } },

                    { text: p.templateParam ? 'Make non-template' : 'Make template', onClick: () => {
                      p.templateParam = !p.templateParam;
                    } },
                  ])
                }}>{p.templateParam && '*'}<PropertyField object={p} property="name"
                  onBeforeChange={() => {
                    trackChange(RenameValuePointParam, {}, [
                      [ UndoActions.modifiedValuePoint, value._id ]
                    ]);
                  }}
                /></span>{
                  (() => {
                    return p.parameters?.length > 0 && <>({p.parameters.map((pp, i) => {
                      return <><span onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [
                          { text: 'Remove parameter', onClick: () => {
                            if (window.confirm('Are you sure?')) p.parameters.splice(i, 1);
                          } },
      
                          // { text: 'Add parameter', onClick: () => {
                          //   XObject.push(value, 'parameters', XObject.obj({
                          //     type: [],
                          //   }));
                          // } },
      
                          // { text: p.templateParam ? 'Make non-template' : 'Make template', onClick: () => {
                          //   p.templateParam = !p.templateParam;
                          // } },
                        ])
                      }}>{pp.templateParam && '*'}<PropertyField object={pp} property="name" /></span>{
                        (() => {
                          
                        })()
                      }{i < p.parameters.length - 1 && ', '}</>
                    })})</>
                  })()
                }{i < value.parameters.length - 1 && ', '}</>
              })})</>
            )
          }
        })()} := <ValuePoint id={value._id} path={this.props.path} />
      </>
    )
  }
}

function namespaces() {
  return XObject.get(_state(), 'namespaces', []);
}

function revertTo(src, to, skip=[]) {
  for (const prop in to) {
    if (skip.includes(prop) || prop == '_id') continue;
    if (!_.isEqual(x(src[prop]), x(to[prop]))) {
      src[prop] = X(x(to[prop]));
    }
  }

  for (const prop in src) {
    if (skip.includes(prop) || prop == '_id') continue;

    if (!(prop in x(to))) {
      console.log('delete', prop)
      delete src[prop];
    }
  }

}

export function createRootValuePoint(namespace?) {
  const newValuePoint = XObject.obj({
    namespace,
  }) as any;
  trackChange(CreateValuePoint, {}, [
    [UndoActions.createRootValuePoint, newValuePoint._id],
  ])
  values().push(addValuePoint(newValuePoint));
  return newValuePoint;
}

function isRootValuePoint(id) {
  return values().findIndex(v => v._id == id) >= 0;
}

@component
class ValuePointSwitch extends Comp<{ id, path? }> {
  render() {
    if (isRootValuePoint(this.props.id)) {
      return <RootValue id={this.props.id} path={this.props.path} />
    }
    else {
      return <ValuePoint id={this.props.id} path={this.props.path} />
    }
  }
}

function valuePointDisplay(valuePoint) {
  if (valuePoint.type) {
    if (valuePoint.type[0] == ValueType.Structure) {
      const struct = getStructureById(valuePoint.type[1]);
      return `${struct.name} (Structure)`;
    }
    return _.invert(ValueType)[valuePoint.type[0]];
  }
  else {
    return 'NULL';
  }
}

export const glueTextStyles = css`
  color: #d4d4d4;

    font-family: Menlo, Monaco, "Courier New", monospace;
    font-weight: normal;
    font-size: 12px;
    font-feature-settings: "liga" 0, "calt" 0;
    line-height: 18px;
    letter-spacing: 0px;
`;


@component
export class GlueStack extends Component<{ state: { stack } }> {
  state
  constructor(props) {
    super(props);
    this.state = this.props.state;
  }
  static t = {
    pane: styled.div`
    width: 630px;
    border-right: 1px solid #ccc;
    padding-right: 8px;
    overflow: auto;
    padding: 8px;
    flex: 0 0 auto;

    .valueInspection {
      .switches {
        display: flex;
        > div {
          margin-right: 8px;
        }
      }
    }

    > ul > li.block {
      > ${ValuePointCont} {
        padding-left: 4px;
        border-left: 2px solid ${borderColor};
      }
    }
  `,
  }
  render() {
    const { t } = GlueStack;
    const stack = this.props.state.stack;
    console.log('render', x(this.props.state.stack));

    return stack.map((pane, i) => {
      const type = pane.type || 'valuePoint';
      if (type == 'valuePoint') {
        const valueId = pane.id;
        const value = getValuePoint(pane.id);

        const SideBarComp = sideBarComps[value.type?.[0]];

        const presentation = () => {
          return XObject.get(value, 'presentation', {});
        }

        return (
          <t.pane key={pane._id}>
            <PaneContext.Provider value={{
              active: this.state.stack[i + 1]?.id,
              selectValuePoint: id => {
                this.state.stack = X([...x(this.state.stack).slice(0, i + 1),
                  XObject.obj({
                    type: 'valuePoint',
                    id,
                  })
                ]);
              }
            }}>
              <b
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Copy ID',
                      onClick: () => {
                        copyToClipboard(value._id);
                      }
                    }
                  ]);
                }}
              >{valuePointDisplay(value)}</b>
              {/* <div className="valueInspection">
                {pane.id} ({value.namespace})

                <PropertyField object={value} property="context" />
                <button onClick={() => {
                  clipboard.state = value._id;
                  toast('Copied to clipboard');
                }}>Copy</button>

                <button onClick={() => {
                  if (clipboard.state) {
                    let cloned = _.cloneDeep(x(getValuePoint(clipboard.state)));
                    const clonedOriginal = _.cloneDeep(cloned);
                    cloned.location = value.location;
                    delete cloned._id;
                    delete cloned.name;
                    cloned = replaceIds(cloned);
                    // console.log(cloned, clonedOriginal);
                    replace(value._id, cloned);
                    tickValuePoint(value._id);
                    indexValuePoints();

                    toast('Pasted from clipboard');
                    delete clipboard.state;
                  }
                  else {
                    toast('Clipboard is empty');
                  }
                }}>Paste</button>

                <button onClick={() => {
                  console.log(x(value));
                }}>Debug</button>

                <button onClick={() => {
                  console.log(x(execute(pane.id, new Runtime(this.context))));
                }}>Execute</button>

                <button onClick={() => {
                  openWindow({
                    type: 'glue',
                    id: pane.id,
                    // renderType: RenderType.entities,
                  })
                }}>View</button>

                <button onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    trackChange(ClearValuePoint, {}, [
                      [ UndoActions.modifiedValuePoint, value._id ],
                    ])
                    delete value.type;
                    delete value.content;  
                    delete value.parameters;
                  }
                }}>Clear</button>

                <button onClick={() => {
                  const cloned = _.cloneDeep(x(value));
                  delete cloned._id;

                  values().push(addValuePoint(XObject.obj({
                    ...cloned,
                    location: value._id,
                  })));

                  delete value.type;
                  delete value.content;
                  delete value.presentation;
                }}>
                  Move into parent
                </button>
                <button onClick={() => {
                  const root = getRootValuePoint(value._id);
                  // console.log(root);
                  if (root.location) {
                    const cloned = _.cloneDeep(x(value));
                    delete cloned._id;

                    values().push(addValuePoint(XObject.obj({
                      ...cloned,
                      location: root.location,
                    })));

                    delete value.type;
                    delete value.content;
                    delete value.presentation;  
                  }
                  else {
                    console.log('No containing scope', x(root));
                  }
                }}>
                  Move into containing scope
                </button>
                <div className="switches">
                  <div onClick={() => value.isState = !value.isState}><input type="checkbox" checked={value.isState} /> State</div>
                  <div onClick={() => value.isTerminal = !value.isTerminal}><input type="checkbox" checked={value.isTerminal} /> Reference</div>
                  <div onClick={() => presentation().compact = !presentation().compact}><input type="checkbox" checked={value.presentation?.compact} /> Compact</div>
                  <div onClick={() => presentation().debug = !presentation().debug}><input type="checkbox" checked={value.presentation?.debug} /> Debug</div>
                  <div onClick={() => presentation().showPreview = !presentation().showPreview}><input type="checkbox" checked={value.presentation?.showPreview} /> Show Preview</div>
                </div>
              </div> */}

              {SideBarComp && (
                <SideBarComp
                  key={value._id}
                  state={value}
                  useStackSystem={(id, args) => {
                    pane.stackSystem = id;
                    pane.stackSystemArgs = args;
                    this.state.stack = this.state.stack.slice(0, i + 1);
                    this.state.stack.push(XObject.obj({
                      type: 'stackSystem',
                      stackSystem: id,
                      stackSystemArgs: args,
                    }));

                  }}
                />
              )}
              <ul>
                {values().filter(value => value.location == pane.id).map((value, i) => {
                  return (
                    <li key={value._id}>
                      <RootValue id={value._id} />
                    </li>
                  );
                })}
              </ul>
              <NotionButton onClick={() => {
                const newValuePoint = XObject.obj({
                  location: pane.id,
                  possibleTypes: x(value.possibleTypes),
                }) as any;
                trackChange(CreateValuePoint, {}, [
                  [ UndoActions.createRootValuePoint, newValuePoint._id ],
                ])
                values().push(addValuePoint(newValuePoint));
              }} />
            </PaneContext.Provider>
          </t.pane>
        )

      }
      else {
        const stackSystem = stackSystems[pane.stackSystem];

        return (
          <t.pane key={pane._id} style={{
            width: stackSystem.width,
            height: stackSystem.height,
            padding: stackSystem.padding,
          }}>
            {stackSystem.render(pane.stackSystemArgs)}
          </t.pane>
        );
      }
    })
  }
}

@component
export class GlueDev extends Comp<{ state }> {
  static t = {
    panes: styled.div`
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      /* right: 250px; */
      right: 0;
      bottom: 0;
      overflow: auto;
    `,

    pane: styled.div`
      width: 630px;
      border-right: 1px solid #ccc;
      padding-right: 8px;
      overflow: auto;
      padding: 8px;
      flex: 0 0 auto;

      .valueInspection {
        .switches {
          display: flex;
          > div {
            margin-right: 8px;
          }
        }
      }

      > ul > li.block {
        > ${ValuePointCont} {
          padding-left: 4px;
          border-left: 2px solid ${borderColor};
        }
      }
    `,

    rightPane: styled.div`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 250px;
      border-left: 2px solid #ccc;

      display: none;
    `,
  }

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${glueTextStyles}
    background: #1e1e1e;


    ${props => css`
      [data-value-point-id="${props.focusValuePoint}"] {
        background: yellow;
      }
    `}
  `;

  state = XInit(class {
    stack: {
      type: 'valuePoint' | 'stackSystem'
      _id
      id
      stackSystem
      stackSystemArgs
    }[] = [];

    namespace

    previewState = {}
  })

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      const el = jQuery(`[data-value-point-id="${this.props.state.focusValuePoint}"]`)[0];
      if (el) {
        el.scrollIntoView();
      }
    }, 100);
  }

  // handlers = [];
  // componentDidMount() {
  //   const addHandler = (event, handler) => {
  //     this.handlers.push([event, handler]);
  //     return handler;
  //   }
  //   jQuery(window).on('keydown', addHandler('keydown', e => {
  //     if (e.key == 'c' && e.metaKey) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       console.log('copy');
  //     }
  //   }));
  // }

  // componentWillUnmount() {
  //   for (const [event, handler] of this.handlers) {
  //     jQuery(window).off(event, handler);
  //   }
  // }

  render(Container?) {
    const { t } = GlueDev;
    const currentNamespace = this.props.state.currentNamespace && namespaces().find(ns => ns._id == this.props.state.currentNamespace);
    const __values = (() => {
      if (this.props.state.valuePoints) {
        const valuePoints =  this.props.state.valuePoints.map(id => getValuePoint(id));
        return valuePoints;
      }
      else {
        return this.props.state.valuePoint ? [ getValuePoint(this.props.state.valuePoint) ] : values().filter(value => {
          return !value.location && value.namespace == this.props.state.currentNamespace
        });
      }
    })();
    return (
      <Container
        focusValuePoint={this.props.state.focusValuePoint}
      >
        <t.panes>
          <PaneContext.Provider value={{
            active: this.state.stack?.[0]?.id,
            selectValuePoint: id => {
              this.state.stack = [ XObject.obj({
                id,
              }) ];
            }
          }}>
            <t.pane>
              {(!this.props.state.valuePoint && !this.props.state.valuePoints) && <div>
                <select
                  value={this.props.state.currentNamespace || ''}
                  onChange={e => {
                    this.props.state.currentNamespace = e.target.value || undefined;
                  }}
                >
                  <option value="">Null</option>
                  {namespaces().map(ns => {
                    return (
                      <option key={ns._id} value={ns._id}>{ns.name}</option>
                    )
                  })}
                </select>
                {currentNamespace && <PropertyField object={currentNamespace} property="name" />}
                
                <button onClick={() => {
                  namespaces().push(XObject.obj({
                    name: 'New namespace',
                  }));
                }}>+</button>
              </div>}
              <ul>
                {__values.map((value, i) => {
                  const block = isBlock(value);

                  return (
                    <li key={value._id + i} className={block && 'block'}>
                      <ValuePointSwitch id={value._id} path={[i]} />

                      {value.presentation?.showPreview && (
                        (() => {
                          return <div><GlueView args={{}} id={value._id} state={XObject.get(this.state.previewState, value._id, {})} /></div>;
                          // return render(value, {}, RenderType.full, XObject.get(this.state.previewState, value._id, {}));
                        })()
                      )}
                    </li>
                  );
                })}
              </ul>
              {!this.props.state.valuePoint && !this.props.state.valuePoints && <button onClick={() => {
                createRootValuePoint(this.props.state.currentNamespace || undefined);
              }}>+</button>}
            </t.pane>

            <GlueStack state={this.state} />

            {/* {this.state.stack.map((pane, i) => {
              const type = pane.type || 'valuePoint';
              if (type == 'valuePoint') {
                const valueId = pane.id;
                const value = getValuePoint(pane.id);
  
                const SideBarComp = sideBarComps[value.type?.[0]];
  
                const presentation = () => {
                  return XObject.get(value, 'presentation', {});
                }
  
                return (
                  <t.pane key={pane._id}>
                    <PaneContext.Provider value={{
                      active: this.state.stack[i + 1]?.id,
                      selectValuePoint: id => {
                        this.state.stack = this.state.stack.slice(0, i + 1);
                        this.state.stack.push(XObject.obj({
                          type: 'valuePoint',
                          id,
                        }));
                      }
                    }}>
                      <div className="valueInspection">
                        {pane.id} ({value.namespace})
                        <PropertyField object={value} property="context" />
                        <button onClick={() => {
                          clipboard.state = value._id;
                          toast('Copied to clipboard');
                        }}>Copy</button>
  
                        <button onClick={() => {
                          if (clipboard.state) {
                            let cloned = _.cloneDeep(x(getValuePoint(clipboard.state)));
                            const clonedOriginal = _.cloneDeep(cloned);
                            cloned.location = value.location;
                            delete cloned._id;
                            delete cloned.name;
                            cloned = replaceIds(cloned);
                            // console.log(cloned, clonedOriginal);
                            replace(value._id, cloned);
                            tickValuePoint(value._id);
                            indexValuePoints();
  
                            toast('Pasted from clipboard');
                            delete clipboard.state;
                          }
                          else {
                            toast('Clipboard is empty');
                          }
                        }}>Paste</button>
  
                        <button onClick={() => {
                          console.log(x(value));
                        }}>Debug</button>
  
                        <button onClick={() => {
                          console.log(x(execute(pane.id, new Runtime(this.context))));
                        }}>Execute</button>
  
                        <button onClick={() => {
                          openWindow({
                            type: 'glue',
                            id: pane.id,
                            // renderType: RenderType.entities,
                          })
                        }}>View</button>
  
                        <button onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            trackChange(ClearValuePoint, {}, [
                              [ UndoActions.modifiedValuePoint, value._id ],
                            ])
                            delete value.type;
                            delete value.content;  
                            delete value.parameters;
                          }
                        }}>Clear</button>
  
                        <button onClick={() => {
                          const cloned = _.cloneDeep(x(value));
                          delete cloned._id;
  
                          values().push(addValuePoint(XObject.obj({
                            ...cloned,
                            location: value._id,
                          })));
  
                          delete value.type;
                          delete value.content;
                          delete value.presentation;
                        }}>
                          Move into parent
                        </button>
                        <button onClick={() => {
                          const root = getRootValuePoint(value._id);
                          // console.log(root);
                          if (root.location) {
                            const cloned = _.cloneDeep(x(value));
                            delete cloned._id;
      
                            values().push(addValuePoint(XObject.obj({
                              ...cloned,
                              location: root.location,
                            })));
      
                            delete value.type;
                            delete value.content;
                            delete value.presentation;  
                          }
                          else {
                            console.log('No containing scope', x(root));
                          }
                        }}>
                          Move into containing scope
                        </button>
                        <div className="switches">
                          <div onClick={() => value.isState = !value.isState}><input type="checkbox" checked={value.isState} /> State</div>
                          <div onClick={() => value.isTerminal = !value.isTerminal}><input type="checkbox" checked={value.isTerminal} /> Reference</div>
                          <div onClick={() => presentation().compact = !presentation().compact}><input type="checkbox" checked={value.presentation?.compact} /> Compact</div>
                          <div onClick={() => presentation().debug = !presentation().debug}><input type="checkbox" checked={value.presentation?.debug} /> Debug</div>
                          <div onClick={() => presentation().showPreview = !presentation().showPreview}><input type="checkbox" checked={value.presentation?.showPreview} /> Show Preview</div>
                        </div>
                      </div>
  
                      {SideBarComp && (
                        <SideBarComp
                          key={value._id}
                          state={value}
                          useStackSystem={(id, args) => {
                            pane.stackSystem = id;
                            pane.stackSystemArgs = args;
                            this.state.stack = this.state.stack.slice(0, i + 1);
                            this.state.stack.push(XObject.obj({
                              type: 'stackSystem',
                              stackSystem: id,
                              stackSystemArgs: args,
                            }));
    
                          }}
                        />
                      )}
                      <ul>
                        {values().filter(value => value.location == pane.id).map((value, i) => {
                          return (
                            <li key={value._id}>
                              <RootValue id={value._id} />
                            </li>
                          );
                        })}
                      </ul>
                      <button onClick={() => {
                        const newValuePoint = XObject.obj({
                          location: pane.id,
                          possibleTypes: x(value.possibleTypes),
                        }) as any;
                        trackChange(CreateValuePoint, {}, [
                          [ UndoActions.createRootValuePoint, newValuePoint._id ],
                        ])
                        values().push(addValuePoint(newValuePoint));
                      }}>+</button>
                    </PaneContext.Provider>
                  </t.pane>
                )
  
              }
              else {
                const stackSystem = stackSystems[pane.stackSystem];

                return (
                  <t.pane key={pane._id} style={{
                    width: stackSystem.width,
                    height: stackSystem.height,
                    padding: stackSystem.padding,
                  }}>
                    {stackSystem.render(pane.stackSystemArgs)}
                  </t.pane>
                );
              }
            })} */}
          </PaneContext.Provider>
        </t.panes>

        <t.rightPane>
          <ul>
            {changeState.changes.map((change, i) => {
              const action = changeTypes[change.actionId];
              if (action) {
                return (
                  <li key={change._id}>
                    {action.name}

                    {changeState.pointer >= i && (
                      <><button
                        onClick={() => {
                          console.log('undo');
                          for (const action of change.actions) {
                            if (action.action[0] == UndoActions.modifiedValuePoint) {
                              const value = getValuePoint(action.action[1]);
                              action.redoData = XClone(value);
                              revertTo(value, action.undoData, ['tick']);
                              tickValuePoint(value._id);
                            }
                            else if (action.action[0] == UndoActions.createRootValuePoint) {
                              const value = getValuePoint(action.action[1]);
                              action.redoData = XClone(value);
                              values().splice(values().indexOf(value), 1);
                            }
                          }
                          changeState.pointer = i - 1;
                        }}
                      >
                        Undo
                        </button></>
                    )}
                    {!(changeState.pointer >= i) && (
                      <><button
                        onClick={() => {
                          console.log('redo');
                          for (const action of change.actions) {
                            if (action.action[0] == UndoActions.modifiedValuePoint) {
                              const value = getValuePoint(action.action[1]);
                              // action.undoData = XClone(value);
                              revertTo(value, action.redoData, ['tick']);

                              // for (const prop in action.redoData) {
                              //   if (prop == 'tick') continue;

                              //   if (!_.isEqual(x(value[prop]), x(action.redoData[prop]))) {
                              //     value[prop] = X(x(action.redoData[prop]));
                              //   }
                              // }

                              // for (const prop in value) {
                              //   if (!(prop in value)) {
                              //     console.log('delete', prop)
                              //     delete value[prop];
                              //   }
                              // }

                              tickValuePoint(value._id);
                            }
                            else if (action.action[0] == UndoActions.createRootValuePoint) {
                              const value = getValuePoint(action.action[1]);
                              // action.undoData = XClone(value);
                              values().push(XClone(action.redoData));
                            }
                          }
                          changeState.pointer = i;
                        }}
                      >
                        Redo
                      </button>
                    </>
                    )}
                  </li>
                );
              }
              else {
                return <li>Unknown action: {change.actionId}</li>;
              }               
            })}
          </ul>
        </t.rightPane>
      </Container>
    )
  }
}


function init() {
  registerArrayType();
  registerEntityType();
  registerValueType();
  registerParamType();
  registerIfChainType();
  registerElementType();
  registerEnumType();
  registerEntityTypeType();
  registerGraphType();
  registerEntityMetaState();
  registerEntityMatchType();
  registerEntityStateType();
  registerStructureType();
  registerStringType();
  registerEntityAttributeType();
  registerStatementType();
  registerEntityStateTypeType();
  registerEntityStateTypesType();
  registerEntityAttributesType();
  registerBooleanType();
  registerRelativeQueriesType();
  registerRelativeQueryType();
  registerEntityElementType();
  registerArgType();
  registerCodeComponentType();

  typeRegistry.registerType({
    $: '77c1df6e-b826-5f72-9d78-15ce5f2a0ae1',
    Prop: '3ec42923-ae07-55fe-bbcf-8565e76d4832',
  }, ids => ({
    _id: ids.$,
    name: 'Test Struct',
    definition: [
      {
        id: ids.Prop,
        type: [ ValueType.String ],
        name: 'Prop',
      }
    ]
  }));

  for (const sructure of typeRegistry.types) {
    addStructToMap(sructure._id, sructure);
  }


}

init();
