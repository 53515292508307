import { XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { resolvePath } from '../etc/queryGraph';
import { showPrompt } from '../etc/showPrompt';

// function getQueue(rootEntity: string, create=false) {
//   if (!rootEntity) {
//     throw new Error('getQueue: rootEntity is null');
//   }
//   const queues = XObject.get(defaultWorkspace(), 'queues', []);
//   let queue = queues.find(q => q.entity == rootEntity);

//   if (!queue && create) {
//     queue = XObject.obj({
//       entity: rootEntity,
//       list: [],
//     });
//     queues.push(queue);
//   }

//   return queue;

// }

export function getQueuePosition(rootEntity: string, entity: string, _queue) {
  const queue = _queue
  if (queue) {
    const index = queue.list.indexOf(entity);
    if (index >= 0) return index;
  }
  return null;
}

export function renderQueuePositions(rootEntity: string, entity: string) {
  // using format: name: position
  const queuesForRoot = XObject.get(defaultWorkspace(), 'queues', []).filter(q => q.list.includes(entity));
  const positions = [];
  for (const queue of queuesForRoot) {
    const position = getQueuePosition(rootEntity, entity, queue);
    if (position !== null) {
      const name = queue.name || '(unnamed)';
      positions.push(`${name}: ${position + 1}`);
    }
  }
  return positions.join(', ');
}
  

export function addToQueue(rootEntity: string, entity: string, _queue) {
  const queue = _queue
  queue.list.push(entity);
}

export function inQueue(rootEntity: string, entity: string, _queue?) {
  return getQueuePosition(rootEntity, entity, _queue) !== null;
}

export function removeFromQueue(rootEntity: string, entity: string, _queue) {
  const queue = _queue
  if (queue) {
    const index = queue.list.indexOf(entity);
    if (index >= 0) {
      queue.list.splice(index, 1);
    }
  }
} 

export function queueMenuItems(rootEntity: string, entity: string, graphId?: string) {
  const entries = [];
  const workspace = defaultWorkspace();
  if (workspace.queues) {
    let queues = workspace.queues.filter(q => q.entity == rootEntity);

    if (graphId) {
      const path = resolvePath(graphId, rootEntity, entity);
      console.log(path, graphId, rootEntity, entity);
      for (const i of path) {
        const otherQueues = workspace.queues.filter(q => q.entity == i);
        queues.splice(0, 0, ...otherQueues);
      }
    }

    // unique by queue id
    queues = queues.filter((q, i) => queues.findIndex(q2 => q2._id == q._id) == i);



    for (const queue of queues) {
      const name = queue.name || '(unnamed)';
      if (!inQueue(rootEntity, entity, queue)) entries.push({
        text: `Add to ${name} (${queue.list.length})`,
        onClick: () => {
          addToQueue(rootEntity, entity, queue);
        }
      });
      else entries.push({
        text: `Remove from ${name} (${queue.list.length})`,
        onClick: () => {
          removeFromQueue(rootEntity, entity, queue);
        }
      });
    }
  }

  entries.push({
    text: 'Add to new queue',
    onClick: async () => {
      const name = await showPrompt('Queue name');
      if (!name) return;
      const queue = XObject.obj({
        entity: rootEntity,
        list: [entity],
        name,
      });
      XObject.get(workspace, 'queues', []).push(queue);
    }
  });

  return entries;
}
