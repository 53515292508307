
export enum EntityType {
  // specification = '34f4741c-99fb-57db-98e9-6a41fd52ad5f',
}

export const entityTypes = [
  // {
  //   _id: EntityType.specification,
  //   name: 'Specification',
  // }
]