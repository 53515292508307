import { typeRegistry } from "../typeRegistry.1";

export const $EntityAttributeBinding = typeRegistry.registerType({
  $: '5d4e6429-3c9e-5be9-9bf8-bf97f94b3fc0',
  Attribute: 'b789afa5-ea87-58db-a7cd-ebcc9ca96f27',
  Value: '09c819d4-c109-50ef-803c-93172384b143',
}, ids => ({
  _id: ids.$,
  name: 'Entity Attribute Binding',
  definition: [
    {
      id: ids.Attribute,
      name: 'Attribute',
      type: [],
      property: 'attribute',
    },
    {
      id: ids.Value,
      name: 'Value',
      type: [],
      property: 'value',
    },
  ]
}));