import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component, styled } from '../component2';
import { isMobile } from '../isMobile';
import { Svg } from './Svg';


const size = 4;
@component
export class InsertionCont extends Component<{ orientation; onInsert; tag; children?; className?; mobileAdd?; disable? }> {
  static el = styled.div`
    &:hover {
      background: #eee;
    }
  `;



  static styles = styled.div`
    .insertion {
      &:hover {
        background: #eee;
      }
      cursor: pointer;
    }

    > .add {
      fill: #bababa;
      height: 12px;
      height: 12px;
    }
  `;

  getSnapshotBeforeUpdate() {
    for (const el of this.els) {
      jQuery(el).remove();
    }
    this.els = [];
  }

  els = [];

  componentDidUpdate(): void {
    this.update();
  }
  update() {
    if (isMobile() || this.props.disable || !this.props.onInsert) return;
    for (const el of this.els) {
      jQuery(el).remove();
    }
    this.els = [];

    const contEl = jQuery(ReactDOM.findDOMNode(this));
    const children = [...contEl.children()];
    let i = 0;
    for (const childEl of children) {
      const j = i;
      const el = jQuery(
        `<div class="insertion" />`
      ).css({
        position: 'absolute',
      }).click(e => {
        this.props.onInsert(j, e);
      });

      if (this.props.orientation === 'vertical') {
        el.css({
          left: 0,
          right: 0,
          height: size,
          top: jQuery(childEl).position().top,
        });
      } else {
        el.css({
          top: 0,
          bottom: 0,
          width: size,
          left: jQuery(childEl).position().left,
        });
      }


      contEl.append(el);

      this.els.push(el);

      ++i;
    }

    const el = jQuery(
      `<div class="insertion" />`
    ).css({
      position: 'absolute',
      top: 0,
    }).click(e => {
      this.props.onInsert(children.length, e);
    });

    const last = children[children.length - 1];
    if (last) {
      if (this.props.orientation === 'vertical') {
        el.css({
          left: 0,
          right: 0,
          height: size,
          top: jQuery(last).position().top + jQuery(last).outerHeight(),
        });
      } else {
        el.css({
          top: 0,
          bottom: 0,
          width: size,
          left: jQuery(last).position().left + jQuery(last).outerWidth(),
        });
      }
      contEl.append(el);
      this.els.push(el);
  
    }
    else {
      if (this.props.orientation === 'vertical') {
        el.css({
          left: 0,
          right: 0,
          height: size,
          top: 0,
        });
      } else {
        el.css({
          top: 0,
          bottom: 0,
          width: size,
          left: 0,
        });
      }
      contEl.append(el);
      this.els.push(el);
    }
  }

  componentDidMount(): void {

    this.update();

  }
  render(Container?) {
    return (
      <Container data-count={this.props.children.length} as={this.props.tag} className={this.props.className} style={{ position: 'relative' }}>
        {this.props.children}

        {this.props.onInsert && !this.props.disable && (isMobile() && this.props.mobileAdd  || !this.props.children.length) && (

          <Svg name="plus"
            className="add"
          onClick={e => {
            this.props.onInsert(this.props.children.length, e);
          }}

          />

        )}
      </Container>
    );
  }
}
