import styled from 'styled-components';


export const ValuePointCont = styled.span`
  &:hover {
    background: rgba(255, 255, 255, 0.05)
  }
  &.block {
    display: block;
    padding-right: 4px;
  }

  &.active {
    background: #ffffff1a;
    outline: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
