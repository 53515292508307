import ReactDOMClient from "react-dom/client";
import jQuery from "jquery";
import { isMobile } from "../../isMobile";
import { closeMobileModal, presentMobileModal } from "../presentMobileModal";
export function presentEditComp({ frame, title = undefined, cell, get, set, state }) {
  console.log('presentEditComp', title);
  if (isMobile() && cell.mobileEditorFullScreen()) {
    frame = {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };

    const d = cell.renderEditor({
      state,
      close: () => {
        closeMobileModal();
      },
      frame,
      value: get,
      setValue: value => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          set(value);
        }, 0);
      }
    });

    presentMobileModal((
      <>
        {d} 

        {/* <button
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            zIndex: 99999999,
          }}
          onClick={() => {
            closeMobileModal();
          }}
        >X</button> */}
      </>
    ), title);
  }
  else {
    const cont2 = jQuery('<div />').css({
      position: 'absolute',
      left: frame.left + 'px',
      top: frame.top + 'px',
    });
  
    const cont = jQuery('<div />').css({
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 9999999,
    });
  
    const close = () => {
      cont.remove();
      root.unmount();
    };
    cont.click((e) => {
      e.preventDefault();
      e.stopPropagation();
      close();
    });
    cont.append(cont2);
    cont2.click((e) => {
      e.stopPropagation();
    });
  
    jQuery('body').append(cont);
    const root = ReactDOMClient.createRoot(cont2[0]);

    const d = cell.renderEditor({
      state: null,
      close,
      frame,
      value: get,
      setValue: value => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          set(value);
        }, 0);
      }
    });

    root.render(d);
  }

  
  let timerId;
  // root.render();
}
