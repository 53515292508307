import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { openWindow } from '../osHelpers';
import { component } from '../component2';
import { XObject } from '../XObject';
import { isId } from '../helpers';
import { WindowType } from '../etc/WindowType';
import { appState } from '../etc/appState';


@component
export class ModeSelect extends Component {
  static styles = styled.div`

  `;

  render() {

    return (
      <select
        value={appState.currentMode || ''}
        onChange={e => {
          const value = e.target.value;
          if (!value) {
            appState.currentMode = null;
          }
          else if (isId(value)) {
            appState.currentMode = value;
          }
          else if (value == 'editMode') {
            openWindow({
              type: WindowType.EditMode,
              id: appState.currentMode,
            });
          }
          else if (value == 'newMode') {
            const newSidebar = XObject.obj({
              children: [],
            });
            db.sidebar.push(newSidebar);

            const newMode = XObject.obj({
              name: 'Untitled',
              sidebar: newSidebar._id,
              useAsSpace: true,
            });
            db.modes.push(newMode);
            appState.currentMode = newMode._id;
          }
          else if (value == 'openLibrary') {
            openWindow({
              type: WindowType.AppLibrary,
            });
          }
        }}
      >
        <option />
        {db.modes.map(mode => (
          <option key={mode._id} value={mode._id}>{mode.name}</option>
        ))}
        <option value="newMode">(Create mode)</option>
        <option value="editMode">(Edit mode)</option>
        <option value="openLibrary">(Open library)</option>
      </select>
    );
  }
}
