import React, { Component } from 'react';
import { db } from '../db';
import { openWindow } from '../osHelpers';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { WindowType } from '../etc/WindowType';


@component
export class DatabasesWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <ul>
          {db.databases.map((x, i) => (
            <li key={x._id}>
              <PropertyField object={x} property="name" />

              <button
                onClick={() => {
                  openWindow({
                    type: WindowType.Database,
                    database: x._id,
                  });
                }}

              >DB</button>

              <button
                onClick={() => {
                  openWindow({
                    type: WindowType.Document,
                    database: x._id,
                  });
                }}

              >Doc</button>

              <button
                onClick={() => {
                  db.databases.splice(i, 1);
                }}
              >-</button>
            </li>
          ))}
        </ul>
        <button
          onClick={() => {
            db.databases.push(XObject.obj({
              name: 'New Database',
              rows: [],
              columns: [],
            }));
          }}
        >+</button>

      </>
    );
  }
}
