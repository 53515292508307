import React, { Component } from 'react';
import { db } from '../db';
import { getValuePoint } from '../glue/main';
import { openWindow } from '../osHelpers';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { x, XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { EntityDisplay } from '../components/EntityDisplay';
import { showContextMenu } from '../helpers';
import { WindowType } from '../etc/WindowType';
import { createEntity } from '../etc/createEntity';


@component
export class OrgEntitiesWindow extends Component<{ window; }> {
  render() {
    const renderOrgEntity = orgEntity => {
      const children = db.orgEntities.filter(x => x.parent === orgEntity._id);
      const entities = db.entities.filter(x => x.orgEntity === orgEntity._id);
      return (
        <div>
          <div
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Add sub org entity',
                  onClick: () => {
                    db.orgEntities.push(XObject.obj({
                      name: 'New Org Entity',
                      parent: orgEntity._id,
                    }));
                  }
                },
                {
                  text: 'Add new entity',
                  onClick: () => {
                    createEntity(XObject.obj({
                      name: 'New Entity',
                      orgEntity: orgEntity._id,
                    }), null);
                  }
                },
                {
                  text: 'Add existing entity',
                  onClick: () => {
                    const id = prompt('Enter entity id');
                    if (id) {
                      const entity = db.entities.findById(id);
                      if (entity) {
                        entity.orgEntity = orgEntity._id;
                      }
                    }
                  }
                },
              ]);
            }}
          >
            <PropertyField object={orgEntity} property="name" />
          </div>
          <ul>
            {entities.map((entity, i) => {
              const queues = defaultWorkspace().queues.filter(q => q.entity === entity._id);
              const actions = orgEntity.entityActions?.[entity._id] || [];
              return (
                <li key={entity._id}>
                  <EntityDisplay id={entity._id}

                    menuItems={[
                      {
                        text: 'Add action',
                        onClick: () => {
                          const entityActions = XObject.get(XObject.get(orgEntity, 'entityActions', {}), entity._id, []);
                          entityActions.push(XObject.obj({
                            type: 'view',
                            id: prompt('Enter view ID'),
                            name: prompt('Enter action name'),
                          }));
                        }
                      },

                      ...actions.map(action => ({
                        text: action.name,
                        onClick: () => {
                          const value = getValuePoint(action.id);
                          console.log(x(value));
                          const param = value.parameters?.[0]?._id;
                          if (param) {
                            const params = {};
                            params[param] = entity._id;
                            openWindow({
                              type: 'glue',
                              id: action.id,
                              args: params,
                            });
                          }

                        }
                      })),
                    ]} />
                  {queues.length > 0 && (
                    <>
                      <h4
                        onClick={() => {
                          openWindow({
                            type: WindowType.EntityQueues,
                            entity: entity._id,
                          });
                        }}

                      >Queues</h4>
                      <ul>
                        {queues.map(queue => (
                          <li key={queue._id}>
                            <PropertyField object={queue} property="name" />
                            <button
                              onClick={() => {
                                openWindow({
                                  type: WindowType.Queue,
                                  queue: queue._id,
                                });
                              }}
                            >.</button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                </li>
              );
            })}
          </ul>
          <ul>
            {children.map((x, i) => (
              <li key={x._id}>
                {renderOrgEntity(x)}
              </li>
            ))}
          </ul>
        </div>
      );
    };

    const rootOrgEntities = db.orgEntities.filter(x => !x.parent);

    return (
      <>
        <ul>
          {rootOrgEntities.map((x, i) => (
            <li key={x._id}>
              {renderOrgEntity(x)}
            </li>
          ))}
        </ul>
        <button
          onClick={() => {
            db.orgEntities.push(XObject.obj({
              name: 'New Org Entity',
            }));
          }}
        >+</button>

      </>
    );
  }
}
