import { structRenderers } from '../structRenderers';
import { renderSplitGraph } from './$Browser';
import { typeRegistry } from '../typeRegistry.1';
import { mapStructure } from '../main';

const $GraphView = typeRegistry.registerType({
  $: '1960ad38-bc85-546a-8abb-dc74501b9b69',
  OverviewGraph: 'cc27b3a7-2d61-5e6d-a6a4-6296da131d13',
  PathGraph: '8f8dbb54-0ad0-5a24-b559-6a5e895dd3f7',
  Contents: 'ef67c21b-6efa-5194-bcc3-a21d0d4a4be3',
  RootEntity: 'aaad36ca-d302-54dd-9670-61be26f8b3f3',
}, ids => ({
  _id: ids.$,
  name: 'Graph View',
  definition: [
    {
      id: ids.OverviewGraph,
      name: 'Graph',
      type: [],
      property: 'overviewGraph',
    },
    {
      id: ids.Contents,
      name: 'Contents',
      type: [],
      property: 'contents',
    },
    {
      id: ids.RootEntity,
      name: 'Root Entity',
      type: [],
      property: 'rootEntity',
    },
    {
      id: ids.PathGraph,
      name: 'Path Graph',
      type: [],
      property: 'pathGraph',
    },
  ]
}));
structRenderers[$GraphView.$] = (value, map, state) => {
  const mapped = mapStructure(value);

  return renderSplitGraph({
    overviewGraph: mapped.overviewGraph.content,
    contentsGraph: mapped.contents,
    entity: mapped.rootEntity.content,
    pathGraph: mapped.pathGraph.content,
    state,
  });
};
