import { db } from '../db';
import { XObject, X, x, XInit, XClone } from '../XObject';
import { getValuePoint } from './main';

export enum ObjectType {
  // ValuePoint = '62aba581-a4dd-52e6-b343-72b526c979a3'
}

export enum UndoActions {
  modifiedValuePoint = '9f353542-2998-530e-9b79-7f676345473f',
  createdNestedValuePoint = '96aa3121-523d-5af6-bb40-d10ac37a2037',
  createRootValuePoint = 'f857ba8b-b575-5dd2-9c1e-0997546c3030',
}
export const changeState = XInit(class {
  changes = [];
  pointer = 0;
});

export function trackChange(actionId, args, actions) {
  console.log('trackChange', actionId, x(args), x(actions));
  const newChange = XObject.obj({
    actionId,
    args,
    actions: actions.map(action => {
      let undoData;
      if (action[0] == UndoActions.modifiedValuePoint) {
        undoData = XClone(getValuePoint(action[1]));
      }
      return {
        action,
        undoData
      }
    })
  });

  const newStack = changeState.changes.slice(0, changeState.pointer + 1);
  newStack.push(newChange);
  changeState.changes = newStack;



  changeState.pointer = changeState.changes.length - 1;
}
export const changeTypes = {};

export function registerChangeAction(id, action) {
  changeTypes[id] = action;
}
