import styled from 'styled-components';
import { db } from '../db';
import { Component } from 'react';
import { component } from '../component';


@component
export class UserBadge extends Component<{ user; }> {
  static styles = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #b0c793;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 600;
  `;
  render() {
    const user = db.users.findById(this.props.user);
    return <>{user.initials}</>;
  }
}
