import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { component } from '../component2';
import { XObject } from '../XObject';
import classNames from 'classnames';
import { memoryAppState } from '../etc/appState';
import { SystemContext } from '../etc/SystemContext';
import { EntityRow } from './EntityRow';
import juration from '../juration';
import { getInbox } from "../etc/getInbox";


@component
export class Inbox extends Component<{}> {
  static styles = styled.div`
    padding: 8px;
    .row {
      &:not(.seen) {
        ${EntityRow} {
          font-weight: 600;
        }
      }
      position: relative;
      padding-left: 16px;
      border-bottom: 1px solid #eee;
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;
      .dot {
        position: absolute;
        top: 12px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgb(87, 91, 199);
      }
      .meta {
        display: flex;
        .time {
          margin-left: auto;
        }
      }
    }
  `;

  context: any;
  static contextType = SystemContext;

  render() {
    const user = db.users.findById(memoryAppState.user);
    const references = getInbox();

    // sort by creation date (creation.timestamp: Date)
    return (
      <SystemContext.Provider value={{
        navigate: config => {
          // inspectFromNav(config
          if (config.type == 'entity') {
            const inboxSeen = XObject.get(user, 'inboxSeen', {});
            inboxSeen[config.id] = true;
          }
          this.context.navigate(config);
        },
        next: this.context.next,
      }}>
        <button
          onClick={() => {
            delete user.inboxSeen;
          }}
        ></button>
        {references.map(({ entity: ref }) => {
          return (
            <div className={classNames("row", { seen: user.inboxSeen?.[ref] })} key={ref}
              onClick={() => {
                const inboxSeen = XObject.get(user, 'inboxSeen', {});
                inboxSeen[ref] = true;

                this.context.navigate({
                  type: 'entity',
                  id: ref,
                });
              }}
            >
              {!user.inboxSeen?.[ref] && <span className="dot" />}
              <EntityRow key={ref} id={ref} />
              <div className="meta">
                <span className="time">{juration.stringify((Date.now() - db.entities.findById(ref).meta.creation.timestamp.getTime()) / 1000, { format: 'micro', units: 1 })}</span>
              </div>
            </div>
          );
        })}
      </SystemContext.Provider>
    );
  }
}
