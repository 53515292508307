
export enum AttributeType {
  text = '0f7375f1-732b-5591-84e1-1bf2c103c5f9',
  entity = '95839512-682e-586f-a744-1f6dad7f8354',
  entities = '7c1b2fa7-82a7-529d-9cc0-1c44ba783fd7',
  datetime = '3657ea3f-d79b-5065-8a17-c486a410ead4',
  valuePoint = '4628b101-6851-597f-89c3-e9bbe4ae10b9',
  priority = '66bcc483-0a9e-5d4a-b219-1ab8f1b41c18',
  dueDate = '55c09e2c-9a88-576e-8d8b-5ac26ed2f8a3',
  switch = '91c007d3-2b2f-5131-83d2-7970a977b580',
  duration = 'duration',

  select = '3100c464-56fc-555a-9e4e-488e10cdfeae',
  multiSelect = 'a49385c6-e337-5036-845c-19b78379e852',

  richText = 'fcf990f6-5bfe-505d-8361-010d60ae83e7',

  event = '0bb706de-cf66-54cd-bb46-90c20013d742',
}
