import React, { Component } from 'react';
import { component } from '../component2';
import { db } from '../db';
import { ObjectDisplay } from './ObjectDisplay';
import { getObject, getObjectParent, setObjectParent } from "./objectFuncs";
import { ObjectType } from '../types/ObjectRef';
import { X, XInit, x } from '../XObject';
import { styled } from '../component';
import { showContextMenu } from '../etc/showContextMenu';
import { EntityRow } from './EntityRow';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { ObjectPicker } from './ObjectPicker';
import { Comp } from '../glue/Comp';

@component
class ObjectEditor extends Component<{ object }> {

}

@component
export class AppLibrary extends Component<{ window }> {
  static styles = styled.div`
    padding: 8px;
  `;
  state = XInit(class {
    moving = {};
    // pickedObject = null;
    movingPicked = false;
  })
  render() {
    const state = this.props.window;
    const objects = {};

    function makeKey(obj) {
      return obj.type + ':' + obj.id;
    }

    function fromKey(key) {
      const [type, id] = key.split(':');
      return {
        type,
        id,
      };
    }

    function addToParent(obj) {
      const parent = getObjectParent(obj);
      if (parent) {
        const key = makeKey(parent);
        if (!objects[key]) {
          objects[key] = {};
        }
        objects[key][makeKey(obj)] = true;

        addToParent(parent);
      }
      else {
        const key = makeKey({
          type: ObjectType.global,
        });
        if (!objects[key]) {
          objects[key] = {}
        }
        objects[key][makeKey(obj)] = true;
      }
    }

    for (const attr of db.attributeTypes) {
      addToParent({
        type: ObjectType.attribute,
        id: attr._id,
      });
    }

    for (const type of db.entityTypes) {
      addToParent({
        type: ObjectType.type,
        id: type._id,
      });
    }

    function renderNode(object) {
      const key = makeKey(object);
      const children = objects[key];
      if (!children) {
        return (
          <li key={key}>
            <ObjectDisplay obj={object} showPath />
          </li>
        );
      }
      else {
        return (
          <li key={key}>
            <ObjectDisplay obj={object} showPath />
            <ul>
              {Object.keys(children).map(key => renderNode(fromKey(key)))}
            </ul>
          </li>
        );
      }
    }

    return (
      <>
      <ObjectPicker
        _onSelect={(obj, clear) => {
          state.pickedObject = obj;
          clear();
        }}
      />

      {state.pickedObject && (
        <div
        >
          <ObjectDisplay obj={state.pickedObject} showPath
          

          />

          <div>
            <button
              onClick={() => {
                this.state.movingPicked = true;
              }}
            >Move</button>
            <button
            
              onClick={() => {
                if (state.pickedObject.type == ObjectType.attribute) {
                  openWindow({
                    type: WindowType.Attribute,
                    id: state.pickedObject.id,
                  })
                }
              }}
            >Edit</button>


            <button>Delete</button>

            {this.state.movingPicked && (
              <>
                <ObjectPicker
                  _onSelect={(ref, clear) => {
                    if (ref.type == ObjectType.global) {
                      setObjectParent(state.pickedObject, null);
                    }
                    else {
                      setObjectParent(state.pickedObject, ref);
                    }
                    this.state.movingPicked = false;
                  }}
                />
              </>
            )}

            {(() => {
              const picked = state.pickedObject;
              if (picked.type == ObjectType.attribute) {
                const entities = db.entities.filter(entity => {
                  return entity.attributes && (picked.id in x(entity.attributes));
                });
                return (
                  <>
                              <button
              onClick={() => {

              }}
            >Merge</button>

                    <h3>Entities</h3>
                    {entities.map(entity => {
                      return <EntityRow
                        id={entity._id}
                        path
                        
                      />
                    })}
                  </>
                )
              }
              else if (picked.type == ObjectType.type) {
                const entities = db.entities.filter(entity => {
                  return entity.type == picked.id;
                });
                return (
                  <>
                    <h3>Entities</h3>
                    {entities.map(entity => {
                      return <EntityRow
                        id={entity._id}
                        path
                        
                      />
                    })}
                  </>
                )
              }

            })()}

            

          </div>
        </div>
      )}

      <h2>Browse</h2>
      <ul>
      {renderNode({
        type: ObjectType.global,
      })}
      </ul>
      <h2>Types</h2>
      <ul>
        {db.entityTypes.map((entityType) => {

            return (
              <li key={entityType._id}>
                <ObjectDisplay
                  obj={{
                    type: ObjectType.type,
                    id: entityType._id,
                  }}
                  showPath
                />
              </li>
            );
          })}
        </ul>

      <h2>Attribute</h2>
      <ul>
        {db.attributeTypes.map((attributeType) => {
          return (
            <li key={attributeType._id}
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Move',
                    onClick: () => {
                      this.state.moving[makeKey({
                        type: ObjectType.attribute,
                        id: attributeType._id,
                      })] =  true;
                    }
                  },
                ])
              }}
            >
              <ObjectDisplay
                obj={{
                  type: ObjectType.attribute,
                  id: attributeType._id,
                }}
                showPath
              />

              {this.state.moving[makeKey({
                type: ObjectType.attribute,
                id: attributeType._id,
              })] && (
                <>
                  <ObjectPicker _onSelect={obj => {
                    this.state.moving[makeKey({
                      type: ObjectType.attribute,
                      id: attributeType._id,
                    })] = false;

                    attributeType.scope = X(obj);
                  }} />
                </>
              )}
            </li>
          );
        })}
        </ul>
      </>
    );
  }
}
