import React, { Component } from 'react';
import { component } from '../component2';
import { X, XInit } from '../XObject';
import { ExpressionEntryType, Conjuction, renderEntry } from '../etc/expressionQuery';


@component
export class QueryExpressionEditorWindow extends Component<{ window; }> {
  state = XInit(class {
  });

  render() {
    if (!this.props.window.root) {
      this.props.window.root = X({
        _id: 'root',
        type: ExpressionEntryType.group,
        conjunction: Conjuction.and,
        entries: [],
      });
    }

    return (
      <>

        {renderEntry(this.props.window.root, [])}


      </>
    );
  }
}
