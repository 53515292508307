import { Component } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component, styled } from '../component2';
import { showContextMenu as showContextMenu2 } from '../helpers';

@component
class CtxMenu extends Component<{
  items: { text, onClick }[],
  _onMount?, _onClick? }> {
  static styles = styled.div`
    background-color: white;
    border-color: #999da3;
    box-shadow: 0px 0px 0px 3px rgb(0 0 0 / 7%);
    border-radius: 3px;
    border: 1px solid #999da3;
    color: black;
  `;

  static t = {
    menuItem: styled.div`
      height: 25px;
      &:not(:last-child) {
        border-bottom: 1px solid #999da3;
      }
      line-height: 25px;
      padding: 0 8px;
      &:hover {
        background: #e2e2e2;
      }
    `
  }

  componentDidMount() {
    this.props._onMount(ReactDOM.findDOMNode(this));
  }
  render() {
    const { t } = CtxMenu;
    return (
      <>
        {/* <t.menuItem onClick={() => this.props._onClick()}>Add As Sub Component</t.menuItem> */}
        {this.props.items.filter(Boolean).map((item, i) => (
					item && <t.menuItem key={i} onClick={e => { this.props._onClick(e); item?.onClick?.(e) }}>{item.text}</t.menuItem>
        ))}
      </>
    )
  }
}


let _close;
export function showContextMenu(e: { clientX, clientY }, menu?: { text, onClick }[]) {
  showContextMenu2(e, menu);
	/*if (_close) _close();
  function updatePosition() {
    cont.css({
      left: e.clientX,
      top: e.clientY,    
    })
  }
  const cont = jQuery('<div />').css({
    position: 'absolute',
    zIndex: 9999999999,
  });
  updatePosition();

  const close = () => {
    ReactDOM.unmountComponentAtNode(cont[0]);
    cont.remove();
		_close = null;
  }

	_close = close;

  jQuery(window).one('mousedown', e => {
    if (e.target == cont[0] || jQuery.contains(cont[0], e.target)) return;
    close()
  });

  document.body.appendChild(cont[0]);
  ReactDOM.render(<CtxMenu
    items={menu}
    _onMount={el => {
      updatePosition();
    }}
    _onClick={() => close()}
  />, cont[0]);*/

}
