import { db } from '../db';

export const resolvePath = path => {
  let doc = db.notionDocuments.findById(path[0]);
  if (!doc)
    return;

  let i = 1;
  let row = doc;
  while (i < path.length) {
    doc = row;
    row = row.tableData.rows.find(row => row._id === path[i]);
    ++i;
  }
  return [doc, row];
};
