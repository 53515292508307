import { typeRegistry } from "../typeRegistry.1";
import { ValueType } from "../ValueType";


export const $StatementDataBinding = typeRegistry.registerType(
  {
    $: '88c97bd4-4a6b-5cb6-88b8-2487489438f3',
    Statement: '5798913b-3829-584d-8609-a73265d51d40',
    Type: '36371462-7f72-5d67-a9ed-ccf30e5470a9',
    Value: '865269ec-f84c-5374-990a-8df464671c25',
  },
  ids => ({
    _id: ids.$,
    name: 'Statement Data Binding',
    definition: [
      {
        // scope: [
        //   {
        //     id: ids.Value,
        //     name: 'Value',
        //     type: [],
        //   }
        // ],
        id: ids.Statement,
        name: 'Statement',
        type: [  ],
        property: 'statement',
      },
      {
        id: ids.Type,
        name: 'Type',
        type: [ ValueType.Enum, '5ac6d256-67b6-5495-bc36-77a7fe4c558c', [
          [ 'entity', 'entity' ],
          [ 'boolean', 'boolean' ],
        ] ],
        property: 'type',
      }
    ]
  })
);