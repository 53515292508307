export enum ViewType {
  list = '6fb314db-5246-5898-97ea-33553acbcdbc',
  table = '2632631b-ef9b-5f3c-98b3-ff7bedcc0e5d',
  split = '72cc541e-953a-5beb-a325-511202b35983',
  graph = '0baf01e2-2e78-510e-8639-b990bc392385',
}


export function supportsGrouping(viewType: ViewType) {
  return viewType === ViewType.list;
}