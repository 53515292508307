import React, { Component } from 'react';
import { component } from '../component2';
import { chatGptManager } from '../etc/chatGptManager';


@component
export class ChatGPTTestWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <button
          onClick={async () => {
            const conv = chatGptManager.createConversation();
            console.log(await conv.sendMessage(`
              
            INSTRUCTIONS:
            Actions: {
              Create: 'ACTION-CREATE'
            }
        
            Schemas: {
              Task {
                Assignee
                Content
                Status: 'TODO' | 'DONE'
              }
        
              Question: {
                Recipient
                Content
              }
        
              Note: {
                About
                Content
              }
        
              Podcast: {
                Host
                Title
              }
        
              Podcast Episode: {
                Podcast
                Title
                Number
              }
        
        
              Person {
                Name
              }
            }
        
            IDs of objects should be based on name
        
            Example action format:
            [
              {
                action: 'CREATE',
                type: 'Question',
                data: {
                  Recipient: 'Jonathan',
                  Content: 'What are your current tasks?'
                }
              },
            
            ]
        
            If the user is asking you to find something, look through the GIVEN OBJECTS and return the IDs matching the question. The user is not asking for a literal word match, but a general relation of the topic.
            
            Use this as the format for returning results:
            [
              {
                objectID: '1',
                reasonForIncluding: 'The note is about cars, and the user is asking about travel'
              }
            ]
        
            
            GIVEN OBJECTS:
        
            PODCAST {
              ID: 'asdfasdfa'
              Title: 'Modern Wisdom'
              Host: 'Chris Williamson'
            }
        
            PODCAST_EPISODE {
              ID: 'asdfasdfa!'
              Title: 'Every hates me'
              Number: 444
              Podcast: 'Modern Wisdom'
            }
        
            NOTE {
              ID: '1'
              About: 'asdfasdfa!'
              Content: 'I'm so sad'
            }
        
        
            NOTE {
              ID: '2'
              About: 'asdfasdfa!'
              Content: 'Apples and oranges'
            }
        
            NOTE {
              ID: '3'
              About: 'asdfasdfa!'
              Content: 'Calories are fun to eat. Calories in calories out'
            }
        
            NOTE {
              ID: '4'
              About: 'asdfasdfa!'
              Content: 'If you're going through hell, keep going'
            }
        
            NOTE {
              ID: '5'
              About: 'asdfasdfa!'
              Content: 'I feel okay'
            }
        
        
        
            REQUEST:
        
        
          
            find all notes about the podcats "Every hates me"
            
        
            `));

          }}
        >
          Test
        </button>
      </>
    );
  }
}
