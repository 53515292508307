import React, { Component } from 'react';
import { component, styled } from '../component2';
import { showContextMenu } from '../helpers';
import classNames from 'classnames';
import { Svg } from './Svg';
import { InsertionCont } from './InsertionCont';
import { WithContextAction } from './WithContextAction';
import { XInit } from '../XObject';

@component
export class ViewsList extends Component<{
  className?
  views: {
    _id;
    icon;
    name;
  }[];
  onAdd(index, e);
  onDelete(id);
  contextMenu(id);
  onClickView(id);
  active;
}> {
  static styles = styled.div`
    display: flex;
    align-items: center;
    .view {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 8px;
      color: #bcbcbc;
      font-weight: 500;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      white-space: nowrap;
      svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        fill: #bcbcbc;
      }
      &.noText {
        svg {
          margin-right: 0;
        }
      }
      &.active {
        color: inherit;
        svg {
          fill: #4a4a4a;
        }
      }
    }
  `;

  render(Container?) {
    return (
      <>
      <InsertionCont
        className={this.props.className}
        tag={Container}
        onInsert={this.props.onAdd && ((i, e) => {
          this.props.onAdd(i, e);
        })}
        orientation="horizontal"
        mobileAdd
      >
        {this.props.views?.map?.(v => {
          return (
            <WithContextAction
              menu={this.props.contextMenu?.(v._id)}
            >
            <span
              className={classNames('view', { active: this.props.active == v._id })}
              onClick={() => {
                this.props.onClickView(v._id);
              }}
              // onContextMenu={e => {
              //   e.preventDefault();
              //   showContextMenu(e, this.props.contextMenu(v._id));
              // }}
            >
              {v.icon && <Svg name={v.icon} />} {v.name}
            </span>
            </WithContextAction>
          );
        })}
      </InsertionCont>

      </>
    );
  }
}
