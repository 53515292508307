import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { EXPRESSION_QUERY } from '../etc/queryFuncs';
import { ExpressionEntryType, Conjuction, renderEntry } from '../etc/expressionQuery';
import { ObjectType } from '../types/ObjectRef';
import { AttributeType } from '../components/AttributeType';
import { EntityTypesCellType } from '../components/cells';
import { Cell } from '../components/Cell';

@component
export class AttributeWindow extends Component<{ window; }> {
  render() {
    const attr = db.attributeTypes.findById(this.props.window.id);
    return (
      <>
        {(attr.type == AttributeType.entities || attr.type == AttributeType.entity) && (
          <>
            {renderEntry(
              XObject.get(attr, 'query', [EXPRESSION_QUERY, {
                _id: 'root',
                type: ExpressionEntryType.group,
                conjunction: Conjuction.and,
                entries: [],
              }])[1], [{ type: ObjectType.attribute, id: attr._id }])}
          </>
        )}
        <ul>
          {attr.reversePropRules?.map?.((rule, i) => {
            const typesScopes = [
              {
                type: ObjectType.attribute,
                id: attr._id,
              }
            ];
            return (
              <li key={rule._id}>
                <button
                  onClick={() => {
                    attr.reversePropRules.splice(i, 1);
                  }}
                >-</button>
                <Cell
                  title="Match"
                  cell={new EntityTypesCellType({
                    scopes: typesScopes,
                  })}
                  get={() => {
                    return XObject.get(rule, 'match', []);
                  }}
                  set={value => {
                    rule.match = value;
                  }} />
                <div>Title: <PropertyField object={rule} property="title" /></div>
                <div>Type: <select
                  value={rule.viewType || ''}
                  onChange={e => {
                    rule.viewType = e.target.value;
                  }}
                >
                  <option />
                  <option value="28460994-2d99-5d47-9f30-c29c7fdfd37f">Line</option>
                  <option value="7aeb14b3-f80d-5273-a228-09b815ccff54">Block</option>
                </select>
                </div>

                {rule.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54' && (
                  <>

                    <div>
                      <input type="checkbox"
                        checked={rule.showEmpty}
                        onChange={e => {
                          rule.showEmpty = e.target.checked;
                        }} /> Show empty
                    </div>
                    {renderEntry(
                      XObject.get(rule, 'filter', [EXPRESSION_QUERY, {
                        _id: 'root',
                        type: ExpressionEntryType.group,
                        conjunction: Conjuction.and,
                        entries: [],
                      }])[1], [
                      ...(rule.match?.map?.(id => ({
                        type: ObjectType.type,
                        id,
                      })) || []),
                      {
                        type: ObjectType.attribute,
                        id: attr._id,
                      }
                    ])}
                  </>
                )}
              </li>
            );
          })}
        </ul>
        <button
          onClick={() => {
            XObject.push(attr, 'reversePropRules', XObject.obj({}));
          }}
        >+ Reverse Prop Rule</button>
      </>
    );
  }
}
