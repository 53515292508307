import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';


@component
export class SpacesWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <ul>
          {db.spaces.map(space => {
            return (
              <li key={space._id}>
                {space.name}
                <div>
                  <input type="checkbox" checked={space.archived} onChange={e => {
                    space.archived = e.target.checked;
                  }} /> Archived
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}
