import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { openWindow } from '../osHelpers';
import { component } from '../component2';
import { XInit, XObject } from '../XObject';
import { WindowType } from '../etc/WindowType';
import { SystemContext } from '../etc/SystemContext';
import { createEntity } from '../etc/createEntity';
import { NotionButton } from './AddButton';
import { createEntityFromQuery } from '../etc/queryFuncs';
import { ViewQueryish } from './ViewQuery';
import { ViewType } from '../types/ViewType';
import { entitiesInSpace } from '../etc/entitiesInSpace';
import { ObjectPageFrame } from './ObjectPageFrame';
import { ObjectType } from '../types/ObjectRef';


@component
export class SpaceEntities extends Component<{ space; state; pathId }> {
  static styles = styled.div`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    .top {
      .type {
        margin-right: 4px;
      }
      display: flex;
      align-items: center;
      ${NotionButton} {
        margin-left: auto;
      }
      margin-bottom: 8px;
    }
    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .queryName {
        font-size: 26px;
      font-weight: 600;
      display: block;
      line-height: 1.2;

      }

  `;

  static contextType = SystemContext;
  context: any;
  state = XInit(class {
    type;
  });
  render() {
    const state = this.props.state;
    const space = db.spaces.findById(this.props.space);
    const entities = entitiesInSpace(space);
    return (
      <ObjectPageFrame
        obj={{
          type: ObjectType.space,
          id: space._id,
        }}
      pathId={this.props.pathId}
        icon="box"
        path={<>
          <span>{space.name}</span>
        </>}
        right={null}
      >
        <span className="queryName">{space.name}</span>
        <ViewQueryish
          entities={entities.map(x => x._id)}
          state={state}
          showToolbar
          add={view => {
            const entity = {
              space: space._id,
              type: view.filter?.contentsType,
            };
            if (view.viewQuery) {
              createEntityFromQuery(view.viewQuery, entity);
            }
            if (view.filterQuery) {
              createEntityFromQuery(view.filterQuery, entity);
            }
            createEntity(entity, null);
          }}
          viewManager={{
            get() {
              return space.views || [];
            },
            addView() {
              XObject.push(space, 'views', XObject.obj({
                type: ViewType.list,
              }));
            },
            init() {
            },
            editView(id) {
              openWindow({
                type: WindowType.QueryView,
                space: space._id,
                view: id,
              });

            },
            deleteView(id) {
              space.views.splice(space.views.findIndex(x => x._id == id), 1);
            },
          }} />
      </ObjectPageFrame>
    );
  }
}
