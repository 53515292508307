
import { isElectron } from "./isElectron";
import { XInit } from '../XObject';

export const promptState = XInit(class {
  current = null
})

export const promptData = {
  cb: null,
}

export function showPrompt(message, defaultValue = null) {
  if (isElectron()) {
    promptState.current = {
      message,
    }

    return new Promise((resolve, reject) => {
      promptData.cb = resolve;
    });
  }
  else {
    return prompt(message);
  }
}
