import React, { Component } from 'react';
import { openWindow } from '../osHelpers';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { TWorkspace } from '../types/TWorkspace';
import { GraphEditor } from '../components/GraphEditor';


@component
export class GraphsWindow extends Component<{ window; }> {
  render() {
    const workspace = defaultWorkspace();
    const graphs: TWorkspace['graphs'] = XObject.get(workspace, 'graphs', []);
    return (
      <>
        <ul>
          {graphs.map((x, i) => {
            return (
              <li key={i}>
                <div>
                  <PropertyField object={x} property="name" />
                </div>
                <div>
                  <GraphEditor value={XObject.get(x, 'graph', {})} />
                </div>
                <button onClick={() => {
                  openWindow({
                    type: 'graph',
                    graph: x._id,
                  });
                }}>View</button>
              </li>
            );
          })}

          <li>
            <button onClick={() => {
              XObject.push(workspace, 'graphs', XObject.obj({
                name: 'New Graph',
              }));
            }}>+</button>
          </li>
        </ul>
      </>
    );
  }
}
