import React from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";

const Set = 'bef83c95-185d-5deb-b0b8-786fd94c6f76'

registerChangeAction(Set, {
  name: 'Set String',
});

@component
export class StringValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`
    color: #ca8f76;
  `;
  timerId
  render() {
    return (
      <>
        "<_Editor inline id={this.props.elId + this.props.tick} defaultContent={this.props.state.content} onChange={(_, v) => {
          clearTimeout(this.timerId);
          this.timerId = setTimeout(() => {
          if (v !== this.props.state.content && this.props.state.type?.[0] == ValueType.String) {
            trackChange(Set, {}, [
              [ UndoActions.modifiedValuePoint, this.props.state._id ],
            ]);
  
            this.props.state.content = v;
          }
        }, 1000);
        }} />"
      </>
    );
  }
}

export const registerStringType = () => {
  registerType(ValueType.String, {
    evaluate: (value, map) => {
      return value.content;
    },
    render: (value, map, state, renderType) => {
      return <span key={value._id} data-value-point={value._id}>{value.content}</span>;
    },
    editorComp: StringValuePoint,
  });
};