import { Svg } from './Svg';
import { component, css, styled } from '../component';
import { Component } from 'react';
import cx from 'classnames';

const hoverStyles = css`

`;
@component
export class NotionButton extends Component<{ dark?, className?, onClick?; onContextMenu?; text?; img?; colors? }> {
  static styles = styled.div`
    border-radius: 3px;
    /* padding: 0px 6px; */
    /* height: 34px; */
    /* display: flex; */
    /* align-items: center; */
    color: #969696;
    &:hover {
      background: #f3f3f3;
    }
    box-sizing: border-box;
    padding-left: 6px;
    padding-right: 6px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: #bababa;
      /* stroke: #bababa; */
    }

    &.dark {
      svg {
        /* fill: rgba(255,255,255,0.81); */
        fill: #797979;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.055);
      }
    }


    &.hasText {
      svg {
        margin-right: 9px;
      }
    }
  `;

  render(Container?) {
    return (
      <Container onContextMenu={this.props.onContextMenu} onClick={this.props.onClick} className={cx(this.props.className, { dark: this.props.dark, hasText: this.props.text })}>
        {this.props.img !== false && <Svg name={this.props.img || "plus"} colors={this.props.colors} />}
        {this.props.text}
      </Container>
    );
  }
}
