import { db } from '../db';

export function entityTypeName(id) {
  const parts = [];
  while (true) {
    const type = db.entityTypes.findById(id);
    if (type) {
      parts.push(type.name);
      id = type.parent;
      if (!id)
        break;  
    }
    else {
      break;
    }
  }

  return parts.reverse().join(' / ');
}
