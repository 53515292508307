import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Component } from 'react';
import { component, styled } from './component2';
import ReactFlow, { Controls,   applyNodeChanges, Node, addEdge, NodeProps, useStore, NodeResizer,
   ControlButton,
  applyEdgeChanges,
 } from 'reactflow';
import 'reactflow/dist/style.css';
import { X, XClone, XInit, XObject, x } from './XObject';
import cx from 'classnames';
import { EntityNameEditor } from './components/EntityNameEditor';
import { showContextMenu } from './helpers';
import { RichTextEditor } from './components/richTextEditor/RichTextEditor';
import { createEntity } from './etc/createEntity';
import { SystemContext } from './etc/SystemContext';
import { db } from './db';
import { CanvasObject } from './components/CanvasView';
import { Svg } from './components/Svg';

export const findNode = (id, nodes) => {
  for (const node of nodes) {
    if (node._id == id) {
      return node;
    }
    if (node.children) {
      const result = findNode(id, node.children);
      if (result) return result;
    }
  }
}

@component
class EntityNode extends Component<NodeProps> {
  static styles = styled.div`

    border: 1px solid #ccc;
    &.selected {
      background: #eee;
    }

    .drag-handle {
      position: absolute;
      top: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: red;
      cursor: grab;
    }
  `;
  render(Container?) {
    return (
      <Container className={cx({ selected: this.props.selected })}>
        <EntityNameEditor id={this.props.data.id} />
        <span className="drag-handle"
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Remove',
                onClick: () => {
                  console.log(this.props);
                }
              }
            ])
          }}
        >⋮</span>
      </Container>
    );
  }
}

const MindMapContext = React.createContext(null);

@component
class Content extends Component<{ node }> {
  render() {
    const node = this.props.node;
    let c;

    if (node.type == 'entity') {
      if (!db.entities.findById(node.entity)) {
        c = 'hello';
      }
      else c = (
        <>
          <EntityNameEditor id={node.entity} />
        </>
      )
    }
    else if (node.type == 'object') {
      c = (
        <div
          className="nowheel nokey"
          onMouseDownCapture={e => {
            // e.stopPropagation();
          }}
        >
          <CanvasObject object={{ ref: node.ref }} />
        </div>
      )
    }
    else {
      c = (
        <>
          <RichTextEditor
            setValue={value => node.content = value}
            value={() => node.content}
            onEntityChosen={entity => {
              node.entity = entity._id;
              node.type = 'entity';
            }}
            actions={[
              {
                label: 'New entity',
                action: () => {
                  const e = createEntity({
                    name: XClone(node.content),
                  }, null);
                  node.entity = e._id;
                  node.type = 'entity';
                },
              }
            ]}
          />
        </>
      )


    }

    return c;


  }
}

@component
class TestNode extends Component<NodeProps> {
  static styles = styled.div`
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
    padding: 6px;
    &.selected {
      /* background: #eee; */
    }

    position: relative;


    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    background: white;

    &:not(:hover) {
      .drag-handle {
        opacity: 0;
      }
    }

    .drag-handle {
      transition: opacity 0.2s;
      position: absolute;
      left: -16px;
      top: 4px;
      width: 10px;
      height: 20px;
      cursor: grab;

      svg {
        fill: #c1c1c1;
      }
    }

    &.entity {
      ${EntityNameEditor} {
        color: #87b397;
      }
    }
  `;

  static t = {
    content: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding: 6px;
    `,
  }

  static contextType = MindMapContext;
  context: any;

  render(Container?) {
    const { t } = TestNode;
    const node = this.context.getNode(this.props.id);
    if (!node) return null;

    const contextMenuActions = [];

    if (node.type == 'entity') {
      contextMenuActions.push({
        text: 'View',
        onClick: () => {
          this.context.navigate({
            type: 'entity',
            id: node.entity,
          })
        }
      })
    }


    return (
      <Container
        className={cx(node.type, {
          selected: this.props.selected,

        })}
        style={!node.autoSize ? {
          width: this.props.data.width,
          height: this.props.data.height,
        } : {}}
      >
        <div className="drag-handle"
        onContextMenu={e => {
          e.preventDefault();
          e.stopPropagation();
          showContextMenu(e, [
            {
              text: 'Clear dimensions',
              onClick: () => {
                node.autoSize = true;
                delete node.width;
                delete node.height;
              }
            },

            ...contextMenuActions
          ])
        }}

        >
          <Svg name="grip" />
        </div>
        {this.props.selected && <NodeResizer />}
        {node.autoSize && (
          <>
            <Content node={node} />
          </>
        )}
        {!node.autoSize && (
          <t.content>
            <Content node={node} />
          </t.content>
        )}
      </Container>
    );
  }
}

const nodeTypes = {
  entity: EntityNode,
  test: TestNode,

}


@component
export class MindMap extends Component<{ window; onSelect? }> {
  static debounce = false;
  state = XInit(class {
    // nodes = [

    //   XObject.obj({
    //     content: 'Hello2',
    //     position: { x: 250, y: 150 },
    //   }),
    // ]
    // edges = [];
  })

  localState


  timerId
  constructor(props) {
    super(props);

    const state: {
      nodes: any[],
      edges: any[],
    } = XObject.get(this.props.window, 'state2', {
      nodes: [],
      edges: [],
    });

    this.localState = XClone(state);


    const observeExternal = () => {
      XObject.observe(state, () => {
        console.log('hello');
        this.localState = XClone(state);
        observeLocal();

        this.forceUpdate();

      })
    }

    observeExternal();


    let aaa;
    const observeLocal = () => {
      XObject.observe(this.localState, () => {
        if (aaa) clearTimeout(aaa);
        aaa = setTimeout(() => {
          this.props.window.state2 = XClone(this.localState);
          observeExternal();
        }, 400);
      })  
    }

    observeLocal();


  }

  static contextType = SystemContext;
  context: any;

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .react-flow__pane, .react-flow__node {
      cursor: default;
    }
  `;

  reactFlow
  render(Container?) {
    const state = this.localState;
    const allNodes = [];

    const _findNode = (id, nodes=state.nodes) => findNode(id, nodes);

    const addNodes = (nodes, parent?) => {
      for (const node of nodes) {
        allNodes.push({
          id: node._id,
          type: 'test',
          dragHandle: '.drag-handle',
          position: node.position,
          positionAbsolute: node.positionAbsolute,
          selected: node.selected,
          dragging: node.dragging,
          data: {
            content: node.content,
            width: node.width,
            height: node.height,
          },
          ...(node.width ? {
            width: node.width,
            height: node.height,
          } : {}),
          ...(parent ? {
            parentNode: parent._id,              
          } : {})
        });
        if (node.children) addNodes(node.children, node);
      }
    }

    addNodes(state.nodes);



    const isDescendantOf = (node: string, parent: string) => {
      if (_findNode(node, _findNode(parent).children || [])) return true;
    }

    const nodeDepth = (node: string, depth=0, nodes=state.nodes) => {
      for (const n of nodes) {
        if (n._id == node) return depth;
        if (n.children) {
          const result = nodeDepth(node, depth + 1, n.children);
          if (!_.isNil(result)) {
            return result;
          }
        }
      }
    }

    const findParent = (node: string, nodes=state.nodes) => {
      for (const n of nodes) {
        if (n.children) {
          if (n.children.find(c => c._id == node)) {
            return n;
          }
          const result = findParent(node, n.children);
          if (result) return result;
        }
      }
    }

    const absolutePosition = (node: string) => {
      const n = _findNode(node);
      if (n) {
        const parent = findParent(node);
        if (parent) {
          const absPos = absolutePosition(parent._id);
          return {
            x: n.position.x + absPos.x,
            y: n.position.y + absPos.y,
          }
        }
        return n.position;
      }
    }

    const addNode = (node, nodeAbsPos, to?: string) => {
      if (to) {
        const toNode = _findNode(to);
        if (toNode) {
          XObject.push(toNode, 'children', node);
          const toNodeAbsPos = absolutePosition(to);
          node.position = {
            x: nodeAbsPos.x - toNodeAbsPos.x,
            y: nodeAbsPos.y - toNodeAbsPos.y,
          }
        }
        else {
          throw new Error(`Node ${to} not found`);
        }
      }
      else {
        XObject.push(state, 'nodes', node);
        node.position = nodeAbsPos;
      }
    }

    const moveNode = (nodeId: string, to: string) => {
      const node = _findNode(nodeId);
      const nodeAbsPos = absolutePosition(nodeId);

      const parent = findParent(nodeId);
      if (parent) {
        parent.children.splice(parent.children.findIndex(c => c._id == nodeId), 1);
      }
      else {
        state.nodes.splice(state.nodes.findIndex(c => c._id == nodeId), 1);
      }

      if (to) {
        const toNode = _findNode(to);
        if (toNode) {
          XObject.push(toNode, 'children', node);
          const toNodeAbsPos = absolutePosition(to);
          node.position = {
            x: nodeAbsPos.x - toNodeAbsPos.x,
            y: nodeAbsPos.y - toNodeAbsPos.y,
          }
        }
        else {
          throw new Error(`Node ${to} not found`);
        }
      }
      else {
        XObject.push(state, 'nodes', node);
        node.position = nodeAbsPos;
      }

      this.forceUpdate();
    }
    
    return (
      <Container
        onContextMenu={event => {
          event.preventDefault();
          showContextMenu(event, [
            {
              text: 'Add node',
              onClick: () => {
                const reactFlowBounds = (ReactDOM.findDOMNode(this) as Element).getBoundingClientRect();
                const position = this.reactFlow.project({
                  x: event.clientX - reactFlowBounds.left,
                  y: event.clientY - reactFlowBounds.top,
                });

                const intersecting = this.reactFlow.getIntersectingNodes({
                  x: position.x,
                  y: position.y,
                  width: 1,
                  height: 1,
                });

                let depth, topNode;
                for (const n of intersecting) {
                  const d = nodeDepth(n.id);
                  if (_.isNil(depth) || d > depth) {
                    depth = d;
                    topNode = n;
                  }
                }

                const newNode = XObject.obj({
                  content: 'Hello',
                  autoSize: true,
                });

                addNode(newNode, position, topNode?.id);
              }
            }
          ]);
        }}
      >
        <MindMapContext.Provider value={{
          getNode: (id) => {
            const node = _findNode(id);
            return node;
          },
          navigate: config => this.context.navigate(config),
        }}>
          <ReactFlow
            onInit={instance => {
              this.reactFlow = instance;
              if (this.props.window.viewport) {
                this.reactFlow.setViewport(x(this.props.window.viewport));
              }
            }}
            minZoom={0.1}
            panOnScroll
            snapGrid={[5, 5]}
            snapToGrid
            nodeTypes={nodeTypes}
            nodes={allNodes}
            edges={x(state.edges)}
            onMoveEnd={e => {
              this.props.window.viewport = this.reactFlow.getViewport();
            }}
            onConnect={(edge) => {
              state.edges = addEdge(edge, x(state.edges));
            }}
            onEdgesChange={changes => {
              state.edges = applyEdgeChanges(changes, x(state.edges));
            }}
            onNodeDrag={(event, node) => {
              const intersecting = this.reactFlow.getIntersectingNodes(node).filter(n => {
                return !isDescendantOf(n.id, node.id);
              });

              if (node.parentNode && !intersecting.find(n => n.id == node.parentNode)) {
                // console.log('moved off');
              }
            }}
            onNodeDragStop={(event, node) => {
              const intersecting = this.reactFlow.getIntersectingNodes(node).filter(n => {
                return !isDescendantOf(n.id, node.id);
              });

              let depth, topNode;
              for (const n of intersecting) {
                const d = nodeDepth(n.id);
                if (_.isNil(depth) || d > depth) {
                  depth = d;
                  topNode = n;
                }
              }

              if (topNode) {
                if (node.parentNode) {
                  if (topNode.id != node.parentNode) {
                    console.log('moved to', topNode.id);

                    const parentNode = _findNode(node.parentNode);
                    moveNode(node.id, topNode.id);

                  }
                }
                else {
                  console.log('moved to', topNode.id);
                  moveNode(node.id, topNode.id);
                }
              }
              else if (node.parentNode) {
                console.log('moved to global');
                moveNode(node.id, null);
              }

            }}
            onNodesChange={changes => {
              for (const change of changes) {
                if (change.type == 'position') {
                  const node = _findNode(change.id);
                  if (change.position) {
                    node.position = X(change.position);
                  }
                  if (change.positionAbsolute) {
                    node.positionAbsolute = X(change.positionAbsolute);
                  }
                  if ('dragging' in change) {
                    node.dragging = change.dragging;
                  }
                  else {
                    console.log('no position', change);
                  }
                }
                else if (change.type == 'select') {
                  const node = _findNode(change.id);
                  node.selected = change.selected;

                  if (change.selected) {
                    this.props.onSelect?.(node);
                    if (node.type == 'entity') {
                      // this.context.navigate({
                      //   type: 'entity',
                      //   id: node.entity,
                      // })
                    }
                  }
                }
                else if (change.type == 'dimensions') {
                  const node = _findNode(change.id);
                  console.log(change);
                  if (change.resizing) {
                    node.autoSize = false;
                  }
                  if (change.dimensions) {
                    node.width = change.dimensions.width;
                    node.height = change.dimensions.height;
                  }
                }
                else if (change.type == 'remove') {
                  const node = _findNode(change.id);
                  if (node) {
                    const parentNode = findParent(node._id);
                    if (parentNode) {
                      parentNode.children.splice(parentNode.children.findIndex(c => c._id == node._id), 1);
                    }
                    else {
                      state.nodes.splice(state.nodes.findIndex(c => c._id == node._id), 1);
                    }
                  }
                }
                else {
                  console.log('unknown change', change);
                }
              }
              // console.log(changes);
              // state.nodes = applyNodeChanges(changes, x(state.nodes));
            }}
            onNodesDelete={nodes => {

              console.log(nodes);
            }}

            onDragOver={e => {
              e.preventDefault();
              e.dataTransfer.dropEffect = 'move';
          
            }}
            onDrop={event => {
              event.preventDefault();
              const reactFlowBounds = (ReactDOM.findDOMNode(this) as Element).getBoundingClientRect();
              const position = this.reactFlow.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
              });

              const dropData = event.dataTransfer.getData('text/plain');

              let newNode;
              try {
                const ref = JSON.parse(dropData);
                console.log(ref);
                newNode = XObject.obj({
                  type: 'object',
                  ref: ref,
                  width: 400,
                  height: 400,
                });

              }
              catch (e) {
                newNode = XObject.obj({
                  type: 'entity',
                  entity: dropData,
                  autoSize: true,
                });
              }

              if (newNode) {
                newNode.id = newNode._id;
                newNode.position = position;
                state.nodes.push(newNode);
                this.forceUpdate();
              }
            }}
          >
            <Controls>
              <ControlButton
                onClick={() => {
                  state.nodes.push(XObject.obj({
                    position: { x: 0, y: 0 },
                    content: 'Hello',
                    autoSize: true,
                  }));
                }}
              >
                +
              </ControlButton>
            </Controls>
          </ReactFlow>
        </MindMapContext.Provider>
      </Container>
    )
  }
}
