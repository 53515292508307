import { Component } from 'react';
import { component } from '../component2';


@component
export class TestWindow extends Component<{ window; }> {
  render() {
    return '';
  }
}
