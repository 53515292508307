import axios from 'axios';
import { Component } from 'react';
import { component, styled } from '../component2';
import config from '../config';
import Sugar from 'sugar';
import { db } from '../db';
import { X, XObject } from '../XObject';
import { renderLinearIssuesLinear, renderLinearIssuesTable } from '../etc/renderLinearIssues';
import { linearData } from '../etc/linearData';

Sugar.extend();

function daysSince(date: Date) {
  const diff = new Date().getTime() - date.getTime();
  return Math.floor(diff / 1000 / 60 / 60 / 24);
}

export function displayDateString(str: string) {
  const date = (Sugar.Date.create(str) as any)

  return `${date.format('{yyyy}-{MM}-{dd}')} (${daysSince(date)})`;
}


@component
export class LinearToolbar extends Component<{issues, state: {
  filter
  groupByState
  sort
} }> {
  render() {
    const data = linearData();
    if (!data) return null;
    const { issues, users, teams, states } = data;


    const filter = XObject.get(this.props.state, 'filter', {});

    const teamIssues = this.props.issues;
    const teamStates = {};
    for (const issue of teamIssues) {
      teamStates[issue.state] = true;
    }
    const stateIds = Object.keys(teamStates);

    let teamIds:any = {};
    for (const issue of issues) {
      teamIds[issue.team] = true;
    }
    teamIds = Object.keys(teamIds);


    return (
      <>
                  {stateIds.map(x => {
              const state = data.states.find(s => s.id == x);
              return (
                <span key={x} className="state"
                style={{ color: state.color }}
                  onClick={() => {
                    filter[x] = !filter[x];
                  }}
                ><input type="checkbox" checked={this.props.state?.filter?.[x]} /> {state.name}</span>
              )
            })}

            <span className="state"
              onClick={() => {
                filter.noEntity = !filter.noEntity;
              }}
            >
              <input type="checkbox" checked={this.props.state?.filter?.noEntity} /> No Entity
            </span>

            <span className="state"
              onClick={() => {
                filter.noParent = !filter.noParent;
              }}
            >
              <input type="checkbox" checked={this.props.state?.filter?.noParent} /> No Parent
            </span>

            <span>
              <input type="checkbox" checked={this.props.state.groupByState} onClick={() => {  
                this.props.state.groupByState = !this.props.state.groupByState;
              }} /> Group by state
            </span>


            <select
              value={this.props.state.sort}
              onChange={(e) => {
                this.props.state.sort = e.target.value;
              }}
            >
              <option value="">Sort</option>
              <option value="updatedAt">Updated At</option>
              <option value="createdAt">Created At</option>
              <option value="priority">Priority</option>
            </select>

      </>
    )
  }
}

export function filterLinearIssues(issues, filter) {
  return issues.filter(x => {
    if (filter.noEntity) {
      if (db.entities.find(e => e.linearId == x.id)) return false;
    }

    if (filter.noParent) {
      const entity = db.entities.find(e => e.linearId == x.id);
      if (entity) {
        const parentId = db.edges.find(e => e.entities[1] == entity._id && e.directed)?.entities?.[0];
        if (parentId) return false;
      }
    }
    return filter?.[x.state];
  })
}

export function defaultState(states, teamIssues) {
  const teamStates = {};
  for (const issue of teamIssues) {
    teamStates[issue.state] = true;
  }
  const stateIds = Object.keys(teamStates);

    const filter = {};
    for (const stateId of stateIds) {
      const type = states.find(s => s.id == stateId).type;
      if (!['canceled', 'completed'].includes(type)) {
        filter[stateId] = true;
      }
    }
    
    return filter;

}

@component
export class LinearWindow extends Component<{ window; }> {
  static styles = styled.div`
    .states {
      .state {
        margin-right: 8px;
      }
    }
    &.hasActiveIssue {
      > .left {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50%;
        overflow: auto;
        padding: 8px;
        box-sizing: border-box;
      }

      > .right {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50%;
        overflow: auto;
      }
    }

    .active {
      font-weight: bold;
    }
  `;

  constructor(props) {
    super(props);
  }

  render(Container?) {
    const data = linearData();
    if (!data) return null;
    const { issues, users, teams, states } = data;


    const teamIssues = issues.filter(x => x.team == this.props.window.team).clone();
    const teamStates = {};
    for (const issue of teamIssues) {
      teamStates[issue.state] = true;
    }
    const stateIds = Object.keys(teamStates);

    // sort by updated at
    teamIssues.sort((a, b) => {
      return Sugar.Date.create(a.updatedAt) > Sugar.Date.create(b.updatedAt) ? -1 : 1;
    });

    const activeIssue = issues.find(x => x.id == this.props.window.issue);




    // sort stateIds by position
    stateIds.sort((a, b) => {
      const stateA = data.states.find(s => s.id == a);
      const stateB = data.states.find(s => s.id == b);
      return stateA.position > stateB.position ? 1 : -1;
    });
    

    if (!this.props.window.sort) {
      this.props.window.sort = 'createdAt';
    }

    const filter = XObject.get(this.props.window, 'filter', {});


    

    let teamIds:any = {};
    for (const issue of issues) {
      teamIds[issue.team] = true;
    }
    teamIds = Object.keys(teamIds);

    return (
      <Container className={activeIssue ? 'hasActiveIssue' : ''}>
        <div className="left">
          {/* <div className="states">
            {stateIds.map(x => {
              const state = data.states.find(s => s.id == x);
              return (
                <span key={x} className="state"
                style={{ color: state.color }}
                  onClick={() => {
                    filter[x] = !filter[x];
                  }}
                ><input type="checkbox" checked={this.props.window?.filter?.[x]} /> {state.name}</span>
              )
            })}

            <span className="state"
              onClick={() => {
                filter.noEntity = !filter.noEntity;
              }}
            >
              <input type="checkbox" checked={this.props.window?.filter?.noEntity} /> No Entity
            </span>

            <span className="state"
              onClick={() => {
                filter.noParent = !filter.noParent;
              }}
            >
              <input type="checkbox" checked={this.props.window?.filter?.noParent} /> No Parent
            </span>

            <span>
              <input type="checkbox" checked={this.props.window.groupByState} onClick={() => {  
                this.props.window.groupByState = !this.props.window.groupByState;
              }} /> Group by state
            </span>

            <select
              value={this.props.window.team}
              onChange={(e) => {
                this.props.window.team = e.target.value;

                const teamIssues = issues.filter(x => x.team == this.props.window.team).clone();
                const teamStates = {};
                for (const issue of teamIssues) {
                  teamStates[issue.state] = true;
                }
                const stateIds = Object.keys(teamStates);
            
                  this.props.window.filter = X({});
                  const filter = {};
                  for (const stateId of stateIds) {
                    const type = states.find(s => s.id == stateId).type;
                    if (!['canceled', 'completed'].includes(type)) {
                      filter[stateId] = true;
                    }
                  }
                  this.props.window.filter = X(filter);
  


              }}

            >
              <option value="">Team</option>
              {teamIds.map(x => {
                const team = teams.find(t => t.id == x);
                return <option key={x} value={x}>{team.name}</option>
              })}
            </select>

            <select
              value={this.props.window.sort}
              onChange={(e) => {
                this.props.window.sort = e.target.value;
              }}
            >
              <option value="">Sort</option>
              <option value="updatedAt">Updated At</option>
              <option value="createdAt">Created At</option>
              <option value="priority">Priority</option>
            </select>

          </div> */}

            <select
              value={this.props.window.team}
              onChange={(e) => {
                this.props.window.team = e.target.value;
                const teamIssues = issues.filter(x => x.team == this.props.window.team).clone();
                this.props.window.filter = X(defaultState(states, teamIssues));
              }}
            >
            <option value="">Team</option>
              {teamIds.map(x => {
                const team = teams.find(t => t.id == x);
                return <option key={x} value={x}>{team.name}</option>
              })}
            </select>

          <LinearToolbar issues={teamIssues} state={this.props.window} />

          {renderLinearIssuesLinear({
            issues: filterLinearIssues(teamIssues, this.props.window.filter),
            data,
            active: this.props.window.issue,
            onClick: (id) => {
              // if (this.props.window.issue == id) delete this.props.window.issue;
              // else (this.props.window.issue = id);
            },
            groupByState: this.props.window.groupByState,
            sort: this.props.window.sort,
          })}
        </div>
        {activeIssue && <div className="right">
          <div>
            <h3>{activeIssue.title}</h3>
            <div>{activeIssue.description}</div>
          </div>
        </div>}
      </Container>
    )
  }
}
