import { x } from "../../XObject";
import { styled } from "../../component";
import { ValueType } from "../ValueType";
import { RenderType, getValuePoint, mapStructure, render } from "../main";
import { registerSignalHandler } from "../signalsRegistry";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { resolveDataBinding } from "./resolveBinding";
import { getProperty } from "../valuePoints/StructureValuePoint";
import { pushValue } from "../valuePoints/ArrayValuePoint";

export const $EntityNameBinding = typeRegistry.registerType({
  $: 'f1efabf3-d7c2-56ca-8cb9-9a08ed255ff9',
  Entity: 'ca55a3d9-55e9-51a8-a5a5-9a83193a0e51',
}, ids => ({
  _id: ids.$,
  name: 'Entity Name Binding',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    }
  ]
}));

const $TitleEditor = typeRegistry.registerType({
  $: '356b78e8-4aab-552b-acf9-d7a4da9b946a',
  DataBinding: '675cc787-19ad-566e-9500-19a1d73213e8',
}, ids => ({
  _id: ids.$,
  name: 'Title Editor',
  definition: [
    {
      id: ids.DataBinding,
      name: 'Data Binding',
      type: [],
      property: 'dataBinding',
    },

  ]
}));

structRenderers[$TitleEditor.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  const dataBinding = resolveDataBinding(mapped.dataBinding, map);
  return (
    <>
      <input data-value-point={value._id} type="text" defaultValue={dataBinding.get()} onChange={e => {
        dataBinding.set(e.target.value);
      }} />
      {/* <button
        onClick={() => {
          const evaluated = evaluate(mapped.dataBinding, map);

          console.log(x(mapped.dataBinding), map, evaluated, dataBinding);
        }}
      >.</button> */}
    </>
  )
}


export const $EntityTemplate = typeRegistry.registerType({
  $: 'fe395e3c-3a0b-57fe-8964-8daf988c8927',
  Content: '70699e31-cb0b-517f-9b7d-c5cacac39651',
  Entity: 'd5d7fd26-e901-58ac-b572-b9d2ca1d9123',
}, ids => ({
  _id: ids.$,
  name: 'Entity Template',
  definition: [
    {
      scope: [
        {
          id: ids.Entity,
          name: '%Entity',
          type: [],
        }
      ],

      id: ids.Content,
      name: 'Content',
      type: [ValueType.Array, []],
      property: 'content',
    }
  ]
}));

const Wrapper = styled.div`
  padding: 8px;
`;

structRenderers[$EntityTemplate.$] = (value, map, state) => {
  const mapped = mapStructure(value);

  return (
    <Wrapper data-value-point={value._id}>
      {/* {map[$EntityTemplate.Entity]} */}
      {/* <PropertyField object={state} property="entity" /> */}
      <div>
        {mapped.content.content.map((c, i) => {
          return (
            <div key={c._id} style={{ marginBottom: '8px' }}>
              {render(c, map/* {
                [$EntityTemplate.Entity]: state.entity,
              } */, RenderType.full, state)}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

export const $RowView = typeRegistry.registerType({
  $: '7070c3f6-e973-5a84-a940-cbdd34e0aeb0',
  Elements: {
    $: 'f16bf385-e7c5-598b-9896-8c691026cd9c',
    $$: 'a9311762-ffe2-5a39-8684-dfa0504054bd',
    Name: '800265bb-7147-5aa6-bbd3-c64501d545d0',
    Content: 'de2374bb-978b-5cb0-a94e-fa941d211953',
  },
  RowElements: {
    $: 'abdf1a73-5245-5537-9483-e1693d98c9ee',
    $$: 'd0ae1b76-c673-5d6d-b6a3-0d52c59de94e',
    Name: '48207ce3-0a9d-50b3-8491-acdee8a65476',
    Content: 'e22622f6-c7ee-58a7-a09e-06ead9120a4c',
  },
  ShowCreator: '0f6bf727-a207-5773-a726-e3885b261934',
  ShowTimestamp: 'bd324c11-1e1a-5318-8eed-01437ac71f60',
}, ids => ({
  _id: ids.$,
  name: 'Row View',
  definition: [
    {
      scope: [
        {
          id: $EntityTemplate.Entity,
          name: '%Entity',
          type: [],
        }
      ],

      id: ids.Elements.$,
      name: 'Elements',
      type: [ValueType.Array, [ValueType.Structure, ids.Elements.$$, [
        {
          id: ids.Elements.Name,
          name: 'Name',
          type: [],
          property: 'name',
        },
        {
          id: ids.Elements.Content,
          name: 'Content',
          type: [],
          property: 'content',
        },
      ]]],
      property: 'elements',
    },
    {
      scope: [
        {
          id: $EntityTemplate.Entity,
          name: '%Entity',
          type: [],
        }
      ],

      id: ids.RowElements.$,
      name: 'Row Elements',
      type: [ValueType.Array, [ValueType.Structure, ids.RowElements.$$, [
        {
          id: ids.RowElements.Name,
          name: 'Name',
          type: [],
          property: 'name',
        },
        {
          id: ids.RowElements.Content,
          name: 'Content',
          type: [],
          property: 'content',
        },
      ]]],
      property: 'rowElements',
    },
    {
      id: ids.ShowCreator,
      name: 'Show Creator',
      type: [],
      property: 'showCreator',
    },
    {
      id: ids.ShowTimestamp,
      name: 'Show Timestamp',
      type: [],
      property: 'showTimestamp',
    },
  ]
}));

export const $EntityTemplateSupplier = typeRegistry.registerType({
  $: 'b830e2b2-e97e-5837-9df6-2c0e998f8446',
  PathGraph: '77520065-7c93-523b-b364-981e31df1298',
  BlockTypes: '63606c80-d6e0-54ef-a4da-27c0bb6d08a6',
  GlobalSets: {
    $: '0d6a0570-3e32-57b1-8a96-c6c028a9e8f0',
    $$: 'ca7c2623-2072-5973-b1a1-3051bc8eb99c',
    Name: '1447dfb4-b346-5866-8fa6-a33a65914083',
    Query: '4dc4e0d0-643c-5e8d-94f5-7beb9468b0a2',
  },
  Matches: {
    $: 'c535ce8a-423a-59f8-ab91-849a0ac0dbfd',
    $$: '3f00ae6a-0063-50a7-bf5b-ebe3caaf2c05',
    Match: '5b18066e-f53d-5873-8bef-4b6d12522521',
    Template: 'e5408d57-9289-5500-a8b7-901866c214c3',
    Name: '9a18e888-b16e-5308-a345-cf23ce4193b7',
    Attributes: '11adeb77-8a4f-5dc6-b19b-31c1d0c09b27',
    Queries: {
      $: 'd0eb75d6-b5fd-59be-9021-5a466d0ea29d',
      $$: 'ef5d37ba-7681-5076-9fe6-76db5b18870b',
      Query: '99423658-18b3-5a90-9b60-537d6299e67c',
      Inline: 'e733ae61-5bee-5491-9d2c-448cefdc0ae2',
    },
    States: 'de460fd4-a291-5c06-91be-f4de42210eda',
    TitleAttribute: 'e75821c1-7b5a-5ef4-b128-be70e364fb19',
    Elements: {
      $: '818455c0-e9ea-5798-844d-d2caea4322aa',
      $$: '99b2c50f-aa1c-5d99-bb0a-e3bf3055bc1d',
      Name: '7550ac75-ab27-5f6a-a14b-3867216a0e30',
      Content: '8dbbb79c-d38b-5fe1-972e-a6ea83bd5701',
    },
    RowElements: {
      $: '071b6f34-3fe4-526f-9839-0ca19dbd4510',
      $$: '82332126-d993-5a97-a1be-430e83276411',
      Name: '19f2763d-d74a-531f-a2d5-0ae06feabd09',
      Content: '0dfadfa7-8fec-5269-b8c2-92f31fb6b3f6',
    },
    ShowCreator: '9b2a92dc-0309-5834-a159-850e7aa02d1a',
    ShowTimestamp: 'f6a9d503-e17a-5ed4-ad3a-85722df58226',
    Sets: {
      $: 'ad6450dd-ff39-5913-99b2-681878757b77',
      $$: 'bc7dc7f4-af2e-58da-9f60-c1ab455b0fd3',
      Name: '89e4d684-c599-5a92-9fb6-c48b86521269',
      Query: 'a3969bcb-7b99-5bf4-a28c-f2c182dbe2ca',
      Creator: 'a2fe7871-b3a3-5338-aa0e-016673fb53a4',
    },
    Actions: {
      $: '48b57d4b-2b72-524a-bac9-6055bff50233',
      $$: 'a2e01bd8-d898-5cc0-80ea-db0bf49bb6a0',
      Name: 'cef775f9-955c-5667-8527-6663743ed954',
      Action: 'dc59addb-c2c7-5593-9278-1bb336bea8be',
    },
    Views: {
      $: '0d75b95f-d8e8-5165-a86c-7408ad10fd3a',
      $$: '7d593200-335b-56a9-84a2-9b6695b7bbce',
      Name: '64802484-6c35-562d-8c16-d8c337aa5dde',
      Content: '38c7da50-8165-56dd-91a4-219fd95983de',
      Fullscreen: 'e66a64c7-c0c2-58f7-bf3f-f921591b7ed8',
    }
  }
}, ids => ({
  _id: ids.$,
  name: 'Entity Schema Supplier',
  definition: [
    {
      id: ids.PathGraph,
      name: 'Path Graph',
      type: [],
      property: 'pathGraph',
    },
    {
      id: ids.BlockTypes,
      name: 'Block Types',
      type: [ValueType.Array, []],
      property: 'blockTypes',
    },
    {
      id: ids.Matches.$,
      name: 'Matches',
      type: [ValueType.Array, [ValueType.Structure, ids.Matches.$$, [
        {
          id: ids.Matches.Name,
          name: 'Name',
          type: [],
          property: 'name',
        },
        {
          id: ids.Matches.TitleAttribute,
          name: 'Title Attribute',
          type: [],
          property: 'titleAttribute',
        },
        {
          id: ids.Matches.Attributes,
          name: 'Attributes',
          type: [],
          property: 'attributes',
        },
        {
          id: ids.Matches.Queries.$,
          name: 'Queries',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.Queries.$$, [
            {
              id: ids.Matches.Queries.Query,
              name: 'Query',
              type: [],
              property: 'query',
            },
            {
              id: ids.Matches.Queries.Inline,
              name: 'Inline',
              type: [],
              property: 'inline',
            },
          ]]],
          property: 'queries',
        },
        {
          id: ids.Matches.States,
          name: 'States',
          type: [],
          property: 'states',
        },
        {
          id: ids.Matches.Match,
          name: 'Match',
          type: [],
          property: 'match',
        },
        {
          scope: [
            {
              id: $EntityTemplate.Entity,
              name: '%Entity',
              type: [],
            }
          ],
    
          id: ids.Matches.Template,
          name: 'Template',
          type: [],
          property: 'template',
        },
        {
          scope: [
            {
              id: $EntityTemplate.Entity,
              name: '%Entity',
            }
          ],
          id: ids.Matches.Views.$,
          name: 'Views',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.Views.$$, [
            {
              id: ids.Matches.Views.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              id: ids.Matches.Views.Content,
              name: 'Content',
              type: [],
              property: 'content',
            },
            {
              id: ids.Matches.Views.Fullscreen,
              name: 'Fullscreen',
              type: [],
              property: 'fullscreen',
            }
          ]]],
          property: 'views',
        },
        {
          scope: [
            {
              id: $EntityTemplate.Entity,
              name: '%Entity',
              type: [],
            }
          ],

          id: ids.Matches.Elements.$,
          name: 'Elements',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.Elements.$$, [
            {
              id: ids.Matches.Elements.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              id: ids.Matches.Elements.Content,
              name: 'Content',
              type: [],
              property: 'content',
            },
          ]]],
          property: 'elements',
        },
        {
          scope: [
            {
              id: $EntityTemplate.Entity,
              name: '%Entity',
              type: [],
            }
          ],

          id: ids.Matches.RowElements.$,
          name: 'Row Elements',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.RowElements.$$, [
            {
              id: ids.Matches.RowElements.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              id: ids.Matches.RowElements.Content,
              name: 'Content',
              type: [],
              property: 'content',
            },
          ]]],
          property: 'rowElements',
        },
        {
          id: ids.Matches.ShowCreator,
          name: 'Show Creator',
          type: [],
          property: 'showCreator',
        },
        {
          id: ids.Matches.ShowTimestamp,
          name: 'Show Timestamp',
          type: [],
          property: 'showTimestamp',
        },
        {
          id: ids.Matches.Sets.$,
          name: 'Sets',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.Sets.$$, [
            {
              id: ids.Matches.Sets.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              scope: [
                {
                  id: $EntityTemplate.Entity,
                  name: '%Entity',
                  type: [],
                }
              ],
              id: ids.Matches.Sets.Query,
              name: 'Query',
              type: [],
              property: 'query',
            },
            {
              scope: [
                {
                  id: $EntityTemplate.Entity,
                  name: '%Entity',
                  type: [],
                }
              ],
              id: ids.Matches.Sets.Creator,
              name: 'Creator',
              type: [],
              property: 'creator',
            },
          ]]],
          property: 'sets',
        },
        {
          id: ids.Matches.Actions.$,
          name: 'Actions',
          type: [ValueType.Array, [ValueType.Structure, ids.Matches.Actions.$$, [
            {
              id: ids.Matches.Actions.Name,
              name: 'Name',
              type: [],
              property: 'name',
            },
            {
              scope: [
                {
                  id: $EntityTemplate.Entity,
                  name: '%Entity',
                  type: [],
                }
              ],
              id: ids.Matches.Actions.Action,
              name: 'Action',
              type: [],
              property: 'action',
            },
          ]]],
          property: 'actions',
        }
      ]]],
      property: 'matches',
    },
    {
      id: ids.GlobalSets.$,
      name: 'Global Sets',
      type: [ValueType.Array, [ValueType.Structure, ids.GlobalSets.$$, [
        {
          id: ids.GlobalSets.Name,
          name: 'Name',
          type: [],
          property: 'name',
        },
        {
          scope: [
            {
              id: $EntityTemplate.Entity,
              name: '%Entity',
              type: [],
            }
          ],
          id: ids.GlobalSets.Query,
          name: 'Query',
          type: [],
          property: 'query',
        },
      ]]],
      property: 'globalSets',
    }
  ]
}));

export const ADD_ATTRIBUTE_SIGNAL = '2f400f5d-2443-5de8-a3c3-1eba3cc4e9a0';
registerSignalHandler([ValueType.Structure, $EntityTemplateSupplier.Matches.$$], ADD_ATTRIBUTE_SIGNAL, (value, attr) => {
  console.log('add attribute', x(value), attr);
  const attributes = getProperty(getValuePoint(value._id), $EntityTemplateSupplier.Matches.Attributes, {
    type: [ValueType.EntityAttributes],
    content: {},
  });
  setTimeout(() => {
    attributes.content[attr] = true;
  }, 500);
});

export const REMOVE_ATTRIBUTE_SIGNAL = '018912b6-39fd-5ada-bc48-fbd46d815d1c';
registerSignalHandler([ValueType.Structure, $EntityTemplateSupplier.Matches.$$], REMOVE_ATTRIBUTE_SIGNAL, (value, attr) => {
  const attributes = getProperty(getValuePoint(value._id), $EntityTemplateSupplier.Matches.Attributes, {
    type: [ValueType.EntityAttributes],
    content: {},
  });
  setTimeout(() => {
    delete attributes.content[attr];
  }, 500);
});


export const ADD_MATCH_SIGNAL = 'f9b0cb36-aa81-5d45-b409-a37dc3a7457d';
registerSignalHandler([ValueType.Structure, $EntityTemplateSupplier.$], ADD_MATCH_SIGNAL, (value, type) => {
  
  return new Promise(resolve => {
    const matches = getProperty(getValuePoint(value._id), $EntityTemplateSupplier.Matches.$, {
      type: [ValueType.Array, [ValueType.Structure, $EntityTemplateSupplier.Matches.$$]],
      content: [],
    });
  
    const newValue = pushValue(matches, {
      type: [ValueType.Structure, $EntityTemplateSupplier.Matches.$$],
      content: [],
    });
  
    setTimeout(() => {
      const match = getProperty(newValue, $EntityTemplateSupplier.Matches.Match, {
        type: [ValueType.EntityType],
      });
      setTimeout(() => {
        match.content = type;
        resolve(newValue);
      }, 500);
    }, 500);
  
  })

  
});