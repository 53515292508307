import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { ObjectField } from '../components/ObjectField';


@component
export class EditModeWindow extends Component<{ window; }> {
  render() {
    const mode = db.modes.findById(this.props.window.id);
    return (
      <>
        {/* <button
          onClick={() => {
            const newSidebar = XObject.obj({
              children: [],
            });
            db.sidebar.push(newSidebar);
            mode.sidebar = newSidebar._id;
          }}
        >Create sidebar</button> */}
        <div>
          <input type="checkbox" checked={mode.useAsSpace} onChange={e => mode.useAsSpace = e.target.checked} /> Use As Space
        </div>
        {/* {!mode.useAsSpace && <div>
          Space: <ObjectField
            value={mode.space}
            setValue={value => mode.space = value} />
        </div>} */}
      </>
    );
  }
}
