import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { Svg } from './Svg';
import { color } from '../color';


@component
export class SidebarAddButton extends Component<{ onClick }> {
  static styles = styled.div`
      height: 47px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid #e9e9e7;
      color: ${color('bgTextColor')};
      font-weight: 600;
      svg {
        margin-right: 12px;
        fill: ${color('bgTextColor')};
      }
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.055);
      }      height: 47px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid #e9e9e7;
      color: ${color('bgTextColor')};
      font-weight: 600;
      svg {
        margin-right: 12px;
        fill: ${color('bgTextColor')};
      }
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.055);
      }
  `;
  render(Container?) {
    return (
      <Container onClick={this.props.onClick}>
        <Svg name="plus" /> New
      </Container>
    );
  }
}
