import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from './db';
import { component } from './component2';
import { XInit, XObject, x } from './XObject';
import { appState } from './etc/appState';
import { SystemContext } from './etc/SystemContext';
import { pushToPane } from './components/AppRoot';
import { QueryEditorWindow } from './windows/QueryEditorWindow';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { EditDocumentWindow } from './windows/EditDocumentWindow';
import { AttributeWindow } from './windows/AttributeWindow';
import { ObjectPicker } from './components/ObjectPicker';
import { EditModeWindow } from './windows/EditModeWindow';
import { createNewObject, objectName, setObjectName, setObjectParent } from './components/objectFuncs';
import { CanvasEditor } from './CanvasEditor';
import { viewObject } from './viewObject';
import { Svg } from './components/Svg';
import { showContextMenu } from './helpers';
import { Sidebar } from './components/Sidebar';
import { objectsHierachy } from './components/allObjects';
import { _inspectState } from './osHelpers';
import { ObjectSidebarItem } from './rightSidebar';
import { SidebarAddButton } from './components/SidebarAddButton';
import { PropertyField } from './components/PropertyField';
import copyToClipboard from 'copy-to-clipboard';
import { Cell } from './components/Cell';
import { EventCellType } from './components/cells';


@component
class ObjectInspect extends Component<{ object, stack }> {
  state = XInit(class {

    sidebarState = {};
    movingPicked
  })

  static contextType = SystemContext;
  context: any;

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${Sidebar} {
      position: absolute;
      top: 0;
      bottom: 47px;
      left: 0;
      right: 0;
      overflow: auto;
    }
  `;
  
  render() {
    const { object } = this.props;
    const hierachy = objectsHierachy([ ObjectType.entity, ObjectType.query, ObjectType.space ]);
    return (
      <>
        <Sidebar
          rootItem={new ObjectSidebarItem(object, hierachy, obj => {
            this.props.stack.push(obj);
          }, this.context)}
          state={this.state.sidebarState}
        />
        <SidebarAddButton
          onClick={e => {
            showContextMenu(e, [
              {
                text: 'Type',
                onClick: () => {
                  createNewObject(ObjectType.type, object);
                }
              },
              {
                text: 'Attribute',
                onClick: () => {
                  createNewObject(ObjectType.attribute, object);
                },
              },
              {
                text: 'Tag',
                onClick: () => {
                  createNewObject(ObjectType.tag, object);
                },
              },
            ], 'bottom')
          }}
        />
      </>
    )
  }
}

@component
class EventEditor extends Component<{ id }> {
  render() {
    const event = db.events.findById(this.props.id);
    return (
      <>
        Toggl Mapping: <PropertyField object={event} property="togglMapping" />

        <Cell
          cell={new EventCellType({})}
          get={() => event.kind}
          set={v => event.kind = v}
          title="Kind"
        />
      </>
    )
  }
}

@component
class ObjectRenamer extends Component<{ obj, onRenamed }> {
  name
  constructor(props) {
    super(props);
    this.name = objectName(props.obj);
  }

  render() {
    const ref = React.createRef<any>();
    return (
      <>
        <input type="text" defaultValue={this.name} ref={ref}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              setObjectName(this.props.obj, ref.current.value);
              this.props.onRenamed();
            }
          }}
         />
        <button
          onClick={() => {
            setObjectName(this.props.obj, ref.current.value);
            this.props.onRenamed();
          }}
        >Done</button>
      </>
    )
  }
}

@component
export class ObjectEditor extends Component<{
  obj
  stack
 }> {
  state = XInit(class {
    mode;
  });

  static contextType = SystemContext;
  context: any;

  static styles = styled.div`
    color: #464646;
    font-family: ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .top {
      padding: 8px;
      > .title {
        flex: 0 0 auto;
        font-weight: 500;
        display: flex;
        align-items: center;
        overflow: hidden;
        .back {
          transform: rotate(90deg);
          flex: 0 0 auto;
          width: 12px;
          height: 12px;
          margin-right: 6px;
          cursor: pointer;
          fill: #7d7d7d;

        }
        > ${ObjectDisplay} {
          display: flex;
          white-space: nowrap;
          color: #7d7d7d;
          svg {
            fill: #7d7d7d;
          }
        }
      }

    }

    .section {
      /* background-color: white; */
      display: flex;
      flex-direction: column;
      .sectionTitle {
        text-transform: uppercase;
        font-size: 10px;
        flex: 0 0 auto;
        padding: 4px;
        font-weight: 500;
        color: #c1c1c1;
      }
      flex: 1 1 0;

      .sectionContent {
        position: relative;
        flex: 1 1 0;
        overflow: auto;
        padding: 4px 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e7;
      }
    }
  `;

  render() {
    const { obj, stack } = this.props;
    const render = obj => {
      return (
        <>
          <SystemContext.Provider value={{
            next: () => { },
            navigate: config => {
              console.log('navigate');
              pushToPane(appState.panes[0], config, false);
            },
          }}>
            <div className="top">
              <div className="title">
                {stack?.length > 0 && <Svg className="back" name="chevron"
                  onClick={() => {
                    stack.pop();
                  }} />}
                <ObjectDisplay
                  _onClick={e => {
                    showContextMenu(e, [
                      {
                        text: 'Rename',
                        onClick: () => {
                          this.state.mode = 'renaming';
                        },
                      },
                      {
                        text: 'Move',
                        onClick: () => {
                          this.state.mode = 'moving';
                        },
                      },
                      {
                        text: 'View',
                        onClick: () => {
                          viewObject(obj, this.context);
                        },
                      },
                      {
                        text: 'Copy ID',
                        onClick: () => {
                          copyToClipboard(obj.id);
                        }
                      },
                    ]);
                  }}
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Rename',
                        onClick: () => {
                          this.state.mode = 'renaming';
                        },
                      },
                      {
                        text: 'Move',
                        onClick: () => {
                          this.state.mode = 'moving';
                        },
                      },
                      {
                        text: 'View',
                        onClick: () => {
                          viewObject(obj, this.context);
                        },
                      },
                      {
                        text: 'Copy ID',
                        onClick: () => {
                          copyToClipboard(obj.id);
                        }
                      },

                    ]);
                  }}
                  obj={obj}
                  showPath
                />
              </div>
              {this.state.mode == 'moving' && (
                <ObjectPicker
                  _onSelect={(ref, clear) => {
                    if (ref.type == ObjectType.global) {
                      setObjectParent(obj, null);
                    }
                    else {
                      setObjectParent(obj, ref);
                    }
                    delete this.state.mode;
                  }}
                />
              )}
              {this.state.mode == 'renaming' && (
                <ObjectRenamer obj={obj} onRenamed={() => {
                  delete this.state.mode;
                }} />
              )}
            </div>
            <div className="section">
              <div className="sectionContent">
                {obj.type == ObjectType.event && (
                  <>
                    <EventEditor id={obj.id} />
                  </> 
                )}
                {obj.type == ObjectType.query && (
                  <QueryEditorWindow window={{
                    query: obj.id,
                  }} />
                )}
                {obj.type == ObjectType.page && (
                  <EditDocumentWindow
                    window={{
                      document: obj.id,
                    }} />
                )}
                {obj.type == ObjectType.attribute && (
                  <AttributeWindow window={{ id: obj.id }} />
                )}
                {obj.type == ObjectType.mode && (
                  <>
                    <EditModeWindow
                      window={{
                        id: obj.id,
                      }} />
                  </>
                )}
                {obj.type == ObjectType.canvas && (
                  <>
                    <CanvasEditor id={obj.id} />
                  </>
                )}
                {obj.type == ObjectType.codeComponent && (
                  <>
                    {(() => {
                      const comp = db.codeComponents.findById(obj.id);
                      if (comp) {
                        return (
                          <>
                            <ul>
                              {comp?.scope?.map?.((obj, i) => (
                                <li key={obj._id}>

                                  <ObjectDisplay obj={obj} showPath /> <button
                                    onClick={() => {
                                      comp.scope.splice(i, 1);
                                    }}
                                  >X</button>
                                </li>
                              ))}
                            </ul>

                            <ObjectPicker
                              _onSelect={(obj, clear) => {
                                XObject.push(comp, 'scope', XObject.obj(obj));
                                clear();
                              }} />
                          </>
                        );
                      }
                    })()}
                  </>
                )}
                {obj.type == ObjectType.tag && (
                  (() => {
                    const tag = db.tags.findById(obj.id);
                    return (
                      <>
                      <>
                    <select
                      value={tag.style || ''}
                      onChange={e => {
                        tag.style = e.target.value;
                      }}
                    >
                      <option />
                      <option value="5c4ad6d5-b808-55f2-b0e2-8583876b97bd">Done</option>
                      <option value="73e24e6e-96e5-577d-bbae-5c9838d41439">Highlight</option>
                      <option value="e712b7ba-7765-5f99-b3f9-76c423ce22e2">Background</option>
                    </select>
                  </>
                      </>
                    )   
                  })()
                )}
              </div>
            </div>
            <div className="section">
              <span className="sectionTitle">Objects</span>
              <div className="sectionContent">
                <ObjectInspect object={obj} stack={this.props.stack} />
              </div>
            </div>
          </SystemContext.Provider>
        </>
      );
    };

    if (stack?.length) {
      return render(stack[stack.length - 1]);
    }
    else {
      return render(obj);
    }
  }
}
