import { Component } from 'react';
import { component } from './component2';
import { styled } from './component';
import { cssImg } from './img';
import { XInit } from './XObject';
import { Link } from 'react-router-dom';

enum ImageEnum {
  logo = 'logo',
  image = 'website/image.png',
}

const mobile = '@media (max-width: 768px)'
const desktop = '@media (min-width: 769px)'


@component
class Topbar extends Component {
  static styles = styled.div`
    display: flex;
    height: 55px;
    padding: 0 16px;
    .logo {
      width: 66px;
      height: 27px;
      background: ${cssImg(ImageEnum.logo)};
    }

    &, .left, .right {
      display: flex;
      align-items: center;
    }

    .left, .right {
      > *:not(:last-child) {
        margin-right: 20px;
      }
    }

    .right {
      margin-left: auto;
    }
    a {
      color: inherit;
    }

    ${mobile} {
      .right {
        /* display: none; */
      }
    }
  `;

  render() {
    return (
      <>
        <div className="left">
          <span className="logo" />
          {/* <a href="#">Download</a> */}
        </div>

        <div className="right">
          <Link to="/signin">sign in</Link>
          {/* <Link to="/signup">sign up</Link> */}
          <Link to="/signup">get ebases free</Link>
        </div>
      </>
    )
  }
}

@component
class App extends Component {
  static styles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: rgb(74, 74, 74);

    .tagline {
      text-align: center;
      font-size: 62px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -3.1px;

      display: flex;
      width: 409px;
      flex-direction: column;
      flex-shrink: 0;

      margin: 10vh auto 16px;

      ${mobile} {
        font-size: 50px;
        width: auto;
        padding: 0 16px;
        margin-top: 3vh;
      }
    }

    .subtagline {
      text-align: center;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.3px;

      display: flex;
      width: 296px;
      height: 60px;
      flex-direction: column;
      flex-shrink: 0;

      margin: auto;

    }

    background-image: ${cssImg(ImageEnum.image)};
    background-position: center bottom -25vh;
    background-size: 95vh;
    background-repeat: no-repeat;

    ${mobile} {
      background-size: 80vh;
      background-position: center bottom -20vh;
    }

    .download {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 126px;
      font-size: 12px;
      height: 36px;
      flex-shrink: 0;

      border-radius: 6px;
      background: rgba(237, 239, 248, 0.80);
      box-shadow: 0px 0px 6px 0px rgba(210, 210, 210, 0.25);
      color: inherit;
    }

    /* .image {
      display: block;
      width: 747px;
      height: 496px;

      background-image: ${cssImg(ImageEnum.image)};
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

    } */
  `;

  state = XInit(class {

  });

  componentDidMount(): void {
    document.title = 'ebases - A database for your life';
  }

  render() {
    return (
      <>
        <Topbar />

        <span className="tagline">
          A database for your life.
        </span>

        <span className="subtagline">
          Capture your thoughts. Connect your ideas. Organize your life.

        </span>

        <Link to="/signup" className="download">
        Get ebases free →
        </Link>

        <span className="image" />
      </>
    )
  }
}

@component
export class MarketingHome extends Component {
  render() {
    return <App />;
  }
}
