import copyToClipboard from 'copy-to-clipboard';
import _ from 'lodash';
import { component } from '../component2';
import { X, XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { RenderType, execute, mapStructure, render } from '../glue/main';
import { Runtime } from '../glue/Runtime';
import { Comp } from '../glue/Comp';
import { db } from '../db';
import { $EntityTemplate } from '../glue/structs/$EntityTemplate';
import { getPathInGraph } from '../etc/queryGraph';
import { SystemContext } from '../etc/SystemContext';
import { Svg } from './Svg';
import { showContextMenu } from '../helpers';
import { entityDisplayName, entityDisplayView } from './entityDisplayName';
import { deleteEntity } from '../etc/deleteEntity';
import { ObjectPageFrame } from './ObjectPageFrame';
import { Component } from 'react';
import { PaneType } from '../types/PaneType';
import { ObjectType } from '../types/ObjectRef';
import { EntityInspect } from './EntityInspect';
import { explicitInspectObj } from '../inspectObj';
import { tagsInScope } from './objectFuncs';
import { showPrompt } from '../etc/showPrompt';
import { appState } from '../etc/appState';
import { isCordova, isMobile } from '../isMobile';
import { getTemplate } from './attributesForType';

@component
class ReactiveWrapper extends Component<{ children }> {
  render() {
    return this.props.children?.();
  }
}

@component
export class EntityView extends Comp<{ id; state; embedded?: boolean; pathId }> {
  state = X({});

  static contextType = SystemContext;
  context: any;

  render() {
    const entity = db.entities.findById(this.props.id);
    const template = getTemplate(entity.type);
    // const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
    // const executed = templateSupplierId && execute(templateSupplierId, new Runtime(this.context));
    // const mapped = executed && mapStructure(executed);
    // let template;
    // // let _match;
    // if (mapped?.matches?.content) for (const match of mapped.matches.content) {
    //   const matchMapped = mapStructure(match);
    //   if (entity.type == matchMapped.match?.content) {
    //     // _match = match;
    //     template = matchMapped.template;
    //   }
    // }

    let tags = [];

    if (entity.type) {
      tags = tagsInScope({
        type: ObjectType.type,
        id: entity.type,
      })
    }

    tags = tags.concat(tagsInScope({
      id: this.props.id,
      type: ObjectType.entity,
    }));

    tags = _.uniq(tags);

    // const path = mapped?.pathGraph?.content;

    let c;
    if (template && !this.props.state.source) c = (
      <div>
        {render(template, {
          [$EntityTemplate.Entity]: this.props.id,
        }, RenderType.full, this.props.state)}
      </div>
    )
    else {
      c = (
        <EntityInspect
          id={this.props.id}
          state={this.props.state}
          onGraphClick={null}
          config={{
            menu: [
              {
                text: 'Create match',
                onClick: () => {
    
                },
              }
            ]
          }}
        /> 
      );

      // c = renderInspect(this.props.id, this.props.state, null, this.context?.navigate, {
      //   menu: [
      //     {
      //       text: 'Create match',
      //       onClick: () => {

      //       },
      //     }
      //   ]
      // });
    }

    return (
      <ObjectPageFrame
        obj={{
          type: ObjectType.entity,
          id: this.props.id,
        }}
        pathId={this.props.pathId}
        icon="tesseract"
        path={

          <ReactiveWrapper>
            {() => {
              return entityDisplayView(this.props.id);
              // return getPathInGraph(path, this.props.id).concat(this.props.id).map(id => {
              //   return (
              //     <span key={id} onClick={() => {
              //       this.context.navigate({
              //         type: 'entity',
              //         id,
              //       })
              //     }}>{entityDisplayView(id)}</span>
              //   )
              // })
            }}
          </ReactiveWrapper>
          
          
      
      }
      

        right={(
          <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Copy ID',
                      onClick: () => {
                        copyToClipboard(this.props.id);
                      }
                    },
                    {
                      text: 'Edit',
                      onClick: () => {
                        explicitInspectObj({
                          type: ObjectType.entity,
                          id: this.props.id,
                        });
                      }
                    },
                    {
                      text: 'Pin',
                      onClick: () => {
                        defaultWorkspace().pinnedEntities.push(this.props.id);
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        deleteEntity(this.props.id);
                      },
                    }
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
              {isCordova() && <span className="graph"
                onClick={() => {
                  this.context.navigate({
                    type: PaneType.graph,
                  })
                }}
              >
                <Svg name="icons8-mind-map" />
              </span>}


              <Svg className="tags" name="icons8-price-tag"
                onClick={e => {
                  showContextMenu(e, tags.map(id => {
                    const tag = db.tags.findById(id);
                    return {
                      text: `[${entity.tags?.[id] ? '-' : '+'}] ${tag.name}`,
                      onClick: () => {
                        const t = entity.tags;
                        if (t) {
                          if (t[id]) {
                            delete t[id];
                          }
                          else {
                            t[id] = X({
                              timestamp: new Date(),
                            })
                          }
  
                        }
                        else {
                          entity.tags = X({
                            [id]: X({
                              timestamp: new Date(),
                            })
                          });
                        }
                      }
                    }
                  }).concat({
                    text: '[+] Create tag',
                    onClick: async () => {
                      const name = await showPrompt('Tag name');
                      if (name) {
                        const tag = XObject.obj({
                          name: name,
                          parent: {
                            type: ObjectType.mode,
                            id: appState.currentMode,
                          }
                        })
                        db.tags.push(tag);
                        if (entity.tags) {
                          entity.tags[tag._id] = X({
                            timestamp: new Date(),
                          })
                        }
                        else {
                          entity.tags = X({
                            [tag._id]: X({
                              timestamp: new Date(),
                            })
                          });
                        }
                      }
                    }
                  }), true)
                }}
              />
          </>
        )}
      >
        {c}
      </ObjectPageFrame>
    )

  }
}
