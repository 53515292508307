import { pather } from '../XObject';
import { StructureType, Unserialize } from './main';

export class TypeRegistry {
  types: any[] = [];

  registerType<T, TT = any>(ids: T, func: (ids: T, p: TT) => StructureType): T & Unserialize<TT> {
    const type = func(ids, pather<TT>());
    const t = Object.assign({}, ids, { $type: type, $class: type['class'], $unserialize: () => null } as Unserialize<TT>);
    this.types.push(type);
    return t;
  }

  getType(id: string) {
    return this.types.find(t => t._id === id);
  }
}
