
export enum ValueType {
  String = '5731eb4a-159c-5758-aaa1-92b9498e8f81',
  Construct = '5bf855d4-49d5-52f2-904f-9b6a45aa22ba',
  Number = 'd7278da2-8081-5471-a223-c5fbe549a076',
  Expression = '7d1677f4-4f2f-55f3-941a-cf214456af56',
  Entity = 'd4cf4879-91b9-5ca0-a10b-d37562dce5de',
  Element = '6d8bb599-5113-5d53-8879-ad8021407429',
  Boolean = '0adefbcc-6f1a-50f5-8ee5-83db3c5d88a3',

  Structure = '8fc5e3ac-e84c-51b6-991d-3d99c89a8b9c',

  Param = '6e625d26-5da7-5ceb-9e3b-962c2160ea4d',

  Value = '3a63b19e-b2af-5539-8ce5-84ef5a97f3d4',

  Array = '3edda452-d3b9-555c-99d3-3caabd7d50e9',

  IfChain = '992b52e7-1c4e-5c58-9cc6-e09a7f13308f',

  Void = 'f02a19b2-9bfc-5007-9f27-ebf14e4d7125',

  // EntitySet = '9cfce4ac-ea08-5709-93e6-2788a95dbf89',
  Enum = 'fb70444b-4497-51d6-b22e-c3509bda0f33',

  // Code = '4a169ceb-914c-54e3-830e-69699ce911d9',
  // Statement = '2fb7af4c-5a64-5289-983b-e10005a6125a',
  Graph = 'a0817dd3-3395-5519-a8a6-22d291653366',

  EntityMatch = 'c722da82-2a75-5bc1-b742-6b690b40b984',

  EntityType = 'ab014726-0996-5541-862a-806e3c9a41c3',

  EntityState = 'fa82aa08-3bdc-50e1-8971-680667e9cd30',
  EntityStateType = 'bcc6b8ed-a357-51fa-a84f-43caa614eca2',
  EntityStateTypes = '79cdfe4a-1e2c-5772-8d68-f0247ec39d68',

  EntityMetaState = '26d3ca85-3b00-577e-b243-b560a96b970b',

  EntityAttribute = 'd4c0fffc-0bd2-5384-b050-2313e75450cc',
  EntityAttributes = 'd3557455-a10d-5a93-8b5e-5d77fefca90f',

  Statement = '420fec6b-4f6b-50af-9c6b-edca5d0d98b7',

  RelativeQueries = '7ed63378-8963-5b79-858d-0265b6875d6d',
  RelativeQuery = '185265e6-ac4c-5b2d-a342-b1f201ff8eba',

  EntityElement = '2f75fc35-03c4-5df8-8de8-4bac2368ef7e',

  Arg = 'e0b471ff-c20f-5065-aeb6-d1d8a075a948',

  CodeComponent = '7ea4cda7-5c2d-5068-9345-6571c104617b',

}
