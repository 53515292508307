import { Component } from 'react';
import { component } from './component2';
import { RichTextEditor } from './components/richTextEditor/RichTextEditor';
import { XInit, x } from './XObject';


@component
export class DataEditorTestWindow extends Component {
  state = XInit(class {
    data = 'hello world'
  })
  render() {
    return <RichTextEditor value={() => this.state.data}
      setValue={e => {
        console.log(x(e));
        this.state.data = e;
      }}
    />;
  }
}
