import React, { Component } from 'react';
import { component, styled } from '../component2';
import { Svg } from './Svg';
import { isMobile, useMobileNavSystem } from '../isMobile';
import { appState, memoryAppState } from '../etc/appState';
import classNames from 'classnames';
import { db } from '../db';
import { X, XObject, x } from '../XObject';
import { SystemContext } from '../etc/SystemContext';
import { getObject } from './objectFuncs';

const scrollPos = {};

@component
export class ObjectPageFrame extends Component<{
  icon;
  path;
  right?;
  children?;
  pathId;
  obj
}> {
  static styles() {
    const { t } = this;
    return styled.div`
      &.embedded {
        > ${t.content} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      &:not(.embedded) {

        > ${t.deleted} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 30px;

        }

        > ${t.top} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 16px;
        }

        > ${t.content} {
          position: absolute;
          top: 30px;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &.deleted {
          > ${t.top} {
            top: 30px;
          }
          > ${t.content} {
            top: 60px;
          }
        }
      }

      &.mobile {
        > ${t.top} {
          padding: 0 8px;
        }
      }
    `;
  }

  static t = {
    deleted: styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #cd4f4f;
          color: white;

      button {
        border: none;
        background: none;
        color: white;
        height: 20px;
        border: 1px solid white;
        border-radius: 2px;
        margin-left: 8px;
        font-size: 10px;
        font-family: inherit;
      }
    `,
    top: styled.div`
      font-size: 8px;   
      border-bottom: 1px solid #f0f0f0; 
      display: flex;
      align-items: center;
      overflow: hidden;

      > .toggleMenu {
        margin-right: 8px;
        width: 12px;
        height: 12px;
        fill: #3131319c;
      }

      > .back {
        transform: rotate(90deg);
        margin-right: 8px;
        fill: #bababa;
        width: 16px;
        height: 16px;
      }




      .space {
        margin-left: 4px;
      }

      .path {
        font-size: 13px;
        font-weight: 400;
        svg {
          width: 14px;
          height: 14px;
          vertical-align: middle;
          margin-right: 2px;
          fill: #3131319c;
          flex: 0 0 auto;

        }
        white-space: nowrap;
        flex: 1 1 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }
        > * {
          cursor: pointer;
        }
        > *:not(:first-child) {
          &:before {
            content: ' / ';
          }
        }

        > svg + span {
          &:before {
            display: none;
          }
        }

      }

      .more {
        margin-left: 8px;
        display: flex;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
          fill: #bababa;
        }
      }

      .graph {
        margin-left: 8px;
        display: flex;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
          fill: #bababa;
        }
      }

      .tags {
        width: 14px;
        height: 14px;
        fill: #3131319c;
      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        > *:not(:first-child) {
          margin-left: 8px;
        }

        .favorite {
          width: 20px;
          height: 20px;
          cursor: pointer;

          &.favorited {
            fill: #f5c518;
          }

          &:not(.favorited) {
            fill: #bababa;
          }
        }
      }
      .sourceCheckbox {
        margin-left: auto;

      }
    `,
    content: styled.div`
      overflow: auto;
      padding: 16px;
    `,
  };

  static contextType = SystemContext;
  context: any;

  render(Container?) {
    const { t } = ObjectPageFrame;
    const favorited = (() => {
      if (this.props.obj && appState.currentMode) {
        const mode = db.modes.findById(appState.currentMode);
        if (mode) {
          if (mode.favorites) {
            if (mode.favorites.find(obj => obj.id == this.props.obj.id)) {
              return true;
            }
          }
        }
      }
      return false;
    })()
    const obj = this.props.obj && getObject(this.props.obj);

    return (
      <Container
        className={classNames({
          mobile: isMobile(),
          deleted: obj?.__deleted,
        })}
        data-path-id={this.props.pathId}
      >
        {obj?.__deleted && (
          <t.deleted>
            This object has been deleted.
            <button
              onClick={() => {
                delete obj.__deleted;
              }}
            >Restore</button>

            {/* <button
              onClick={() => {

              }}
            >Delete permanently</button> */}
          </t.deleted> 
        )}

        <t.top>
          {isMobile() && (
            <>
              {useMobileNavSystem() && (
                <Svg
                  onClick={() => {
                    this.context.back();
                    // if (isCordova()) {
                    //   appState.mobileStack.pop();
                    // }
                    // else {
                    //   window['history'].back();
                    // }
                  }}
                  name="chevron"
                  className="back"
                />
              )}
              {!useMobileNavSystem() && (
                <Svg
                  onClick={() => {
                    memoryAppState.showSidebar = !memoryAppState.showSidebar;
                  }}
                  name="menu"
                  className="toggleMenu"
                />
              )}
            </>
          )}
          <span className="path">
            <Svg name={this.props.icon} /> {this.props.path}
          </span>
          <div className="right">
            {this.props.right}

            {this.props.obj && <Svg className={classNames('favorite', {
              favorited,
            })} name={favorited ? 'notion-starFilled' : 'notion-starUnfilled'}
              onClick={() => {
                if (appState.currentMode) {
                  const mode = db.modes.findById(appState.currentMode);
                  if (mode) {
                    if (mode.favorites) {
                      if (!mode.favorites.find(obj => obj.id == this.props.obj.id)) {
                        mode.favorites.push(this.props.obj);
                      }
                      else {
                        mode.favorites = mode.favorites.filter(obj => obj.id != this.props.obj.id);
                      }
                    }
                    else {
                      mode.favorites = X([this.props.obj]);
                    }
                  }
                }
              }}
            />}
          </div>
        </t.top>
        <t.content
          onScroll={e => {
            scrollPos[this.props.pathId] = e.currentTarget.scrollTop;
          }}
          ref={e => {
            if (e) {
              e.scrollTop = scrollPos[this.props.pathId] || 0;
            }
          }}
        >
          {this.props.children}
        </t.content>
      </Container>
    );
  }
}
