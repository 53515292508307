
import React from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";

const Set = '8e98cf2f-1748-5b09-aab2-60e271d4fea6'

registerChangeAction(Set, {
  name: 'Set Boolean',
});


@component
export class BooleanValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`
    color: #ca8f76;
  `;
  timerId
  render() {
    return (
      <>
      <input type="checkbox" checked={this.props.state.content} onChange={e => {
        trackChange(Set, {}, [
          [ UndoActions.modifiedValuePoint, this.props.state._id ],
        ]);

        this.props.state.content = e.target.checked;
      }} />

      </>
    );
  }
}

export const registerBooleanType = () => {
  registerType(ValueType.Boolean, {
    evaluate: (value, map) => {
      return value.content;
    },
    render: (value, map, state, renderType) => {
      return <span key={value._id} data-value-point={value._id}>{value.content}</span>;
    },
    editorComp: BooleanValuePoint,
  });
};