import { Component } from "react";
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component, styled } from "../component2";
import { NotionDocument } from "../components/notionDocument/NotionDocument";
import { concatData, dataLength, expandToText, sliceData } from "../components/richTextHelpers";
import { X, XClone, XObject, x } from "../XObject";
import { MyBlock, MyBlockManager } from "./MyBlockManager";
import { db } from "../db";
import { createEntity } from "../etc/createEntity";
import { ObjectType } from "../types/ObjectRef";
import { attributesInScope, typesInScope, typesInScopes } from "../components/objectFuncs";
import { renderBlock } from "./renderBlock";
import { SystemContext } from "../etc/SystemContext";
import { Block } from "../components/notionDocument/BlockManager";
import { componentSystem } from "../componentSystem";
import { appState } from "../etc/appState";
import { InspectState } from "../components/InspectState";
import { types } from "./types";
import { showPrompt } from "../etc/showPrompt";
import { resumeMode } from "../resumeMode";
import { ObjectDisplay } from '../components/ObjectDisplay';
import { Tag } from "../components/Tag";

@component
class ShowEntity extends Component<{ id }> {
  static styles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    ${Tag} {
      margin-left: auto;
      flex: 0 0 auto;
    }

    ${ObjectDisplay} {
      white-space: nowrap;
      flex: 1 1 auto;
      /* width: 0; */
      overflow: hidden;
    }

  `;  
  render() {
    const entity = db.entities.findById(this.props.id);
    const type = entity.type && db.entityTypes.findById(entity.type);
    return <><ObjectDisplay obj={{ type: ObjectType.entity, id: this.props.id }} showPath /> {type?.name && <Tag text={type.name} />}</>
  }
}


@component
export class MyNotionDocument extends Component<{
  title;
  setTitle;
  blocks;
  setBlocks;
  baseEntity;
  extendEntity;
  docId;
  insidePositionContext?
  tick?
  scopes?
}> {
  static styles = styled.div`

  `;

  blocks;
  constructor(props) {
    super(props);

    const blocks = this.props.blocks;
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    const observeExternalChanges = () => {
      XObject.observe(this.props.blocks, (change) => {
        this.blocks = XClone(this.props.blocks);
        this.forceUpdate();
      });
    }

    observeExternalChanges();

    XObject.observe(this.blocks, (change) => {
      this.props.setBlocks(XClone(this.blocks));
      observeExternalChanges();
    });
  }

  static contextType = SystemContext;
  context: any;

  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this);


    jQuery(el).on('click', '[data-type="entity"]', (e) => {
      const id = JSON.parse(atob(e.target.getAttribute('data-entity-data')));
      this.context?.navigate?.({
        type: 'entity',
        id
      })
    });

    jQuery(el).on('click', '[data-type="code"]', (e) => {
      const aaa = jQuery(e.target).closest('[data-code-data]');
      const id = JSON.parse(atob(aaa.attr('data-code-data')));
      appState.appInspect = {
        mode: InspectState.code,
        component: id.component,
      }
    });


  }

  options() {
    const types = this.props.docId ? typesInScopes([{
      type: ObjectType.page,
      id: this.props.docId,
    }].concat(this.props.scopes || [])) : [];

    const attributes = this.props.docId ? attributesInScope({
      type: ObjectType.page,
      id: this.props.docId,
    }) : [];

    const entityOptions = [

      {
        label: 'Insert entity',
        action: (block, menuPos) => {
          const position = menuPos;
          const length = dataLength(this.ctx, block.data);
          const firstPart = sliceData(this.ctx, block.data, 0, position);
          const secondPart = sliceData(this.ctx, block.data, position, length);
          const e = [['6435a0493239f5c3f494c1d6', 'entity']];
          block.data = concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart);
        }
      },

      {
        label: 'Entity',
        action: block => {
          block.type = 'entity';
        }
      },


      {
        label: 'Attach new entity',
        action: (block: MyBlock) => {
          const entity = XObject.obj({
            name: XClone(block.getContent()),
          });
          this.props.extendEntity?.(entity);
          createEntity(entity, this.props.baseEntity);
          block.block.id = entity._id;
        },
      },
      {
        label: 'Attach existing entity',
        action: async (block: MyBlock) => {
          const id = await showPrompt('Enter entity id');
          if (id) {
            block.block.id = id;
            const entity = db.entities.findById(id);
            block.setContent(XClone(entity.name));
          }
        }
      },
    ]    .concat(types.map(id => ({
      label: `Create ${db.entityTypes.findById(id).name}`,
      action: (block: MyBlock) => {
        const entity = XObject.obj({
          type: id,
        });
        createEntity(entity, this.props.baseEntity); // createEntityNull
        block.block.id = entity._id;
      }
    }))).concat(attributes.map(id => ({
      label: 'Set attribute ' + db.attributeTypes.findById(id).name,
      action: async (block: MyBlock) => {
        const value = await showPrompt('Enter value');

        if (block.block.attributes) {
          block.block.attributes[id] = value;
        }
        else {
          block.block.attributes = X({
            [id]: value,
          });
        }
      },
    })));

    let options = [
      {
        label: 'Insert code',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);
          const component = componentSystem.createComponent();
          const e = [[{
            id: XObject.id(),
            component: component._id,
          }, 'code']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
          appState.appInspect = {
            mode: InspectState.code,
            component: component._id,
          }
        },
      },
      {
        label: 'To-do list',
        action: (block: MyBlock) => {
          block.block.type = 'checkItem';
        }
      },
      {
        label: 'Plain block',
        action: block => {
          delete block.type;
        }
      },
      {
        label: 'Heading 1',
        action: (block: MyBlock) => {
          block.block.type = 'heading_1';
        }
      },
      {
        label: 'Code',
        action: (block: MyBlock) => {
          block.block.type = 'code';
        }
      },
      {
        label: 'Media',
        action: block => {
          block.block.type = 'media';
        }
      },



    ];

    if (!resumeMode.enabled) {
      options = options.concat(entityOptions);
    }

    return options.filter(Boolean);
  }
  tick = 0;

  ctx = {
    types,
  };


  entitySelectOptions(filter, onSelectEntity) {
    const r =  db.entities.filter(e => {
      if (!_.isString(e.name)) return false;
      return expandToText(this.ctx, e.name).toLowerCase().includes(filter.toLowerCase());
    }).map(e => ({
      key: e._id,
      label: <ShowEntity id={e._id} />,
      // filterText: expandToText(this.ctx, e.name),

      action: (block: MyBlock, menuPos) => {
        onSelectEntity(e, block, menuPos);      }
    }))

    return r.concat({
      label: `Create entity "${filter}"`,
      action: (block, menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        onSelectEntity(e, block, menuPos);
      }
    })
  }

  memory = {};

  render() {
    return (
      <NotionDocument
        key={this.props.tick?.()}
        insidePositionContext={this.props.insidePositionContext}
        title={this.props.title}
        setTitle={this.props.setTitle}
        renderBlock={renderBlock}
        onBlockSelected={(block: MyBlock) => {
          if (block?.getId()) {
            appState.inspecting = {
              type: 'entity',
              id: block.getId(),
            }
          }  
        }}
        renderBlockArgs={{
          onClickEntityDot: id => {
            console.log(id);
            this.context.navigate({
              type: 'entity',
              id,
            })
          },
          docId: this.props.docId,
        }}
        types={types}
        blockManager={new MyBlockManager(
          () => this.blocks,
          blocks => {
            this.props.setBlocks(blocks);
            this.blocks = XClone(blocks);
            XObject.observe(this.blocks, (change) => {
              this.props.setBlocks(XClone(this.blocks));
            });
            this.tick++;
            this.forceUpdate();
            // ref.current.forceUpdate();
          },
          {
            baseEntity: this.props.baseEntity,
            ctx: this.ctx,
            extendEntity: this.props.extendEntity,
            memory: this.memory,
          })
        }
        menuIniters={{
          '/': this.options(),
          ...(!resumeMode.enabled ? {
            '@': filter => this.entitySelectOptions(filter, (e, block: Block, menuPos) => {
              const ctx = {
                types
              }
              const data = block.getContent();
              const position = menuPos;
              const length = dataLength(ctx, data);
              const firstPart = sliceData(ctx, data, 0, position);
              const secondPart = sliceData(ctx, data, position, length);
              block.setContent(concatData(ctx, concatData(ctx, firstPart, [[e._id, 'entity']]), secondPart), true);
            }),
          } : {}),
          ...(!resumeMode.enabled ? {
            '$': filter => this.entitySelectOptions(filter, (e, block: MyBlock, menuPos) => {
              block.block.id = e._id;
            }),
          } : {}),
        }}
      />
    );

  }

}
