import React from 'react';
import ReactDOM from 'react-dom';
import { XInit } from './XObject';
import jQuery from 'jquery';
import classNames from 'classnames';
import _ from 'lodash';
import { DragSource } from 'react-dnd';
// import { ContextMenu as _ContextMenu, MenuItem as _MenuItem, ContextMenuTrigger as _ContextMenuTrigger } from 'react-contextmenu';
import { openWindow, currentOs, closeWindow, setWindowTransitionMode, getWindowTransitionMode, windowAreaList, WindowArea, windowRegistry, setLastMovePos, setLastSize, setOsKey, _inspectHandlers, _inspectState, currentSpace } from './osHelpers';
// import { registerDragger } from './helpers';
import { TransitionGroup } from 'react-transition-group';
import { CSSTransition } from './CSSTransition';
import styled from 'styled-components';
import { component } from './component2';
import { w } from './w';
import { DndProvider as _DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import './styles/os.css';
import OriginalHTML5Backend from 'react-dnd-html5-backend/dist/cjs/HTML5Backend';
import { darkModeBg } from './etc/themeState';
import { appState } from './etc/appState';

function ContextMenuTrigger(props: {
	renderTag
	attributes
	children?
	id?
}) {
	const Tag = props.renderTag;
	return <Tag {...props.attributes}>{props.children}</Tag>;
}

// const ContextMenu = _ContextMenu as any;
// const MenuItem = _MenuItem as any;
// const ContextMenuTrigger = _ContextMenuTrigger as any;
const DndProvider = _DndProvider as any;

class HTML5BackendWithEvents extends OriginalHTML5Backend {
	handleDrop(e, id) {
		// console.log('handleDrop', e, id, this.registry.getTarget(id), this.registry.getTarget(id).monitor);
		// if (this.registry.getTarget(id).monitor) {
		// 	this.registry.getTarget(id).monitor.originalEvent = e;
		// }

		window['_g_dropEvent'] = e;
		console.log(e);
		return super.handleDrop(...arguments);
	}
}

function createHTMLBackend(manager) {
	return new (HTML5BackendWithEvents as any)(manager);
}


export const registerDragger = (() => {
	let currentDragger, _started, _ended;
	let root;

	jQuery(window).mousemove(e => {
		if (currentDragger) {
			if (_started) {
				_started();
				_started = null;
			}
	
			const x = e.pageX - root().offset().left;
			const y = e.pageY - root().offset().top;
	
			currentDragger({ x, y });
		}
	});

	window.addEventListener('mouseup', e => {
		if (currentDragger) {
			e.preventDefault();
			e.stopPropagation();
			currentDragger = null;
			if (_ended) {
				_ended();
				_ended = null;
			}
		}
	});

	return Object.assign(({ started, dragger, ended }: { started?, ended?, dragger }) => {
		_started = started;
		_ended = ended;
		currentDragger = dragger;
	}, { setRoot: r => root = r })
})();


export namespace DBTypes {
  export interface Window {
    _id: string;
    state: string;
  }
}

const _window: { g_debugState: any, computeStyles, $, dec, g_windowComponent, $value, g_components, renderWindowInterface } = window as any;

interface WindowTypes { [type: string]: {
	title?(win): string;
	component?(win): any;
	toolbarComponent?(win): any;
	buttons?: string[];
} }


let windowType: WindowTypes;

// function windowTitle(win) {
// 	return windowType[win.type].title(win);
// }

// function windowComponent(win) {
// 	return windowType[win.type]?.component?.(win);
// }

// function toolbarComponent(win) {
// 	return windowType[win.type].toolbarComponent && windowType[win.type].toolbarComponent(win);
// }


// function windowButtons(win) {
// 	return [ 'minimize', 'close' ];
// 	// return windowType[win.type].buttons;
// }


class WindowTypeReg {
	constructor(private windowTypes: WindowTypes) {}
	title(win): any {
		if (this.windowTypes[win.type]) {
			return this.windowTypes[win.type].title(win);
		}
		else {
			return '(invalid)';
		}
	}

	component(win): any {
		return this.windowTypes[win.type]?.component?.(win);
	}

	buttons(win): string[] {
		return [ 'minimize', 'close' ];
	}

	toolbarComponent(win): any {
		return this.windowTypes[win.type]?.toolbarComponent && windowType[win.type].toolbarComponent(win);
	}
}


@component
class _WindowHandle extends React.Component<{ window: any, connectDragSource }> {
	render() {
  	switch (this.props.window.type) {
  		case 'entity':
				return this.props.connectDragSource(
					<a className="handle" onClick={(e) => e.preventDefault()} href={`/entities/${this.props.window.props.entity}`} />
				);

		  default:
				return this.props.connectDragSource(
					<span className="handle" />
				);
  	}
	}
}

const WindowHandle = DragSource('object', {
  beginDrag(props: any) {
  	switch (props.window.type) {
  		case 'entity':
		    return {
		    	type: 'entity',
		    	entity: props.window.props.entity,
		    };

		  case 'interface':
		  	return {
		  		type: 'interface',
		  		props: {
		  			pos: props.window.pos,
		  			size: props.window.size,
		  			props: props.window.props,
		  		}
		  	};

		  case 'data':
		  	return {
		  		type: 'data',
		  		id: props.window.props.id,
		  	};
  	}
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
  }
}, (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
})(_WindowHandle)



@component
class Windows extends React.Component<{ parent, windowTypes: WindowTypeReg }> {
	willDrag;
	dragging;

	componentDidMount() {
		jQuery('body').addClass('os');
		registerDragger.setRoot(() => jQuery(this.props.parent.refs.area));
	}

	render() {
		const windowedWindows = windowAreaList(WindowArea.windows).map(id => windowRegistry()[id]);
		const sortedWindows = [].concat(windowedWindows);
		sortedWindows.sort((a, b) => a._id < b._id ? -1 : 1);

		const { windowTypes } = this.props;

		return (
			<TransitionGroup className="windows elements">
				{sortedWindows.map((win, i) => {
					if (!win) return null;
					let Comp = windowTypes.component(win);
					if (!Comp) return null;
					Comp = Comp({ mode: 'window' })
					const ToolbarComp = windowTypes.toolbarComponent(win); 
					const zIndex = windowedWindows.indexOf(win);
					return (
						<CSSTransition key={win._id} classNames="window">
							<div
								ref={el => {
									if (el) {
										el.style.left = win.pos.x + 'px';
										el.style.top = win.pos.y + 'px';
										el.style.height = win.size.height + 'px';
									el.style.width = win.size.width + 'px';
									}
								}}
								key={win._id}
								data-id={win._id}
								className={classNames('window', { front: zIndex == windowedWindows.length - 1 })}
								// style={{ left: `${win.pos.x}px`, top: `${win.pos.y}px`, width: `${win.size.width}px`, height: `${win.size.height}px`, zIndex }}
								style={{ zIndex,
								...({
									'-webkit-app-region': 'no-drag',
								})	
								}}
								onMouseDown={e => {
									let el = jQuery(e.target);
									if (el.is('button') || el.is('.handle')) return;

									moveWindowToTop(win);

									if (!el.is('.windows > .window')) {
										el = el.parents('.windows > .window:first');
									}
									if (!el.offset()) return;

									const local = { x: e.pageX - el.offset().left, y: e.pageY - el.offset().top };

									function updateWin() {
										const el = jQuery(`[data-id=${win._id}]`);
										el.css({
											left: win.pos.x,
											top: win.pos.y,
											width: win.size.width,
											height: win.size.height
										});						
									}
									
									function resizeDragger(anchor) {
										registerDragger({
											dragger: ({ x, y }) => {
												let width, height;
												if (anchor == 'br') {
													width = x - win.pos.x;
													height = y - win.pos.y;
												}
												else if (anchor == 'bl') {
													width = (win.pos.x + win.size.width) - x;
													height = y - win.pos.y;
													win.pos.x = x;
												}
												win.size = { width: Math.max(width, 170), height: Math.max(height, 100) };
												setLastSize(win, win.size);

												updateWin();
											},
											ended() {
												jQuery(document.body).css('cursor', '');
												jQuery('body').removeClass('defaultCursor');
											}
										});
									}

									if (local.y <= el.find('.top-bar').outerHeight()) {
										e.preventDefault();
										// this.willDrag = { window: win, offset: local, type: 'move' };	
										registerDragger({
											started: () => {
												jQuery('body').addClass('defaultCursor');
											},
											ended: () => {
												jQuery(document.body).css('cursor', '');
												jQuery('body').removeClass('defaultCursor');									
											},
											dragger: ({ x, y }) => {
												win.pos.x = x - local.x;
												win.pos.y = Math.max(0, y - local.y);
												setLastMovePos(win, { x: win.pos.x, y: win.pos.y });
												updateWin();
											},
										});
									}
									else if (local.y > el.outerHeight() - 10 && local.x > el.outerWidth() - 10) {
										e.preventDefault();
										// this.dragging = { window: win, type: 'resize', anchor: 'br' }
										resizeDragger('br');
										jQuery(document.body).css({ cursor: 'nwse-resize' });
									}
									else if (local.y > el.outerHeight() - 10 && local.x < 10) {
										e.preventDefault();
										// this.dragging = { window: win, type: 'resize', anchor: 'bl' }
										resizeDragger('bl');
										jQuery(document.body).css({ cursor: 'nesw-resize' });
									}
								}}
							>
								{/* <ContextMenu id={`windowTitle-${win._id}`}>
									<MenuItem
										onClick={() => {
											openWindow({
												type: 'windowDev',
												window: win._id
											}, [ 374, 242 ])
										}}
									>Dev</MenuItem>
								</ContextMenu> */}
								<div className="top-bar">
									{/* <ContextMenuTrigger id={`windowTitle-${win._id}`}>{''}</ContextMenuTrigger> */}
									{!ToolbarComp && (
										<span className="title">
											<WindowHandle window={win} />
											<span className="value">{windowTypes.title(win)}</span>
										</span>
									)}
									<div className="buttons">
										{([ 'minimize', 'close' ]).filter((button) => windowTypes.buttons(win).indexOf(button) != -1).map((button) => ({
											'minimize': (
												<span
													className={classNames('windowAction', 'button', button)}
													key={button}
													onClick={() => minimizeWindow(win._id)}
												><span className="gg gg-math-minus" /></span>
											),
											'close': (
												<span
													className={classNames('windowAction', 'button', button)}
													key={button}
													onClick={() => {
														setWindowTransitionMode('close');
														closeWindow(win);
														this.forceUpdate();
													}}
												><span className="gg gg-close" /></span>
											)
										}[button]))}
									</div> 
									{ToolbarComp && <ToolbarComp />}
								</div>
								<div className="client">
									{/* <Comp mode="window" /> */}
									{Comp}
								</div>
								<div className="blGrip" />
								<div className="brGrip" />
							</div>
						</CSSTransition>
					);
				})}
			</TransitionGroup>
		);
	}
}

function removeWindow(id: string): void {
	while (true) {
		const i = windowAreaList(WindowArea.windows).indexOf(id);
		if (i != -1) {
			windowAreaList(WindowArea.windows).splice(i, 1);
		}
		else {
			break;
		}
	}
}

function minimizeWindow(id: string): void {
	setWindowTransitionMode('minimize');
	removeWindow(id);
}

function moveWindowToTop(win) {
	const windows = windowAreaList(WindowArea.windows);
	const i = windows.indexOf(win._id);
	if (i != windows.length - 1 && i != -1) {
		removeWindow(win._id);
		windows.push(win._id);
	}
}

@component
export class OS extends React.Component<{ menu?, windowTypes: WindowTypes, showBar?, osKey?, children? }> {
	terminal: any = {};
	state = XInit(class {
		loading = false;
		startMenu = false;
	});

	windowTypes: WindowTypeReg;
	constructor(props) {
		super(props);

		this.windowTypes = new WindowTypeReg(this.props.windowTypes);

		if (this.props.osKey) {
			setOsKey(this.props.osKey);
		}
	}

	static styles() {
		const { c } = this;
		return styled.div`
			.${c.console} {
				margin-left: auto;
			}

			&.${c.showSideBar} .os-page {
				right: ${props => props.sideBarWidth}px;
			}
			.os-page {
				position: absolute;
				left: 0;
				bottom: 0;
				top: 0;
				overflow: hidden;
				right: 0;
			}

			.mainContent {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: auto;
			}

		`;
	}

	static c = {
		console: '',
		showSideBar: '',
	}

	static t = {
		sideBar: (() => {
			const t = {
				shadow: styled.span`
					position: absolute;
					top: -100px;
					left: -25px;
					bottom: -100px;
					box-shadow: 0 0 4px 0 #00000026;
					width: 25px;
					z-index: 1;
				`,
				resizer: styled.span`
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 5px;
					cursor: col-resize;
					z-index: 1;

				`,
			};

			return w('', styled.div`
				overflow: auto;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				padding: 8px 16px;
				box-sizing: border-box;
				background-color: #f7f7f5;

				.toggleAppInspect {
					position: absolute;
					right: 8px;
					top: 5px;
					z-index: 999;
				}
			`, t);
		})()
	}


	render(Container?) {
		const { t, c } = OS;

		const defaultSideBarWidth = 400;

		const o = a => {
			return (e) => {
				a();
				this.forceUpdate();
				e.preventDefault();
				this.state.startMenu = false;
			}
		}
		const l = (text, a) => {
			return <div><span className="link" onClick={o(a)}>{text}</span></div>
		}

		const windows: DBTypes.Window[] = windowAreaList(WindowArea.windows).map(id => windowRegistry()[id]).filter(Boolean);
		const minWindows: DBTypes.Window[] = windowAreaList(WindowArea.taskBar).map(id => windowRegistry()[id]).filter(Boolean);

		

		let mainContent = this.props.children;


		const showBar = this.props.showBar || windows.length > 0;
		return (this.state.loading ? <div /> :
			<DndProvider backend={createHTMLBackend as any}>

			<Container className={classNames('--os', 'theme-Light', currentSpace().sideBar && c.showSideBar)} sideBarWidth={currentSpace().sideBarWidth || defaultSideBarWidth}>
					<div className={classNames('os-page', 'min-bar', getWindowTransitionMode() && ('windowTransitionMode-' + getWindowTransitionMode()))}>
						<div className={classNames('area', { showBar: showBar })} ref="area" style={{ bottom: this.terminal.open && (this.terminal.height || 0) + 30}}>
							
							<div className="mainContent">{mainContent}</div>
							
							<Windows windowTypes={this.windowTypes} parent={this} />
						</div>
						
						{showBar && <div style={{ flex: '0 0 auto'}} className={classNames('min-bar', { terminalOpen: this.terminal.open })}>
							<div className={classNames('start', { open: this.state.startMenu })} onClick={() => this.state.startMenu = !this.state.startMenu}>Start</div>
							<CSSTransition in={this.state.startMenu} classNames="menu-transition"
							>
								<div className="menu"
									onClick={() => {
										this.state.startMenu = false;
									}}
								>
								{this.props.menu}
								</div>
							</CSSTransition>
							<TransitionGroup className="reps">
								{minWindows.map((win, j) => {
									const position = windows.findIndex(w => w._id == win._id);
									const top = position != -1 && position == windows.length - 1;
									const open = position != -1;
									return (
										<CSSTransition key={win._id} classNames="minWindow">
											<ContextMenuTrigger
												id={`min-${win._id}`} renderTag="div"
											attributes={{
												className: classNames('rep', win.state, { top, open }),
												onClick: () => {
													const windowPosition = windowAreaList(WindowArea.windows).indexOf(win._id);
													if (windowPosition == -1) {
														setWindowTransitionMode('maximize');
														windowAreaList(WindowArea.windows).push(win._id);
														// moveWindowToTop(win);
													}
													else {
														if (top) {
															minimizeWindow(win._id);
														}
														else {
															moveWindowToTop(win);
														}
													}
													this.forceUpdate();
												}
											}}>
												{/* <ContextMenu id={`min-${win._id}`}>
													<MenuItem onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														closeWindow(win);
													}}>Close</MenuItem>
												</ContextMenu> */}
												{this.windowTypes.title(win)}
											</ContextMenuTrigger>
										</CSSTransition>
									);
								})}
							</TransitionGroup>

							<div className={classNames('rep', c.console, { open: currentSpace().sideBar })} onClick={() => {
								currentSpace().sideBar = !currentSpace().sideBar;
							}}>Console</div>
						</div>}
			
					</div>
					{currentSpace().sideBar && <t.sideBar style={{ width: currentSpace().sideBarWidth || defaultSideBarWidth }}>
						<t.sideBar.shadow />
						<t.sideBar.resizer onMouseDown={e => {
              e.preventDefault();
              const el = ReactDOM.findDOMNode(this);
              registerDragger({
                dragger: ({ x, y }) => {
                  const width = jQuery(el).width() - x;
									currentSpace().sideBarWidth = width;
                }
              });
            }}
						/>

<input type="checkbox" className="toggleAppInspect"
              checked={appState.appInspectOverlay}
              onChange={e => {
                appState.appInspectOverlay = e.target.checked;
              }} />


						{_inspectHandlers[_inspectState()?.focused?.type]?.render?.(_inspectState()?.focused?.args)}
					</t.sideBar>}
			</Container>
			</DndProvider>
		);
	}
}
