import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { execute, mapStructure } from '../glue/main';
import { component } from '../component2';
import { X, XObject } from '../XObject';
import { showContextMenu } from '../helpers';
import { Cell } from "../components/Cell";
import { $GroupedSelectAttribute_Multi_CellType, EntityTypesCellType, MultiSelectCellType, SelectCellType } from "../components/cells";
import { AttributeType } from '../components/AttributeType';
import { $GroupedSelectAttribute } from '../glue/structs/$GroupedSelectAttribute';
import { AttributeSelector } from '../components/EntityInspect';
import { Svg } from '../components/Svg';
import { css } from '../component';

const spacing = '6px';

@component
class Static extends Component<{ children? }> {
  static styles = styled.div`
    font-weight: bold;
  `;
  render() {
    return this.props.children;
  }
}


const layoutCss = css`
    display: flex;
    align-items: center;
    > *:not(:first-child) {
      margin-left: ${spacing};
    }

`

@component
class SelectButton extends Component<{ icon?, content, _onClick? }> {
  static styles = styled.div`
    border: 1px solid rgba(55, 53, 47, 0.16);
    border-radius: 3px;
    height: 32px;
    padding: 0px 8px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    background-color: #fff;

    svg {
      margin-left: 4px;
    }
  `;
  render(Container?) {
    return (
      <Container onClick={this.props._onClick}>
        {this.props.content}

        <Svg name="chevron" />
      </Container>
    )
  }
}

export const CHAINED_ENTITY = 'b1ef080e-af65-5cd6-9a9e-6b34f67a70af';

@component
class OperatorTypeSelector extends Component<{ value, setValue, types }> {
  render() {
    return (
      <>
        <SelectButton
          content={this.props.types.find(t => t[0] == this.props.value)?.[1] || '...'}
          _onClick={e => {
            showContextMenu(e, this.props.types.map(t => ({
              text: t[1],
              onClick: () => this.props.setValue(t[0])
            })))

          }}
        />
      

        {/* <select
          value={this.props.value || ''}
          onChange={e => this.props.setValue(e.target.value)}
        >
          <option value="">--</option>
          {this.props.types.map(type => (
            <option key={type[0]} value={type[0]}>{type[1]}</option>
          ))}
        </select> */}
      </>
    )
  }
}

export enum ExpressionEntryType {
  attribute = '09484897-4f32-506e-ae61-b9f4434490ad',
  tags = '5fef737d-c4e6-5674-97de-d93bad3ead42',
  type = '915e75e3-0937-52ae-a8ed-4f2a07f91dbd',

  space = '13a25012-a06a-51aa-bf04-9cb0476885e8',

  referencedBy = '041ee9aa-54cd-5ec2-9333-ef9f4bf21016',
  children = 'e88485ce-e96c-50a8-949e-17c908692e32',
  descendants = 'cae86647-bafc-502a-bb40-1a8e5dabdcfa',

  backlinks = 'af16f066-6f9c-57c0-8698-5c8c1e3bd49f',

  embedded = 'c8a1567f-50ca-54d3-939e-d9d58e09ed6a',

  group = '0c8ab8f4-7c4d-5bca-b8fe-5d7a63b78cbd'
}
interface ExpressionEntryBase {
  _id?: string;
  type: ExpressionEntryType;
}
interface GroupEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.group;
  conjunction: Conjuction;
  entries: ExpressionEntry[];
}
interface AttributeEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.attribute;
  attribute: string;
  operator: any;
}
interface TagsEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.tags;
  operator: any;
}
interface SpaceEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.space;
  operator: any;
}
interface ChildrenEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.children;
  entity: string;
}
interface DescendantsEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.descendants;
  entity: string;
  includeSelf: boolean;
}
export enum TypeOperator {
  is = '7e7e3250-d1f9-56d7-9477-ed67ba411acf',
  isNot = 'a46d9eb4-4006-5ab5-abbf-a651a73a858f',

  isSubtypeOf = '72d5c28e-3e18-5504-8f71-3aad1cdaff2b',
  isNotSubtypeOf = 'fbde7926-1af0-5a1d-a3ca-318a2e4ef856',

  noType = '81b5f5fb-b7c2-5dcb-ba46-16cf247bc161',
  hasType = '88a17633-7bf5-5102-b8ad-900e4af50249'
}
interface TypeOperatorBase {
  type: TypeOperator;
}
interface TypeOperatorIs extends TypeOperatorBase {
  type: TypeOperator.is;
  typeValues: string[];
}
interface TypeOperatorIsNot extends TypeOperatorBase {
  type: TypeOperator.isNot;
  typeValues: string[];
}
interface TypeOperatorIsSubtypeOf extends TypeOperatorBase {
  type: TypeOperator.isSubtypeOf;
  typeValues: string[];
}
interface TypeOperatorIsNotSubtypeOf extends TypeOperatorBase {
  type: TypeOperator.isNotSubtypeOf;
  typeValues: string[];
}
interface TypeOperatorNoType extends TypeOperatorBase {
  type: TypeOperator.noType;
}
interface TypeOperatorHasType extends TypeOperatorBase {
  type: TypeOperator.hasType;
}
enum TextOperator {
  Is = 'e00fc8b7-b7b5-56d3-8fe1-9ea1d06ef6bd',
  IsNot = '85da2a39-9f64-512f-b382-8bf7a5d042b4',
  Contains = 'af74d060-e066-519b-9b24-1c360dcb7d35',
  DoesNotContain = '935451c8-ae2d-521f-8e57-072f668a76be',
  StartsWith = '462065f9-c51a-501e-84d9-8f0a13df24e1',
  EndsWith = 'a2b1f454-dee4-56e5-97e2-6d6300c6e3f9',
  IsEmpty = '6161fbd9-93b7-548a-9ab8-24ff9401ee64',
  IsNotEmpty = '6a2c0662-b3b7-5585-a582-951d03d3f058'
}
interface TextOperatorBase {
  type: TextOperator;
}
interface TextOperatorIs extends TextOperatorBase {
  type: TextOperator.Is;
  value: string;
}
interface TextOperatorIsNot extends TextOperatorBase {
  type: TextOperator.IsNot;
  value: string;
}
interface TextOperatorContains extends TextOperatorBase {
  type: TextOperator.Contains;
  value: string;
}
interface TextOperatorDoesNotContain extends TextOperatorBase {
  type: TextOperator.DoesNotContain;
  value: string;
}
interface TextOperatorStartsWith extends TextOperatorBase {
  type: TextOperator.StartsWith;
  value: string;
}
interface TextOperatorEndsWith extends TextOperatorBase {
  type: TextOperator.EndsWith;
  value: string;
}
interface TextOperatorIsEmpty extends TextOperatorBase {
  type: TextOperator.IsEmpty;
}
interface TextOperatorIsNotEmpty extends TextOperatorBase {
  type: TextOperator.IsNotEmpty;
}
enum DateOperator {
  Is,
  IsBefore,
  IsAfter,
  IsOnOrBefore,
  IsOnOrAfter,
  IsBetween,
  IsEmpty,
  IsNotEmpty
}
export enum SetValuesOperator {
  Contains = '8cdf1620-e762-5f89-afcd-48831ae02a2f',
  DoesNotContain = '2d6df722-4ab3-5428-b385-8b7c97d61932',
  IsEmpty = 'd3e9fdb7-ad9a-55e4-9b62-865e87770fc0',
  IsNotEmpty = '4ecd7312-a426-58be-a9a0-99623a5826c2',
}
interface SetValuesOperatorBase {
  type: SetValuesOperator;
}
interface SetValuesOperatorContains extends SetValuesOperatorBase {
  type: SetValuesOperator.Contains;
  values: string[];
}
interface SetValuesOperatorDoesNotContain extends SetValuesOperatorBase {
  type: SetValuesOperator.DoesNotContain;
  values: string[];
}
interface SetValuesOperatorIsEmpty extends SetValuesOperatorBase {
  type: SetValuesOperator.IsEmpty;
}
interface SetValuesOperatorIsNotEmpty extends SetValuesOperatorBase {
  type: SetValuesOperator.IsNotEmpty;
}
export enum SetValueOperator {
  Is = '340fc824-2012-56be-8c8c-2ad6aeb5125a',
  IsNot = '0b9403c7-8f04-5d0b-a06b-31f5506fcc84',
  IsEmpty = 'b5484316-4176-5e40-9312-f7c196251f30',
  IsNotEmpty = 'c86b1b22-d0e1-57ad-848b-c0e44a6d4f02'
}
interface SetValueOperatorBase {
  type: SetValueOperator;
}
interface SetValueOperatorIs extends SetValueOperatorBase {
  type: SetValueOperator.Is;
  values: string[];
}
interface SetValueOperatorIsNot extends SetValueOperatorBase {
  type: SetValueOperator.IsNot;
  values: string[];
}
interface SetValueOperatorIsEmpty extends SetValueOperatorBase {
  type: SetValueOperator.IsEmpty;
}
interface SetValueOperatorIsNotEmpty extends SetValueOperatorBase {
  type: SetValueOperator.IsNotEmpty;
}
export enum StructuredSetValueOperator {
  Is = '2329b195-5abf-5192-a501-82d10ddb3ff5',
  IsNot = '77b7ce00-af8f-5a97-9166-71676b1be61e',
  IsEmpty = 'b2fad720-5793-5f0f-9971-ab9737282bcc',
  IsNotEmpty = '25d5a527-2c70-5260-9d03-5e662093bbc5'
}
interface StructuredSetValueOperatorBase {
  type: StructuredSetValueOperator;
}
interface StructuredSetValueOperatorIs extends StructuredSetValueOperatorBase {
  type: StructuredSetValueOperator.Is;
  values: string[];
}
interface StructuredSetValueOperatorIsNot extends StructuredSetValueOperatorBase {
  type: StructuredSetValueOperator.IsNot;
  values: string[];
}
interface StructuredSetValueOperatorIsEmpty extends StructuredSetValueOperatorBase {
  type: StructuredSetValueOperator.IsEmpty;
}
interface StructuredSetValueOperatorIsNotEmpty extends StructuredSetValueOperatorBase {
  type: StructuredSetValueOperator.IsNotEmpty;
}
enum BoolValueOperator {
  is,
  isNot
}
enum NumberValueOperator {
  equals,
  notEquals,
  lessThan,
  lessThanOrEqual,
  greaterThan,
  greaterThanOrEqual,
  between,
  notBetween,
  isEmpty,
  isNotEmpty
}
interface TypeEntry extends ExpressionEntryBase {
  type: ExpressionEntryType.type;
  operator: any;
}
enum OperatorType {
  text,
  number,
  date,
  setValues,
  setValue,

  structuredSetValue,

  boolValue,
  type
}
type ExpressionEntry = GroupEntry | AttributeEntry | TypeEntry | SpaceEntry | ChildrenEntry | DescendantsEntry | TagsEntry;
export enum Conjuction {
  and = 'bd705124-e9af-5cf3-8b0b-23d020f2b9a5',
  or = '14b2bcb4-b960-52fc-86ef-3668bae44adc'
}
export function renderEntry(entry: ExpressionEntry, scopes) {
  if (entry.type == ExpressionEntryType.group) {
    return (
      <GroupEntryComp entry={entry as any} scopes={scopes} />
    );
  }
  else if (entry.type == ExpressionEntryType.attribute) {
    return (
      <AttributeEntryComp entry={entry as any} scopes={scopes} />
    );
  }
  else if (entry.type == ExpressionEntryType.type) {
    return (
      <TypeEntryComp entry={entry as any} scopes={scopes} />
    );
  }
  else if (entry.type == ExpressionEntryType.space) {
    return (
      <SpaceEntryComp entry={entry as any} />
    )
  }
  else if (entry.type == ExpressionEntryType.tags) {
    return (
      <TagsEntryComp entry={entry as any} />
    )
  }

  else if (entry.type == ExpressionEntryType.children) {
    return (
      <ChildrenEntryComp entry={entry as any} />
    )
  }
  else if (entry.type == ExpressionEntryType.descendants) {
    return (
      <DescendantsEntryComp entry={entry as any} />
    )
  }
  return (
    <div>
      Unknown entry type: {(entry as any).type}
    </div>
  );
}

@component
class Entry extends Component<{ entry: ExpressionEntry; onDelete?; scopes }> {
  static styles = styled.div`
    position: relative;
    padding-right: 32px;
    /* display: flex; */
    /* align-items: center; */
    > .options {
      position: absolute;
      right: 0;
      top: 0;

      svg {
        width: 16px;
        height: 16px;
        fill: #bababa;
      }
    }
  `;
  render() {
    return (
      <>
        {renderEntry(this.props.entry, this.props.scopes)}
        <div className="options">
          <Svg name="dots" 
                      onClick={e => {
                        showContextMenu(e, [
                          this.props.onDelete && {
                            text: 'Delete',
                            onClick: () => {
                              this.props.onDelete();
                            }
                          }
                        ].filter(Boolean), true);
                      }}
          
          />

        </div>

      </>
    );
  }
}
function createOperator(type: OperatorType) {
  if (type == OperatorType.text) {
    return {
      type: TextOperator.Is,
    };
  }
  else if (type == OperatorType.number) {
    return {
      type: NumberValueOperator.equals,
    };
  }
  else if (type == OperatorType.date) {
    return {
      type: DateOperator.Is,
    };
  }
  else if (type == OperatorType.setValues) {
    return {
      type: SetValuesOperator.Contains,
      values: [],
    };
  }
  else if (type == OperatorType.setValue) {
    return {
      type: SetValueOperator.Is,
      values: [],
    };
  }
  else if (type == OperatorType.boolValue) {
    return {
      type: BoolValueOperator.is,
    };
  }
  else if (type == OperatorType.type) {
    return {
      type: TypeOperator.is,
    };
  }

  return {};
}
function create(type: ExpressionEntryType) {
  if (type == ExpressionEntryType.group) {
    return {
      type,
      conjunction: Conjuction.and,
      entries: [],
    } as GroupEntry;
  }
  else if (type == ExpressionEntryType.type) {
    return {
      type,
      operator: createOperator(OperatorType.type),
    };
  }
  else if (type == ExpressionEntryType.space) {
    return {
      type,
      OperatorType: createOperator(OperatorType.setValue),
    };
  }
  else {
    return {
      type,
    };
  }
}

@component
class GroupEntryComp extends Component<{ entry: GroupEntry; scopes }> {
  static styles = styled.div`
    /* border: 1px solid black;
    padding: 8px;
    border-radius: 8px; */
    ${Entry} {
      margin-bottom: ${spacing};

      &:not(:first-child) {
      }
    }
    padding: 8px;

    background: rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    box-shadow: rgb(55 53 47 / 10%) 0px 0px 0px 1px;
    margin: 1px;
    align-self: stretch;
  `;

  render(Container?) {
    return (
      <Container key={this.props.entry._id}>
        {this.props.entry.entries.map(entry => {
          return (
              <Entry
                key={entry._id}
              entry={entry}
                onDelete={() => {
                  this.props.entry.entries.splice(this.props.entry.entries.indexOf(entry), 1);
                }}
                scopes={this.props.scopes}
                />
          );
        })}
        <select
          onChange={e => {
            this.props.entry.entries.push(XObject.obj(create(e.target.value as any)));
            e.target.value = '';
          }}
        >
          <option value="">Add entry...</option>
          {(_.keys(ExpressionEntryType) as any).map((key) => {
            return (
              <option key={ExpressionEntryType[key]} value={ExpressionEntryType[key]}>{_.startCase(key)}</option>
            );
          })}
        </select>
      </Container>
    );
  }
}

@component
class SetValuesOperatorComp extends Component<{ operator; meta; }> {
  static styles = styled.span`
    ${layoutCss}
  `;

  render() {
    const typeSelector = [
      SetValuesOperator.Contains,
      SetValuesOperator.DoesNotContain,
    ].includes(this.props.operator.type);
    return (
      <>

        <OperatorTypeSelector
          value={this.props.operator.type}
          setValue={v => {
            this.props.operator.type = v;
          }}
          types={[
            [SetValuesOperator.Contains, 'Contains'],
            [SetValuesOperator.DoesNotContain, 'Does not contain'],
            [SetValuesOperator.IsEmpty, 'Is empty'],
            [SetValuesOperator.IsNotEmpty, 'Is not empty'],
          ]}
        />
        {/* <select
          value={this.props.operator.type || ''}
          onChange={e => {
            this.props.operator.type = e.target.value as any;
          }}
        >
          <option />
          <option value={SetValuesOperator.Contains}>Contains</option>
          <option value={SetValuesOperator.DoesNotContain}>Does not contain</option>
          <option value={SetValuesOperator.IsEmpty}>Is empty</option>
          <option value={SetValuesOperator.IsNotEmpty}>Is not empty</option>
        </select> */}
        {typeSelector && (
          <SelectButton
            content={<Cell
            title={'Values'}
            disableClick
              cell={new MultiSelectCellType({
                options: this.props.meta.options
              })}
              get={() => XObject.get(this.props.operator, 'values', [])}
              set={v => {
                this.props.operator.values = v;
              }} />}
          />
          
        )}


      </>
    );
  }
}

@component
class SetValueOperatorComp extends Component<{ operator; meta; }> {
  static styles = styled.span`
    ${layoutCss}
  `;

  render() {
    const typeSelector = [
      SetValueOperator.Is,
      SetValueOperator.IsNot,
    ].includes(this.props.operator?.type);
    return (
      <>
        <OperatorTypeSelector
          value={this.props.operator?.type}
          setValue={v => {
            this.props.operator.type = v;
          }}
          types={[
            [SetValueOperator.Is, 'Is'],
            [SetValueOperator.IsNot, 'Is not'],
            [SetValueOperator.IsEmpty, 'Is empty'],
            [SetValueOperator.IsNotEmpty, 'Is not empty'],
          ]}
        />
        {/* <select
          value={this.props.operator.type || ''}
          onChange={e => {
            this.props.operator.type = e.target.value as any;
          }}
        >
          <option />
          <option value={SetValueOperator.IsNot}>is not</option>
          <option value={SetValueOperator.Is}>is</option>
          <option value={SetValueOperator.IsEmpty}>is empty</option>
          <option value={SetValueOperator.IsNotEmpty}>is not empty</option>
        </select> */}


        {typeSelector && (
          <SelectButton
            content={
<Cell
  title="Values"
            disableClick
            cell={new MultiSelectCellType(this.props.meta || {})}
            get={() => XObject.get(this.props.operator, 'values', [])}
            set={v => {
              this.props.operator.values = v;
            }} />
            }
          />
          
        )}


      </>
    );
  }
}

@component
class StructuredSetValueOperatorComp extends Component<{ operator; meta; }> {
  static styles = styled.span`
    ${Cell} {
      display: inline-block;
      &:hover {
        background: #eee;
      }
    }
  `;

  render() {
    const typeSelector = [
      StructuredSetValueOperator.Is,
      StructuredSetValueOperator.IsNot,
    ].includes(this.props.operator.type);

    return (
      <>
        <OperatorTypeSelector
          value={this.props.operator.type}
          setValue={v => {
            this.props.operator.type = v;
          }}
          types={[
            [StructuredSetValueOperator.Is, 'Is'],
            [StructuredSetValueOperator.IsNot, 'Is not'],
            [StructuredSetValueOperator.IsEmpty, 'Is empty'],
            [StructuredSetValueOperator.IsNotEmpty, 'Is not empty'],
          ]}
        />
        {/* <select
          value={this.props.operator.type || ''}
          onChange={e => {
            this.props.operator.type = e.target.value as any;
          }}
        >
          <option />
          <option value={StructuredSetValueOperator.IsNot}>is not</option>
          <option value={StructuredSetValueOperator.Is}>is</option>
          <option value={StructuredSetValueOperator.IsEmpty}>is empty</option>
          <option value={StructuredSetValueOperator.IsNotEmpty}>is not empty</option>
        </select> */}


        {typeSelector && (
          <SelectButton
            content={
<Cell
  title="Values"
disableClick
            cell={new $GroupedSelectAttribute_Multi_CellType({
              valuePoint: this.props.meta.valuePoint,
            })}
            get={() => XObject.get(this.props.operator, 'values', [])}
            set={v => {
              this.props.operator.values = v;
            }} />
            }
          />
          
        )}


      </>
    );
  }
}

@component
class TypeOperatorComp extends Component<{ operator: any; meta }> {
  static styles = styled.span`
    ${layoutCss}
  `;
  render() {
    const typeSelection = [
      TypeOperator.is,
      TypeOperator.isNot,
      TypeOperator.isNotSubtypeOf,
      TypeOperator.isSubtypeOf,
    ].includes(this.props.operator.type);
    return (
      <>
        <OperatorTypeSelector
          value={this.props.operator.type}
          setValue={v => {
            this.props.operator.type = v;
          }}
          types={[
            [TypeOperator.is, 'is'],
            [TypeOperator.isNot, 'is not'],
            [TypeOperator.hasType, 'has type'],
            [TypeOperator.noType, 'no type'],
            [TypeOperator.isSubtypeOf, 'is subtype of'],
            [TypeOperator.isNotSubtypeOf, 'is not subtype of'],
          ]}
        />
        {typeSelection && (
          <SelectButton
              content={<Cell
              title="Values"
              disableClick
                cell={new EntityTypesCellType(this.props.meta)}
                get={() => {
                  return XObject.get(this.props.operator, 'typeValues', []);
                }}
                set={value => {
                  this.props.operator.typeValues = value;
                }}
              />}
          />
          
        )}
      </>
    );
  }
}
function renderOperator(type: OperatorType, state, meta) {
  if (type == OperatorType.text) {
  }
  else if (type == OperatorType.setValue) {
    return (
      <SetValueOperatorComp operator={state} meta={meta} />
    );
  }
  else if (type == OperatorType.setValues) {
    return (
      <SetValuesOperatorComp operator={state} meta={meta} />
    );
  }

  else if (type == OperatorType.type) {
    return (
      <TypeOperatorComp operator={state} meta={meta} />
    );
  }
  else if (type == OperatorType.structuredSetValue) {
    return (
      <StructuredSetValueOperatorComp operator={state} meta={meta} />
    );
  }
}

@component
class AttributeEntryComp extends Component<{ entry: AttributeEntry; scopes }> {
  static styles = styled.div`
    ${layoutCss}
  `;
  render() {
    const attribute = db.attributeTypes.find(a => a._id == this.props.entry.attribute);
    let operatorType: OperatorType;
    let meta;
    if (attribute) {
      if (attribute.type == AttributeType.text) {
        operatorType = OperatorType.text;
      }
      else if (attribute.type == AttributeType.entities) {
        operatorType = OperatorType.setValues;
        meta = {
          options: [
            {
              _id: CHAINED_ENTITY,
              title: 'Chained Entity',
            }
          ].concat(db.entities.filter(e => _.isString(e.name)).map(e => {
            return {
              _id: e._id,
              title: e.name,
            };
          })),
        };
      }
      else if (attribute.type == AttributeType.entity) {
        operatorType = OperatorType.setValue;
        meta = {
          options: [
            {
              _id: CHAINED_ENTITY,
              title: 'Chained Entity',
            }
          ].concat(db.entities.filter(e => _.isString(e.name)).map(e => {
            return {
              _id: e._id,
              title: e.name,
            };
          })),
        };
      }
      else if (attribute.type == AttributeType.select) {
        operatorType = OperatorType.setValue;
        meta = {
          options: attribute.options,
          addOption: (name) => {
            const option = XObject.obj({
              title: name,
            })
            XObject.push(attribute, 'options', option);
            return option._id;
          },
  
        };

      }
      else if (attribute.type == AttributeType.switch && this.props.entry.operator?.attrKey) {
        const value = mapStructure(execute(attribute.valuePoint));
        const entry = value.entries.content.find(e => e._id == this.props.entry.operator.attrKey);
        const content = mapStructure(entry).content;

        if (content?.type?.[1] == $GroupedSelectAttribute.$) {
          operatorType = OperatorType.structuredSetValue;
          meta = {
            valuePoint: content._id,
          };
        }
      }
    }

    if (!meta) {
      meta = {};
    }

    // meta.scopes = this.props.scopes;

    return (
      <>
        {!this.props.entry.attribute && <AttributeSelector
          onSelect={value => {
            this.props.entry.attribute = value;
            let operatorType: OperatorType;
            const attribute = db.attributeTypes.find(a => a._id == this.props.entry.attribute);

            if (attribute) {
              if (attribute.type == AttributeType.text) {
                operatorType = OperatorType.text;
              }
              else if (attribute.type == AttributeType.entities) {
                operatorType = OperatorType.setValues;
              }
              else if (attribute.type == AttributeType.entity) {
                operatorType = OperatorType.setValue;
              }
            }

            this.props.entry.operator = X(createOperator(operatorType));


          }}
          scopes={this.props.scopes}
        />}

        {this.props.entry.attribute && <SelectButton
          content={db.attributeTypes.findById(this.props.entry.attribute).name}
          _onClick={e => {
            showContextMenu(e, [
              {
                text: 'Clear',
                onClick: () => {
                  delete this.props.entry.attribute;
                }
              },
            ]);

          }}
        />} {(() => {
          if (attribute?.type == AttributeType.switch) {
            const value = mapStructure(execute(attribute.valuePoint));
            return (
              <select
                value={this.props.entry?.operator?.attrKey || ''}
                onChange={e => {
                  this.props.entry.operator.attrKey = e.target.value;
                }}
              >
                <option />
                {value.entries.content.map(entry => {
                  const mapped = mapStructure(entry);
                  return (
                    <option key={entry._id} value={entry._id}>{mapped.name.content}</option>
                  );
                })}
              </select>
            );
          }
        })()} {operatorType && renderOperator(operatorType, this.props.entry.operator, meta)}
      </>
    );
  }
}



@component
class SpaceEntryComp extends Component<{ entry: SpaceEntry; }> {
  static styles = styled.div`
    ${layoutCss}
  `;
  render() {
    return (
      <>
        <Static>Space</Static> {renderOperator(OperatorType.setValue, XObject.get(this.props.entry, 'operator', {}), {
          options: db.spaces.map(s => {
            return {
              _id: s._id,
              title: s.name,
            };
          }),
        })}
      </>
    );
  }
}


@component
class TagsEntryComp extends Component<{ entry: TagsEntry; }> {
  static styles = styled.div`
    ${layoutCss}
  `;
  render() {
    return (
      <>
        <Static>Tags</Static> {renderOperator(OperatorType.setValues, XObject.get(this.props.entry, 'operator', {}), {
          options: db.tags.map(s => {
            return {
              _id: s._id,
              title: s.name,
            };
          }),
        })}
      </>
    );
  }
}

@component
class ChildrenEntryComp extends Component<{ entry: ChildrenEntry; }> {
  render() {
    return (
      <>
        Children of <select
          value={this.props.entry.entity || ''}
          onChange={e => {
            this.props.entry.entity = e.target.value;
          }}
        >
          <option />
          <option value={CHAINED_ENTITY}>Chained Entity</option>
        </select>
      </>
    );
  }
}

@component
class DescendantsEntryComp extends Component<{ entry: DescendantsEntry; }> {
  render() {
    return (
      <>
        Descendants of <select
          value={this.props.entry.entity || ''}
          onChange={e => {
            this.props.entry.entity = e.target.value;
          }}
        >
          <option />
          <option value="b1ef080e-af65-5cd6-9a9e-6b34f67a70af">Chained Entity</option>
        </select>

        <input type="checkbox" checked={this.props.entry.includeSelf} onChange={e => {
          this.props.entry.includeSelf = e.target.checked;
        }} /> Include Self

      </>
    );
  }
}


@component
class TypeEntryComp extends Component<{ entry: TypeEntry; scopes }> {
  static styles = styled.div`
    ${layoutCss}
  `;
  render() {
    return (
      <>
        <Static>Type</Static> {renderOperator(OperatorType.type, XObject.get(this.props.entry, 'operator', {}), {
          scopes: this.props.scopes,
        })}
      </>
    );
  }
}
