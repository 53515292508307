import React from 'react';
import {component} from '../../component2';
import {defaultWorkspace} from '../../etc/defaultWorkspace';
import {ValuePointProps} from '../main';
import {Comp} from "../Comp";
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";


@component
export class EntityMetaStateValuePoint extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <select
          value={this.props.state.content || ''}
          onChange={(e) => {
            this.props.state.content = e.target.value || null;
          }}
        >
          <option value="">Any</option>
          {defaultWorkspace().metaStates.map((x, i) => <option key={i} value={x._id}>{x.name}</option>)}
        </select>
      </>
    );
  }
}

export const registerEntityMetaState = () => {
    registerType(ValueType.EntityMetaState, {
        evaluate: (value, map) => {
            return value.content;
        },
        editorComp: EntityMetaStateValuePoint
    });
};