import { compareTypes } from './compareTypes';
import { db } from '../db';
import { defaultWorkspace } from './defaultWorkspace';
import { getEdgesForEntity } from './getEdgesForEntity';
import { entityMetaStates } from './entityMatch';

function isBackground(id) {
  const metaStates = entityMetaStates(id);
  return !!metaStates.find(id => {
    const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
    if (metaState.backgroundState) return true;
  });


}

export function getGraphParent(graph, entity: string): string {
  const edges = getEdgesForEntity(entity);
  const parent = edges.find(x => x.directed && x.entities[1] == entity);
  if (parent) {
    return parent.entities[0];
  }
}

export function getPathInGraph(graph, entity, root?) {
  const path = [];
  let current = entity;
  while (current) {
    if (current == root) break;
    path.unshift(current);
    current = getGraphParent(graph, current);
  }
  return path.slice(0, -1);
}

export function resolvePath(graph: string, rootEntity: string, entity: string) {
  // return [];
  const contents = queryGraph(graph, rootEntity);
  const path: string[] = [];
  let current = entity;
  while (current) {
    path.unshift(current);
    current = contents.find(x => x.entity == current)?.parent;
  }
  return path.slice(0, -1);
}

export function getGraphMatch(graph, entity) {
  return graph?.graph?.matches?.find?.(x => !x.match?.type || compareTypes(entity.type, x.match?.type));
}

export function queryGraph(graphId: string, rootEntity: string, includeRoot = true, directDescendentsOnly = false) {
  const workspace = defaultWorkspace();
  const graph = workspace.graphs.find(x => x._id == graphId);

  function processEntity(id, level=0) {
    const entity = db.entities.findById(id);
    const match = getGraphMatch(graph, entity);
    if (!match) {
      return [];
    }
 
    const edges = getEdgesForEntity(id);
    const entities = [];
    for (const edge of edges) {
      if (!db.entities.findById(edge.entities[0]) || !db.entities.findById(edge.entities[1])) continue;

      const otherId = edge.entities.find(x => x != id);
      const other = db.entities.findById(otherId);
      for (const relMatch of match.rels) {
        if (!relMatch.entityMatch?.type || compareTypes(other.type, relMatch.entityMatch?.type)) {
          if (relMatch.direction == 'any') {
            entities.push(otherId);
          }
          else if (relMatch.direction == 'in' && edge.directed) {
            if (edge.entities[0] == otherId) {
              entities.push(otherId);
            }
          }
          else if (relMatch.direction == 'out' && edge.directed) {
            if (edge.entities[1] == otherId) {
              entities.push(otherId);
            }
          }
          // else if (relMatch.direction == 'undirected') {
        }
      }

    }

    const result = [];
    for (const entity of entities) {
      result.push({
        entity,
        parent: id,
      });

      if (!directDescendentsOnly) {
        result.splice(result.length, 0, ...processEntity(entity, level+1));
      }
    }

    return result;
  }

  const nodes: {
    entity;
    parent;
  }[] = rootEntity && includeRoot ? [
    {
      entity: rootEntity,
      parent: null,
    }
  ] : [];

  if (rootEntity) {
    nodes.splice(nodes.length, 0, ...processEntity(rootEntity));

  }

  return nodes;
}



export function queryGraphBasic(rootEntity: string, includeRoot = true, directDescendentsOnly = false, excludeBackground=false, includeDeleted=false) {
  const workspace = defaultWorkspace();

  function processEntity(id, level=0) {
    const entity = db.entities.findById(id);

    const edges = getEdgesForEntity(id).filter(x => x.directed && x.entities[0] == id);
    const entities = [];
    for (const edge of edges) {
      if (!db.entities.findById(edge.entities[0]) || !db.entities.findById(edge.entities[1])) continue;

      const entity = db.entities.findById(edge.entities[1]);
      if (!includeDeleted && entity.__deleted) continue;

      const otherId = edge.entities.find(x => x != id);
      entities.push(otherId);


    }

    const result = [];
    for (const entity of entities) {
      if (excludeBackground && isBackground(entity)) continue;
      result.push({
        entity,
        parent: id,
      });

      if (!directDescendentsOnly) {
        result.splice(result.length, 0, ...processEntity(entity, level+1));
      }
    }

    return result;
  }

  const nodes: {
    entity;
    parent;
  }[] = rootEntity && includeRoot ? [
    {
      entity: rootEntity,
      parent: null,
    }
  ] : [];

  if (rootEntity) {
    nodes.splice(nodes.length, 0, ...processEntity(rootEntity));

  }

  return nodes;
}
