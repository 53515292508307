import ReactDOM from "react-dom";
import cx from 'classnames';
import jQuery from 'jquery';
import { Data, expandToHtml } from "../components/richTextHelpers";
import { x } from "../XObject";
import { component } from "../component2";
import { Component } from "react";
import { styled } from "../component";

@component
export class RenderData extends Component<{ placeholder?; className?; ctx; data: Data; attrs?; tag?; args? }> {
  componentDidMount() {
    this.doMount();
  }

  mounted = false;
  comps: any = {};

  doMount() {
    if (!this.mounted) {
      const el = ReactDOM.findDOMNode(this);

      for (const key in this.comps) {
        const e = jQuery(el).find(`[data-mount-point="${key}"]`);
        if (!e.length) {
        }
        else {
          if (this.comps[key].mount) {
            console.log('[mount]', key, this.comps[key].mount);
            ReactDOM.render(this.comps[key].mount, e[0]);
          }
          else {
            e.replaceWith(this.comps[key].render());
          }
        }
      }

      const last = el.lastChild as any;
      // console.log(last);
      // if (last?.getAttribute?.('contenteditable') === 'false') {
        // console.log('insert blank');
        if (last) {
          last.after(document.createTextNode(''));

        }
        else {
          el.appendChild(document.createTextNode(''));
        }
      // }

      this.mounted = true;
    }
  }

  static styles = styled.span`
    .--entity-- {
      color: #71a5c4;
      /* cursor: pointer; */
    }

    &.placeholder {
      &:empty::before {
          content: '―';
      }

      &:empty:focus::before {
          content: "";
      }
    }


  `;

  componentDidUpdate(prevProps: Readonly<{ ctx: any; data: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    this.doMount();

  }
  render(Container?) {
    this.mounted = false;
    this.comps = {};
    const html = expandToHtml(this.props.ctx, x(this.props.data) || [], this.comps, this.props.args);
    return <Container as={this.props.tag} className={cx(this.props.className, {
      placeholder: this.props.placeholder
    })} {...(this.props.attrs || {})} dangerouslySetInnerHTML={{ __html: html }} />;
  }
}
