import React from 'react';
import juration from './juration';
import axios from 'axios';
import { styled } from './component';
import { db } from './db';
import { X, x } from './XObject';
import { component } from './component2';
import { RenderType, execute, getValuePoint, render } from './glue/main';
import { $EntityDocumentEntities } from './glue/structs/$EntityDocumentsEntities';
import { collectBlocks, findBlock } from './components/collectEntitiesGood';

const gComponents = window['g_components'] = {};

const state = X({ tick: 0 });

let cb;

function doQuery(queryArg, ...args) {
  const valuePoint = getValuePoint(queryArg);
  if (valuePoint?.type?.[1] == $EntityDocumentEntities.$) {
    const compiled = execute(valuePoint._id);
    const map = {};
    for (let i = 0; i < args.length; ++ i) {
      map['%' + i] = args[i];
    }
    return render(compiled, map, RenderType.entities).map(id => {
      const entity = db.entities.findById(id);
      return new Proxy({}, {
        get: (target, prop: string) => {
          if (prop.slice(0, 2) == '_$') prop = prop.slice(2, -2);
          if (db.attributeTypes.findById(prop)) {
            return entity.attributes?.[prop];
          }
        }
      })
    })
  }
}

export function getCodeComponent(id) {
  // eslint-disable-next-line
  state.tick;
  return gComponents[id];
}

window['__initComponents__'] = (...args) => {
  if (args[0]?.error) {

  }
  else {
    const [ order, main ] = args;
    const builder = main(dep => {
      return ({
        React,
        component,
        styled,
        query: doQuery,
        x: x,
        table: id => {
          const table = db.notionDocuments.findById(id);
          return table?.tableData?.rows || [];
        },
        page: id => {
          const table = db.notionDocuments.findById(id);
          return {
            blocks: (blockId, filter) => {
              return collectBlocks(table.blocks, blockId, filter);
            },
            block: blockId => {
              return findBlock(table.blocks, blockId);
            },
          }
        },
        juration,
      })[dep];
    });

    for (const id of order) {
      gComponents[id] = builder[id]?.([], gComponents);
    }


    state.tick++;

  }

  cb?.();
  cb = null;

}



export async function pushCode() {
  // const r = (await axios.post('http://localhost:3132/push-code', { components: x(db.codeComponents) })).data;
  // const s = document.createElement('script');
  // s.setAttribute('type', 'text/javascript');
  // s.setAttribute('async', 'true');
  // s.setAttribute('src', `http://localhost:3132/client-code?cb=___initComponents&id=${r}`);
  // document.body.appendChild(s);

  // return new Promise((resolve, reject) => {
  //   cb = resolve;
  // });
}


window['g_pushCode'] = pushCode;