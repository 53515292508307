import React from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';


export function renderAttributes(entity) {
  if (entity.attributes) {
    const r = [];
    for (const id in entity.attributes) {
      // const attr = entity.attributes[id]
      const attrType = db.attributeTypes.findById(id);
      r.push(<span key={id}><PropertyField object={entity.attributes} property={id} type={attrType.type} /></span>);
    }
    return r;
  }
}
