import { x } from "../XObject";

export const signalsRegistry = {};

export function registerSignalHandler(type, signal, handler) {
  const key = JSON.stringify(type);
  // add handler to registry
  signalsRegistry[key] ||= {};
  signalsRegistry[key][signal] ||= [];
  signalsRegistry[key][signal].push(handler);
}

export function sendSignal(value, signal, message) {
  const type = JSON.stringify(x(value.type));
  signalsRegistry[type] ||= {};
  signalsRegistry[type][signal] ||= [];
  const promises = [];
  let called = 0;
  for (const handler of signalsRegistry[type][signal]) {
    const p = handler(value, message);
    ++ called;
    if (p) {
      promises.push(p);
    }
  }
  if (!called) {
    console.warn(`No signal handler for signal ${signal} and type ${type}`);
  }
  return promises[0];
}
