import React from 'react';
import styled from 'styled-components';
import { component } from '../../component2';
import { x, XObject } from '../../XObject';
import { showContextMenu, SortableCont, SortableEl, SortHandle } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { Comp } from '../Comp';
import {
  _findValue,
  _iterate,
  _matches,
  addEnumToMap,
  addStructToMap,
  addValuePoint,
  CompiledValuePoint,
  evaluate,
  execute,
  render,
  resolveTypeList,
  ValuePointProps,
  ValuePointType
} from '../main';
import { Runtime } from "../Runtime";
import { registerType } from "../__typeRegistry";
import { ValueType } from "../ValueType";
import { Svg } from '../../components/Svg';
import { indentWidth } from '../borderColor';
import { NotionButton } from '../../components/AddButton';

export function pushValue(value, init={}) {
  const values = XObject.get(value, 'content', []);
  const newValuePoint = addValuePoint(XObject.obj({
    possibleTypes: resolveTypeList(x(value.type[1])),
    parent: value._id,
    ...init,
  }));
  values.push(newValuePoint);
  return newValuePoint;

}


@component
export class ArrayValuePoint extends Comp<ValuePointProps> {
  static styles = styled.div`
    > ul {
      padding-left: ${indentWidth};
      list-style-type: disc;
      /* list-style-type: none; */

    }
    > ul > li {
      position: relative;
      &:not(:hover) {
        > span > .delete {
          display: none;
        }
        > span > .dragHandle {
          display: none;
        }
      }
      > span > .delete {
        position: absolute;
        left: -18px;
        top: 1px;
      }

      > span > .dragHandle {
        position: absolute;
        left: -33px;
        top: 1px;
        cursor: grab;

      }
    }
  `;
  render() {
    const values = XObject.get(this.props.state, 'content', []);
    return (
      <>
        <SortableCont
          useDragHandle
          tag={'ul'}
          distance={10}
          onSortEnd={(sortEnd, sortEvent) => {
            const id = values[sortEnd.oldIndex];
            values.splice(sortEnd.oldIndex, 1);
            values.splice(sortEnd.newIndex, 0, id);

            // console.log(sortEnd, sortEvent);
          }}
        >
          {values.map((v, i) => (
            <SortableEl index={i} key={v._id}>
              <li>
                <SortHandle><button className="delete" onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    values.splice(i, 1);
                  }
                }}>-</button></SortHandle>

                  <SortHandle><div className="dragHandle">
                  <Svg name="grip" />
                  </div></SortHandle>
                <ValuePoint
                  id={v._id}
                  path={this.props.path && this.props.path.concat(i)}  
                />
              </li>
            </SortableEl>
          ))}
          <li><NotionButton
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Add beginning',
                  onClick: () => {
                    const newValue = addValuePoint(XObject.obj({
                      possibleTypes: resolveTypeList(x(this.props.state.type[1])),
                      parent: this.props.state._id,
                    }));

                    values.splice(0, 0, newValue);
                    // values.push(newValue);
                  },
                },
              ]);
            }}
          onClick={() => {
            pushValue(this.props.state);
          }} /></li>
        </SortableCont>
      </>
    );
  }
}

// Array
export const registerArrayType = () => {
  function executeArray(value: ValuePointType, rt: Runtime): CompiledValuePoint {
    return {
      _id: value._id,
      type: value.type,
      content: value.content?.map?.((x, i) => execute(x._id, rt.appendPath([value._id, i]))) || [],
      rt,
      isState: false,
      presentation: value.presentation,
    };
  }

  function evaluteArray(value: CompiledValuePoint, map = {}) {
    return value.content?.map?.(x => evaluate(x, map));
  }

  registerType(ValueType.Array, {
    execute: (value, rt) => {
      return executeArray(value, rt);
    },
    render: (value, map, state, renderType) => {
      return value.content.map(x => render(x, map, renderType, XObject.get(state, x._id, {})));
    },
    evaluate: (value, map) => {
      return evaluteArray(value, map);
    },
    isBlock: () => true,
    addStructToMap: field => {
      const type = field.type[1];
      if (type[0] == ValueType.Structure) {
        if (type[2]) {
          addStructToMap(type[1], { definition: type[2] });
        }
      } else if (type[0] == ValueType.Enum) {
        addEnumToMap(type[1], type[2]);
      }
    },
    findValue: (value, pattern) => {
      for (const item of value.content) {
        if (_matches(item, pattern)) {
          return item;
        } else {
          const r = _findValue(item, pattern);
          if (r) return r;
        }
      }
    },
    iterate: (value, parent, func, set) => {
      if (value.content?.length) {
        const list = value.content || [];
        for (let i = 0; i < list.length; i++) {
          const r = _iterate(list[i], value, func, value => {
            list[i] = value;
          });
          if (r !== undefined) return r;
        }
        // return _iterate(value.content, value, func);

      }
    },
    editorComp: ArrayValuePoint,
  });
};