import _ from 'lodash';
import styled from 'styled-components';
import cx from 'classnames';
import { db } from '../db';
import { showContextMenu } from '../helpers';
import { Tag } from "./Tag";
import { Component } from 'react';
import { component } from '../component';
import { SystemContext } from '../etc/SystemContext';
import { EntityName } from './EntityDisplay';
import { deleteEdge } from '../etc/getEdgesForEntity';
import { deleteEntity } from '../etc/deleteEntity';
import { entitySchema } from './attributesForType';
import { EntityPath } from './EntityPath';
import { _renderEntityElements, renderEntityElements, renderEntityRowElememnts } from './EntityInspect';
import { UserBadge } from './UserBadge';
import { getSidebar } from '../etc/getSidebar';
import { XObject } from '../XObject';
import { SidebarItemType } from '../types/SidebarItemType';
import { execute, mapStructure } from '../glue/main';
import { appState } from '../etc/appState';
import { entityStyles } from './entityStyles';
import { styleStyles } from './doneStyles';

export enum EntityViewType {
  row = '31bf10b9-6f23-51c9-8de5-4ed5b59c0277',
  block = '7cf7cfc0-ad9d-5012-8202-3c6cc6f9f832',
}

@component
export class EntityRow extends Component<{ dontHighlight?, id; path?: boolean|string; parent?; _onClick?, uiContext?, el?, farRight? }> {
  static styles = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 6px;


    &:hover {
      background: #f8f8f8;
    }
    &.active {
      background: #f2f5fe;
    }
    white-space: nowrap;

    .el {
      font-size: 10px;
      color: gray;
    }

    .rowElements {
      margin-right: 4px;
      display: flex;
      align-items: center;
    }

    .name {
${styleStyles}

      margin-right: auto;
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;

      ${EntityName} {
        white-space: nowrap;
      }

      ${EntityPath} {
        margin-left: 4px;
        color: rgba(55, 53, 47, 0.5);
        white-space: nowrap;
      }
    }

    .type {
      display: flex;
      align-items: center;
      > *{
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    .timestamp {
      font-size: 10px;
      color: rgba(55, 53, 47, 0.5);
    }

    .rightElements {
      display: flex;
      align-items: center;
      > * {
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    ${EntityPath} {
      font-size: 8px;
    }
  `;

  static contextType = SystemContext;
  context: any;

  render(Container?) {
    let valuePoint, showTimestamp, showCreator, rowElements, elements;
    const id = this.props.id;
    const n = this.props.parent;
    const entity = db.entities.findById(id);
    if (!entity) return null;
    const view = entity.type && db.entityTypes.findById(entity.type)?.views?.find?.(v => v.viewType == EntityViewType.row);
    const type = entity.type && db.entityTypes.findById(entity.type);

    if (view) {
      valuePoint = view.valuePoint;
      const mapped = mapStructure(execute(valuePoint));
      showCreator = mapped?.showCreator?.content;
      showTimestamp = mapped?.showTimestamp?.content;
      elements = _renderEntityElements(this.props.id, mapped.elements?.content || []);
    }
    else {
      const schema = entitySchema(id, {});
      elements = renderEntityElements(id);
      if (!entity) {
        return (
          <Container
            className="entityRow"
            key={id}
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Delete edge',
                  onClick: () => {
                    const edge = db.edges.find(e => e.entities.includes(n) && e.entities.includes(id));
                    if (edge)
                      deleteEdge(edge._id);
                  }
                }
              ]);
            }}
          >
            <span className="name">
              {id}
            </span>
          </Container>
        );
      }
      rowElements = renderEntityRowElememnts(id);
  
      valuePoint = schema?.match?._id;
  
      showTimestamp = schema?.showTimestamp;
      showCreator = schema?.showCreator;
  
    }
    const path = this.props.path;

    const styles = entityStyles(id);
    return (
      <Container
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', id);
        }}
        draggable
        key={id}
        className={cx("entityRow", {
          active: !this.props.dontHighlight && this.context?.next?.()?.id == id,
        })}
        data-value-point={valuePoint}
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Delete edge',
              onClick: () => {
                const edge = db.edges.find(e => e.entities.includes(n) && e.entities.includes(id));
                if (edge)
                  deleteEdge(edge._id);
              }
            },
            {
              text: 'Delete entity',
              onClick: () => {
                deleteEntity(id
                );
              }
            },
            {
              text: 'Add to sidebar',
              onClick: () => {
                getSidebar().children.push(XObject.obj({
                  type: SidebarItemType.entity,
                  id: id,
                }))
              },
            }
          ]);
        }}
        onClick={e => {
          e.stopPropagation();
          if (this.props._onClick) {
            this.props._onClick();
          }
          else {
            appState.inspecting = {
              type: 'entity',
              id: id,
            }
            this.context?.navigate?.({
              type: 'entity',
              id: id,
            });
  
          }
        }}
        data-ui-binding={JSON.stringify({
          viewType: EntityViewType.row,
          entity: id,
          context: this.props.uiContext,
        })}
      >
        {rowElements?.length > 0 && <span className="rowElements">{rowElements}</span>}
        <span className={cx('name', styles.map(s => '_' + s))}>
          <EntityName id={id} /> {path && <EntityPath entity={id} root={_.isString(this.props.path) && this.props.path} />}
        </span>
        <span className="type">
          <span className="rightElements">
            {this.props.el && <span className="el">{this.props.el}</span>}
            {elements}
            </span>
          {showTimestamp && entity.meta?.creation?.timestamp && (
            <span className="timestamp">{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
          )}
          {type && <Tag text={type.name} />}
          {showCreator && entity.meta?.creation?.user && <UserBadge user={entity.meta?.creation?.user} />}

          {this.props.farRight && <span className="rightElements">{this.props.farRight}</span>}
        </span>
      </Container>
    );
  }
}
