import { Svg } from "../components/Svg";
import { component, styled } from "../component2";
import { Component } from "react";
import { color } from "../components/notionDocument/color";



@component
export class CheckBox extends Component<{
  checkbox: {
    binding;
  }; block; _onChange?; _onBeforeChange; dataValuePoint?;
}> {
  static styles = styled.span`
    user-select: none;
    width: 14px;
    height: 14px;
    display: inline-block;
    cursor: pointer;
    svg {
      display: block;
    }

    transition: background 200ms ease-out 0s;

    &:hover {
      background: ${color('checkbox.hover.bg')};
    }

    &[data-checked="true"] {
      width: 14px;
      height: 14px;
      background: #5aa5ef;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 10px;
        height: 10px;
        polygon {
          fill: white;
        }
        background: #5aa5ef;
        transition: background 200ms ease-out 0s;

      }
    }

    &:not([data-checked="true"]) {
      svg path {
        fill: ${color('text')};
      }
    }

  `;
  render(Container?) {
    const { checkbox, block } = this.props;
    const checked = checkbox ? checkbox.binding.get(block.id) : block.checked;
    return <Container
      data-value-point={this.props.dataValuePoint}
      data-checked={checked}
      onClick={e => {
        if (checkbox) {
          checkbox.binding.set(block.id, !checkbox.binding.get(block.id));
          this.props._onChange?.();
        }
        else {
          this.props._onBeforeChange?.();
          block.checked = !block.checked;
          this.props._onChange?.();
        }
      }}
      contentEditable={false}
    >
      {checked ? <Svg name="checked" /> : <Svg name="unchecked" />}
    </Container>;
  }
}
